import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ListLoading } from '../../../../modules/view/ListLoading'
import Swal from 'sweetalert2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { freezeCard } from '../../core/_requests'
import format from 'date-fns/format'
import { useFunnelDispatch } from '../../../funnel/core/FunnelContext'
import { useCardFreezeModalDispatch } from '../../core/CardFreezeModalContext'

type Props = {
  opportunity: any
}

type OptionSelect = {
  label: string,
  value: any
}

const editDataSchema = Yup.object().shape({

})

const CardFreezeModalForm: FC<Props> = ({ opportunity }) => {

  const funnelDispatch = useFunnelDispatch();
  const cardFreezeModalDispatch = useCardFreezeModalDispatch();

  const [dataForEdit] = useState<any>({
    data: new Date(),
    hora: new Date()
  })

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      if (!values.data || !values.hora) Swal.fire({
        title: 'Opss..',
        text: 'Preencha os Campos corretamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      })

      try {

        let datetime = format(values.data, 'yyyy-MM-dd') + " " + format(values.hora, 'HH:mm:ss');

        let opUpdated = await freezeCard(opportunity.id, { freezeUntilDate: datetime });

        if(funnelDispatch)
        {
          if (opUpdated) {
            opportunity.status = opUpdated.status;
          }

          funnelDispatch({
            type: "update-step-card",
            data: opportunity
          })
        }
        
        cardFreezeModalDispatch({
          type: "close-modal"
        })

        Swal.fire('Oportunidade congelada!', '', 'success')

      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao congelar oportunidade.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_freeze_opportunity' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_freeze_opportunity_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_freeze_opportunity_header'
          data-kt-scroll-wrappers='#kt_modal_freeze_opportunity_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <DatePicker
            {...formik.getFieldProps('data')}
            label="Data"
            className='mb-7 mt-2'
            value={formik.values.data}
            onChange={value =>
              formik.setFieldValue("data", value)
            }
          />

          <MobileTimePicker
            value={formik.values.hora}
            onChange={value =>
              formik.setFieldValue("hora", value)
            }
            label="Hora"
            //disablePast={true}
            localeText={{ cancelButtonLabel: "Cancelar", okButtonLabel: "OK", toolbarTitle: "Selecionar Hora" }}
          />

        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Congelar</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting) && <ListLoading />}
    </>
  )
}

export { CardFreezeModalForm }
