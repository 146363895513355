import React, {useState} from 'react'

interface FieldProps {
  title: string
  value: any
  setControlFormEdit: any
  field: string
  controlFormEdit: any
}

const BoxEditable: React.FC<FieldProps> = ({
  title,
  value,
  setControlFormEdit,
  field,
  controlFormEdit,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <>
      {/* <div style={{ borderBottom: '1px dashed #000'}}>
      
    </div> */}
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className='list-custom-wl'
        onClick={() => {
          setControlFormEdit({
            field:field
          })
          // let data = {...controlFormEdit}
          // data.fields[field].editable = true
          // setControlFormEdit(data)
        }}
      >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{whiteSpace:"pre-line",wordBreak:"break-word"}}>
            <strong>{title}</strong>
            <p>{value ? value : 'Não informado'}</p>
          </div>
          {isHovered && (
            <div>
              <i
                className='fas fa-edit'
                style={{
                  cursor: 'pointer',
                  fontSize: '17px',
                  position: 'relative',
                  top: '-2px',
                  color: isHovered ? '#FFFFFF' : '#93a4ee',
                }}
              ></i>
            </div>
          )}
          {/*!isHovered && (
            <div
              style={{
                color: '#93a4ee',
                fontWeight: '700',
                fontSize: '9px',
                position: 'relative',
                top: '-5px',
                
              }}
            >
              clique para editar
            </div>
            )*/}
        </div>
      </div>
    </>
  )
}

export default BoxEditable
