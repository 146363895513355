import React from 'react'

type StepProps = {
  label: string
  children: React.ReactNode
}

const Step: React.FC<StepProps> = ({label, children}) => {
  return (
    <div style={{margin: 10}}>
      {/* <h5 style={{marginTop: 10, marginBottom: 10}}>{label}</h5> */}
      <div>{children}</div>
    </div>
  )
}

export default Step
