import {createContext, useContext, useReducer} from 'react'

export interface TeamFormModalContextModel {
  open: boolean
  form_action: string | null
  TeamId: number | null
}

const initialData = {
  open: false,
  TeamId: null,
  form_action: null,
}

const TeamFormModalContext = createContext<TeamFormModalContextModel>(initialData)
const TeamFormModalDispatchContext = createContext<any>(null)

export function TeamFormModalProvider({children}: any) {
  const [data, dispatch] = useReducer(TeamFormModalReducer, initialData)

  return (
    <TeamFormModalContext.Provider value={data}>
      <TeamFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </TeamFormModalDispatchContext.Provider>
    </TeamFormModalContext.Provider>
  )
}

export function useTeamFormModal() {
  return useContext(TeamFormModalContext)
}

export function useTeamFormModalDispatch() {
  return useContext(TeamFormModalDispatchContext)
}

function TeamFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {...data, open: true, form_action: 'add'}
    }
    case 'open-edit-modal': {
      return {...data, open: true, TeamId: action.data, form_action: 'edit'}
    }
    case 'close-modal': {
      return {...data, open: false, TeamId: null, form_action: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
