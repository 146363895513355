import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { initialQueryState } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../../../../modules/view/QueryRequestProvider'
import { useQueryResponse } from '../../../../../modules/view/QueryResponseProvider'
import { InputFieldSet } from '../../../../../../_metronic/partials/inputs/InputFieldSet'

const ImportFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [status, setStatus] = useState<string | undefined>()


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { status },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      {/* <button
        disabled={isLoading}
        type='button'
        className='btn btn-sm btn-wl-custom-primary-collor-inverse me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
       <i className="fa-solid fa-filter fs-2"></i>
        Filtros
      </button> */}

      <div className='me-3'></div>

      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

          {/* <div className='mb-10'>
            <InputFieldSet
              label={"Status"}
            >
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-sm  fieldset-input'
                defaultValue=''
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value=''>Todos</option>
                <option value='1'>Ativo</option>
                <option value='0'>Inativo</option>
              </select>

            </InputFieldSet>
          </div> */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              //onClick={resetData}
              className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Limpar
            </button>
            <button
              disabled={isLoading}
              type='button'
              //onClick={filterData}
              className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Aplicar
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ImportFilter }
