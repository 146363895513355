import { useListView } from '../../../../modules/view/ListViewProvider'
import { KTIcon } from '../../../../../_metronic/helpers'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import { Dropdown1 } from '../../../../../_metronic/partials'
import {CompaniesListSearchComponent} from './CompaniesListSearchComponent'
import {CompaniesListFilter} from './CompaniesListFilter'
import {  useCompanyFormModalDispatch } from '../../core/CompanyFormModalContext'

const CompaniesListHeader = () => {

  const companyFormModalDispatch = useCompanyFormModalDispatch()

  const openAddModal = () => {
    companyFormModalDispatch({
      type: 'open-add-modal'
    })
  }
  
  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
    <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid">

      <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className="page-title d-flex flex-wrap me-3 flex-row justify-content-center">
        <i className="fa-solid fa-filter fs-2 me-1"></i><h3><strong>Empresas</strong></h3>
      </div>

      <div className='d-flex align-items-center gap-2 gap-lg-3'>

      <CompaniesListSearchComponent />

      <CompaniesListFilter />

        <a
          href='#'
          onClick={openAddModal}
          className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
        >
          Adicionar
        </a>
      </div>
    </div>
  </div>
  )
}

export { CompaniesListHeader }
