import {useEffect, useState} from 'react'
import {HoursInterval} from '../core/_models'
import {Form} from 'react-bootstrap'
import {format} from 'date-fns'
import {MobileTimePicker} from '@mui/x-date-pickers'
import styled from 'styled-components'

const StyledMobileTimePicker = styled(MobileTimePicker)`
  & .MuiInputBase-input {
    height: 18px;
    padding: 6px 0;
    padding-left: 14px;
    font-size: 14px;
  }

  & .MuiInputBase-root {
    height: auto;
    min-height: 32px;
  }
`

const daysOfWeek: DayOfWeek[] = [
  {id: 1, name: 'Domingo', shortName: 'dom'},
  {id: 2, name: 'Segunda-feira', shortName: 'seg'},
  {id: 3, name: 'Terça-feira', shortName: 'ter'},
  {id: 4, name: 'Quarta-feira', shortName: 'qua'},
  {id: 5, name: 'Quinta-feira', shortName: 'qui'},
  {id: 6, name: 'Sexta-feira', shortName: 'sex'},
  {id: 7, name: 'Sábado', shortName: 'sab'},
]

type DayOfWeek = {
  id: number
  name: string
  shortName: string
}

interface Props {
  setState: (state: any) => void
  switchTitle: string
  intervals?: any
}

interface Intervals {
  seg: HoursInterval[]
  ter: HoursInterval[]
  qua: HoursInterval[]
  qui: HoursInterval[]
  sex: HoursInterval[]
  sab: HoursInterval[]
  dom: HoursInterval[]
}

const initialIntervals = {
  seg: [],
  ter: [],
  qua: [],
  qui: [],
  sex: [],
  sab: [],
  dom: [],
}

const RestrictedInvervals = (props: Props) => {
  const [intervals, setIntervals] = useState<Intervals | null>(orderIntervals())

  useEffect(() => {
    props.setState(intervals)
  }, [intervals])

  useEffect(() => {}, [props.intervals])

  function orderIntervals() {
    if (props.intervals !== null) {
      //order intervals
      const orderedIntervals: Intervals = {
        seg: [],
        ter: [],
        qua: [],
        qui: [],
        sex: [],
        sab: [],
        dom: [],
      }

      //order days
      Object.keys(props.intervals).forEach((key) => {
        const interval = props.intervals[key as keyof Intervals]

        orderedIntervals[key as keyof Intervals] = interval
      })

      return orderedIntervals
    }

    return null
  }

  function convertHourToDate(hour: string) {
    const date = new Date()
    date.setHours(parseInt(hour.split(':')[0]))
    date.setMinutes(parseInt(hour.split(':')[1]))
    return date
  }

  return (
    <>
      <div className='fv-row mb-7'>
        <Form.Check type={'switch'} reverse style={{textAlign: 'left', marginTop: '14px'}}>
          <Form.Check.Label style={{color: 'black'}}>
            <strong>{props.switchTitle}</strong>
          </Form.Check.Label>
          <Form.Check.Input
            // value={intervals === null ? 'true' : 'false'}
            checked={intervals !== null}
            type={'checkbox'}
            onChange={(e: any) => {
              if (!e.target.checked) {
                setIntervals(null)
              } else {
                setIntervals(initialIntervals)
              }
            }}
            onBlur={() => {}}
          />
        </Form.Check>
      </div>
      {intervals !== null
        ? Object.keys(intervals).map((key) => {
            const interval = intervals[key as keyof Intervals]
            return (
              <>
                <div className='fv-row mb-7'>
                  <Form.Check type={'checkbox'} style={{textAlign: 'left', marginTop: '14px'}}>
                    <Form.Check.Label style={{color: 'black'}}>
                      {daysOfWeek.find((day) => day.shortName === key)?.name}
                    </Form.Check.Label>
                    <Form.Check.Input
                      // value={interval.length ? 'true' : 'false'}
                      checked={interval.length > 0}
                      type={'checkbox'}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          const newInterval = [...interval, {startAt: '08:00', endAt: '18:00'}]
                          setIntervals({...intervals, [key]: newInterval})
                        } else {
                          setIntervals({...intervals, [key]: []})
                        }
                      }}
                      onBlur={() => {}}
                    />
                  </Form.Check>
                </div>
                {interval.map((hour, index) => {
                  const hourStartAt = convertHourToDate(hour.startAt)
                  const hourEndAt = convertHourToDate(hour.endAt)

                  return (
                    <div className='fv-row mb-7 d-flex justify-content-around'>
                      <StyledMobileTimePicker
                        value={hourStartAt}
                        onChange={(value: any) => {
                          const newInterval = interval.map((e, i) => {
                            if (i === index) {
                              return {...e, startAt: format(value, 'HH:mm')}
                            }
                            return e
                          })
                          setIntervals({...intervals, [key]: newInterval})
                        }}
                        label={
                          <>
                            {'Hora inicial'}
                            <i
                              className='fa-solid fa-asterisk'
                              style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                            ></i>
                          </>
                        }
                        //disablePast={true}
                        localeText={{
                          cancelButtonLabel: 'Cancelar',
                          okButtonLabel: 'OK',
                          toolbarTitle: 'Selecionar Hora',
                        }}
                      />
                      <StyledMobileTimePicker
                        value={hourEndAt}
                        onChange={(value: any) => {
                          const newInterval = interval.map((e, i) => {
                            if (i === index) {
                              return {...e, endAt: format(value, 'HH:mm')}
                            }
                            return e
                          })
                          setIntervals({...intervals, [key]: newInterval})
                        }}
                        minTime={hourStartAt}
                        label={
                          <>
                            {'Hora final'}
                            <i
                              className='fa-solid fa-asterisk'
                              style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                            ></i>
                          </>
                        }
                        //disablePast={true}
                        localeText={{
                          cancelButtonLabel: 'Cancelar',
                          okButtonLabel: 'OK',
                          toolbarTitle: 'Selecionar Hora',
                        }}
                      />
                      {index === 0 ? (
                        <a
                          href='#'
                          onClick={() => {
                            const newInterval = [...interval, {startAt: '00:00', endAt: '23:59'}]
                            setIntervals({...intervals, [key]: newInterval})
                          }}
                        >
                          <i className='fa-solid fa-plus' style={{marginTop: '10px'}}></i>
                        </a>
                      ) : (
                        <a
                          href='#'
                          onClick={() => {
                            const newInterval = interval.filter((e, i) => i !== index)
                            setIntervals({...intervals, [key]: newInterval})
                          }}
                        >
                          <i className='fa-solid fa-trash' style={{marginTop: '10px'}}></i>
                        </a>
                      )}
                    </div>
                  )
                })}
              </>
            )
          })
        : null}
    </>
  )
}

export default RestrictedInvervals
