
import {TasksListSearchComponent} from './TasksListSearchComponent'
import {TasksListFilter} from './TasksListFilter'
import { useTaskFormModalDispatch } from '../../core/TaskFormModalContext'

const TasksListHeader = () => {

  const taskFormModalDispatch = useTaskFormModalDispatch()

  const openAddModal = () => {
    taskFormModalDispatch({
      type: 'open-add-modal'
    })
  }

  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
      <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid">

        <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className="page-title d-flex flex-wrap me-3 flex-row justify-content-center">
          <i className="fa-solid fa-filter fs-2 me-1"></i><h3><strong>Tarefas</strong></h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>

        <TasksListSearchComponent />

        <TasksListFilter />
        
          <a
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
            style={{marginLeft: "3rem",position: "relative",top: "2px"}}
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export { TasksListHeader }
