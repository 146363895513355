/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {completeTask, removeTask} from '../../core/_requests'
import {TASK_STATUS} from '../../core/_models'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import {CARD_TYPES} from '../../../cards/core/_models'

type Props = {
  entity: any
}

const ActionsCell: FC<Props> = ({entity}) => {
  const {setItemIdForUpdate} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    navigate(`/tasks/edit/${entity.id}`)
  }

  const confirmCompleteTask = () => {
    Swal.fire({
      title: 'Concluir Tarefa',
      text: 'Tem certeza que deseja concluir esta tarefa? Esta ação é irreversível.',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        execCompleteTask(entity.id)
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  const execCompleteTask = async (idTask: any) => {
    try {
      await completeTask(idTask)
      Swal.fire('Tarefa Concluída!', '', 'success')
      refetch()
    } catch (e) {
      console.log(e)
    }
  }

  const deleteItem = useMutation(() => removeTask(entity.id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      refetch()
    },
  })

  const confirmDeleteCard = async (id: any) => {
    Swal.fire({
      title: 'Excluir Registro',
      text: 'Tem certeza que deseja excluir o registro?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteItem.mutateAsync()
        } catch (e: any) {
          let errorDetected = ''
          if (e.response?.data?.key && e.response?.data?.message) {
            errorDetected = e.response?.data?.message
          }
          Swal.fire('Opss', 'Falha ao Excluir o registro. ' + errorDetected, 'error')
        }
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  return (
    <>
      <a href='#' className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon svg-icon-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Alterar
          </a>
        </div>

        {/* abrir cards oportunidades e tickets */}
        {entity.card !== null && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() =>
                navigate(
                  `/${
                    entity.card?.type == CARD_TYPES.OPPORTUNITY.id ? 'opportunities' : 'tickets'
                  }/details/${entity.card?.id}`
                )
              }
            >
              Abrir {entity.card?.type === CARD_TYPES.OPPORTUNITY.id ? 'Oportunidade' : 'Ticket'}
            </a>
          </div>
        )}

        {/* abrir contato */}
        {entity.contact !== null && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => navigate(`/contacts/details/${entity.contact?.id}`)}
            >
              Abrir Contato
            </a>
          </div>
        )}

        {/* abrir empresa */}
        {entity.company !== null && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => navigate(`/companies/details/${entity.company?.id}`)}
            >
              Abrir Empresa
            </a>
          </div>
        )}

        {/* abrir chat */}
        <div
          className='menu-item px-3'
          aria-disabled={
            entity.contact?.integrationId &&
            currentUser?.customerAccount?.id &&
            (1 == currentUser?.customerAccount?.id ||
              1 == currentUser?.customerAccount?.reseller?.id)
          }
        >
          <Tooltip
            title={
              !entity.contact ? (
                <>
                  Essa tarefa não possui
                  <br />
                  um contato vinculado.
                </>
              ) : entity.contact.integrationId ? (
                <>
                  Essa tarefa não possui
                  <br />
                  código de integração vinculado .
                </>
              ) : undefined
            }
            TransitionComponent={Zoom}
            arrow
            placement='left'
            sx={{fontSize: 12}}
          >
            <a
              className='menu-link px-3'
              onClick={() => {
                if (window && entity.contact?.integrationId) {
                  window.open(
                    `https://app-spa.poli.digital/chat#${entity.contact?.integrationId}`,
                    '_blank'
                  )
                } else {
                  window.alert('Este contato não possui nenhum código de integração vinculado.')
                }
              }}
              style={{
                cursor: !entity.contact ? 'default' : 'pointer',
              }}
            >
              Abrir Chat
            </a>
          </Tooltip>
        </div>

        {/* end::Menu item */}
        {entity.status != TASK_STATUS.COMPLETED && entity.status != TASK_STATUS.CANCELED && (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={confirmCompleteTask}>
              Concluir Tarefa
            </a>
          </div>
        )}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            // data-kt-users-table-filter='delete_row'
            onClick={() => confirmDeleteCard(entity.id)}
          >
            Excluir
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
