import {FC} from 'react'

type Props = {
  triggerId?: number
}

/**
 * 
 * @param param0 {
        "id": 1,
        "key": "CARD_ENTER_STEP",
        "entity": "CARD",
        "name": "Quando entrar na etapa"
    },
    {
        "id": 2,
        "key": "CARD_LEAVE_STEP",
        "entity": "CARD",
        "name": "Quando sair de uma etapa"
    },
    {
        "id": 3,
        "key": "CARD_MARKED_AS_WIN",
        "entity": "CARD",
        "name": "Oportunidade marcada como ganha"
    },
    {
        "id": 4,
        "key": "CARD_MARKED_AS_LOST",
        "entity": "CARD",
        "name": "Oportunidade marcada como perda"
    },
    {
        "id": 5,
        "key": "CARD_MARKED_AS_OPEN",
        "entity": "CARD",
        "name": "Oportunidade marcada como aberta"
    },
    {
        "id": 6,
        "key": "CARD_MARKED_AS_FREEZE",
        "entity": "CARD",
        "name": "Oportunidade marcada como congelada"
    },
    {
        "id": 7,
        "key": "CARD_MARKED_AS_SOLVED",
        "entity": "CARD",
        "name": "Ticket marcado como resolvido"
    },
    {
        "id": 8,
        "key": "CARD_MARKED_AS_UNSOLVED",
        "entity": "CARD",
        "name": "Ticket marcado como não resolvido"
    },
    {
        "id": 9,
        "key": "CARD_MARKED_AS_ARCHIVED",
        "entity": "CARD",
        "name": "Ticket marcado como arquivado"
    }
 * @returns 
 */

const TriggerIdCell: FC<Props> = ({triggerId}) => (
  <>
    {' '}
    {triggerId === 1 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6 fs-6'>Quando entrar na etapa</div>
    ) : triggerId === 2 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Quando sair de uma etapa</div>
    ) : triggerId === 3 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Oportunidade marcada como ganha</div>
    ) : triggerId === 4 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Oportunidade marcada como perda</div>
    ) : triggerId === 5 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Oportunidade marcada como aberta</div>
    ) : triggerId === 6 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>
        Oportunidade marcada como congelada
      </div>
    ) : triggerId === 7 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Ticket marcado como resolvido</div>
    ) : triggerId === 8 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>
        Ticket marcado como não resolvido
      </div>
    ) : triggerId === 9 ? (
      <div className='badge badge-light-dark fw-bolder  fs-6'>Ticket marcado como arquivado</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder  fs-6'>Não identificado</div>
    )}
  </>
)

export {TriggerIdCell}
