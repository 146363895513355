import { format } from "date-fns";

export function formatCustomFieldDbDataToInput(field: any, customFieldsConfig: any) {
  try {
    let findCustomField = customFieldsConfig?.find((e: any) => e.id == field.id);

    if (!findCustomField) return field.value;

    if (findCustomField?.type?.key === 'date') {
      if (!field.value) return null;
      return new Date(field.value + " 00:00:00");
    } else if (findCustomField?.type?.key === 'time') {
      if (!field.value) return null;
      return new Date("2000-01-01 " + field.value);
    } else if (findCustomField?.type?.key === 'datetime') {
      if (!field.value) return null;
      return new Date(field.value);
    }

    return field.value;
  } catch (e) {
    console.log('Erro formatCustomFieldDbDataToInput', e);
    return undefined;
  }
}