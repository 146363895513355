import {useEffect} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useCardFormModal, useCardFormModalDispatch} from '../core/CardFormModalContext'
import {getCardById} from '../core/_requests'
import {CARD_TYPES} from '../core/_models'
import {CardsForm} from './CardsForm'
import {useNavigate} from 'react-router-dom'
import {getCustomForms} from '../../settings/custom-forms/core/_requests'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCardCustomForm} from '../../settings/custom-forms/core/_requests'
import {ListLoading} from '../../../modules/view/ListLoading'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'

const CardsFormModal = () => {
  const cardFormModal = useCardFormModal()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const navigate = useNavigate()
  const {refetch} = useQueryResponse()

  const handleClose = () => {
    cardFormModalDispatch({
      type: 'close-modal',
    })
    if (
      window?.location?.href?.includes('opportunities') ||
      window?.location?.href?.includes('tickets')
    ) {
      navigate(
        `${cardFormModal.cardType == CARD_TYPES.OPPORTUNITY.id ? '/opportunities' : '/tickets'}`
      )
    }
    refetch()
  }

  //console.log("cardFormModal", cardFormModal);

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['cards', 'edit', cardFormModal.cardId],
    queryFn: () => {
      return getCardById(cardFormModal.cardId)
    },
    enabled:
      cardFormModal.open && cardFormModal.formAction != 'detail' && cardFormModal.cardId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'complement-custom-forms', cardFormModal.cardId],
    queryFn: async () => {
      let funnelId = null
      if (data?.funnelId) {
        funnelId = data.funnelId
      } else if (cardFormModal?.funnelId) {
        funnelId = cardFormModal?.funnelId
      }
      if (!funnelId) return null
      let auxData = await getCustomForms(
        `entity=card&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}&funnelId=${funnelId}`
      ) //Complemento

      return auxData.data
    },
    enabled:
      cardFormModal.open &&
      (cardFormModal.formAction == 'edit' || cardFormModal.formAction == 'duplicate') &&
      cardFormModal.cardId &&
      data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'initial-custom-forms', cardFormModal.cardId],
    queryFn: () => {
      let funnelId = null
      if (data?.funnelId) {
        funnelId = data.funnelId
      } else if (cardFormModal?.funnelId) {
        funnelId = cardFormModal?.funnelId
      }

      if (!funnelId) return null

      return getCardCustomForm('card', funnelId, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled:
      (cardFormModal?.formAction == 'edit' || cardFormModal?.formAction == 'duplicate') && data?.id
        ? true
        : cardFormModal?.formAction == 'add' && cardFormModal.open
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (cardFormModal.open && cardFormModal.formAction != 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [cardFormModal.open])

  const loadTitle = () => {
    let title = ''
    switch (cardFormModal.cardType) {
      case CARD_TYPES.OPPORTUNITY.id:
        title = 'Oportunidade'
        break
      case CARD_TYPES.TICKET.id:
        title = 'Tícket'
        break
    }
    return title
  }

  if ((cardFormModal.formAction == 'edit' || cardFormModal.formAction == 'duplicate') && !data)
    return null

  if (!cardFormModal.open || cardFormModal.formAction == 'detail') return null

  if (isError) return null

  if (
    (isLoading || complementCustomFormsIsLoading || initialCustomFormIsLoading) &&
    cardFormModal.open &&
    cardFormModal.formAction != 'add'
  )
    return <ListLoading />

  return (
    <>
      <Modal
        show={cardFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{fontSize: '1.5rem', fontWeight: '700'}}>
            {cardFormModal.formAction == 'edit' ? 'Alterar ' : 'Adicionar '} {loadTitle()}
          </div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <Row>
            <Col>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail'>
                    Cadastro{' '}
                  </a>
                </li>
                {cardFormModal.formAction == 'edit' ? (
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_contact'>
                      Contato
                    </a>
                  </li>
                ) : null}
                {cardFormModal.formAction == 'edit' ? (
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_company'>
                      Empresa
                    </a>
                  </li>
                ) : null}
                {cardFormModal.formAction == 'edit'
                  ? complementCustomForms?.map((form: any, index: number) => {
                      return (
                        <li className='nav-item'>
                          <a
                            className='nav-link'
                            data-bs-toggle='tab'
                            href={'#card_tab_pane_complement' + form.id}
                          >
                            {form.name}
                          </a>
                        </li>
                      )
                    })
                  : null}
              </ul>
              <div className='tab-content'>
                {initialCustomForm?.fields?.length > 0 ? (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <CardsForm
                      customFormId={initialCustomForm?.id}
                      addData={cardFormModal.addData}
                      isLoading={false}
                      action={cardFormModal.formAction}
                      card={data}
                      cardtype={cardFormModal.cardType}
                      funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id}
                      onSave={handleClose}
                    />{' '}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <CardsForm
                      addData={cardFormModal.addData}
                      isLoading={false}
                      card={data}
                      action={cardFormModal.formAction}
                      cardtype={cardFormModal.cardType}
                      funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id}
                      onSave={handleClose}
                    />
                  </div>
                )}

                {/* <div
                  className='tab-pane fade show active overflow-hidden'
                  id='card_tab_pane_detail'
                  role='tabpanel'
                >
                  <CardsForm
                    isLoading={false}
                    card={data}
                    type={CUSTOM_FORM_TYPES.INITIAL.id}
                    cardtype={cardFormModal.cardType}
                    funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data.funnel?.id}
                  />
                </div>
                 */}
                <div className='tab-pane fade show' id='card_tab_pane_contact' role='tabpanel'>
                  {data?.contact && (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{data?.contact?.name}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/contacts/details/${data?.contact.id}`)
                          }}
                        ></i>
                      </div>

                      {data?.contact.cpf ? (
                        <>
                          {`CPF: ${data?.contact.cpf}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data?.contact.createdAt ? (
                        <>
                          {`Criado em ${new Date(data?.contact.createdAt).toLocaleString()}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>

                <div className='tab-pane fade show' id='card_tab_pane_company' role='tabpanel'>
                  {data?.company && (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{data?.company?.name}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/companies/details/${data?.company.id}`)
                          }}
                        ></i>
                      </div>
                      {data?.company?.corporateName ? (
                        <>
                          {`Razão Social: ${data?.company?.corporateName}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data?.company?.cnpj ? (
                        <>
                          {`CNPJ: ${data?.company?.cnpj}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data?.company?.createdAt ? (
                        <>
                          {`Criado em ${new Date(data?.company?.createdAt).toLocaleString()}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <div
                      className={'tab-pane fade show'}
                      id={'card_tab_pane_complement' + form.id}
                      role='tabpanel'
                      key={index}
                    >
                      <CardsForm
                        isLoading={false}
                        addData={cardFormModal.addData}
                        customFormId={form.id}
                        action={cardFormModal.formAction}
                        card={data}
                        cardtype={cardFormModal.cardType}
                        funnelId={
                          cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id
                        }
                        onSave={handleClose}
                      />
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CardsFormModal}
