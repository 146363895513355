import {useEffect, useRef, useState} from 'react'
import {initialQueryState} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/view/QueryRequestProvider'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {TASK_STATUS, TASK_TYPES} from '../../core/_models'
import {getUsers} from '../../core/_requests'
import debounce from 'debounce-promise'
import SelectAsync from 'react-select/async'
import {Dropdown} from 'react-bootstrap'
import styled from 'styled-components'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {format} from 'date-fns'
import StyledDatePicker from '../../../../modules/view/StyledDatePicker'
import StyledDatetimePicker from '../../../../modules/view/StyledDatetimePicker'

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
  position: relative;
  top: 2px;
  left: 22px;
`
const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '17px',
    minHeight: '17px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    height: '100%',
    position: 'relative',
    top: '-10px',
    clearIndicator: (base: any) => ({
      ...base,
      padding: '0px',
      margin: '0px',
    }),
  }),
}

const TasksListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [type, setType] = useState<string | undefined>()
  const [status, setStatus] = useState<string | undefined>()
  const [responsible, setResponsible] = useState<any | undefined>()
  const [dateStartAt, setDateStartAt] = useState<any | undefined>()
  const [dateEndAt, setDateEndAt] = useState<any | undefined>()

  const [dateAtIsOpen, setDateAtIsOpen] = useState({
    dateStart: false,
    dateEnd: false,
  })

  const dateStartRef: any = useRef(null)
  const dateEndRef: any = useRef(null)
  const dropdownRef: any = useRef(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setResponsible(null)
    setStatus('')
    setType('')
    setDateStartAt(null)
    setDateEndAt(null)
    updateState({
      filter: {type: null, status: null, responsibleId: null, dateStartAt: null, dateEndAt: null},
      ...initialQueryState,
    })
  }

  const filterData = () => {
    updateState({
      filter: {
        type,
        status,
        responsibleId: responsible?.value ? responsible.value : null,
        dateStartAt: dateStartAt ? format(dateStartAt, 'yyyy-MM-dd HH:mm:ss') : undefined,
        dateEndAt: dateEndAt ? format(dateEndAt, 'yyyy-MM-dd HH:mm:ss') : undefined,
      },
      ...initialQueryState,
    })

    setShowDropdown(false)
  }

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  useEffect(() => {
    let timeoutId: any = null
    function handleClickOutside(event: any) {
      if (
        dropdownRef?.current &&
        !dropdownRef.current?.contains(event?.target) &&
        !dateStartRef.current?.contains(event?.target) &&
        !dateEndRef.current?.contains(event?.target)
      ) {
        //if para ve se onBlur foi fora do dropdown e se todos os calendarios estiverem fechados
        if (!Object.values(dateAtIsOpen).some((status) => status)) {
          timeoutId = setTimeout(() => {
            //delay pra nao dar bug visual
            setShowDropdown(false)
          }, 150)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [dateAtIsOpen])

  return (
    <div className='m-0'>
      <Dropdown ref={dropdownRef} id='dropdown-true' show={showDropdown}>
        <StyledDropdownToggle
          variant='wl-custom-primary-collor-inverse'
          id='dropdown-basic'
          className='btn btn-sm me-3'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <i className='fa-solid fa-filter fs-2'></i>
          Filtros
        </StyledDropdownToggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-400px w-md-425px'>
          <Dropdown.Header className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
          </Dropdown.Header>
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5' data-kt-user-table-filter='form'>
            <div className='mb-7'>
              <InputFieldSet label={'Status'}>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-sm  fieldset-input'
                  defaultValue=''
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value=''>Todos</option>
                  <option value={TASK_STATUS.SCHEDULED}>Agendada</option>
                  <option value={TASK_STATUS.LATE}>Atrasada</option>
                  <option value={TASK_STATUS.COMPLETED}>Concluída</option>
                  <option value={TASK_STATUS.CANCELED}>Cancelada</option>
                </select>
              </InputFieldSet>
            </div>

            <div className='mb-7'>
              <InputFieldSet label={'Tipo da Tarefa *'}>
                <select
                  name='type'
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  data-control='select2'
                  className={
                    'form-control form-select form-select-sm form-select-white fieldset-input'
                  }
                >
                  <option value=''>Todos</option>
                  {TASK_TYPES.map((tp) => (
                    <option value={tp.id} key={tp.id}>
                      {tp.value}
                    </option>
                  ))}
                </select>
              </InputFieldSet>
            </div>

            <div className='mb-7'>
              <InputFieldSet label={'Responsável'}>
                <SelectAsync
                  placeholder={''}
                  name='responsible'
                  loadOptions={debounceResponsiblesOptions}
                  value={responsible}
                  cacheOptions
                  defaultOptions
                  isClearable
                  onChange={(selectedOption) => setResponsible(selectedOption)}
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>

            <div className='d-flex justify-content-between'>
              <InputFieldSet style={{marginRight: 5}} label={'Data da Tarefa Início'}>
                <StyledDatetimePicker
                  ref={dateStartRef}
                  value={dateStartAt}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                  onChange={(e: any) => {
                    setDateStartAt(e)
                    setDateAtIsOpen((prevState) => ({...prevState, dateStart: false}))
                  }}
                  open={dateAtIsOpen.dateStart}
                  onClose={() => setDateAtIsOpen((prevState) => ({...prevState, dateStart: false}))}
                  onOpen={() => setDateAtIsOpen((prevState) => ({...prevState, dateStart: true}))}
                />
              </InputFieldSet>

              <InputFieldSet label={'Data da Tarefa Fim'}>
                <StyledDatetimePicker
                  ref={dateEndRef}
                  value={dateEndAt}
                  slotProps={{
                    field: {
                      clearable: true,
                    },
                  }}
                  onChange={(e: any) => {
                    setDateEndAt(e)
                    setDateAtIsOpen((prevState) => ({...prevState, dateEnd: false}))
                  }}
                  open={dateAtIsOpen.dateEnd}
                  onClose={() => setDateAtIsOpen((prevState) => ({...prevState, dateEnd: false}))}
                  onOpen={() => setDateAtIsOpen((prevState) => ({...prevState, dateEnd: true}))}
                />
              </InputFieldSet>
            </div>

            {/* begin::Actions */}
            <div className='d-flex justify-content-end mt-4'>
              <button
                type='button'
                disabled={isLoading}
                onClick={resetData}
                className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
              >
                Limpar
              </button>
              <button
                disabled={isLoading}
                type='button'
                onClick={filterData}
                className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Aplicar
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export {TasksListFilter}
