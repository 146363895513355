import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {TerminationReason, TerminationReasonsQueryResponse} from './_models'
import {request} from "../../../../services/crm-api/request"

const API_URL = process.env.REACT_APP_API_URL
const TERMINATION_REASON_URL = `${API_URL}/termination-reasons`
const TERMINATION_REASONS_URL = `${API_URL}/termination-reasons`

const getTerminationReasons = async (query: string):  Promise<TerminationReasonsQueryResponse> => {

  return request({
    method: "GET",
    url: `/termination-reasons?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<TerminationReasonsQueryResponse>) => d.data);
}

const getTerminationReasonById = (id: ID): Promise<TerminationReason | undefined> => {

  //Temporário
  return request({
    method: "GET",
    url: `/termination-reasons/${id}?include_subreasons=true&include_funnels=true`
  },{
    setAuth: true
  })
  .then((response: Response<TerminationReason>) => response.data)
}

const createTerminationReason = (dataForm: TerminationReason): Promise<TerminationReason | undefined> => {

  let data = {...dataForm};
  if(data.type != undefined)
  {
    data.type = parseInt(data.type.toString());
  }

  if(data.status != undefined)
  {
    data.status = parseInt(data.status.toString());
  }

  return request({
    method: "post",
    url: `/termination-reasons`,
    data: data
  },{
    setAuth: true
  })
   // .post(TERMINATION_REASONS_URL, data)
    .then((response: AxiosResponse<Response<TerminationReason>>) => response.data)
    .then((response: Response<TerminationReason>) => response.data)
}

const updateTerminationReason = (dataForm: TerminationReason): Promise<TerminationReason | undefined> => {

  let data = {...dataForm};
  if(data.type != undefined)
  {
    data.type = parseInt(data.type.toString());
  }

  if(data.status != undefined)
  {
    data.status = parseInt(data.status.toString());
  }

  return request({
    method: "patch",
    url: `/termination-reasons/${dataForm.id}`,
    data: data
  },{
    setAuth: true
  })
    //.patch(`${TERMINATION_REASONS_URL}/${dataForm.id}`, data)
    .then((response: AxiosResponse<Response<TerminationReason>>) => response.data)
    .then((response: Response<TerminationReason>) => response.data)
}

const deleteTerminationReason = (id: ID): Promise<void> => {
  return request({
    method: "delete",
    url: `/termination-reasons/${id}`,
  },{
    setAuth: true
  })
  .then(() => {})
  //axios.delete(`${TERMINATION_REASONS_URL}/${id}`).then(() => {})
}


export {getTerminationReasons, getTerminationReasonById, createTerminationReason, updateTerminationReason, deleteTerminationReason}
