/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { TicketCategoriesHeader } from './components/header/TicketCategoriesHeader'
import { TicketCategoriesTable } from './table/TicketCategoriesTable'
import { TicketCategoriesEditModal } from './edit-modal/TicketCategoriesEditModal'
import {getTicketCategories} from './core/_requests'
import { TicketCategoryFormModalProvider } from './core/TicketCategoryFormModalContext'

const TicketCategoriesPage = () => {
  return (
    <>
      <TicketCategoriesHeader />
      <KTCard>
        <TicketCategoriesTable />
      </KTCard>
      <TicketCategoriesEditModal />
    </>
  )
}


const TicketCategories: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getTicketCategories}>
          <ListViewProvider>
            <TicketCategoryFormModalProvider>
              <TicketCategoriesPage />
            </TicketCategoryFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { TicketCategories }
