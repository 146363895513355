import { createContext, useContext, useReducer } from 'react';

export interface TicketCategoryFormModalContextModel {
  open: boolean
  categoryId: number | null
}

const initialData = {
  open: false,
  categoryId: null,
};

const TicketCategoryFormModalContext = createContext<TicketCategoryFormModalContextModel>(initialData);
const TicketCategoryFormModalDispatchContext = createContext<any>(null);

export function TicketCategoryFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    ticketCategoryFormModalReducer,
    initialData
  );

  return (
    <TicketCategoryFormModalContext.Provider value={data}>
      <TicketCategoryFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </TicketCategoryFormModalDispatchContext.Provider>
  </TicketCategoryFormModalContext.Provider>
  );
}

export function useTicketCategoryFormModal() {
  return useContext(TicketCategoryFormModalContext);
}

export function useTicketCategoryFormModalDispatch() {
  return useContext(TicketCategoryFormModalDispatchContext);
}

function ticketCategoryFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, categoryId: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, categoryId: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
