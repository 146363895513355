import debounce from 'debounce-promise'
import React, {useEffect, useState} from 'react'
import {Button, Form, Modal, Nav} from 'react-bootstrap'
import {useQuery} from 'react-query'
import Select from 'react-select'
import SelectAsync from 'react-select/async'
import Swal from 'sweetalert2'
import {ID, isNotEmpty} from '../../../../../_metronic/helpers'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {getFunnelStages} from '../../../funnel/core/_requests'
import {getFunnels} from '../../funnels/core/_requests'
import {
  useAutomaticActionsFormModal,
  useAutomaticActionsFormModalDispatch,
} from '../core/AutomaticActionsFormModalContext'
import {AutomaticAction} from '../core/_models'
import {
  getAutomaticActionsById,
  getAutomaticActionsConfigActions,
  getAutomaticActionsConfigStatus,
  getAutomaticActionsConfigTriggers,
  postAutomaticActionsConfig,
  updateAutomaticActionsConfig,
} from '../core/_requests'
import RestrictedInvervals from './RestrictedInvervals'
import Step from './Step'
import TaskForm from './TaskForm'
import {CARD_STATUS} from '../../../cards/core/_models'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {getTerminationReasons} from '../../termination-reasons/core/_requests'
import {Divider} from '@mui/material'

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    minHeight: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
}

type StepProps = {
  label: string
  content: React.ReactNode
}

type NavItemProps = {
  label: string
  onClick: () => void
  active: boolean
}

const InitialData: AutomaticAction = {
  title: '',
  triggerId: 0,
  triggerConfig: {
    entity: 'card',
  },
  triggerRestrictedToInterval: null,
  actionId: 0,
  actionConfig: null,
  actionRestrictedToInterval: null,
}

const NavItem: React.FC<NavItemProps> = ({label, onClick, active}) => {
  return (
    <Nav.Item>
      <Nav.Link onClick={onClick} active={active}>
        {label}
      </Nav.Link>
    </Nav.Item>
  )
}

const MultiStepForm: React.FC = () => {
  const [automaticAction, setAutomaticAction] = useState<AutomaticAction>(InitialData)
  const [currentStep, setCurrentStep] = useState(1)
  const automaticActionsFormModal = useAutomaticActionsFormModal()
  const automaticActionsFormModalDispatch = useAutomaticActionsFormModalDispatch()
  const [stages, setStages] = useState<any>([])
  const [actionStages, setActionStages] = useState<any>([])
  const [triggerConfigFunnel, setTriggerConfigFunnel] = useState<any>()
  const [triggerConfigStep, setTriggerConfigStep] = useState<any>()
  const [triggerStatusOptions, setTriggerStatusOptions] = useState<any[]>([])
  const [actionStatusOptions, setActionStatusOptions] = useState<any[]>([])
  const [terminationReasons, setTerminationReasons] = useState<any[]>([])
  const [terminationSubReasons, setTerminationSubReasons] = useState<any[]>([])
  const allStatusOptions = CARD_STATUS
  const {refetch} = useQueryResponse()
  const [resetFunnel, setResetFunnel] = useState(false)

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  const handlePrev = () => {
    setCurrentStep(currentStep - 1)
  }

  const {data} = useQuery({
    queryKey: ['automatic-actions', automaticActionsFormModal.automaticActionId],
    queryFn: () => {
      return getAutomaticActionsById(automaticActionsFormModal.automaticActionId)
    },
    enabled:
      automaticActionsFormModal.open && automaticActionsFormModal.automaticActionId ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (automaticActionsFormModal.open && automaticActionsFormModal.automaticActionId) {
      if (data) {
        setAutomaticAction(data)
        setTriggerConfigFunnel({
          label: data.triggerConfig?.funnelName,
          value: data.triggerConfig?.funnelId,
        })
        setTriggerConfigStep({
          label: data.triggerConfig?.stepName,
          value: data.triggerConfig?.stepId,
        })

        if (data.actionId == 1) {
          setAutomaticAction({
            ...data,
            actionId: data.actionId,
            actionConfig: {
              ...data.actionConfig,
              task: {
                title: data.actionConfig?.task?.title,
                observation: data.actionConfig?.task?.observation,
                status: data.actionConfig?.task?.status,
                type: data.actionConfig?.task?.type,
                subType: data.actionConfig?.task?.subType,
                customFields: data.actionConfig?.task?.customFields,
              },
            },
          })
        }

        if (data.actionId == 2) {
          setAutomaticAction({
            ...data,
            actionConfig: {
              ...data.actionConfig,
              funnel: {
                label: data.actionConfig?.funnelName,
                value: data.actionConfig?.funnelId,
              },
              step: {
                label: data.actionConfig?.stepName,
                value: data.actionConfig?.stepId,
              },
            },
          })
        } else if (data.actionId == 3) {
          setAutomaticAction({
            ...data,
            actionConfig: {
              ...data.actionConfig,
              terminationReason: {
                label: data.actionConfig?.terminationReasonName,
                value: data.actionConfig?.terminationReasonId,
              },
              terminationSubreason: {
                label: data.actionConfig?.terminationSubreasonName,
                value: data.actionConfig?.terminationSubreasonId,
              },
            },
          })
        }
        fillTriggerConfigs(data.triggerConfig?.entity, data.triggerConfig?.entityType)
      }
    }
  }, [data])

  console.log(automaticAction)

  useEffect(() => {
    if (automaticActionsFormModal.open) {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [automaticActionsFormModal.open])

  //#region Stage & Status Options
  useEffect(() => {
    loadStages(automaticAction?.triggerConfig?.funnelId)
    loadStatusOptions(automaticAction?.triggerConfig?.funnelId)
  }, [automaticAction?.triggerConfig?.funnelId])
  let loadStages = async (funnel_id: any) => {
    try {
      let list: Array<any> = []
      if (funnel_id) {
        let funnelStages = await getFunnelStages(funnel_id, 'page=1&perPage=100')
        if (funnelStages && funnelStages != undefined) {
          for (let i = 0; i < funnelStages?.length; i++) {
            list.push({
              label: funnelStages[i].title,
              value: funnelStages[i].id,
              funnel: funnel_id,
            })
          }
        }
      }

      setStages(list)
    } catch (e) {
      console.log(e)
      setStages([])
    }
  }

  useEffect(() => {
    loadActionStages(automaticAction?.actionConfig?.funnelId)
    loadActionStatusOptions(automaticAction?.actionConfig?.funnelId)
  }, [automaticAction?.actionConfig?.funnelId])
  let loadActionStages = async (funnel_id: any) => {
    try {
      let list: Array<any> = []
      if (funnel_id) {
        let funnelStages = await getFunnelStages(funnel_id, 'page=1&perPage=100')
        if (funnelStages && funnelStages != undefined) {
          for (let i = 0; i < funnelStages?.length; i++) {
            list.push({
              label: funnelStages[i].title,
              value: funnelStages[i].id,
              funnel: funnel_id,
            })
          }
        }
      }

      setActionStages(list)
    } catch (e) {
      console.log(e)
      setActionStages([])
    }
  }

  let loadStatusOptions = async (funnel_id: any) => {
    try {
      let list: Array<any> = []
      if (funnel_id) {
        let funnelStatus = await getAutomaticActionsConfigStatus(funnel_id)
        if (funnelStatus && funnelStatus != undefined) {
          for (let i = 0; i < funnelStatus?.length; i++) {
            const statusAux = Object.values(allStatusOptions).find(
              (status) => status.id === funnelStatus[i].id
            )
            if (statusAux) {
              list.push({
                label: statusAux.label,
                value: statusAux.id,
              })
            }
          }
        }
      }

      setTriggerStatusOptions(list)
      setActionStatusOptions(list)
    } catch (e) {
      console.log(e)
      setTriggerStatusOptions([])
      setActionStatusOptions([])
    }
  }

  let loadActionStatusOptions = async (funnel_id: any) => {
    try {
      let list: Array<any> = []
      if (funnel_id) {
        let funnelStatus = await getAutomaticActionsConfigStatus(funnel_id)
        if (funnelStatus && funnelStatus != undefined) {
          for (let i = 0; i < funnelStatus?.length; i++) {
            const statusAux = Object.values(allStatusOptions).find(
              (status) => status.id === funnelStatus[i].id
            )
            if (statusAux) {
              list.push({
                label: statusAux.label,
                value: statusAux.id,
              })
            }
          }
        }
      }

      setActionStatusOptions(list)
    } catch (e) {
      console.log(e)
      setActionStatusOptions([])
    }
  }

  //#endregion

  //#region Funnel Options
  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let funnels: any = await getFunnels(
        'page=1&perPage=20&type=' +
          encodeURIComponent(automaticAction.triggerConfig?.entityType ?? '') +
          '&search=' +
          encodeURIComponent(inputValue)
      )
      if (funnels && funnels != undefined) {
        for (let i = 0; i < funnels.data?.length; i++) {
          list.push({label: funnels.data[i].name, value: funnels.data[i].id})
        }
      }
      resolve(list)
    })
  let debounceFunnelsOptions = debounce(funnelsOptions, 500)
  //#endregion

  //#region Trigger Configs
  const [triggerConfigOptions, setTriggerConfigOptions] = useState<{label?: string; value: ID}[]>(
    []
  )

  async function fillTriggerConfigs(entity?: string, entityType?: number, funnelId?: number) {
    let response = await getAutomaticActionsConfigTriggers(entity, entityType, funnelId)

    if (response && response != undefined) {
      const options = []
      for (let i = 0; i < response?.length; i++) {
        options.push({
          label: response[i].name,
          value: response[i].id,
        })
      }
      setTriggerConfigOptions(options)
    }
  }

  //#endregion

  //#region Action configs
  const [actionConfigOptions, setActionConfigOptions] = useState<{label?: string; value: ID}[]>([])

  async function fillActionConfigs() {
    if (actionConfigOptions.length > 0) return

    let response = await getAutomaticActionsConfigActions()

    if (response && response != undefined) {
      for (let i = 0; i < response?.length; i++) {
        const options = actionConfigOptions

        options.push({
          label: response[i].name,
          value: response[i].id,
        })

        setActionConfigOptions(options)
      }
    }
  }

  //#endregion

  //#region TerminationReasons e TerminationSubReasons
  const terminationReasonsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let terminationReasons = await getTerminationReasons(
        `page=1&perPage=20&include_funnels=true&include_subreasons=true&type=${
          automaticAction.actionConfig?.status ?? 0
        }&search=${encodeURIComponent(inputValue)}&funnelIds=${
          automaticAction.triggerConfig?.funnelId
        }&status=1`
      )
      if (terminationReasons && terminationReasons?.data != undefined) {
        for (let i = 0; i < terminationReasons.data.length; i++) {
          let reason = terminationReasons.data[i]
          let subReasons = []
          if (reason?.subreasons?.length) {
            for (let j = 0; j < reason.subreasons.length; j++) {
              let subReason = reason.subreasons[j]
              subReasons.push({label: subReason.name, value: subReason.id})
            }
          }

          list.push({label: reason.name, value: reason.id, subReasons: subReasons})
        }
      }
      resolve(list)
      setTerminationReasons(list)
    })

  //#endregion

  useEffect(() => {
    fillTriggerConfigs()
    fillActionConfigs()
  }, [])

  function actionConfigChange(value: any, field: string, type: number) {
    /*
      type = 1 -> Criar Tarefa
      type = 2 -> Mover Etapa
      type = 3 -> Alterar Status
    */
    if (type == 2) {
      let config = automaticAction.actionConfig

      if (config === undefined || config == null) {
        config = {
          funnelId: 0,
          stepId: 0,
          status: 0,
        }
      }

      if (field == 'funnelId') {
        config.funnelId = parseInt(value.value)
        config.funnel = value
        config.stepId = 0
        config.step = null
      } else if (field == 'stepId') {
        config.stepId = parseInt(value.value)
        config.step = value
      } else if (field == 'status') {
        config.status = parseInt(value)
      }

      setAutomaticAction({
        ...automaticAction,
        actionConfig: config,
      })
    } else if (type == 3) {
      let statusAction = automaticAction.actionConfig

      if (statusAction === undefined || statusAction == null) {
        statusAction = {
          status: 0,
        }
      }

      if (field == 'status') {
        statusAction.status = parseInt(value)
      } else if (field == 'terminationReason') {
        if (value == null) {
          statusAction.terminationReasonId = null
          statusAction.terminationReasonName = null
          statusAction.terminationReason = null
        } else {
          statusAction.terminationReasonId = value.value
          statusAction.terminationReasonName = value.label
          statusAction.terminationReason = value
        }
      } else if (field == 'terminationSubreason') {
        if (value == null) {
          statusAction.terminationSubreasonId = null
          statusAction.terminationSubreasonName = null
          statusAction.terminationSubreason = null
        } else {
          statusAction.terminationSubreasonId = value.value
          statusAction.terminationSubreasonName = value.label
          statusAction.terminationSubreason = value
        }
      }

      setAutomaticAction({
        ...automaticAction,
        actionConfig: statusAction,
      })
    }
  }

  function handleHide() {
    setAutomaticAction(InitialData)
    setCurrentStep(1)
    setTriggerConfigFunnel(null)
    setTriggerConfigStep(null)

    automaticActionsFormModalDispatch({
      type: 'close-modal',
    })
    refetch()
  }

  function handleOnChange(e: any) {
    setAutomaticAction({
      ...automaticAction,
      [e.target.name]: e.target.value,
    })
  }

  function handleOnChangeParsing(e: any) {
    setAutomaticAction({
      ...automaticAction,
      [e.target.name]: parseInt(e.target.value),
    })
  }

  function validateFields() {
    if (!isNotEmpty(automaticAction.title)) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'O campo Título é obrigatório',
      })

      return false
    }

    if (automaticAction.triggerId == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'O campo Gatilho é obrigatório',
      })

      return false
    }

    if (automaticAction.triggerId !== 0) {
      if (!automaticAction.triggerConfig?.funnelId) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'O campo Funil é obrigatório',
        })

        return false
      }

      if (
        !automaticAction.triggerConfig?.stepId &&
        (automaticAction.triggerId == 1 || automaticAction.triggerId == 2)
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'O campo Etapa do Funil é obrigatório',
        })

        return false
      }
    }

    if (automaticAction.actionId !== 0) {
      if (automaticAction.actionId == 1) {
        if (!automaticAction.actionConfig?.task?.title) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo Título da Tarefa é obrigatório',
          })

          return false
        }
        if (!automaticAction.actionConfig?.task?.type) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo Tipo da Tarefa é obrigatório',
          })

          return false
        }

        if (
          !automaticAction.actionConfig?.task?.subType &&
          !automaticAction.actionConfig.task.customFields
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo categoria da Tarefa é obrigatório',
          })

          return false
        }
      } else if (automaticAction.actionId == 2) {
        if (!automaticAction.actionConfig?.funnelId) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo Funil é obrigatório',
          })

          return false
        }

        if (!automaticAction.actionConfig?.stepId) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo Etapa do Funil é obrigatório',
          })

          return false
        }
      } else if (automaticAction.actionId == 3) {
        if (!automaticAction.actionConfig?.status) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O campo Status é obrigatório',
          })

          return false
        }
      }
    }
    return true
  }

  async function onSubmit() {
    if (!validateFields()) {
      return
    }

    try {
      let actionConfigResponse = null
      if (automaticAction.actionId == 1) {
        console.log(automaticAction.actionConfig?.task)
        let custom_fields = automaticAction.actionConfig?.task?.customFields.map((cf: any) => {
          return {
            integrationId: cf.integrationId,
            value: cf.value?.value
              ? cf.value
              : {
                  value: cf.value,
                  label: cf.label,
                },
          }
        })

        actionConfigResponse = {
          funnelId: automaticAction.actionConfig?.funnelId,
          task: {
            title: automaticAction.actionConfig?.task?.title,
            observation: automaticAction.actionConfig?.task?.observation,
            status:
              automaticAction.actionConfig?.task?.status == 0
                ? undefined
                : automaticAction.actionConfig?.status,
            type: automaticAction.actionConfig?.task?.type,
            subType: automaticAction.actionConfig?.task?.subType,
            customFields: custom_fields ?? null,
          },
        }
      } else if (automaticAction.actionId == 2) {
        actionConfigResponse = {
          funnelId: automaticAction.actionConfig?.funnelId,
          stepId: automaticAction.actionConfig?.stepId,
          status:
            automaticAction.actionConfig?.status == 0
              ? undefined
              : automaticAction.actionConfig?.status,
        }
      } else if (automaticAction.actionId == 3) {
        actionConfigResponse = {
          terminationReasonId: automaticAction.actionConfig?.terminationReasonId ?? null,
          terminationSubreasonId: automaticAction.actionConfig?.terminationSubreasonId ?? null,
          status: automaticAction.actionConfig?.status,
        }
      }

      const responseObj = {
        title: automaticAction.title,
        triggerId: automaticAction.triggerId,
        triggerConfig: {
          entity: automaticAction.triggerConfig?.entity,
          entityType: automaticAction.triggerConfig?.entityType,
          funnelId: automaticAction.triggerConfig?.funnelId,
          stepId: automaticAction.triggerConfig?.stepId
            ? automaticAction.triggerConfig?.stepId
            : null,
          status: automaticAction.triggerConfig?.status
            ? automaticAction.triggerConfig?.status
            : null,
        },
        triggerRestrictedToInterval: automaticAction.triggerRestrictedToInterval,
        actionId: automaticAction.actionId,
        actionConfig: actionConfigResponse,
        actionRestrictedToInterval: automaticAction.actionRestrictedToInterval,
      }

      if (automaticActionsFormModal.automaticActionId) {
        await updateAutomaticActionsConfig(automaticActionsFormModal.automaticActionId, responseObj)
      } else {
        await postAutomaticActionsConfig(responseObj)
      }
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Ação automática salva com sucesso',
      })
      handleHide()
    } catch (error: any) {
      console.log(error)
      let errorDetected = ''

      if (error.response?.data?.key && error.response?.data?.message) {
        errorDetected = error.response?.data?.message
      }

      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao salvar ação automática. ' + errorDetected,
      })
    }
  }

  const steps: StepProps[] = [
    {
      label: 'Configurar Condição para a Ação',
      content: (
        <>
          {/* <Form.Label>Primeiro, crie uma nova Ação definindo os campos abaixo:</Form.Label> */}
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Título da Ação '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <input
                placeholder=''
                type='text'
                name='title'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={false}
                defaultValue={automaticAction.title}
                onChange={handleOnChange}
              />
            </InputFieldSet>
          </div>

          {automaticAction.triggerConfig?.entity == 'card' && (
            <div className='fv-row mb-7'>
              <InputFieldSet
                label={
                  <>
                    {'Tipo de Entitade *'}
                    <i
                      className='fa-solid fa-asterisk'
                      style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                    ></i>
                  </>
                }
              >
                <select
                  name='triggerConfig.entityType'
                  onChange={async (e: any) => {
                    setAutomaticAction({
                      ...automaticAction,
                      triggerConfig: {
                        entity: automaticAction.triggerConfig?.entity,
                        entityType: parseInt(e.target.value),
                        funnelId: 0,
                        stepId: 0,
                      },
                      triggerId: 0,
                    })

                    await setResetFunnel(true)
                    setResetFunnel(false)

                    setTriggerConfigFunnel(null)
                    setTriggerConfigStep(null)

                    fillTriggerConfigs(
                      automaticAction.triggerConfig?.entity,
                      parseInt(e.target.value)
                    )
                  }}
                  onBlur={() => {}}
                  value={automaticAction.triggerConfig?.entityType}
                  data-control='select2'
                  data-hide-search='true'
                  className={
                    'form-control form-select form-select-sm form-select-white fieldset-input'
                  }
                  disabled={false}
                >
                  <option value={0}>Selecione</option>
                  <option value={1}>Oportunidade</option>
                  <option value={2}>Ticket</option>
                </select>
              </InputFieldSet>
            </div>
          )}

          {/* <Divider style={{marginTop: 20, marginBottom: 20}}>Condição para a ação</Divider> */}
          {/*
          <div className='fv-row mb-7 mt-7'>
            <InputFieldSet
              label={
                <>
                  {'Entidade *'}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <select
                name='triggerConfig.entity'
                onChange={(e: any) => {
                  setAutomaticAction({
                    ...automaticAction,
                    triggerConfig: {
                      ...automaticAction.triggerConfig,
                      entity: e.target.value,
                    },
                  })
                  fillTriggerConfigs(e.target.value)
                }}
                onBlur={() => {}}
                value={automaticAction.triggerConfig?.entity}
                data-control='select2'
                data-hide-search='true'
                className={
                  'form-control form-select form-select-sm form-select-white fieldset-input'
                }
                disabled={false}
              >
                <option value=''>Selecione</option>
                {ENTITY_TYPES.map((tp) => (
                  <option value={tp.id} key={tp.id}>
                    {tp.name}
                  </option>
                ))}
              </select>
            </InputFieldSet>
          </div> */}

          {automaticAction.triggerConfig?.entity && automaticAction.triggerConfig?.entityType && (
            <>
              <Divider style={{marginTop: 20, marginBottom: 20}}>Condição para o Gatilho</Divider>
              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'A partir de qual evento ocorrerá a ação? '}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <select
                    name='triggerId'
                    onChange={handleOnChangeParsing}
                    onBlur={() => {}}
                    data-control='select2'
                    data-hide-search='true'
                    className={
                      'form-control form-select form-select-sm form-select-white fieldset-input'
                    }
                    defaultValue={automaticAction.triggerId}
                    value={automaticAction.triggerId}
                  >
                    <option value=''>Selecionar</option>
                    {triggerConfigOptions.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </InputFieldSet>
              </div>
              {!resetFunnel && (
                <div className='fv-row mb-7'>
                  <InputFieldSet
                    label={
                      <>
                        {'Funil *'}
                        <i
                          className='fa-solid fa-asterisk'
                          style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                        ></i>
                      </>
                    }
                  >
                    <SelectAsync
                      menuPortalTarget={document.body}
                      placeholder={''}
                      noOptionsMessage={() => 'Sem Funil'}
                      name='funnels'
                      loadOptions={debounceFunnelsOptions}
                      value={triggerConfigFunnel}
                      cacheOptions
                      defaultOptions
                      isMulti={false}
                      isDisabled={false}
                      onChange={(selectedOption: any) => {
                        setAutomaticAction({
                          ...automaticAction,
                          triggerConfig: {
                            ...automaticAction.triggerConfig,
                            funnelId: parseInt(selectedOption.value),
                          },
                        })
                        setTriggerConfigFunnel(selectedOption)
                        setTriggerConfigStep(null)
                      }}
                      className='fieldset-input'
                      styles={customStyles}
                    />
                  </InputFieldSet>
                </div>
              )}
              {automaticAction.triggerId !== 0 && (
                <div>
                  {automaticAction.triggerConfig?.funnelId ? (
                    <div className='fv-row mb-7'>
                      <InputFieldSet
                        label={
                          automaticAction.triggerId == 1 || automaticAction.triggerId == 2 ? (
                            <>
                              {'Etapa do Funil '}
                              <i
                                className='fa-solid fa-asterisk'
                                style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                              ></i>
                            </>
                          ) : (
                            'Etapa do Funil'
                          )
                        }
                      >
                        <Select
                          menuPortalTarget={document.body}
                          placeholder={'Selecionar etapa do funil'}
                          name={'triggerConfig.stepId'}
                          options={stages}
                          value={triggerConfigStep}
                          className='fieldset-input'
                          onChange={(e: any) => {
                            setAutomaticAction({
                              ...automaticAction,
                              triggerConfig: {
                                ...automaticAction.triggerConfig,
                                stepId: parseInt(e.value),
                              },
                            })
                            setTriggerConfigStep(e)
                          }}
                          styles={customStyles}
                          noOptionsMessage={() => 'Sem Etapa Funil'}
                        />
                      </InputFieldSet>
                    </div>
                  ) : null}
                  <div className='fv-row mb-7'>
                    <InputFieldSet label={'Status'}>
                      <select
                        name='triggerConfig.status'
                        onChange={(e: any) => {
                          setAutomaticAction({
                            ...automaticAction,
                            triggerConfig: {
                              ...automaticAction.triggerConfig,
                              status: parseInt(e.target.value),
                            },
                          })
                        }}
                        onBlur={() => {}}
                        value={automaticAction.triggerConfig?.status}
                        data-control='select2'
                        data-hide-search='true'
                        className='form-control form-select form-select-sm form-select-white fieldset-input'
                        defaultValue={0}
                      >
                        <option value={0}>Selecionar</option>
                        {triggerStatusOptions.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </InputFieldSet>
                  </div>
                </div>
              )}
              <RestrictedInvervals
                setState={(state: any) => {
                  setAutomaticAction({...automaticAction, triggerRestrictedToInterval: state})
                }}
                switchTitle='Restringir horário'
                intervals={automaticAction.triggerRestrictedToInterval}
              />
            </>
          )}
        </>
      ),
    },
    {
      label: 'Configurar Ação',
      content: (
        <>
          <Form.Label>Agora, escolha uma ação a ser executada:</Form.Label>
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Qual tipo de ação deseja utilizar? '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <select
                name='actionId'
                onChange={handleOnChangeParsing}
                onBlur={() => {
                  setAutomaticAction({
                    ...automaticAction,
                    actionConfig: null,
                  })
                }}
                value={automaticAction.actionId}
                data-control='select2'
                data-hide-search='true'
                className={
                  'form-control form-select form-select-sm form-select-white fieldset-input'
                }
                defaultValue={automaticAction.actionId}
              >
                <option value=''>Selecionar</option>
                {actionConfigOptions.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </InputFieldSet>
          </div>
          {automaticAction.actionId == 1 ? (
            // criar form com titulo, data, hora, tipo da tarefa, contato e empresa
            <TaskForm
              setState={(state: any) => {
                //setar actionConfig.task
                setAutomaticAction({...automaticAction, actionConfig: {task: state}})
              }}
              state={automaticAction.actionConfig?.task}
            />
          ) : automaticAction.actionId == 2 ? (
            <div>
              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'Funil *'}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <SelectAsync
                    menuPortalTarget={document.body}
                    placeholder={''}
                    noOptionsMessage={() => 'Sem Funil'}
                    name='actionConfig.funnelId'
                    loadOptions={debounceFunnelsOptions}
                    value={automaticAction.actionConfig?.funnel}
                    cacheOptions
                    defaultOptions
                    isDisabled={false}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        actionConfigChange(selectedOption, 'funnelId', 2)
                      } else {
                        actionConfigChange(null, 'funnelId', 2)
                      }
                    }}
                    className='fieldset-input'
                    styles={customStyles}
                  />
                </InputFieldSet>
              </div>
              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'Etapa do Funil '}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <Select
                    menuPortalTarget={document.body}
                    placeholder={'Selecionar etapa do funil'}
                    name={'actionConfig.stepId'}
                    options={actionStages}
                    value={automaticAction.actionConfig?.step}
                    className='fieldset-input'
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        actionConfigChange(selectedOption, 'stepId', 2)
                      } else {
                        actionConfigChange(null, 'stepId', 2)
                      }
                    }}
                    styles={customStyles}
                    noOptionsMessage={() => 'Sem Etapa Funil'}
                  />
                </InputFieldSet>
              </div>
            </div>
          ) : automaticAction.actionId == 3 ? (
            <div>
              <div className='fv-row mb-7'>
                <InputFieldSet
                  label={
                    <>
                      {'Novo Status '}
                      <i
                        className='fa-solid fa-asterisk'
                        style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                      ></i>
                    </>
                  }
                >
                  <select
                    name='actionConfig.status'
                    onChange={(e) => {
                      actionConfigChange(e.target.value, 'status', 3)
                      terminationReasonsOptions('')
                    }}
                    onBlur={() => {}}
                    value={automaticAction.actionConfig?.status}
                    data-control='select2'
                    data-hide-search='true'
                    className='form-control form-select form-select-sm form-select-white fieldset-input'
                    defaultValue={0}
                  >
                    <option value={0}>Selecionar</option>
                    {actionStatusOptions.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </InputFieldSet>
              </div>
              {automaticAction.actionConfig?.status &&
                automaticAction.actionConfig?.status != 1 && // aberto
                automaticAction.actionConfig?.status != 4 && // congelado
                automaticAction.actionConfig?.status != 7 && ( // arquivado
                  <>
                    <div className='fv-row mb-7'>
                      <InputFieldSet label={'Motivo de Finalização'}>
                        <SelectAsync
                          menuPortalTarget={document.body}
                          placeholder={''}
                          name='terminationReason'
                          value={automaticAction.actionConfig?.terminationReason}
                          isClearable
                          //cacheOptions
                          defaultOptions={terminationReasons}
                          onChange={(selectedOption) => {
                            console.log(selectedOption)
                            if (selectedOption) {
                              actionConfigChange(selectedOption, 'terminationReason', 3)
                            } else {
                              actionConfigChange(null, 'terminationReason', 3)
                            }
                            if (selectedOption?.subReasons)
                              setTerminationSubReasons(selectedOption.subReasons)
                          }}
                          className='fieldset-input'
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderStyle: 'none',
                              backgroundColor: undefined,
                              boxShadow: 'none',
                            }),
                          }}
                        />
                      </InputFieldSet>
                    </div>

                    <div className='fv-row mb-7'>
                      <InputFieldSet label={'Sub Motivo de Finalização'}>
                        <SelectAsync
                          menuPortalTarget={document.body}
                          placeholder={''}
                          name='terminationSubReason'
                          value={automaticAction.actionConfig?.terminationSubreason}
                          isClearable
                          cacheOptions
                          defaultOptions={terminationSubReasons}
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              actionConfigChange(selectedOption, 'terminationSubreason', 3)
                            } else {
                              actionConfigChange(null, 'terminationSubreason', 3)
                            }
                          }}
                          className='fieldset-input'
                          styles={{
                            menuPortal: (base) => ({...base, zIndex: 9999}),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderStyle: 'none',
                              backgroundColor: undefined,
                              boxShadow: 'none',
                            }),
                          }}
                        />
                      </InputFieldSet>
                    </div>
                  </>
                )}
            </div>
          ) : null}
          <RestrictedInvervals
            setState={(state: any) => {
              setAutomaticAction({...automaticAction, actionRestrictedToInterval: state})
            }}
            switchTitle='Definir quando uma ação deve ser executada'
            intervals={automaticAction.actionRestrictedToInterval}
          />
        </>
      ),
    },
  ]

  const handleStepClick = (step: number) => {
    if (step < currentStep) {
      handlePrev()
    } else if (step > currentStep) {
      handleNext()
    }
  }

  return (
    <Modal
      show={automaticActionsFormModal.open}
      onHide={handleHide}
      centered
      scrollable
      contentClassName={''}
      dialogClassName={'modal-md'}
    >
      <Modal.Header>
        <Modal.Title>
          {automaticActionsFormModal.automaticActionId ? 'Editar' : 'Nova'} Ação Automatizada
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Nav variant='tabs'>
          {steps.map((step, index) => (
            <NavItem
              key={index}
              label={step.label}
              onClick={() => handleStepClick(index + 1)}
              active={index + 1 === currentStep}
            />
          ))}
        </Nav>

        <Step label={steps[currentStep - 1].label}>{steps[currentStep - 1].content}</Step>
      </Modal.Body>

      <Modal.Footer>
        {currentStep > 1 ? (
          <>
            <Button variant='secondary' onClick={handlePrev} disabled={currentStep === 1}>
              Anterior
            </Button>
            <Button variant='primary' onClick={onSubmit} disabled={false}>
              Salvar
            </Button>
          </>
        ) : currentStep == 1 ? (
          <>
            <Button variant='primary' onClick={handleNext} disabled={currentStep === steps.length}>
              Próximo
            </Button>
          </>
        ) : null}
      </Modal.Footer>
    </Modal>
  )
}

export default MultiStepForm
