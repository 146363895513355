import {createContext, useContext, useReducer} from 'react'

export interface AutomaticActionFormModalContextModel {
  open: boolean
  automaticActionId: number | null
}

const initialData = {
  open: false,
  automaticActionId: null,
}

const AutomaticActionFormModalContext =
  createContext<AutomaticActionFormModalContextModel>(initialData)
const AutomaticActionFormModalDispatchContext = createContext<any>(null)

export function AutomaticActionFormModalProvider({children}: any) {
  const [data, dispatch] = useReducer(automaticActionsFormModalReducer, initialData)

  return (
    <AutomaticActionFormModalContext.Provider value={data}>
      <AutomaticActionFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </AutomaticActionFormModalDispatchContext.Provider>
    </AutomaticActionFormModalContext.Provider>
  )
}

export function useAutomaticActionsFormModal() {
  return useContext(AutomaticActionFormModalContext)
}

export function useAutomaticActionsFormModalDispatch() {
  return useContext(AutomaticActionFormModalDispatchContext)
}

function automaticActionsFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {...data, open: true}
    }
    case 'open-edit-modal': {
      return {...data, open: true, automaticActionId: action.data}
    }
    case 'close-modal': {
      return {...data, open: false, automaticActionId: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
