// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Label} from '../../core/_models'
import { CARD_TYPES } from '../../../../cards/core/_models'

const tableColumns: ReadonlyArray<Column<Label>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='ID' className='min-w-125px' sort={false}/>,
    id: 'id',
    accessor: 'id',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Título' className='min-w-125px' sort={false}/>,
    id: 'label',
    accessor: 'label',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Entidade' className='min-w-125px' sort={false}/>
    ),
    id: 'entity',
    Cell: ({...props}) => {
      let entity = "";
      switch(props.data[props.row.index].entity)
      {
        case "contact":
            entity = "Contato";
            break;
        case "company":
          entity = "Empresa";
          break;
        case "card":
          entity =  "Oportunidade/Tícket";
          switch(props.data[props.row.index].entityType)
          {
            case CARD_TYPES.OPPORTUNITY.id:
              entity = "Oportunidade";
              break;
            case CARD_TYPES.TICKET.id:
              entity = "Tícket";
              break;
          }
          break;  
      }
      return entity;
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Tipo' className='min-w-125px' sort={false}/>
    ),
    id: 'type',
    Cell: ({...props}) => {
      return props.data[props.row.index].type?.name;
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {tableColumns}
