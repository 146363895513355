import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Label, LabelsQueryResponse} from './_models'
import {request} from "../../../../services/crm-api/request"

const getLabels = async (query: string):  Promise<LabelsQueryResponse> => {

  return request({
    method: "GET",
    url: `/labels?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<LabelsQueryResponse>) => d.data);
}

const getLabelById = (id: ID): Promise<Label | undefined> => {

  return request({
    method: "GET",
    url: `/labels/${id}`
  },{
    setAuth: true
  })
  .then((response: Response<Label>) => response.data)
}

const createLabel = (dataCreate: Label): Promise<Label | undefined> => {

  let data = {...dataCreate};

  if(data.status)
  {
    data.status = parseInt(data.status.toString());
  }

  return request({
    method: "post",
    url: `/labels`,
    data: data
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<Label>>) => response.data)
    .then((response: Response<Label>) => response.data)
}

const updateLabel = (dataUpdate: Label): Promise<Label | undefined> => {

  let data = {...dataUpdate};
  if(data.status)
  {
    data.status = parseInt(data.status.toString());
  }

  return request({
    method: "patch",
    url: `/labels/${dataUpdate.id}`,
    data: data
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<Label>>) => response.data)
    .then((response: Response<Label>) => response.data)
}

const deleteLabel = (id: ID): Promise<Label | undefined> => {

  return request({
    method: "DELETE",
    url: `/labels/${id}`
  },{
    setAuth: true
  })
  .then((response: any) => response.data)
}

export {getLabels, getLabelById, createLabel, updateLabel, deleteLabel}
