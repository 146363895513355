
import { Response, ID } from '../../../../../_metronic/helpers'
export type Task = {
  id?: ID
  title?: string
  date?: Date
  card?: any
  contact?: any
  company?: any
  responsible?: any
  observation?: string
  status?: number
  type?: any
  customFields?: any[]
  subType?: any
  showSubType?: boolean
}

export const TASK_STATUS = {
  SCHEDULED: 1,
  LATE: 2,
  COMPLETED: 3,
  CANCELED: 4,
};
export const USERS_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};
export const TASK_TYPES = [
  {
    id: 1,
    value: 'Tarefa',
  },
  {
    id: 2,
    value: 'E-mail',
  },
  {
    id: 3,
    value: 'Ligação',
  },
  {
    id: 4,
    value: 'Reunião',
  },
  {
    id: 5,
    value: 'Almoço',
  },
  {
    id: 6,
    value: 'Whatsapp',
  }
];

export type TasksQueryResponse = Response<Array<Task>>

export const initialTask: Task = {

}
