import { createContext, useContext, useReducer } from 'react';
import update from 'immutability-helper'

export interface CustomFormEditorFormFieldContextModel {
  formInternalId: null | number
  formType: null | number
  internalId: number | null
  id: number | null
  name: string
  type: any
  source: string
  label: string
  description?: string | null
  required: boolean
  status: number
  options: Array<any> | null
}

export interface CustomFormEditorFormContextModel {
  id: number | null
  internalId: null | number
  type: number | null
  name: string | null
  fields: Array<CustomFormEditorFormFieldContextModel>
  saved: boolean
}

export interface CustomFormEditorFieldEditorContextModel {
  field: CustomFormEditorFormFieldContextModel | null
  open: boolean
  action: string | null
}

export interface CustomFormEditorDetailEditorContextModel {
  form: CustomFormEditorFormContextModel | null
  open: boolean
}

export interface CustomFormEditorContextModel {
  entity: number | null
  entityType: number | null
  funnel: number | null
  forms: Array<CustomFormEditorFormContextModel>
  fieldEdit: CustomFormEditorFieldEditorContextModel
  formDetailEdit: CustomFormEditorDetailEditorContextModel
  activeForm: number | null
}

const formInitialData = {
  id: null,
  internalId: null,
  type: null,
  name: null,
  fields: [],
  saved: false
};

const fieldEditInitialData = {
  field: null,
  open: false,
  action: null
}

const formDetaildEditInitialData = {
  form: null,
  open: false
}

const initialData = {
  entity: null,
  entityType: null,
  funnel: null,
  forms: [],
  fieldEdit: fieldEditInitialData,
  formDetailEdit: formDetaildEditInitialData,
  activeForm: null,
}

const CustomFormEditorContext = createContext<CustomFormEditorContextModel>(initialData);
const CustomFormEditorDispatchContext = createContext<any>(null);

export function CustomFormEditorProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    customFormEditorReducer,
   initialData
  );

  return (
    <CustomFormEditorContext.Provider value={data}>
      <CustomFormEditorDispatchContext.Provider value={dispatch}>
        {children}
      </CustomFormEditorDispatchContext.Provider>
  </CustomFormEditorContext.Provider>
  );
}

export function useCustomFormEditor() {
  return useContext(CustomFormEditorContext);
}

export function useCustomFormEditorDispatch() {
  return useContext(CustomFormEditorDispatchContext);
}

function customFormEditorReducer(data: any, action: any) {
  switch (action.type) {
    case 'init': {
      let aux = {...initialData, forms: []};
      aux = {...aux, ...action.data};
      return aux;
    }
    case 'reset': {
      let aux = {...initialData, forms: []};
      return aux;
    }
    case 'set-active-form': {
      let aux = {...data};

      aux.activeForm = action.data;

      return aux;
    }
    case 'load-form': {
      let aux = {...data};

      aux.forms = [...aux.forms, action.data];

      if(aux.forms.length == 1)
      {
        aux.activeForm = action.data.internalId;
      }

      return aux;
    }
    case 'add-form': {
      let aux = {...data};

      aux.forms.push({...formInitialData, fields: [], ...action.data});

      return aux;
    }
    case 'update-form-detail': {
      let aux = {...data};

     let findForm = aux.forms.find((e: any) => e.internalId == action.data.internalId);
      if(findForm)
      {
        Object.assign(findForm, {name: action.data.name, saved: false});
      }

      return aux;
    }
    case 'remove-form': {
      let aux = {...data};

      aux.forms = aux.forms.filter((e: any) => e.internalId != action.data.internalId);

      if(aux.forms.length == 1 &&  aux.activeForm == action.data.internalId)
      {
        aux.activeForm = aux.forms[0].internalId;
      }

      return aux;
    }
    case 'save-form': {
      let aux = {...data};

      let findForm = aux.forms.find((e: any) => e.internalId == action.data.internalId);

      if(findForm)
      {
        if(!findForm.id) findForm.id = action.data.id;
        findForm.saved = true;
      }

      return aux;
    }
    case 'open-config-field-modal': {
      let aux = {...data};

      aux.fieldEdit.field = action.data;
      aux.fieldEdit.open = true;
      aux.fieldEdit.action = "config";

      return aux;
    }
    case 'open-add-field-modal': {
      let aux = {...data};

      aux.fieldEdit.field = action.data;
      aux.fieldEdit.open = true;
      aux.fieldEdit.action = "add";

      return aux;
    }
    case 'close-edit-field-modal': {
      let aux = {...data};

      aux.fieldEdit.field = null;
      aux.fieldEdit.open = false;

      return aux;
    }
    case 'open-edit-form-modal': {
      let aux = {...data};

      aux.formDetailEdit.form = action.data;
      aux.formDetailEdit.open = true;

      return aux;
    }
    case 'close-edit-form-modal': {
      let aux = {...data};

      aux.formDetailEdit.form = null;
      aux.formDetailEdit.open = false;

      return aux;
    }
    case 'add-field': {
      let aux = {...data};

      let findForm = aux.forms.find((e: any) => e.internalId == action.data.formInternalId);

      if(findForm)
      {
        findForm.fields.push(action.data);
        findForm.saved = false;
      }
        
      return aux;
    }
    case 'move-field': {
      let aux = {...data};

      let findForm = aux.forms.find((e: any) => e.internalId == action.data.formInternalId);
      if(findForm)
      {
        findForm.fields = update(findForm.fields, {
          $splice: [
            [action.data.dragIndex, 1],
            [action.data.hoverIndex, 0, findForm.fields[action.data.dragIndex]],
          ],
        });
        findForm.saved = false;
      }

      return aux;
    }
    case 'update-field': {
      let aux = {...data};
      let findForm = aux.forms.find((e: any) => e.internalId == action.data.formInternalId);
      if(findForm)
      {
        let findField = findForm.fields.find((e: any) => e.internalId == action.data.internalId);
        if(findField)
        {
          Object.assign(findField, action.data);
        }

        if(!action.notChangeSaveState) findForm.saved = false;
      }
      return aux;
    }
    case 'remove-field': {
      let aux = {...data};

      let findForm = aux.forms.find((e: any) => e.internalId == action.data.formInternalId);
      if(findForm)
      {
        findForm.fields = findForm.fields.filter((e: any) => e.internalId != action.data.internalId);
        findForm.saved = false;
      }

      return aux;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
