import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import { TicketCategory, TicketCategoriesQueryResponse} from './_models'
import {request} from "../../../../services/crm-api/request"

const getTicketCategories = async (query: string):  Promise<TicketCategoriesQueryResponse> => {

  return request({
    method: "GET",
    url: `/ticket-categories?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<TicketCategoriesQueryResponse>) => d.data);
}

const getTicketCategoryById = async (id: ID): Promise<any | undefined> => {

  return request({
    method: "GET",
    url: `/ticket-categories/${id}`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const createTicketCategory = (dataCreate: TicketCategory[]): Promise<TicketCategory | undefined> => {

  return request({
    method: "post",
    url: `/ticket-categories`,
    data: dataCreate
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<TicketCategory>>) => response.data)
    .then((response: Response<TicketCategory>) => response.data)
}

const updateTicketCategory = (dataUpdate: TicketCategory): Promise<TicketCategory | undefined> => {

  let data = {...dataUpdate};

  if(data.status)
  {
    data.status = parseInt(data.status.toString());
  }

  return request({
    method: "patch",
    url: `/ticket-categories/${dataUpdate.id}`,
    data: data
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<TicketCategory>>) => response.data)
    .then((response: Response<TicketCategory>) => response.data)
}

const deleteTicketCategory = (id: ID): Promise<TicketCategory | undefined> => {

  return request({
    method: "DELETE",
    url: `/ticket-categories/${id}`
  },{
    setAuth: true
  })
  .then((response: any) => response.data)
}

export {deleteTicketCategory, updateTicketCategory, createTicketCategory, getTicketCategoryById, getTicketCategories}
