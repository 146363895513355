import {useEffect} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {CardArchiveModalForm} from './CardArchiveModalForm'

import { useCardArchiveModal, useCardArchiveModalDispatch} from '../../core/CardArchiveModalContext'

type Props = {

}

const CardArchiveModal = ({} : Props) => {

  const cardArchiveModal = useCardArchiveModal();
  const cardArchiveModalDispatch = useCardArchiveModalDispatch()

  if(!cardArchiveModal.open) 
  {
    document.body.classList.remove('modal-open')
    return <></>
  }

  document.body.classList.add('modal-open');

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_freeze_opportunity'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-481px'>
          
          {/* begin::Modal content */}
          <div className='modal-content'>

          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>Arquivar Tícket</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              className='btn btn-icon btn-sm '
              data-kt-users-modal-action='close'
              onClick={() => cardArchiveModalDispatch({type: "close-modal"})}
              style={{cursor: 'pointer'}}
            >
              <KTIcon iconName='cross' iconType='solid' className='fs-1' style={{color:"#000"}}/>
          </div>
            {/* end::Close */}
          </div>

            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-7'>
                <CardArchiveModalForm card={cardArchiveModal.card}/>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {CardArchiveModal}
