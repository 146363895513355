import {FC} from 'react'

type Props = {
  color?: string
}

const ColorCell: FC<Props> = ({color}) => {
  return <><span style={{display: "block", width: "50px", height: "20px", backgroundColor: color}}></span></>;
}

export {ColorCell}
