import {FC} from 'react'

type Props = {
  company?: any
}

const CompanyCell: FC<Props> = ({company}) => {

  let text = "";

  if(company != undefined)
  {
    text = company.name;
  }
  
  return <>{text}</>;
}

export {CompanyCell}
