import axios, { AxiosResponse } from 'axios'
import { request } from '../../../../services/crm-api/request'
import { Response, ID } from '../../../../../_metronic/helpers'


const getTeamById = (id: ID): Promise<any | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/teams/${id}?include_users=true&include_funnels=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<any>) => response.data)
}

const createTeam = (dataCreate: any): Promise<any | undefined> => {
  return request(
    {
      method: 'post',
      url: `/teams`,
      data: dataCreate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateTeam = (id: number, dataUpdate: any): Promise<any | undefined> => {
  return request(
    {
      method: 'patch',
      url: `/teams/${id}`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}



const getTeams = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/teams?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => {
    return response.data
  })
}
const deleteTeam = (id: number): Promise<any | undefined> => {
  return request(
    {
      method: 'delete',
      url: `/teams/${id}`,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}



export {
  getTeamById,
  createTeam,
  updateTeam,
  getTeams,
  deleteTeam
}
