import {useQuery} from 'react-query'
import {FunnelsEditModalForm} from './FunnelsEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../../../modules/view/ListViewProvider'

type Props = {
  funnelStage: any
  onCloseModal: any
  funnel: any
}

const FunnelsEditModalFormWrapper = ({funnel, funnelStage, onCloseModal}: Props) => {

  if (!funnelStage) {
    return <FunnelsEditModalForm isLoading={false} funnel={funnel} funnelStage={{id: undefined}} onCloseModal={onCloseModal}/>
  }

  if (funnelStage) {
    return <FunnelsEditModalForm isLoading={false} funnel={funnel} funnelStage={funnelStage} onCloseModal={onCloseModal}/>
  }

  return null
}

export {FunnelsEditModalFormWrapper}
