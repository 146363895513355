import {TeamsListSearchComponent} from './TeamsListSearchComponent'
import {TeamsListFilter} from './TeamsListFilter'
import {useTeamFormModalDispatch} from '../../core/TeamFormModalContext'
import {useNavigate} from 'react-router-dom'
const TeamsListHeader = () => {
  const navigate = useNavigate()
  const userFormModalDispatch = useTeamFormModalDispatch()
  const openAddModal = () => {
    // userFormModalDispatch({
    //   type: 'open-add-modal',
    // })
    navigate('/teams/add')
  }

  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-people-group fs-2 me-1'></i>
          <h3>
            <strong> Equipes</strong>
          </h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <TeamsListSearchComponent />

          <TeamsListFilter />

          <a
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
            style={{marginLeft: '3rem'}}
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export {TeamsListHeader}
