export const strHasLink = (str: string) => {
  return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
    str
  )
}

export const replaceUrlsToLinks = (str: string) => {
  let words = str.split(' ')
  words = words.map((word) => {
    if (strHasLink(word)) {
      return '<a style="color: #0000c8;" target="_blank" href="' + word + '">' + word + '</a>'
    }

    return word
  })
  return words.join(' ')
}
