import Afeganistao from '../assets/images/flags/Afeganistão.png'
import AfricadoSul from '../assets/images/flags/África do Sul.png'
import Albania from '../assets/images/flags/Albânia.png'
import Alemanha from '../assets/images/flags/Alemanha.png'
import Andorra from '../assets/images/flags/Andorra.png'
import Angola from '../assets/images/flags/Angola.png'
import Anguilla from '../assets/images/flags/Anguilla.png'
import AntiguaeBarbuda from '../assets/images/flags/Antígua e Barbuda.png'
import AntilhasHolandesas from '../assets/images/flags/Antilhas Holandesas.png'
import ArabiaSaudita from '../assets/images/flags/Arábia Saudita.png'
import Argelia from '../assets/images/flags/Argélia.png'
import Argentina from '../assets/images/flags/Argentina.png'
import Armenia from '../assets/images/flags/Armênia.png'
import Aruba from '../assets/images/flags/Aruba.png'
import Australia from '../assets/images/flags/Austrália.png'
import Austria from '../assets/images/flags/Áustria.png'
import Azerbaijao from '../assets/images/flags/Azerbaijão.png'
import Bahamas from '../assets/images/flags/Bahamas.png'
import Bangladesh from '../assets/images/flags/Bangladesh.png'
import Barbados from '../assets/images/flags/Barbados.png'
import Bahrein from '../assets/images/flags/Bahrein.png'
import Belgica from '../assets/images/flags/Bélgica.png'
import Belize from '../assets/images/flags/Belize.png'
import Benim from '../assets/images/flags/Benim.png'
import Bermudas from '../assets/images/flags/Bermudas.png'
import Bielorrussia from '../assets/images/flags/Bielorrússia.png'
import Bolivia from '../assets/images/flags/Bolívia.png'
import BosniaeHerzegovina from '../assets/images/flags/Bósnia e Herzegovina.png'
import Botswana from '../assets/images/flags/Botswana.png'
import Brasil from '../assets/images/flags/Brasil.png'
import Brunei from '../assets/images/flags/Brunei.png'
import Bulgaria from '../assets/images/flags/Bulgária.png'
import BurkinaFaso from '../assets/images/flags/Burkina Faso.png'
import Burundi from '../assets/images/flags/Burundi.png'
import Butao from '../assets/images/flags/Butão.png'
import CaboVerde from '../assets/images/flags/Cabo Verde.png'
import Camaroes from '../assets/images/flags/Camarões.png'
import Camboja from '../assets/images/flags/Camboja.png'
import Canada from '../assets/images/flags/Canadá.png'
import Cazaquistao from '../assets/images/flags/Cazaquistão.png'
import Chade from '../assets/images/flags/Chade.png'
import Chile from '../assets/images/flags/Chile.png'
import RepublicaPopulardaChina from '../assets/images/flags/República Popular da China.png'
import Chipre from '../assets/images/flags/Chipre.png'
import Colombia from '../assets/images/flags/Colômbia.png'
import Comores from '../assets/images/flags/Comores.png'
import CongoBrazzaville from '../assets/images/flags/CongoBrazzaville.png'
import CongoKinshasa from '../assets/images/flags/CongoKinshasa.png'
import CoreiadoNorte from '../assets/images/flags/Coreia do Norte.png'
import CoreiadoSul from '../assets/images/flags/Coreia do Sul.png'
import CostadoMarfim from '../assets/images/flags/Costa do Marfim.png'
import CostaRica from '../assets/images/flags/Costa Rica.png'
import Croacia from '../assets/images/flags/Croácia.png'
import Cuba from '../assets/images/flags/Cuba.png'
import Dinamarca from '../assets/images/flags/Dinamarca.png'
import Djibuti from '../assets/images/flags/Djibuti.png'
import Dominica from '../assets/images/flags/Dominica.png'
import Egipto from '../assets/images/flags/Egipto.png'
import ElSalvador from '../assets/images/flags/El Salvador.png'
import EmiradosArabesUnidos from '../assets/images/flags/Emirados Árabes Unidos.png'
import Equador from '../assets/images/flags/Equador.png'
import Eritreia from '../assets/images/flags/Eritreia.png'
import Eslovaquia from '../assets/images/flags/Eslováquia.png'
import Eslovenia from '../assets/images/flags/Eslovénia.png'
import Espanha from '../assets/images/flags/Espanha.png'
import EstadosUnidos from '../assets/images/flags/Estados Unidos.png'
import Estonia from '../assets/images/flags/Estónia.png'
import Etiopia from '../assets/images/flags/Etiópia.png'
import Fiji from '../assets/images/flags/Fiji.png'
import Filipinas from '../assets/images/flags/Filipinas.png'
import Finlandia from '../assets/images/flags/Finlândia.png'
import Franca from '../assets/images/flags/França.png'
import Gabao from '../assets/images/flags/Gabão.png'
import Gambia from '../assets/images/flags/Gâmbia.png'
import Gana from '../assets/images/flags/Gana.png'
import Georgia from '../assets/images/flags/Geórgia.png'
import Gibraltar from '../assets/images/flags/Gibraltar.png'
import Granada from '../assets/images/flags/Granada.png'
import Grecia from '../assets/images/flags/Grécia.png'
import Groenlandia from '../assets/images/flags/Groenlândia.png'
import Guadalupe from '../assets/images/flags/Guadalupe.png'
import Guam from '../assets/images/flags/Guam.png'
import Guatemala from '../assets/images/flags/Guatemala.png'
import Guiana from '../assets/images/flags/Guiana.png'
import GuianaFrancesa from '../assets/images/flags/Guiana Francesa.png'
import Guine from '../assets/images/flags/Guiné.png'
import GuineBissau from '../assets/images/flags/GuinéBissau.png'
import GuineEquatorial from '../assets/images/flags/Guiné Equatorial.png'
import Haiti from '../assets/images/flags/Haiti.png'
import HongKong from '../assets/images/flags/Hong Kong.png'
import Hungria from '../assets/images/flags/Hungria.png'
import Iemen from '../assets/images/flags/Iêmen.png'
import IlhasCayman from '../assets/images/flags/Ilhas Cayman.png'
import IlhaChristmas from '../assets/images/flags/Ilha Christmas.png'
import IlhasCocos from '../assets/images/flags/Ilhas Cocos.png'
import IlhasCook from '../assets/images/flags/Ilhas Cook.png'
import IlhasFeroe from '../assets/images/flags/Ilhas Féroe.png'
import IlhaHeardeIlhasMcDonald from '../assets/images/flags/Ilha Heard e Ilhas McDonald.png'
import Maldivas from '../assets/images/flags/Maldivas.png'
import IlhasMalvinas from '../assets/images/flags/Ilhas Malvinas.png'
import IlhasMarianasdoNorte from '../assets/images/flags/Ilhas Marianas do Norte.png'
import IlhasMarshall from '../assets/images/flags/Ilhas Marshall.png'
import IlhaNorfolk from '../assets/images/flags/Ilha Norfolk.png'
import IlhasSalomao from '../assets/images/flags/Ilhas Salomão.png'
import IlhasVirgensAmericanas from '../assets/images/flags/Ilhas Virgens Americanas.png'
import IlhasVirgensBritanicas from '../assets/images/flags/Ilhas Virgens Britânicas.png'
import India from '../assets/images/flags/Índia.png'
import Indonesia from '../assets/images/flags/Indonésia.png'
import Ira from '../assets/images/flags/Irã.png'
import Iraque from '../assets/images/flags/Iraque.png'
import Irlanda from '../assets/images/flags/Irlanda.png'
import Islandia from '../assets/images/flags/Islândia.png'
import Israel from '../assets/images/flags/Israel.png'
import Italia from '../assets/images/flags/Itália.png'
import Jamaica from '../assets/images/flags/Jamaica.png'
import Japao from '../assets/images/flags/Japão.png'
import Jordania from '../assets/images/flags/Jordânia.png'
import Kiribati from '../assets/images/flags/Kiribati.png'
import Kosovo from '../assets/images/flags/Kosovo.png'
import Kuwait from '../assets/images/flags/Kuwait.png'
import Laos from '../assets/images/flags/Laos.png'
import Lesoto from '../assets/images/flags/Lesoto.png'
import Letonia from '../assets/images/flags/Letônia.png'
import Libano from '../assets/images/flags/Líbano.png'
import Liberia from '../assets/images/flags/Libéria.png'
import Libia from '../assets/images/flags/Líbia.png'
import Liechtenstein from '../assets/images/flags/Liechtenstein.png'
import Lituania from '../assets/images/flags/Lituânia.png'
import Luxemburgo from '../assets/images/flags/Luxemburgo.png'
import Macau from '../assets/images/flags/Macau.png'
import RepublicadaMacedonia from '../assets/images/flags/República da Macedônia.png'
import Madagascar from '../assets/images/flags/Madagascar.png'
import Malasia from '../assets/images/flags/Malásia.png'
import Malawi from '../assets/images/flags/Malawi.png'
import Mali from '../assets/images/flags/Mali.png'
import Malta from '../assets/images/flags/Malta.png'
import Marrocos from '../assets/images/flags/Marrocos.png'
import Martinica from '../assets/images/flags/Martinica.png'
import Mauricia from '../assets/images/flags/Maurícia.png'
import Mauritania from '../assets/images/flags/Mauritânia.png'
import Mayotte from '../assets/images/flags/Mayotte.png'
import Mexico from '../assets/images/flags/México.png'
import EstadosFederadosdaMicronesia from '../assets/images/flags/Estados Federados da Micronésia.png'
import Mocambique from '../assets/images/flags/Moçambique.png'
import Moldavia from '../assets/images/flags/Moldávia.png'
import Monaco from '../assets/images/flags/Mônaco.png'
import Mongolia from '../assets/images/flags/Mongólia.png'
import Montenegro from '../assets/images/flags/Montenegro.png'
import Montserrat from '../assets/images/flags/Montserrat.png'
import Myanmar from '../assets/images/flags/Myanmar.png'
import Namibia from '../assets/images/flags/Namíbia.png'
import Nauru from '../assets/images/flags/Nauru.png'
import Nepal from '../assets/images/flags/Nepal.png'
import Nicaragua from '../assets/images/flags/Nicarágua.png'
import Niger from '../assets/images/flags/Níger.png'
import Nigeria from '../assets/images/flags/Nigéria.png'
import Niue from '../assets/images/flags/Niue.png'
import Noruega from '../assets/images/flags/Noruega.png'
import NovaCaledonia from '../assets/images/flags/Nova Caledônia.png'
import NovaZelandia from '../assets/images/flags/Nova Zelândia.png'
import Oma from '../assets/images/flags/Omã.png'
import PaisesBaixos from '../assets/images/flags/Países Baixos.png'
import Palau from '../assets/images/flags/Palau.png'
import Palestina from '../assets/images/flags/Palestina.png'
import Panama from '../assets/images/flags/Panamá.png'
import PapuaNovaGuine from '../assets/images/flags/PapuaNova Guiné.png'
import Paquistao from '../assets/images/flags/Paquistão.png'
import Paraguai from '../assets/images/flags/Paraguai.png'
import Peru from '../assets/images/flags/Peru.png'
import PolinesiaFrancesa from '../assets/images/flags/Polinésia Francesa.png'
import Polonia from '../assets/images/flags/Polônia.png'
import PortoRico from '../assets/images/flags/Porto Rico.png'
import Portugal from '../assets/images/flags/Portugal.png'
import Qatar from '../assets/images/flags/Qatar.png'
import Quenia from '../assets/images/flags/Quênia.png'
import Quirguistao from '../assets/images/flags/Quirguistão.png'
import RepublicaCentroAfricana from '../assets/images/flags/República CentroAfricana.png'
import RepublicaDominicana from '../assets/images/flags/República Dominicana.png'
import RepublicaTcheca from '../assets/images/flags/República Tcheca.png'
import Reuniao from '../assets/images/flags/Reunião.png'
import Romenia from '../assets/images/flags/Romênia.png'
import Ruanda from '../assets/images/flags/Ruanda.png'
import Russia from '../assets/images/flags/Rússia.png'
import SaaraOcidental from '../assets/images/flags/Saara Ocidental.png'
import Samoa from '../assets/images/flags/Samoa.png'
import SamoaAmericana from '../assets/images/flags/Samoa Americana.png'
import SantaHelenaterritorio from '../assets/images/flags/Santa Helena território.png'
import SantaLucia from '../assets/images/flags/Santa Lúcia.png'
import SaoCristovaoeNevis from '../assets/images/flags/São Cristóvão e Nevis.png'
import SaoMarinho from '../assets/images/flags/São Marinho.png'
import SaintPierreeMiquelon from '../assets/images/flags/SaintPierre e Miquelon.png'
import SaoVicenteeGranadinas from '../assets/images/flags/São Vicente e Granadinas.png'
import Seicheles from '../assets/images/flags/Seicheles.png'
import Senegal from '../assets/images/flags/Senegal.png'
import SerraLeoa from '../assets/images/flags/Serra Leoa.png'
import Servia from '../assets/images/flags/Sérvia.png'
import Singapura from '../assets/images/flags/Singapura.png'
import Siria from '../assets/images/flags/Síria.png'
import Somalia from '../assets/images/flags/Somália.png'
import SriLanka from '../assets/images/flags/Sri Lanka.png'
import Suazilandia from '../assets/images/flags/Suazilândia.png'
import Sudao from '../assets/images/flags/Sudão.png'
import SudaodoSul from '../assets/images/flags/Sudão do Sul.png'
import Suecia from '../assets/images/flags/Suécia.png'
import Suica from '../assets/images/flags/Suíça.png'
import Suriname from '../assets/images/flags/Suriname.png'
import Tadjiquistao from '../assets/images/flags/Tadjiquistão.png'
import Tailandia from '../assets/images/flags/Tailândia.png'
import RepublicadaChina from '../assets/images/flags/República da China.png'
import Tanzania from '../assets/images/flags/Tanzânia.png'
import TerritorioBritanicodoOceanoIndico from '../assets/images/flags/Território Britânico do Oceano Índico.png'
import TimorLeste from '../assets/images/flags/TimorLeste.png'
import Togo from '../assets/images/flags/Togo.png'
import Tokelau from '../assets/images/flags/Tokelau.png'
import Tonga from '../assets/images/flags/Tonga.png'
import TrinidadeTobago from '../assets/images/flags/Trinidad e Tobago.png'
import Tunisia from '../assets/images/flags/Tunísia.png'
import TurcaseCaicos from '../assets/images/flags/Turcas e Caicos.png'
import Turquemenistao from '../assets/images/flags/Turquemenistão.png'
import Turquia from '../assets/images/flags/Turquia.png'
import Tuvalu from '../assets/images/flags/Tuvalu.png'
import Ucrania from '../assets/images/flags/Ucrânia.png'
import Uganda from '../assets/images/flags/Uganda.png'
import Uruguai from '../assets/images/flags/Uruguai.png'
import Uzbequistao from '../assets/images/flags/Uzbequistão.png'
import Vanuatu from '../assets/images/flags/Vanuatu.png'
import Venezuela from '../assets/images/flags/Venezuela.png'
import Zimbabue from '../assets/images/flags/Zimbábue.png'

export const JsonFlags = [
  {
    pais: 'Brasil',
    img: Brasil,
    ddi: 55,
    continente: 'América do Sul',
  },
  {
    pais: 'Afeganistão',
    img:Afeganistao,
    ddi: 93,
    continente: 'Ásia',
  },
  {
    pais: 'África do Sul',
    img: AfricadoSul,
    ddi: 27,
    continente: 'África',
  },
  {
    pais: 'Albânia',
    img: Albania,
    ddi: 355,
    continente: 'Europa',
  },
  {
    pais: 'Alemanha',
    img: Alemanha,
    ddi: 49,
    continente: 'Europa',
  },
  {
    pais: 'Andorra',
    img: Andorra,
    ddi: 376,
    continente: 'Europa',
  },
  {
    pais: 'Angola',
    img: Angola,
    ddi: 244,
    continente: 'África',
  },
  {
    pais: 'Anguilla',
    img: Anguilla,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Antígua e Barbuda',
    img: AntiguaeBarbuda,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Antilhas Holandesas',
    img: AntilhasHolandesas,
    ddi: 599,
    continente: 'América Central',
  },
  {
    pais: 'Arábia Saudita',
    img: ArabiaSaudita,
    ddi: 966,
    continente: 'Ásia',
  },
  {
    pais: 'Argélia',
    img: Argelia,
    ddi: 213,
    continente: 'África',
  },
  {
    pais: 'Argentina',
    img: Argentina,
    ddi: 54,
    continente: 'América do Sul',
  },
  {
    pais: 'Armênia',
    img: Armenia,
    ddi: 374,
    continente: 'Ásia',
  },
  {
    pais: 'Aruba',
    img: Aruba,
    ddi: 297,
    continente: 'América Central',
  },
  // {
  //   pais: 'Ascensão',
  //   img: Ascensao,
  //   ddi: 247,
  //   continente: 'África',
  // },
  {
    pais: 'Austrália',
    img: Australia,
    ddi: 61,
    continente: 'Oceania',
  },
  {
    pais: 'Áustria',
    img: Austria,
    ddi: 43,
    continente: 'Europa',
  },
  {
    pais: 'Azerbaijão',
    img: Azerbaijao,
    ddi: 994,
    continente: 'Ásia',
  },
  {
    pais: 'Bahamas',
    img: Bahamas,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Bangladesh',
    img: Bangladesh,
    ddi: 880,
    continente: 'Ásia',
  },
  {
    pais: 'Barbados',
    img: Barbados,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Bahrein',
    img: Bahrein,
    ddi: 973,
    continente: 'Ásia',
  },
  {
    pais: 'Bélgica',
    img: Belgica,
    ddi: 32,
    continente: 'Europa',
  },
  {
    pais: 'Belize',
    img: Belize,
    ddi: 501,
    continente: 'América Central',
  },
  {
    pais: 'Benim',
    img: Benim,
    ddi: 229,
    continente: 'África',
  },
  {
    pais: 'Bermudas',
    img: Bermudas,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Bielorrússia',
    img: Bielorrussia,
    ddi: 375,
    continente: 'Europa',
  },
  {
    pais: 'Bolívia',
    img: Bolivia,
    ddi: 591,
    continente: 'América do Sul',
  },
  {
    pais: 'Bósnia e Herzegovina',
    img: BosniaeHerzegovina,
    ddi: 387,
    continente: 'Europa',
  },
  {
    pais: 'Botswana',
    img: Botswana,
    ddi: 267,
    continente: 'África',
  },
  {
    pais: 'Brunei',
    img: Brunei,
    ddi: 673,
    continente: 'Ásia',
  },
  {
    pais: 'Bulgária',
    img: Bulgaria,
    ddi: 359,
    continente: 'Europa',
  },
  {
    pais: 'Burkina Faso',
    img: BurkinaFaso,
    ddi: 226,
    continente: 'África',
  },
  {
    pais: 'Burundi',
    img: Burundi,
    ddi: 257,
    continente: 'África',
  },
  {
    pais: 'Butão',
    img: Butao,
    ddi: 975,
    continente: 'Ásia',
  },
  {
    pais: 'Cabo Verde',
    img: CaboVerde,
    ddi: 238,
    continente: 'África',
  },
  {
    pais: 'Camarões',
    img: Camaroes,
    ddi: 237,
    continente: 'África',
  },
  {
    pais: 'Camboja',
    img: Camboja,
    ddi: 855,
    continente: 'Ásia',
  },
  {
    pais: 'Canadá',
    img: Canada,
    ddi: 1,
    continente: 'América do Norte',
  },
  {
    pais: 'Cazaquistão',
    img: Cazaquistao,
    ddi: 7,
    continente: 'Ásia',
  },
  {
    pais: 'Chade',
    img: Chade,
    ddi: 235,
    continente: 'África',
  },
  {
    pais: 'Chile',
    img: Chile,
    ddi: 56,
    continente: 'América do Sul',
  },
  {
    pais: 'República Popular da China',
    img: RepublicaPopulardaChina,
    ddi: 86,
    continente: 'Ásia',
  },
  {
    pais: 'Chipre',
    img: Chipre,
    ddi: 357,
    continente: 'Europa',
  },
  {
    pais: 'Colômbia',
    img: Colombia,
    ddi: 57,
    continente: 'América do Sul',
  },
  {
    pais: 'Comores',
    img: Comores,
    ddi: 269,
    continente: 'África',
  },
  {
    pais: 'CongoBrazzaville',
    img: CongoBrazzaville,
    ddi: 242,
    continente: 'África',
  },
  {
    pais: 'CongoKinshasa',
    img: CongoKinshasa,
    ddi: 243,
    continente: 'África',
  },
  {
    pais: 'Coreia do Norte',
    img: CoreiadoNorte,
    ddi: 850,
    continente: 'Ásia',
  },
  {
    pais: 'Coreia do Sul',
    img: CoreiadoSul,
    ddi: 82,
    continente: 'Ásia',
  },
  {
    pais: 'Costa do Marfim',
    img: CostadoMarfim,
    ddi: 225,
    continente: 'África',
  },
  {
    pais: 'Costa Rica',
    img: CostaRica,
    ddi: 506,
    continente: 'América Central',
  },
  {
    pais: 'Croácia',
    img: Croacia,
    ddi: 385,
    continente: 'Europa',
  },
  {
    pais: 'Cuba',
    img: Cuba,
    ddi: 53,
    continente: 'América Central',
  },
  {
    pais: 'Dinamarca',
    img: Dinamarca,
    ddi: 45,
    continente: 'Europa',
  },
  {
    pais: 'Djibuti',
    img: Djibuti,
    ddi: 253,
    continente: 'África',
  },
  {
    pais: 'Dominica',
    img: Dominica,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Egipto',
    img: Egipto,
    ddi: 20,
    continente: 'África/Ásia',
  },
  {
    pais: 'El Salvador',
    img: ElSalvador,
    ddi: 503,
    continente: 'América Central',
  },
  {
    pais: 'Emirados Árabes Unidos',
    img: EmiradosArabesUnidos,
    ddi: 971,
    continente: 'Ásia',
  },
  {
    pais: 'Equador',
    img: Equador,
    ddi: 593,
    continente: 'América do Sul',
  },
  {
    pais: 'Eritreia',
    img: Eritreia,
    ddi: 291,
    continente: 'África',
  },
  {
    pais: 'Eslováquia',
    img: Eslovaquia,
    ddi: 421,
    continente: 'Europa',
  },
  {
    pais: 'Eslovénia',
    img: Eslovenia,
    ddi: 386,
    continente: 'Europa',
  },
  {
    pais: 'Espanha',
    img: Espanha,
    ddi: 34,
    continente: 'Europa',
  },
  {
    pais: 'Estados Unidos',
    img: EstadosUnidos,
    ddi: 1,
    continente: 'América do Norte',
  },
  {
    pais: 'Estónia',
    img: Estonia,
    ddi: 372,
    continente: 'Europa',
  },
  {
    pais: 'Etiópia',
    img: Etiopia,
    ddi: 251,
    continente: 'África',
  },
  {
    pais: 'Fiji',
    img: Fiji,
    ddi: 679,
    continente: 'Oceania',
  },
  {
    pais: 'Filipinas',
    img: Filipinas,
    ddi: 63,
    continente: 'Ásia',
  },
  {
    pais: 'Finlândia',
    img: Finlandia,
    ddi: 358,
    continente: 'Europa',
  },
  {
    pais: 'França',
    img: Franca,
    ddi: 33,
    continente: 'Europa',
  },
  {
    pais: 'Gabão',
    img: Gabao,
    ddi: 241,
    continente: 'África',
  },
  {
    pais: 'Gâmbia',
    img: Gambia,
    ddi: 220,
    continente: 'África',
  },
  {
    pais: 'Gana',
    img: Gana,
    ddi: 233,
    continente: 'África',
  },
  {
    pais: 'Geórgia',
    img: Georgia,
    ddi: 995,
    continente: 'Ásia',
  },
  {
    pais: 'Gibraltar',
    img: Gibraltar,
    ddi: 350,
    continente: 'Europa',
  },
  {
    pais: 'Granada',
    img: Granada,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Grécia',
    img: Grecia,
    ddi: 30,
    continente: 'Europa',
  },
  {
    pais: 'Groenlândia',
    img: Groenlandia,
    ddi: 299,
    continente: 'América do Norte',
  },
  {
    pais: 'Guadalupe',
    img: Guadalupe,
    ddi: 590,
    continente: 'América Central',
  },
  {
    pais: 'Guam',
    img: Guam,
    ddi: 1,
    continente: 'Oceania',
  },
  {
    pais: 'Guatemala',
    img: Guatemala,
    ddi: 502,
    continente: 'América Central',
  },
  {
    pais: 'Guiana',
    img: Guiana,
    ddi: 592,
    continente: 'América do Sul',
  },
  {
    pais: 'Guiana Francesa',
    img: GuianaFrancesa,
    ddi: 594,
    continente: 'América do Sul',
  },
  {
    pais: 'Guiné',
    img: Guine,
    ddi: 224,
    continente: 'África',
  },
  {
    pais: 'GuinéBissau',
    img: GuineBissau,
    ddi: 245,
    continente: 'África',
  },
  {
    pais: 'Guiné Equatorial',
    img: GuineEquatorial,
    ddi: 240,
    continente: 'África',
  },
  {
    pais: 'Haiti',
    img: Haiti,
    ddi: 509,
    continente: 'América Central',
  },
  // {
  //   pais: 'Honduras',
  //   img: Honduras,
  //   ddi: 504,
  //   continente: 'América Central',
  // },
  {
    pais: 'Hong Kong',
    img: HongKong,
    ddi: 852,
    continente: 'Ásia',
  },
  {
    pais: 'Hungria',
    img: Hungria,
    ddi: 36,
    continente: 'Europa',
  },
  {
    pais: 'Iêmen',
    img: Iemen,
    ddi: 967,
    continente: 'Ásia',
  },
  {
    pais: 'Ilhas Cayman',
    img: IlhasCayman,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Ilha Christmas',
    img: IlhaChristmas,
    ddi: 672,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Cocos',
    img: IlhasCocos,
    ddi: 672,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Cook',
    img: IlhasCook,
    ddi: 682,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Féroe',
    img: IlhasFeroe,
    ddi: 298,
    continente: 'Europa',
  },
  {
    pais: 'Ilha Heard e Ilhas McDonald',
    img: IlhaHeardeIlhasMcDonald,
    ddi: 672,
    continente: 'Oceania',
  },
  {
    pais: 'Maldivas',
    img: Maldivas,
    ddi: 960,
    continente: 'Ásia',
  },
  {
    pais: 'Ilhas Malvinas',
    img: IlhasMalvinas,
    ddi: 500,
    continente: 'América do Sul',
  },
  {
    pais: 'Ilhas Marianas do Norte',
    img: IlhasMarianasdoNorte,
    ddi: 1,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Marshall',
    img: IlhasMarshall,
    ddi: 692,
    continente: 'Oceania',
  },
  {
    pais: 'Ilha Norfolk',
    img: IlhaNorfolk,
    ddi: 672,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Salomão',
    img: IlhasSalomao,
    ddi: 677,
    continente: 'Oceania',
  },
  {
    pais: 'Ilhas Virgens Americanas',
    img: IlhasVirgensAmericanas,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Ilhas Virgens Britânicas',
    img: IlhasVirgensBritanicas,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Índia',
    img: India,
    ddi: 91,
    continente: 'Ásia',
  },
  {
    pais: 'Indonésia',
    img: Indonesia,
    ddi: 62,
    continente: 'Ásia/Oceania',
  },
  {
    pais: 'Irã',
    img: Ira,
    ddi: 98,
    continente: 'Ásia',
  },
  {
    pais: 'Iraque',
    img: Iraque,
    ddi: 964,
    continente: 'Ásia',
  },
  {
    pais: 'Irlanda',
    img:Irlanda,
    ddi: 353,
    continente: 'Europa',
  },
  {
    pais: 'Islândia',
    img: Islandia,
    ddi: 354,
    continente: 'Europa',
  },
  {
    pais: 'Israel',
    img: Israel,
    ddi: 972,
    continente: 'Ásia',
  },
  {
    pais: 'Itália',
    img: Italia,
    ddi: 39,
    continente: 'Europa',
  },
  {
    pais: 'Jamaica',
    img: Jamaica,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Japão',
    img: Japao,
    ddi: 81,
    continente: 'Ásia',
  },
  {
    pais: 'Jordânia',
    img: Jordania,
    ddi: 962,
    continente: 'Ásia',
  },
  {
    pais: 'Kiribati',
    img: Kiribati,
    ddi: 686,
    continente: 'Oceania',
  },
  {
    pais: 'Kosovo',
    img: Kosovo,
    ddi: 383,
    continente: 'Europa',
  },
  {
    pais: 'Kuwait',
    img: Kuwait,
    ddi: 965,
    continente: 'Ásia',
  },
  {
    pais: 'Laos',
    img: Laos,
    ddi: 856,
    continente: 'Ásia',
  },
  {
    pais: 'Lesoto',
    img: Lesoto,
    ddi: 266,
    continente: 'África',
  },
  {
    pais: 'Letônia',
    img: Letonia,
    ddi: 371,
    continente: 'Europa',
  },
  {
    pais: 'Líbano',
    img:Libano,
    ddi: 961,
    continente: 'Ásia',
  },
  {
    pais: 'Libéria',
    img:Liberia,
    ddi: 231,
    continente: 'África',
  },
  {
    pais: 'Líbia',
    img:Libia,
    ddi: 218,
    continente: 'África',
  },
  {
    pais: 'Liechtenstein',
    img: Liechtenstein,
    ddi: 423,
    continente: 'Europa',
  },
  {
    pais: 'Lituânia',
    img:Lituania,
    ddi: 370,
    continente: 'Europa',
  },
  {
    pais: 'Luxemburgo',
    img:Luxemburgo,
    ddi: 352,
    continente: 'Europa',
  },
  {
    pais: 'Macau',
    img:Macau,
    ddi: 853,
    continente: 'Ásia',
  },
  {
    pais: 'República da Macedônia',
    img:RepublicadaMacedonia,
    ddi: 389,
    continente: 'Europa',
  },
  {
    pais: 'Madagascar',
    img:Madagascar,
    ddi: 261,
    continente: 'África',
  },
  {
    pais: 'Malásia',
    img:Malasia,
    ddi: 60,
    continente: 'Ásia',
  },
  {
    pais: 'Malawi',
    img: Malawi,
    ddi: 265,
    continente: 'África',
  },
  {
    pais: 'Mali',
    img:Mali,
    ddi: 223,
    continente: 'África',
  },
  {
    pais: 'Malta',
    img:Malta,
    ddi: 356,
    continente: 'Europa',
  },
  {
    pais: 'Marrocos',
    img:Marrocos,
    ddi: 212,
    continente: 'África',
  },
  {
    pais: 'Martinica',
    img:Martinica,
    ddi: 596,
    continente: 'América Central',
  },
  {
    pais: 'Maurícia',
    img:Mauricia,
    ddi: 230,
    continente: 'África',
  },
  {
    pais: 'Mauritânia',
    img:Mauritania,
    ddi: 222,
    continente: 'África',
  },
  {
    pais: 'Mayotte',
    img:Mayotte,
    ddi: 269,
    continente: 'África',
  },
  {
    pais: 'México',
    img:Mexico,
    ddi: 52,
    continente: 'América do Norte',
  },
  {
    pais: 'Estados Federados da Micronésia',
    img:EstadosFederadosdaMicronesia,
    ddi: 691,
    continente: 'Oceania',
  },
  {
    pais: 'Moçambique',
    img:Mocambique,
    ddi: 258,
    continente: 'África',
  },
  {
    pais: 'Moldávia',
    img:Moldavia,
    ddi: 373,
    continente: 'Europa',
  },
  {
    pais: 'Mônaco',
    img:Monaco,
    ddi: 377,
    continente: 'Europa',
  },
  {
    pais: 'Mongólia',
    img:Mongolia,
    ddi: 976,
    continente: 'Ásia',
  },
  {
    pais: 'Montenegro',
    img:Montenegro,
    ddi: 382,
    continente: 'Europa',
  },
  {
    pais: 'Montserrat',
    img:Montserrat,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Myanmar',
    img:Myanmar,
    ddi: 95,
    continente: 'Ásia',
  },
  {
    pais: 'Namíbia',
    img:Namibia,
    ddi: 264,
    continente: 'África',
  },
  {
    pais: 'Nauru',
    img:Nauru,
    ddi: 674,
    continente: 'Oceania',
  },
  {
    pais: 'Nepal',
    img:Nepal,
    ddi: 977,
    continente: 'Ásia',
  },
  {
    pais: 'Nicarágua',
    img:Nicaragua,
    ddi: 505,
    continente: 'América Central',
  },
  {
    pais: 'Níger',
    img:Niger,
    ddi: 227,
    continente: 'África',
  },
  {
    pais: 'Nigéria',
    img:Nigeria,
    ddi: 234,
    continente: 'África',
  },
  {
    pais: 'Niue',
    img:Niue,
    ddi: 683,
    continente: 'Oceania',
  },
  {
    pais: 'Noruega',
    img:Noruega,
    ddi: 47,
    continente: 'Europa',
  },
  {
    pais: 'Nova Caledônia',
    img:NovaCaledonia,
    ddi: 687,
    continente: 'Oceania',
  },
  {
    pais: 'Nova Zelândia',
    img:NovaZelandia,
    ddi: 64,
    continente: 'Oceania',
  },
  {
    pais: 'Omã',
    img:Oma,
    ddi: 968,
    continente: 'Ásia',
  },
  {
    pais: 'Países Baixos',
    img:PaisesBaixos,
    ddi: 31,
    continente: 'Europa',
  },
  {
    pais: 'Palau',
    img:Palau,
    ddi: 680,
    continente: 'Oceania',
  },
  {
    pais: 'Palestina',
    img:Palestina,
    ddi: 970,
    continente: 'Ásia',
  },
  {
    pais: 'Panamá',
    img:Panama,
    ddi: 507,
    continente: 'América Central',
  },
  {
    pais: 'PapuaNova Guiné',
    img:PapuaNovaGuine,
    ddi: 675,
    continente: 'Oceania',
  },
  {
    pais: 'Paquistão',
    img:Paquistao,
    ddi: 92,
    continente: 'Ásia',
  },
  {
    pais: 'Paraguai',
    img:Paraguai,
    ddi: 595,
    continente: 'América do Sul',
  },
  {
    pais: 'Peru',
    img:Peru,
    ddi: 51,
    continente: 'América do Sul',
  },
  {
    pais: 'Polinésia Francesa',
    img:PolinesiaFrancesa,
    ddi: 689,
    continente: 'Oceania',
  },
  {
    pais: 'Polônia',
    img:Polonia,
    ddi: 48,
    continente: 'Europa',
  },
  {
    pais: 'Porto Rico',
    img:PortoRico,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Portugal',
    img:Portugal,
    ddi: 351,
    continente: 'Europa',
  },
  {
    pais: 'Qatar',
    img:Qatar,
    ddi: 974,
    continente: 'Ásia',
  },
  {
    pais: 'Quênia',
    img:Quenia,
    ddi: 254,
    continente: 'África',
  },
  {
    pais: 'Quirguistão',
    img:Quirguistao,
    ddi: 996,
    continente: 'Ásia',
  },
  // {
  //   pais: 'Reino Unido',
  //   img:ReinoUnido,
  //   ddi: 44,
  //   continente: 'Europa',
  // },
  {
    pais: 'República CentroAfricana',
    img:RepublicaCentroAfricana,
    ddi: 236,
    continente: 'África',
  },
  {
    pais: 'República Dominicana',
    img: RepublicaDominicana,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'República Tcheca',
    img:RepublicaTcheca,
    ddi: 420,
    continente: 'Europa',
  },
  {
    pais: 'Reunião',
    img:Reuniao,
    ddi: 262,
    continente: 'África',
  },
  {
    pais: 'Romênia',
    img: Romenia,
    ddi: 40,
    continente: 'Europa',
  },
  {
    pais: 'Ruanda',
    img: Ruanda,
    ddi: 250,
    continente: 'África',
  },
  {
    pais: 'Rússia',
    img: Russia,
    ddi: 7,
    continente: 'Europa/Ásia',
  },
  {
    pais: 'Saara Ocidental',
    img:SaaraOcidental,
    ddi: 212,
    continente: 'África',
  },
  {
    pais: 'Samoa',
    img: Samoa,
    ddi: 685,
    continente: 'Oceania',
  },
  {
    pais: 'Samoa Americana',
    img: SamoaAmericana,
    ddi: 1,
    continente: 'Oceania',
  },
  {
    pais: 'Santa Helena território',
    img: SantaHelenaterritorio,
    ddi: 290,
    continente: 'África',
  },
  {
    pais: 'Santa Lúcia',
    img: SantaLucia,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'São Cristóvão e Nevis',
    img: SaoCristovaoeNevis,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'São Marinho',
    img: SaoMarinho,
    ddi: 378,
    continente: 'Europa',
  },
  {
    pais: 'SaintPierre e Miquelon',
    img: SaintPierreeMiquelon,
    ddi: 508,
    continente: 'América do Norte',
  },
  // {
  //   pais: 'São Tomé e Príncipe',
  //   img: SaoTomeePrincipe,
  //   ddi: 239,
  //   continente: 'África',
  // },
  {
    pais: 'São Vicente e Granadinas',
    img: SaoVicenteeGranadinas,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Seicheles',
    img: Seicheles,
    ddi: 248,
    continente: 'África',
  },
  {
    pais: 'Senegal',
    img: Senegal,
    ddi: 221,
    continente: 'África',
  },
  {
    pais: 'Serra Leoa',
    img: SerraLeoa,
    ddi: 232,
    continente: 'África',
  },
  {
    pais: 'Sérvia',
    img: Servia,
    ddi: 381,
    continente: 'Europa',
  },
  {
    pais: 'Singapura',
    img: Singapura,
    ddi: 65,
    continente: 'Ásia',
  },
  {
    pais: 'Síria',
    img: Siria,
    ddi: 963,
    continente: 'Ásia',
  },
  {
    pais: 'Somália',
    img: Somalia,
    ddi: 252,
    continente: 'África',
  },
  {
    pais: 'Sri Lanka',
    img: SriLanka,
    ddi: 94,
    continente: 'Ásia',
  },
  {
    pais: 'Suazilândia',
    img: Suazilandia,
    ddi: 268,
    continente: 'África',
  },
  {
    pais: 'Sudão',
    img: Sudao,
    ddi: 249,
    continente: 'África',
  },
  {
    pais: 'Sudão do Sul',
    img: SudaodoSul,
    ddi: 211,
    continente: 'África',
  },
  {
    pais: 'Suécia',
    img: Suecia,
    ddi: 46,
    continente: 'Europa',
  },
  {
    pais: 'Suíça',
    img: Suica,
    ddi: 41,
    continente: 'Europa',
  },
  {
    pais: 'Suriname',
    img: Suriname,
    ddi: 597,
    continente: 'América do Sul',
  },
  {
    pais: 'Tadjiquistão',
    img: Tadjiquistao,
    ddi: 992,
    continente: 'Ásia',
  },
  {
    pais: 'Tailândia',
    img: Tailandia,
    ddi: 66,
    continente: 'Ásia',
  },
  {
    pais: 'República da China',
    img:RepublicadaChina,
    ddi: 886,
    continente: 'Ásia',
  },
  {
    pais: 'Tanzânia',
    img:Tanzania,
    ddi: 255,
    continente: 'África',
  },
  {
    pais: 'Território Britânico do Oceano Índico',
    img:TerritorioBritanicodoOceanoIndico,
    ddi: 246,
    continente: 'África',
  },
  {
    pais: 'TimorLeste',
    img:TimorLeste,
    ddi: 670,
    continente: 'Ásia',
  },
  {
    pais: 'Togo',
    img:Togo,
    ddi: 228,
    continente: 'África',
  },
  {
    pais: 'Tokelau',
    img:Tokelau,
    ddi: 690,
    continente: 'Oceania',
  },
  {
    pais: 'Tonga',
    img:Tonga,
    ddi: 676,
    continente: 'Oceania',
  },
  {
    pais: 'Trinidad e Tobago',
    img:TrinidadeTobago,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Tunísia',
    img:Tunisia,
    ddi: 216,
    continente: 'África',
  },
  {
    pais: 'Turcas e Caicos',
    img: TurcaseCaicos,
    ddi: 1,
    continente: 'América Central',
  },
  {
    pais: 'Turquemenistão',
    img: Turquemenistao,
    ddi: 993,
    continente: 'Ásia',
  },
  {
    pais: 'Turquia',
    img: Turquia,
    ddi: 90,
    continente: 'Ásia//Europa',
  },
  {
    pais: 'Tuvalu',
    img: Tuvalu,
    ddi: 688,
    continente: 'Oceania',
  },
  {
    pais: 'Ucrânia',
    img: Ucrania,
    ddi: 380,
    continente: 'Europa',
  },
  {
    pais: 'Uganda',
    img:Uganda,
    ddi: 256,
    continente: 'África',
  },
  {
    pais: 'Uruguai',
    img:Uruguai,
    ddi: 598,
    continente: 'América do Sul',
  },
  {
    pais: 'Uzbequistão',
    img:Uzbequistao,
    ddi: 998,
    continente: 'Ásia',
  },
  {
    pais: 'Vanuatu',
    img:Vanuatu,
    ddi: 678,
    continente: 'Oceania',
  },
  // {
  //   pais: 'Vaticano',
  //   img:Vaticano,
  //   ddi: 379,
  //   continente: 'Europa',
  // },
  {
    pais: 'Venezuela',
    img:Venezuela,
    ddi: 58,
    continente: 'América do Sul',
  },
  // {
  //   pais: 'Vietnã',
  //   img: Vietna,
  //   ddi: 84,
  //   continente: 'Ásia',
  // },
  // {
  //   pais: 'Wallis e Futuna',
  //   img:WalliseFutuna,
  //   ddi: 681,
  //   continente: 'Oceania',
  // },
  // {
  //   pais: 'Zâmbia',
  //   img: Zambia,
  //   ddi: 260,
  //   continente: 'África',
  // },
  {
    pais: 'Zimbábue',
    img:Zimbabue,
    ddi: 263,
    continente: 'África',
  },
]
