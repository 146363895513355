import {getTasks} from '../../../app/pages/tasks/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {TASK_STATUS} from '../../../app/pages/tasks/core/_models'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ptBRTimeStrings from 'react-timeago/lib/language-strings/pt-br'
import {ButtonAddAttachment} from '../attachament-component/styles'
import {useTaskFormModalDispatch} from '../../../app/pages/tasks/core/TaskFormModalContext'
import {TaskFormModalProvider} from '../../../app/pages/tasks/core/TaskFormModalContext'
import {TaskFormModal} from '../../../app/pages/tasks/edit-modal/TaskFormModal'
const TaskData = ({entityData, entity}: any) => {
  const navigate = useNavigate()
  const formatter = buildFormatter(ptBRTimeStrings)
  const taskFormModalDispatch = useTaskFormModalDispatch()

  const {
    isLoading,
    isError,
    data: tasks,
    error,
    refetch,
  } = useQuery({
    queryKey: ['companies', 'tasks', 'contact', entityData.id],
    queryFn: () => {
      console.log('queryFn load TasksTabDetail')
      return getTasks(
        `include_responsible=true&page=1&perPage=100&${
          entity == 'contact' ? 'contactId=' : entity == 'card' ? 'cardId=' : 'companyId='
        }` + entityData.id
      )
    },
    enabled: true,
    cacheTime: 10 * 1000, //10 seconds
    staleTime: 10 * 1000, //10 seconds
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadTaskStatus = (status: number) => {
    let stText = ''
    switch (status) {
      case TASK_STATUS.CANCELED:
        stText = 'Cancelada'
        break
      case TASK_STATUS.COMPLETED:
        stText = 'Concluída'
        break
      case TASK_STATUS.LATE:
        stText = 'Atrasada'
        break
      case TASK_STATUS.SCHEDULED:
        stText = 'Agendada'
        break
    }

    return stText
  }

  const openAddModal = () => {
    taskFormModalDispatch({
      type: 'open-add-modal',
      entity_data: {
        entity_type: entity,
        entityData: entityData,
      },
    })
  }

  if (isError || !tasks) return null

  return (
    <>
      {' '}
      <TaskFormModal
        needRefetch={() => {
          refetch()
        }}
      />
      <h3 style={{marginTop: '10px', marginBottom: '10px'}}>Tarefas</h3>
      {tasks?.data?.length == 0 ? 'Sem tarefas até o momento.' : ''}
      {tasks?.data?.map((e: any) => (
        <>
          <div className='mb-5'>
            <div className='d-flex justify-content-between' style={{fontWeight: '700'}}>
              {e.title}{' '}
              <i
                className='fa-solid fa-arrow-up-right-from-square'
                style={{fontSize: '1.5rem', cursor: 'pointer', color: 'var(--wl-btn-background-primary-collor)'}}
                onClick={() => {
                  const url = `/tasks/edit/${e.id}`
                  window.open(url, '_blank')
                  // navigate(`/tasks/edit/${e.id}`)
                }}
              ></i>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <div>
                  {' '}
                  <i className='fa-solid fa-user' style={{color: 'var(--wl-second)'}}></i>{' '}
                  {e.responsible.name}
                </div>
                <div>
                  Criada{' '}
                  {e.createdAt && (
                    <TimeAgo live={false} date={new Date(e.createdAt)} formatter={formatter} />
                  )}
                </div>
              </div>
              <div>
                <div style={{textAlign: 'right'}}>{loadTaskStatus(e.status)}</div>
                <div>
                  Agendada para{' '}
                  {new Date(e.date).toLocaleDateString('pt-BR', {
                    hour: '2-digit',
                    hour12: false,
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ))}{' '}
      <div className='mt-5'>
        {' '}
        <button
          type='button'
          className='btn-sm btn-wl-custom-primary-collor-inverse-border'
          onClick={openAddModal}
        >
          Adicionar Tarefa
        </button>
      </div>
    </>
  )
}

const TaskComponent = ({entityData, entity}: any) => {
  return (
    <>
      {' '}
      <TaskFormModalProvider>
        <TaskData entityData={entityData} entity={entity} />
      </TaskFormModalProvider>
    </>
  )
}

export default TaskComponent
