import {useQuery} from 'react-query'
import {TerminationReasonEditModalForm} from './TerminationReasonEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {getTerminationReasonById} from '../core/_requests'

const TerminationReasonEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: terminationReason,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    async () => {
      let result:any = await getTerminationReasonById(itemIdForUpdate)
      for (let i =0; i<result?.funnels?.length;i++){
        result.funnels[i] = {label: result.funnels[i].name, value: result.funnels[i].id}
      }
      return result
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <TerminationReasonEditModalForm isLoading={isLoading} terminationReason={{id: undefined}} />
  }

  if (!isLoading && !error && terminationReason) {
    return <TerminationReasonEditModalForm isLoading={isLoading} terminationReason={terminationReason} />
  }

  return null
}

export {TerminationReasonEditModalFormWrapper}
