/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
//import { QueryResponseProvider } from './core/QueryResponseProvider'
import { TerminationReasonsListHeader } from './components/header/TerminationReasonsListHeader'
import { TerminationReasonsTable } from './table/TerminationReasonsTable'
import { TerminationReasonEditModal } from './edit-modal/TerminationReasonEditModal'
import {getTerminationReasons} from './core/_requests'

const TerminationReasonsSettingsPage = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <TerminationReasonsListHeader />
      <KTCard>
        <TerminationReasonsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TerminationReasonEditModal />}
    </>
  )
}

const TerminationReasonsSettings: FC = () => {
  return (
    <>
    {/*
      <h1>
        <strong>CRM</strong>
      </h1>
      <div className='breadcrumbs' style={{ marginBottom: "0.5rem" }}>
        Painel da Empresa / CRM / Configurações / Motivos de Finalização
      </div>
  */}
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getTerminationReasons}>
          <ListViewProvider>
            <TerminationReasonsSettingsPage />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { TerminationReasonsSettings }
