import { useDrag } from 'react-dnd'

const FieldTypeDraggable = ({ obj, children, source, canDrag }: { obj: any, children: any, source: string, canDrag?: boolean }) => {

    obj.source = source;

    const [{ isDragging }, dragRef] = useDrag({
      type: source,
      item: obj,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    })
  
    return (
      <div style={{
        minHeight: "46px",
        padding: "8px",
        backgroundColor: canDrag===false?"#cacaca" :"var(--wl-third)",
        borderRadius: "8px",
        // border: "1px solid #f5f6f7",
        alignItems: "center",
        display: "flex",
        fontWeight: "600",
        cursor: "move"
      }}
        ref={canDrag===false?undefined:dragRef}
        key={obj.internalId}
      >
        {children}
      </div>
    )
  }

  export default FieldTypeDraggable;