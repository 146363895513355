import { createContext, useContext, useReducer } from 'react';

export interface CardFormModalContextModel {
  open: boolean
  formAction?: string
  cardType?: number
  cardId?: number
  funnelId?: number
  funnelName?:string
  activeTab?: string
  addData?: any
  customData?: any
}

const initialData = {
  open: false
};

const CardFormModalContext = createContext<CardFormModalContextModel>(initialData);
const CardFormModalDispatchContext = createContext<any>(null);

export function CardFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardFormModalReducer,
    initialData
  );

  return (
    <CardFormModalContext.Provider value={data}>
      <CardFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardFormModalDispatchContext.Provider>
  </CardFormModalContext.Provider>
  );
}

export function useCardFormModal() {
  return useContext(CardFormModalContext);
}

export function useCardFormModalDispatch() {
  return useContext(CardFormModalDispatchContext);
}

function cardFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-primary-data-modal': {
      return { ...data, open: true, formAction: "add-primary-data", cardType: action.data.type, customData:action?.data?.customData}
    }
    case 'open-add-modal': {
      return { ...data, open: true, formAction: "add", cardType: action.data.type, funnelId: action.data.funnelId,addData:action?.data?.addData}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, formAction: "edit", cardType: action.data.type, cardId: action.data.id, customData:action?.data?.customData }
    }
    case 'open-duplicate-modal': {
      return { ...data, open: true, formAction: "duplicate", cardType: action.data.type, cardId: action.data.id }
    }
    case 'open-detail-modal': {
      return { ...data, open: true, formAction: "detail", cardType: action.data.type, cardId: action.data.id, funnelId: action.data.funnelId }
    }
    case 'close-modal': {
      return { ...data, open: false, formAction: undefined, cardType: undefined, cardId: undefined }
    }
    case 'set-active-tab': {
      return { ...data, activeTab: action.data }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
