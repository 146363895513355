import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {Company} from '../core/_models'
import {ListLoading} from '../../../modules/view/ListLoading'
import {
  createCompany,
  updateCompany,
  getBusinessSegments,
  getLabels,
  getUsers,
} from '../core/_requests'
import {
  getCustomFormByEntity,
  getDefaultFields,
  getCustomFields,
} from '../../settings/custom-forms/core/_requests'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import Swal from 'sweetalert2'
import debounce from 'debounce-promise'
import {useQuery, useMutation} from '@tanstack/react-query'
import FieldTypeInput from '../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'
import {useCompanyFormModalDispatch} from '../core/CompanyFormModalContext'
import {title} from 'process'
import {useNavigate} from 'react-router-dom'
import {getCustomFormById} from '../../settings/custom-forms/core/_requests'
import {formatCustomFieldInputDataToDb} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldInputDataToDb'
import {formatCustomFieldDbDataToInput} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldDbDataToInput'

type Props = {
  isLoading: boolean
  company?: Company
  embed?: boolean
  onSave?: any
  customFormId?: number
}

type OptionSelect = {
  label: string
  value: any
}

const editDataSchema = Yup.object().shape({})

const CompaniesForm: FC<Props> = ({company, isLoading, embed, onSave, customFormId}) => {
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  const companyFormModalDispatch = useCompanyFormModalDispatch()

  const handleClose = () => {
    if (onSave) onSave()

    if (embed) return

    companyFormModalDispatch({
      type: 'close-modal',
    })

    navigate('/companies')
    refetch()
  }

  const {
    isLoading: systemFormIsLoading,
    isError: systemFormIsError,
    data: systemFormData,
    error: systemFormError,
  } = useQuery({
    queryKey: ['companies', 'custom-forms', 'details', 'company', 'customform', customFormId],
    queryFn: async () => {
      let dataRes = null
      if (customFormId) {
        dataRes = await getCustomFormById(customFormId)
      }

      if (dataRes) {
        let workFields: any = []
        dataRes.fields.forEach((e: any) => {
          if (e.customFieldId) {
            let options = e.customField?.options ? e.customField.options : [];
            workFields.push({...e, name: 'custom_' + e.customFieldId, options: options })
          } else {
            workFields.push(e)
          }
        })

        dataRes.fields = workFields
      }

      return dataRes
    },
    enabled: true,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: defaultSystemFieldsIsLoading,
    isError: defaultSystemFieldsIsError,
    data: defaultSystemFields,
    error: defaultSystemFieldsError,
  } = useQuery({
    queryKey: ['companies', 'custom-forms', 'default-fields', 'company'],
    queryFn: async () => {
      let dfFields = await getDefaultFields('company')
      let workDfFields: any = []
      dfFields.forEach((e: any) => {
        workDfFields.push({...e, type: {key: e.type}})
      })
      return workDfFields
    },
    enabled: !systemFormData && !systemFormIsError,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: customFieldsIsLoading,
    isError: customFieldsIsError,
    data: customFields,
    error: customFieldsError,
  } = useQuery({
    queryKey: ['companies', 'custom-forms', 'custom-fields', 'company'],
    queryFn: async () => {
      let cfields = await getCustomFields('company')
      let workCFields: any = []
      cfields.forEach((e: any) => {
        workCFields.push({...e, name: 'custom_' + e.id})
      })
      return workCFields
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: businessSegmentsIsLoading,
    isError: businessSegmentsIsError,
    data: businessSegmentsData,
    error: businessSegmentsError,
  } = useQuery({
    queryKey: ['business-segments'],
    queryFn: async () => {
      let segData = await getBusinessSegments('perPage=100')
      let segList: any = []
      segData.forEach((e) => {
        segList.push({label: e.name, value: e.id})
      })

      return segList
    },
    enabled: true,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadLabels = () => {
    let labels: Array<any> = []
    if (!company) return labels

    if (company.labels == undefined) return labels
    for (let i = 0; i < company.labels?.length; i++) {
      labels.push({label: company.labels[i].name, value: company.labels[i].id})
    }
    return labels
  }

  const loadCustomFields = (entityCustomFields: any, customFieldsCheck: any) => {
    let fields: any = {}
    if (!entityCustomFields) return fields

    for (let i = 0; i < entityCustomFields.length; i++) {
      let useCField = entityCustomFields[i]

      let formatReturn = formatCustomFieldDbDataToInput(useCField, customFieldsCheck)

      fields['custom_' + useCField.id] = formatReturn
    }

    return fields
  }

  const [dataForEdit, setDataForEdit] = useState<any>(null)

  const labelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let labels = await getLabels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (labels && labels != undefined) {
        for (let i = 0; i < labels.length; i++) {
          list.push({label: labels[i].name, value: labels[i].id})
        }
      }
      resolve(list)
    })
  const debounceLabelsOptions = debounce(labelsOptions, 500)

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const peopleOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let peop = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (peop && peop.data != undefined) {
        for (let i = 0; i < peop?.data?.length; i++) {
          list.push({label: peop.data[i].name, value: peop.data[i].id})
        }
      }
      resolve(list)
    })
  const debouncePeopleOptions = debounce(peopleOptions, 500)

  const mountSelectLoadOptions = (field: any) => {
    switch (field.name) {
      case 'labelIds':
        return debounceLabelsOptions
      case 'responsibleId':
        return debounceResponsiblesOptions
    }

    switch (field.type?.key) {
      case 'person':
        return debouncePeopleOptions
      default:
        return undefined
    }
  }

  useEffect(() => {
    if (customFields === undefined) return undefined //Aguardar para ver se tem campos customizados
    if (dataForEdit) return undefined

    setDataForEdit({
      ...company,
      customFields: [],
      businessSegmentId: company ? company.businessSegment?.id : undefined,
      responsibleId: company?.responsible
        ? {label: company.responsible.name, value: company.responsible.id}
        : undefined,
      labelIds: loadLabels(),
      ...loadCustomFields(company?.customFields, customFields),
    })
  }, [systemFormIsLoading, defaultSystemFieldsIsLoading, customFieldsIsLoading])

  if (systemFormIsLoading || defaultSystemFieldsIsLoading || customFieldsIsLoading || !dataForEdit)
    return <ListLoading />

  if (systemFormIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  if (defaultSystemFieldsIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  return (
    <>
      <Formik
        initialValues={dataForEdit}
        validationSchema={editDataSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true)
          //console.log("values", values);

          let auxValues: any = {...values}
          if (!auxValues.name)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Nome Fantasia é Obrigatório.',
              icon: 'warning',
              confirmButtonText: 'OK',
            })
          if (auxValues.labelIds) {
            let auxLabels: Array<number> = []
            for (let i = 0; i < auxValues.labelIds.length; i++) {
              auxLabels.push(auxValues.labelIds[i].value)
            }
            auxValues.labelIds = auxLabels
          }

          if (auxValues.responsibleId && auxValues.responsibleId.value) {
            auxValues.responsibleId = auxValues.responsibleId.value
          }

          if (auxValues.businessSegmentId && auxValues.businessSegmentId.value) {
            auxValues.businessSegmentId = auxValues.businessSegmentId.value
          }

          let customFieldsValue = []
          let objKeys = Object.keys(auxValues)
          for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i].includes('custom_')) {
              let useField = auxValues[objKeys[i]]
              let idCustom = objKeys[i].replace('custom_', '')

              let valueCustom = formatCustomFieldInputDataToDb(
                {
                  id: idCustom,
                  value: useField,
                },
                customFields
              )

              if (valueCustom === undefined) {
                console.log('Falha ao formatar valor do campo customizado')
                continue
              }

              customFieldsValue.push({
                id: idCustom,
                value: valueCustom,
              })
            }
          }
          auxValues.customFields = customFieldsValue

          try {
            if (isNotEmpty(company?.id)) {
              await updateCompany(auxValues)
            } else {
              await createCompany(auxValues)
            }

            Swal.fire('Registro salvo com Sucesso!!', '', 'success')

            handleClose()
          } catch (ex: any) {
            console.error(ex.response.data)
            let errorDetected = ''

            if (ex.response?.data?.key && ex.response?.data?.message) {
              errorDetected = ex.response?.data?.message
            } else if (ex.response?.data?.message && Array.isArray(ex.response?.data?.message)) {
              if (ex.response?.data?.message.includes('name should not be empty'))
                errorDetected = 'Campo Nome Fantasia é Obrigatório'
            }
            Swal.fire({
              title: 'Opss..',
              text: errorDetected ? errorDetected : 'Houve um problema ao salvar a empresa.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            {/* begin::Scroll 
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 gap-8'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          */}
            <div className='d-flex flex-column me-n7 pe-7 gap-8' id='kt_modal_add_user_scroll'>
              {systemFormData &&
                systemFormData.fields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      selectOptions={
                        field.name == 'businessSegmentId' ? businessSegmentsData : undefined
                      }
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}

              {!systemFormData &&
                defaultSystemFields &&
                defaultSystemFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      selectOptions={
                        field.name == 'businessSegmentId' ? businessSegmentsData : undefined
                      }
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}

              {!systemFormData &&
                customFields &&
                customFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-sm btn-wl-custom-primary-collor'
                data-kt-users-modal-action='submit'
                disabled={isLoading || props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(props.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(props.isSubmitting || isLoading) && <ListLoading />}
          </form>
        )}
      </Formik>
    </>
  )
}

export {CompaniesForm}
