import styled from 'styled-components'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {DateTimePicker} from '@mui/x-date-pickers'

export default styled(DateTimePicker)`
  & .MuiInputBase-input {
    height: 20px;
    padding: 0px;
  }

  & .MuiInputBase-root {
    height: auto;
    min-height: 32px;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`
