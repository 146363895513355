import axios, { AxiosResponse } from 'axios'
import { Response, ID } from '../../../../../_metronic/helpers'
import { request } from '../../../../services/crm-api/request'


const getUserById = (id: ID): Promise<any | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/users/${id}?include_role=true&include_teams=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<any>) => response.data)
}

const createUser = (dataCreate: any): Promise<any | undefined> => {
  return request(
    {
      method: 'post',
      url: `/users`,
      data: dataCreate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateUser = (id: number, dataUpdate: any): Promise<any | undefined> => {
  return request(
    {
      method: 'patch',
      url: `/users/${id}`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}



const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const activateUser = (user_id: number): Promise<any | undefined> => {
  return request(
    {
      method: 'post',
      url: `/users/${user_id}/reactivate`,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const deactivateUser = (user_id: number): Promise<any | undefined> => {
  return request(
    {
      method: 'post',
      url: `/users/${user_id}/deactivate`,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateUserPassword = (id: number, dataUpdate: any): Promise<any | undefined> => {
  return request(
    {
      method: 'post',
      url: `users/${id}/change-password`,
      data: dataUpdate,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}
export {
  getUserById,
  createUser,
  updateUser,
  getUsers,
  deactivateUser,
  activateUser,
  updateUserPassword
}
