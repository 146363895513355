import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'

const ExpandImageModal: React.FC<any> = ({show, imageUrl, onClose}) => {
  const handleClose = () => {
    onClose()
  }

  const handleDownload = () => {
    const downloadLink = document.createElement('a')
    downloadLink.href = imageUrl
    downloadLink.target = '_blank'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  // const handleDownload = () => {

  //   fetch(imageUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'imagem.jpg';
  //       a.style.display = 'none';
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       document.body.removeChild(a);
  //     })
  //     .catch((error) => {
  //       console.error('Erro ao baixar a imagem:', error);
  //     });
  // };

  if (!show) return null
  return (
    <>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header style={{paddingBottom: '5px', paddingTop: '5px'}}>
          <Button variant='link' className='modal-icon' onClick={handleDownload}>
            <i className='fas fa-download' style={{fontSize: '30px', color: '#93a4ee'}}></i>
          </Button>
          <Button variant='link' className='modal-icon' onClick={handleClose}>
            <i className='fas fa-times' style={{fontSize: '30px', color: '#93a4ee'}}></i>
          </Button>
        </Modal.Header>

        <Image src={imageUrl} fluid />
      </Modal>
    </>
  )
}

export default ExpandImageModal
