import {FC} from 'react'

type Props = {
  phones?: Array<any>
}

const PhoneCell: FC<Props> = ({phones}) => {

  let text = "";

  if(phones != undefined && phones.length > 0)
  {
    for(let i = 0; i < phones.length; i++)
    {
      if(i == 2) break;
      if(i == 1) text += ", ";
      text += phones[i].number;
    }
  }
  
  return <>{text}</>;
}

export {PhoneCell}
