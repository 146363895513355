import React, {useState} from 'react'

interface CustomInputProps {
  name: string
  type: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string // Adicionamos uma propriedade para o placeholder
}

const AuthInput: React.FC<CustomInputProps> = ({name, type, value, onChange, placeholder}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isOvered, setIsOvered] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlurFocus = () => {
    setIsFocused(false)
  }
  const handleOver = () => {
    setIsOvered(true)
  }
  const handleBlurOver = () => {
    setIsOvered(false)
  }
  return (
    <input
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      autoComplete="new-password"
      placeholder={placeholder}
      className='form-control bg-white'
      style={{
        border: '2px solid #e1e3ea',
        borderColor: isFocused || isOvered ? '#0000c8' : '',
        backgroundColor: isFocused || isOvered ? 'white' : '',
      }}
      onMouseOver={handleOver}
      onMouseLeave={handleBlurOver}
      onFocus={handleFocus}
      onBlur={handleBlurFocus}
    />
  )
}

export default AuthInput
