import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl, KTIcon} from '../../../../../_metronic/helpers'
import {initialFunnel, Funnel} from '../core/_models'
import {getUsers, getTeams} from '../core/_requests'
import clsx from 'clsx'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {createFunnel, updateFunnel} from '../core/_requests'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import debounce from 'debounce-promise'
import SelectAsync from 'react-select/async'
import {useFunnelSettingsModalDispatch} from '../core/FunnelSettingsModalContext'
import Form from 'react-bootstrap/Form'

type Props = {
  isLoading: boolean
  funnel?: Funnel
}

const editDataSchema = Yup.object().shape({})

const FunnelSettingsForm: FC<Props> = ({funnel, isLoading}) => {
  const {refetch} = useQueryResponse()

  const funnelSettingsModalDispatch = useFunnelSettingsModalDispatch()

  const handleClose = () => {
    funnelSettingsModalDispatch({
      type: 'close-modal',
    })
    refetch()
  }

  const loadUsers = () => {
    let users: Array<any> = []
    if (!funnel) return users
    if (funnel.users == undefined) return users
    for (let i = 0; i < funnel.users?.length; i++) {
      users.push({label: funnel.users[i].name, value: funnel.users[i].id})
    }
    return users
  }

  const loadTeams = () => {
    let teams: Array<any> = []
    if (!funnel) return teams
    if (funnel.teams == undefined) return teams
    for (let i = 0; i < funnel.teams?.length; i++) {
      teams.push({label: funnel.teams[i].name, value: funnel.teams[i].id})
    }
    return teams
  }

  const [dataForEdit] = useState<Funnel>({
    ...funnel,
    name: funnel ? funnel.name : undefined,
    type: funnel ? funnel.type : undefined,
    status: funnel ? funnel.status : 1,
    lostReasonRequired: funnel ? funnel.lostReasonRequired : false,
    unsolvedReasonRequired: funnel ? funnel.unsolvedReasonRequired : false,
    winReasonRequired: funnel ? funnel.winReasonRequired : false,
    resolvedReasonRequired: funnel ? funnel.resolvedReasonRequired : false,
    teamIds: loadTeams(),
    userIds: loadUsers(),
  })

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      let auxValues: any = {...values}

      if (auxValues.visibilityType)
        auxValues.visibilityType = parseInt(auxValues.visibilityType.toString())

      if (auxValues.userIds) {
        let auxUsers: Array<number> = []
        for (let i = 0; i < auxValues.userIds.length; i++) {
          auxUsers.push(auxValues.userIds[i].value)
        }
        auxValues.userIds = auxUsers
      }

      if (auxValues.teamIds) {
        let auxTeams: Array<number> = []
        for (let i = 0; i < auxValues.teamIds.length; i++) {
          auxTeams.push(auxValues.teamIds[i].value)
        }
        auxValues.teamIds = auxTeams
      }

      console.log('auxValues', auxValues)

      try {
        if (isNotEmpty(values.id)) {
          await updateFunnel(auxValues)
        } else {
          await createFunnel(auxValues)
        }

        Swal.fire('Funil foi Registrado!', '', 'success')

        handleClose()
      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao salvar o Funil.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  const usersOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceUsersOptions = debounce(usersOptions, 500)

  const teamsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps: any = await getTeams('page=1&perPage=20&search=' + encodeURIComponent(inputValue))

      if (resps?.data && resps?.data?.length > 0) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps?.data[i]?.name, value: resps?.data[i]?.id})
        }
      }
      resolve(list)
    })
  const debounceTeamsOptions = debounce(teamsOptions, 500)

  return (
    <>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#funnel_tab_pane_1'>
            Configurações
          </a>
        </li>
        {/*}
  <li className="nav-item">
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#funnel_tab_pane_2"
    >
      Formulário Inicial
    </a>
  </li>
  <li className="nav-item">
    <a
      className="nav-link"
      data-bs-toggle="tab"
      href="#funnel_tab_pane_3"
    >
      Etapas
    </a>
  </li>
  */}
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div
          className='tab-pane fade show active overflow-hidden'
          id='funnel_tab_pane_1'
          role='tabpanel'
        >
          <div className='d-flex justify-content-center'>
            <div className='col'>
              <h2>
                {' '}
                <p>Configurações do Funil</p>
              </h2>
              <form
                id='kt_modal_add_user_form'
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    <InputFieldSet
                      label={
                        <>
                          {'Nome do Funil '}
                          <i
                            className='fa-solid fa-asterisk'
                            style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                          ></i>
                        </>
                      }
                    >
                      <input
                        placeholder=''
                        {...formik.getFieldProps('name')}
                        type='text'
                        name='name'
                        className={clsx(
                          'form-control fieldset-input',
                          {'is-invalid': formik.touched.name && formik.errors.name},
                          {
                            'is-valid': formik.touched.name && !formik.errors.name,
                          }
                        )}
                        autoComplete='off'
                        style={{paddingRight: '3.5rem'}}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </InputFieldSet>
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <InputFieldSet
                      label={
                        <>
                          {'Tipo '}
                          <i
                            className='fa-solid fa-asterisk'
                            style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                          ></i>
                        </>
                      }
                    >
                      <select
                        {...formik.getFieldProps('type')}
                        name='type'
                        data-control='select2'
                        data-hide-search='true'
                        className={clsx(
                          'form-control form-select form-select-sm form-select-white fieldset-input',
                          {'is-invalid': formik.touched.type && formik.errors.type},
                          {
                            'is-valid': formik.touched.type && !formik.errors.type,
                          }
                        )}
                        disabled={formik.isSubmitting || isLoading || funnel?.id != undefined}
                      >
                        <option value=''>Selecione</option>
                        <option value='1'>Vendas</option>
                        <option value='2'>Suporte</option>
                      </select>
                      {formik.touched.type && formik.errors.type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.type}</span>
                          </div>
                        </div>
                      )}
                    </InputFieldSet>
                  </div>

                  {funnel && funnel?.id != undefined && (
                    <div className='fv-row mb-7'>
                      <InputFieldSet label={'Status'}>
                        <select
                          {...formik.getFieldProps('status')}
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-control form-select form-select-sm form-select-white fieldset-input'
                          defaultValue='1'
                        >
                          <option value='1'>Ativo</option>
                          <option value='0'>Inativo</option>
                        </select>
                      </InputFieldSet>
                    </div>
                  )}

                  <div className='fv-row mb-7'>
                    <InputFieldSet
                      label={
                        <>
                          {'Visibilidade dos Cards Funil '}
                          <i
                            className='fa-solid fa-asterisk'
                            style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                          ></i>
                        </>
                      }
                    >
                      <select
                        {...formik.getFieldProps('visibilityType')}
                        name='visibilityType'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-control form-select form-select-sm form-select-white fieldset-input'
                        defaultValue='1'
                      >
                        <option value='1'>
                          Geral - Visualização de todas as oportunidades do funil
                        </option>
                        <option value='2'>
                          Responsável - Visualização de oportunidades em que é responsável
                        </option>
                      </select>
                    </InputFieldSet>
                  </div>

                  <div className='fv-row mb-7'>
                    <InputFieldSet label={'Usuários com Acesso ao funil'}>
                      <SelectAsync
                        menuPortalTarget={document.body}
                        {...formik.getFieldProps('userIds')}
                        isMulti={true}
                        placeholder={''}
                        name='userIds'
                        loadOptions={debounceUsersOptions}
                        cacheOptions
                        defaultOptions
                        onChange={(selectedOption) =>
                          formik.setFieldValue(
                            'userIds',
                            selectedOption ? selectedOption : undefined
                          )
                        }
                        className='fieldset-input'
                        styles={{
                          menuPortal: (base) => ({...base, zIndex: 9999}),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderStyle: 'none',
                            backgroundColor: undefined,
                            boxShadow: 'none',
                          }),
                        }}
                      />
                    </InputFieldSet>
                  </div>

                  <div className='fv-row mb-7'>
                    <InputFieldSet label={'Times com Acesso ao funil'}>
                      <SelectAsync
                        menuPortalTarget={document.body}
                        {...formik.getFieldProps('teamIds')}
                        isMulti={true}
                        placeholder={''}
                        name='teamIds'
                        loadOptions={debounceTeamsOptions}
                        cacheOptions
                        defaultOptions
                        onChange={(selectedOption) =>
                          formik.setFieldValue(
                            'teamIds',
                            selectedOption ? selectedOption : undefined
                          )
                        }
                        className='fieldset-input'
                        styles={{
                          menuPortal: (base) => ({...base, zIndex: 9999}),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderStyle: 'none',
                            backgroundColor: undefined,
                            boxShadow: 'none',
                          }),
                        }}
                        noOptionsMessage={() => 'Sem registros...Digite para buscar'}
                      />
                    </InputFieldSet>
                  </div>

                  {formik.values.type == 1 && (
                    <>
                      <Form.Check
                        type={'switch'}
                        id={`lostReasonRequired`}
                        reverse
                        style={{textAlign: 'left', marginTop: '14px'}}
                      >
                        <Form.Check.Label style={{color: 'black'}}>
                          <strong>Obrigatório informar Motivo de Perda</strong>
                        </Form.Check.Label>
                        <Form.Check.Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lostReasonRequired ? 'true' : 'false'}
                          checked={formik.values.lostReasonRequired ? true : false}
                          type={'checkbox'}
                          name={'lostReasonRequired'}
                        />
                      </Form.Check>
                      <Form.Check
                        type={'switch'}
                        id={`winReasonRequired`}
                        reverse
                        style={{textAlign: 'left', marginTop: '14px'}}
                      >
                        <Form.Check.Label style={{color: 'black'}}>
                          <strong>Obrigatório informar Motivo de Ganho</strong>
                        </Form.Check.Label>
                        <Form.Check.Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.winReasonRequired ? 'true' : 'false'}
                          checked={formik.values.winReasonRequired ? true : false}
                          type={'checkbox'}
                          name={'winReasonRequired'}
                        />
                      </Form.Check>
                    </>
                  )}

                  {formik.values.type == 2 && (
                    <>
                      <Form.Check
                        type={'switch'}
                        id={`unsolvedReasonRequired`}
                        reverse
                        style={{textAlign: 'left', marginTop: '14px'}}
                      >
                        <Form.Check.Label style={{color: 'black'}}>
                          <strong>Obrigatório informar Motivo de Não Resolvido</strong>
                        </Form.Check.Label>
                        <Form.Check.Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.unsolvedReasonRequired ? 'true' : 'false'}
                          checked={formik.values.unsolvedReasonRequired ? true : false}
                          type={'checkbox'}
                          name={'unsolvedReasonRequired'}
                        />
                      </Form.Check>

                      <Form.Check
                        type={'switch'}
                        id={`resolvedReasonRequired`}
                        reverse
                        style={{textAlign: 'left', marginTop: '14px'}}
                      >
                        <Form.Check.Label style={{color: 'black'}}>
                          <strong>Obrigatório informar Motivo de Resolvido</strong>
                        </Form.Check.Label>
                        <Form.Check.Input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.resolvedReasonRequired ? 'true' : 'false'}
                          checked={formik.values.resolvedReasonRequired ? true : false}
                          type={'checkbox'}
                          name={'resolvedReasonRequired'}
                        />
                      </Form.Check>
                    </>
                  )}
                </div>

                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='submit'
                    className='btn btn-sm  btn-wl-custom-primary-collor'
                    data-kt-users-modal-action='submit'
                    disabled={
                      isLoading || formik.isSubmitting || !formik.isValid || !formik.touched
                    }
                  >
                    <span className='indicator-label'>Salvar</span>
                    {(formik.isSubmitting || isLoading) && (
                      <span className='indicator-progress'>
                        Por Favor Aguarde...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
              {(formik.isSubmitting || isLoading) && <ListLoading />}
            </div>
          </div>
        </div>

        <div className='tab-pane fade' id='funnel_tab_pane_2' role='tabpanel'>
          painel
        </div>
      </div>
    </>
  )
}

export {FunnelSettingsForm}
