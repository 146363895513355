import { AxiosResponse } from "axios";
import {request} from "../../../../services/crm-api/request"

const createImport = async (dataForm: any): Promise<any> => {
    return await request(
        {
            method: 'post',
            url: `/imports`,
            data: dataForm,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        },
        {
            setAuth: true,
        }
    ).then((response: any) => response.data)
}

const getImports = async (query: string):  Promise<any> => {

    return request({
      method: "GET",
      url: `/imports?${query}`
    },{
      setAuth: true
    })
    .then((d: AxiosResponse<any>) => d.data);
  }

  const undoneImports = async (id: any): Promise<any> => {
    return request(
      {
        method: 'patch',
        url: `/imports/${id}`,
      },
      {
        setAuth: true,
      }
    )
    .then((d: AxiosResponse<any>) => d.data);
  }
export {
    createImport,
    getImports,
    undoneImports
}
