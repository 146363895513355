import { useCustomFormEditor, useCustomFormEditorDispatch } from '../../core/CustomFormEditorContext'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik, FieldArray, Field } from 'formik'
import { InputFieldSet } from '../../../../../../_metronic/partials/inputs/InputFieldSet'

const FormDetailEditModal = (defaultInputs:any) => {

    const customFormEditor = useCustomFormEditor();
    const customFormEditorDispatch = useCustomFormEditorDispatch();

    const handleClose = () => {
        customFormEditorDispatch({
            type: 'close-edit-form-modal'
        })
    };

    if (!customFormEditor.formDetailEdit) return <></>;

   // console.log("customFormEditor.formDetailEdit?.form", customFormEditor.formDetailEdit?.form);

    return <Modal
        size="lg"
        show={customFormEditor.formDetailEdit.open}
        onHide={handleClose}
        centered
        scrollable
        dialogClassName={"modal-form-edit-field"}
    >
        <Modal.Body style={{ padding: "32px 48px 20px" }}>
            <div style={{ marginBottom: "2rem" }}>
                <div style={{ fontSize: "1.5rem" }}><strong>{customFormEditor.formDetailEdit?.form?.internalId ? "Alterar " : "Novo "}Formulário</strong></div>
            </div>
            <Formik
                initialValues={{
                    name: customFormEditor.formDetailEdit.form?.name,
                }}
                validationSchema={null}
                onSubmit={async (valuesForm, { setSubmitting, resetForm }) => {

                    // console.log();

                    let values = Object.assign({}, valuesForm);

                    let isNew = customFormEditor.formDetailEdit.form?.internalId ? false : true;

                    let auxData: any = {
                        id: customFormEditor.formDetailEdit.form?.id,
                        internalId: customFormEditor.formDetailEdit.form?.internalId ? customFormEditor.formDetailEdit.form?.internalId : Date.now(),
                        type: customFormEditor.formDetailEdit.form?.type, //Complementar
                        name: values.name
                    };

                   // console.log("auxData", auxData);

                    if (isNew) {
                        customFormEditorDispatch({
                            type: 'add-form',
                            data: auxData
                        })
                    }
                    else {
                        customFormEditorDispatch({
                            type: 'update-form-detail',
                            data: auxData
                        })
                    }
                    resetForm();
                    handleClose();
                }}
            >
                {props => (

                    <form id='edit_field' className='form' onSubmit={props.handleSubmit} noValidate style={{}}>

                        <InputFieldSet
                            label={"Nome do formulário"}
                        >
                            <input
                                placeholder=''
                                {...props.getFieldProps('name')}
                                type='text'
                                name="name"
                                className={'form-control fieldset-input'}
                                autoComplete='off'
                                style={{ paddingRight: "3.5rem" }}
                                disabled={props.isSubmitting}
                                autoFocus
                            />
                        </InputFieldSet>

                        <hr style={{ marginTop: "30px" }}></hr>
                        <div style={{ textAlign: "right", marginTop: "20px" }}>

                            <button
                                type='reset'
                                onClick={handleClose}
                                className='btn btn-sm btn-wl-custom-link btn-wl-custom-hover-danger me-3'
                                disabled={props.isSubmitting}
                            >
                                Cancelar
                            </button>
                            <button
                                type='submit'
                                className='btn btn-sm btn-wl-custom-primary-collor'
                                disabled={props.isSubmitting || !props.isValid || !props.touched}
                                style={{ marginLeft: "20px", marginTop: "5px" }}
                            >
                                <span className='indicator-label'>Salvar</span>
                            </button>
                        </div>

                    </form>
                )}
            </Formik>
        </Modal.Body>
    </Modal>
}

export default FormDetailEditModal;