import { FC, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'
import { getFunnels } from '../core/_requests'
import debounce from 'debounce-promise'
import { InputFieldSet } from '../../../../_metronic/partials/inputs/InputFieldSet'
import SelectAsync from 'react-select/async'
import { getFunnelStages, getContactById } from '../core/_requests'
import { CARD_TYPES } from '../core/_models'
import Select from 'react-select'
import { Button } from 'react-bootstrap'
import { useCardFormModal, useCardFormModalDispatch } from '../core/CardFormModalContext'
import { CardFormAddPrimaryData } from './CardFormAddPrimaryData'

const CardFormAddPrimaryDataModal = () => {

  const cardFormModal = useCardFormModal()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const navigate = useNavigate()

  const handleSave = (data: any) => {

    cardFormModalDispatch({
      type: 'open-add-modal',
      data: data
    })

  }

  const handleClose = () => {

    cardFormModalDispatch({
      type: 'close-modal',
    })

    if (
      window?.location?.href?.includes('opportunities') ||
      window?.location?.href?.includes('tickets')
    ) {
      navigate(
        `${cardFormModal.cardType == CARD_TYPES.OPPORTUNITY.id ? '/opportunities' : '/tickets'}`
      )
    }
  }

  if (!(cardFormModal.open && cardFormModal.formAction == 'add-primary-data')) return null;

  const dataForm = {
    cardType: cardFormModal.cardType,
    customData: cardFormModal.customData,
  }

  return (
    <>
      <Modal
        show={cardFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={''}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{ fontSize: '1.5rem', fontWeight: '700' }}>Selecionar Funil</div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <CardFormAddPrimaryData data={dataForm} onSave={handleSave} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { CardFormAddPrimaryDataModal }
