/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC,useEffect } from 'react'
import { ListViewProvider, useListView } from '../../modules/view/ListViewProvider'
import { KTCard } from '../../../_metronic/helpers'
import { QueryRequestProvider } from '../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../modules/view/QueryResponseProvider'
//import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ContactsListHeader } from './components/header/ContactsListHeader'
import { ContactsTable } from './table/ContactsTable'
import { ContactsFormModal } from './edit-modal/ContactsFormModal'
import {getContacts} from './core/_requests'
import {ContactFormModalProvider} from './core/ContactFormModalContext'
import { ContactDetailModal } from './detail-modal/ContactDetailModal'
import { useContactFormModalDispatch, useContactFormModal } from './core/ContactFormModalContext'
import {useLocation} from 'react-router-dom'

const ContactsPage = () => {

  const contactFormModalDispatch = useContactFormModalDispatch();
  const contactFormModal = useContactFormModal();

  const location = useLocation()

  //Analisar URL
  useEffect(() => {
    const regex = /^\/contacts\/(details|edit)\/([^/]+)$/
    const match = location?.pathname?.match(regex)

    if (match) {
      const action = match[1] === 'details' ? 'open-detail-modal' :  match[1] === 'edit' ? 'open-edit-modal' : ''
      const parameter = match[2]
      if (parameter && parameter !== '') {
        contactFormModalDispatch({
          type: action,
          data: parameter,
        })
      }
    }
    else if(contactFormModal.open)
    {
      contactFormModalDispatch({
        type: 'close-modal',
      })
    }

  }, [location?.pathname])

  return (
    <>
      <ContactsListHeader />
      <KTCard>
        <ContactsTable />
      </KTCard>
      <ContactsFormModal />
      <ContactDetailModal />
    </>
  )
}

const Contacts: FC = () => {
  
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getContacts}>
          <ListViewProvider>
            <ContactFormModalProvider>
              <ContactsPage />
            </ContactFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { Contacts }
