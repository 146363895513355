import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useImportModal, useImportModalDispatch } from '../core/ImportModalContext'

import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import CustomImportModal from './CustomImportModal';


const ImportModal = () => {
  const systemImportModal = useImportModal();
  const systemImportModalDispatch = useImportModalDispatch()

  const {refetch} = useQueryResponse()

  const handleClose = () => {
    if(systemImportModal.loading) return
    systemImportModalDispatch({
      type: 'close-modal'
    })
    refetch();
  };

  useEffect(() => {

    if (systemImportModal.open) {

      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [systemImportModal.open])


  if (!systemImportModal.open) return <></>

  return (<>

    <Modal
      size="xl"
      show={systemImportModal.open}
      onHide={handleClose}
      centered
      backdrop="static"
      scrollable
      contentClassName={"modal-form-manage"}
    >
      <Modal.Header closeButton>
        <h3><strong>Importar Arquivo</strong></h3>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <CustomImportModal onClose={handleClose} formId={systemImportModal.importId}/>
      </Modal.Body>
    </Modal></>
  )
}

export { ImportModal }
