/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from './QueryResponseProvider'
import {useQueryRequest} from './QueryRequestProvider'
import { PAGINATION_PERPAGE } from '../../../_metronic/helpers/crud-helper/consts'

const MAX_PAGES = 5;
const MAX_BETWEEN_PAGES = (MAX_PAGES-1)/2;
const ListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, perPage: pagination.perPage || PAGINATION_PERPAGE})
  }

  let pages = [];
  if(pagination.pagesCount > 1)
  {
      if(pagination.pagesCount > MAX_PAGES)
      {
        if(pagination.page > (pagination.pagesCount-MAX_PAGES+1))
        {
          for(let i = (pagination.pagesCount-MAX_PAGES+1); i <= pagination.pagesCount; i++)
          {
            pages.push({pageNumber: i});
          }
        }
        else if(pagination.page > MAX_BETWEEN_PAGES)
        {
          let lasPage = (pagination.page+MAX_BETWEEN_PAGES) > pagination.pagesCount ? pagination.pagesCount :  (pagination.page+MAX_BETWEEN_PAGES);
          for(let i = (pagination.page-MAX_BETWEEN_PAGES); i <= lasPage; i++)
          {
            pages.push({pageNumber: i});
          }
        }
        else
        {
          for(let i = 1; i <= MAX_PAGES; i++)
          {
            pages.push({pageNumber: i});
          }
        }
      }
      else
      {
        for(let i = 1; i <= pagination.pagesCount; i++)
        {
          pages.push({pageNumber: i});
        }
      }
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagination.page > 1 && 
              (<li
                key={"Primeiro"}
                className={clsx('page-item', {
                  active: false,
                  disabled: isLoading,
                  previous: true,
                  next: false,
                })}
              >
                <a
                  className={clsx('page-link', {
                    'page-text': true,
                   // 'me-5': true,
                  })}
                  onClick={() => pagination.page > 1 ?  updatePage(1) : null}
                  style={{cursor: 'pointer'}}
                >
                  <i className="fa-solid fa-angles-left" style={{fontSize: "1rem"}}></i>
                </a>
              </li>)}

            {pagination.page > 1 && 
            (<li
              key={"Anterior"}
              className={clsx('page-item', {
                active: false,
                disabled: isLoading,
                previous: true,
                next: false,
              })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true,
                 // 'me-5': true,
                })}
                onClick={() => pagination.page > 1 ?  updatePage(pagination.page-1) : null}
                style={{cursor: 'pointer'}}
              >
                
                <i className="fa-solid fa-angle-left" style={{fontSize: "1rem"}}></i>
              </a>
            </li>)}

            {pages.map((page) => (
                <li
                  key={page.pageNumber}
                  className={clsx('page-item', {
                    active: pagination.page === page.pageNumber,
                    disabled: isLoading,
                    previous: false,
                    next: false,
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': false,
                      'me-5': false,
                    })}
                    onClick={() => updatePage(page.pageNumber)}
                    style={{cursor: 'pointer'}}
                  >
                    {page.pageNumber}
                  </a>
                </li>
              ))}

            {pagination.page < pagination.pagesCount && 
            (<li
              key={"Próximo"}
              className={clsx('page-item', {
                active: false,
                disabled: isLoading,
                previous: false,
                next: true,
              })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true,
                  //'me-5': true,
                })}
                onClick={() => pagination.page < pagination.pagesCount ? updatePage(pagination.page+1) : null}
                style={{cursor: 'pointer'}}
              >
                <i className="fa-solid fa-angle-right" style={{fontSize: "1rem"}}></i>
              </a>
            </li>
            )}

            {pagination.page < pagination.pagesCount && 
            (<li
              key={"Último"}
              className={clsx('page-item', {
                active: false,
                disabled: isLoading,
                previous: false,
                next: true,
              })}
            >
              <a
                className={clsx('page-link', {
                  'page-text': true,
                  'me-5': true,
                })}
                onClick={() => pagination.page < pagination.pagesCount ? updatePage(pagination.pagesCount) : null}
                style={{cursor: 'pointer'}}
              >
               <i className="fa-solid fa-angles-right" style={{fontSize: "1rem"}}></i>
              </a>
            </li>
            )}

          </ul>
        </div>
      </div>
    </div>
  )
}

export {ListPagination}
