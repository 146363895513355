import {useEffect} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useContactFormModal, useContactFormModalDispatch} from '../core/ContactFormModalContext'
import {getContactById} from '../core/_requests'
import {ContactsForm} from './ContactsForm'
import {useNavigate} from 'react-router-dom'
import {getCustomForms} from '../../settings/custom-forms/core/_requests'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCardCustomForm} from '../../settings/custom-forms/core/_requests'
const ContactsFormModal = () => {
  const navigate = useNavigate()
  const contactFormModal = useContactFormModal()
  const contactFormModalDispatch = useContactFormModalDispatch()

  const handleClose = () => {
    contactFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/contacts')
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['contacts', contactFormModal.contactId],
    queryFn: () => {
      return getContactById(contactFormModal.contactId)
    },
    enabled:
      contactFormModal.open &&
      contactFormModal.form_action != 'detail' &&
      contactFormModal.contactId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['contacts', 'detail', 'initial-custom-forms', contactFormModal.contactId],
    queryFn: () => {
      return getCardCustomForm('contact', null, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled: contactFormModal?.open ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['contacts', 'detail', 'complement-custom-forms', contactFormModal.contactId],
    queryFn: async () => {
      let auxData = await getCustomForms(`entity=contact&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}`) //Complemento

      return auxData.data
    },
    enabled: contactFormModal?.open && contactFormModal?.form_action == 'edit' ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (contactFormModal.open && contactFormModal.form_action != 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [contactFormModal.open])

  if (!contactFormModal.open) return null

  if (isError || (contactFormModal.contactId && !data) || contactFormModal.form_action == 'detail')
    return null

  return (
    <>
      <Modal
        show={contactFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{fontSize: '1.5rem', fontWeight: '700'}}>
            {contactFormModal.contactId ? 'Alterar ' : 'Adicionar '} Contato
          </div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <Row>
            <Col>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail'>
                    Cadastro{' '}
                  </a>
                </li>
                {contactFormModal.form_action == 'edit' && data?.company ? (
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_company'>
                      Empresa
                    </a>
                  </li>
                ) : null}
                {contactFormModal.form_action == 'edit' &&
                  complementCustomForms?.map((form: any, index: number) => {
                    return (
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-bs-toggle='tab'
                          href={'#contact_tab_pane_complement' + form.id}
                        >
                          {form.name}
                        </a>
                      </li>
                    )
                  })}
              </ul>
              {/* <ContactsForm isLoading={false} contact={data} /> */}
              <div className='tab-content'>
                {initialCustomForm?.fields?.length > 0 ? (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <ContactsForm
                      customFormId={initialCustomForm?.id}
                      isLoading={false}
                      contact={data}
                    />{' '}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <ContactsForm isLoading={false} contact={data} />
                  </div>
                )}
                <div className='tab-pane fade show' id='card_tab_pane_company' role='tabpanel'>
                  {data?.company && (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{data.company?.name}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/companies/details/${data.company.id}`)
                          }}
                        ></i>
                      </div>
                      {data.company.corporateName ? (
                        <>
                          {`Razão Social: ${data.company.corporateName}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data.company.cnpj ? (
                        <>
                          {`CNPJ: ${data.company.cnpj}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data.company.createdAt ? (
                        <>
                          {`Criado em ${new Date(data.company.createdAt).toLocaleString()}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <div
                      className={'tab-pane fade show'}
                      id={'contact_tab_pane_complement' + form.id}
                      role='tabpanel'
                      key={index}
                    >
                      <ContactsForm isLoading={false} customFormId={form.id} contact={data} />
                    </div>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ContactsFormModal}
