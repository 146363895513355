/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTCard } from '../../../_metronic/helpers'
import { CardsListHeader } from './components/header/CardsListHeader'
import { CardsTable } from './table/CardsTable'
import { CardsFormModal } from './edit-modal/CardsFormModal'

import { CardDetailModal } from './detail-modal/CardDetailModal'
import { useCardFormModalDispatch, useCardFormModal } from './core/CardFormModalContext'
import { useEffect } from 'react'
import { CARD_TYPES } from './core/_models'
import { useLocation } from 'react-router-dom'

import { CardMoveModalProvider } from './core/CardMoveModalContext'
import { CardMoveModal } from '../cards/components/move-card-modal/CardMoveModal'

import { CardTransferResponsibleModalProvider } from '../cards/core/CardTransferResponsibleModalContext'
import { CardTransferResponsibleModal } from '../cards/components/transfer-responsible-modal/CardTransferResponsibleModal'

import { CardMarkModalProvider } from '../cards/core/CardMarkModalContext'
import { CardMarkModal } from '../cards/components/mark-modal/CardMarkModal'

import { CardArchiveModalProvider } from '../cards/core/CardArchiveModalContext'
import { CardArchiveModal } from '../cards/components/archive-modal/CardArchiveModal'

import { CardFreezeModalProvider } from '../cards/core/CardFreezeModalContext'
import { CardFreezeModal } from '../cards/components/freeze-modal/CardFreezeModal'
import { CardFormAddPrimaryDataModal } from './edit-modal/CardFormAddPrimaryDataModal'

const CardsPage = ({ type }: { type: number }) => {
  const location = useLocation();

  const cardFormModalDispatch = useCardFormModalDispatch();
  const cardFormModal = useCardFormModal();

  //Analisar URL
  useEffect(() => {

    let regex = null;
    if (CARD_TYPES?.OPPORTUNITY?.id == type) {
      regex = /^\/opportunities\/(details|edit|add)\/*([^/]+)*$/
    }
    else {
      regex = /^\/tickets\/(details|edit|add)\/*([^/]+)*$/
    }

    const match = location?.pathname?.match(regex)
    
    let queryVars : any = Object.fromEntries(new URLSearchParams(location.search));
  
    if (match && match.length > 0) {
      let action = null;
      switch (match[1]) {
        case 'details':
          action = 'open-detail-modal';
          break;
        case 'edit':
          action = 'open-edit-modal';
          break;
        case 'add':
          action = 'open-add-primary-data-modal';
          break;
      }

      if (action == 'open-add-primary-data-modal') {
        cardFormModalDispatch({
          type: action,
          data: {
            type: type,
            customData: queryVars 
          }
        })
      }
      else {
        const parameter = match[2]
        if (parameter && parameter !== '') {
          cardFormModalDispatch({
            type: action,
            data: {
              id: parameter,
              type: type
            }
          })
        }
      }
    }
    else if (cardFormModal.open) {
      cardFormModalDispatch({
        type: 'close-modal',
      })
    }

  }, [location?.pathname])

  return (
    <>
      <CardsListHeader type={type} />
      <KTCard>
        <CardsTable />
      </KTCard>
      <CardArchiveModalProvider>
        <CardFreezeModalProvider>
          <CardMarkModalProvider>
            <CardTransferResponsibleModalProvider>
              <CardMoveModalProvider>
                <CardsFormModal />
                <CardDetailModal />
                <CardMoveModal />
                <CardTransferResponsibleModal />
                <CardFreezeModal />
                <CardArchiveModal />
                <CardMarkModal />
              </CardMoveModalProvider>
            </CardTransferResponsibleModalProvider>
          </CardMarkModalProvider>
        </CardFreezeModalProvider>
      </CardArchiveModalProvider>
      <CardFormAddPrimaryDataModal />
    </>
  )
}

export { CardsPage }
