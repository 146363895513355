import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import {updateUserPassword} from '../core/_requests'
interface ChangePasswordModalProps {
  show: boolean
  user_data: any
  handleClose: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  show,
  user_data,
  handleClose,
}) => {
  const [controlFormData, setControlFormData] = useState({
    password: '',
    confirmPassword: '',
  })
  const onSubmit = async () => {
    try {
      if (!controlFormData.confirmPassword || !controlFormData.password) {
        return Swal.fire('Atenção', 'Preencha todos os campos!', 'warning')
      }
      if (controlFormData.confirmPassword != controlFormData.password) {
        return Swal.fire('Senhas não conferem, digite novamente!', '', 'warning')
      }

      await updateUserPassword(user_data?.id, {password: controlFormData.password})
      Swal.fire('Registro salvo com Sucesso!', '', 'success')
      handleClose()
    } catch (e) {
      console.log(e)
      Swal.fire({
        title: 'Opss..',
        text: 'Houve um problema ao salvar o registro.',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }
  useEffect(() => {
    setControlFormData({
      password: '',
      confirmPassword: '',
    })
  }, [])
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Senha - {user_data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fv-row mb-7'>
          <InputFieldSet label={<>{'Nova senha '}</>}>
            <input
              placeholder=''
              type='password'
              name='password'
              className={'form-control fieldset-input'}
              autoComplete='off'
              style={{paddingRight: '3.5rem'}}
              onChange={(e: any) => {
                let data = {...controlFormData}
                data.password = e?.target?.value
                setControlFormData(data)
              }}
            />
          </InputFieldSet>
        </div>
        <div className='fv-row mb-7'>
          <InputFieldSet label={<>{'Confirmação nova senha '}</>}>
            <input
              placeholder=''
              type='password'
              name='confirmPassword'
              className={'form-control fieldset-input'}
              autoComplete='off'
              style={{paddingRight: '3.5rem'}}
              onChange={(e: any) => {
                let data = {...controlFormData}
                data.confirmPassword = e?.target?.value
                setControlFormData(data)
              }}
            />
          </InputFieldSet>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='wl-custom-secondary-collor' onClick={handleClose} size='sm'>
          Fechar
        </Button>
        <Button variant='wl-custom-primary-collor' onClick={onSubmit} size='sm'>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePasswordModal
