import {Card, Col, Dropdown, Row} from 'react-bootstrap'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {useFunnel, useFunnelDispatch} from '../../../funnel/core/FunnelContext'
import styled from 'styled-components'
import {KTCard} from '../../../../../_metronic/helpers'
import CountUp from 'react-countup'

const DashboardTotalSales = ({data}: any) => {
  return (
    <Card style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
      <Card.Title className='px-6 py-6'>Vendas Totais</Card.Title>
      <Row className='pb-3'>
        <Col sm='12'>
          <h1 className='px-6 py-4 text-wl-custom-title-collor'>
            <CountUp
              start={0}
              end={data.totalValue}
              duration={2.75}
              className='text-bold'
              separator='.'
              prefix='R$ '
              decimals={2}
              decimal=','
            />
          </h1>
        </Col>
      </Row>
      <Row className='mt-6 mb-3'>
        <Col
          style={{
            borderRight: '1px solid #d3d3d3',
          }}
        >
          <h5 className='px-6'>
            {' '}
            <CountUp
              start={0}
              end={data.countSales}
              duration={2.75}
              className='text-bold'
              separator='.'
            />
            <span> Vendas</span>
          </h5>
        </Col>
        <Col
          style={{
            borderRight: '1px solid #d3d3d3',
            textAlign: 'center',
          }}
        >
          <h5>
            {' '}
            <CountUp
              start={0}
              end={data.countUniqueSales}
              duration={2.75}
              className='text-bold'
              separator='.'
            />
            <span> Vendas Únicas</span>
          </h5>
        </Col>
        <Col style={{textAlign: 'center'}}>
          <h5>
            {' '}
            <CountUp
              start={0}
              end={data.countRecurrentSales}
              duration={2.75}
              className='text-bold'
              separator='.'
            />
            <span> Vendas Recorrentes</span>
          </h5>
        </Col>
      </Row>
      <Row className='my-1 pb-2'>
        <Col
          style={{
            borderRight: '1px solid #d3d3d3',
          }}
        >
          <h5 className='px-6'>
            {' '}
            <CountUp
              start={0}
              end={data.totalValue}
              duration={2.75}
              className='text-bold'
              separator='.'
              decimals={2}
              decimal=','
              prefix='R$ '
            />
            <span> Vendas</span>
          </h5>
        </Col>
        <Col
          style={{
            borderRight: '1px solid #d3d3d3',
            textAlign: 'center',
          }}
        >
          <h5>
            <CountUp
              start={0}
              end={data.sumUniqueSales}
              duration={2.75}
              className='text-bold'
              separator='.'
              decimals={2}
              decimal=','
              prefix='R$ '
            />
            <span> Vendas Únicas</span>
          </h5>
        </Col>
        <Col style={{textAlign: 'center'}}>
          <h5>
            {' '}
            <CountUp
              start={0}
              end={data.sumRecurrentSales}
              duration={2.75}
              className='text-bold'
              separator='.'
              decimals={2}
              decimal=','
              prefix='R$ '
            />
            <span> Vendas Recorrentes</span>
          </h5>
        </Col>
      </Row>
    </Card>
  )
}
export {DashboardTotalSales}
