import {FC} from 'react'

type Props = {
  type?: number
}

const TypeCell: FC<Props> = ({type}) => {
  let dataReturn : string = "";
  switch(type){
    case 1:
      dataReturn = "Venda"
      break;
    case 2:
      dataReturn = "Suporte"
      break;
  }
  return <>{dataReturn}</>;
}

export {TypeCell}
