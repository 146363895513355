/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../../modules/view/QueryResponseProvider'
import Swal from 'sweetalert2'
import {useAutomaticActionsFormModalDispatch} from '../../core/AutomaticActionsFormModalContext'
import {
  changeAutomaticActionStatusRequest,
  deleteAutomaticActionsConfig,
} from '../../core/_requests'

type Props = {
  id: ID
  status: number
}

const ActionsCell: FC<Props> = ({id, status}) => {
  const {refetch} = useQueryResponse()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const automaticActionsModalDispatch = useAutomaticActionsFormModalDispatch()

  const openEditModal = () => {
    automaticActionsModalDispatch({
      type: 'open-edit-modal',
      data: id,
    })
  }

  const confirmDeleteAutomaticAction = async (id: any) => {
    Swal.fire({
      title: 'Excluir Registro',
      text: 'Tem certeza que deseja excluir a ação automatizada?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAutomaticActionsConfig(id)
          refetch()
          Swal.fire('Sucesso!', 'Ação automatizada excluída com sucesso!', 'success')
        } catch (e: any) {
          let errorDetected = ''
          if (e.response?.data?.key && e.response?.data?.message) {
            errorDetected = e.response?.data?.message
          }
          Swal.fire('Opss', 'Falha ao Excluir a ação automatizada. ' + errorDetected, 'error')
        }
      }
    })
  }

  const changeAutomaticActionStatus = async (id: any, status: number) => {
    try {
      await changeAutomaticActionStatusRequest(id, status)
      refetch()
      Swal.fire('Sucesso!', 'Status da ação automatizada alterado com sucesso!', 'success')
    } catch (e: any) {
      let errorDetected = ''
      if (e.response?.data?.key && e.response?.data?.message) {
        errorDetected = e.response?.data?.message
      }
      Swal.fire('Opss', 'Falha ao alterar o status da ação automatizada. ' + errorDetected, 'error')
    }
  }

  return (
    <>
      <a href='#' className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon svg-icon-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-150px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Alterar
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => changeAutomaticActionStatus(id, status === 1 ? 0 : 1)}
          >
            {status === 1 ? 'Desativar' : 'Ativar'}
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => {
              confirmDeleteAutomaticAction(id)
            }}
          >
            Excluir
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
