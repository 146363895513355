/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {USERS_STATUS} from '../../core/_models'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {activateUser, deactivateUser} from '../../core/_requests'
import ChangePasswordModal from '../../change-password-modal/Index'
import { useQueryResponse } from '../../../../../modules/view/QueryResponseProvider'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
type Props = {
  entity: any
}

const ActionsCell: FC<Props> = ({entity}) => {
  const {query, refetch} = useQueryResponse()
  const navigate = useNavigate()
  const [controlCpModal, setControlCpModal] = useState({
    show: false,
  })
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    navigate(`/users/edit/${entity.id}`)
  }

  const confirmActionRow = async (id: any) => {
    Swal.fire({
      title: `${entity?.status == USERS_STATUS.ACTIVE ? 'Inativar' : 'Ativar'} Usuário`,
      text: `Tem certeza que deseja continuar?`,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (entity?.status == USERS_STATUS.ACTIVE) {
            await deactivateUser(id)
          } else {
            await activateUser(id)
          }
          refetch()
          Swal.fire('Sucesso!', 'Registro atualizado com sucesso!', 'success')
        } catch (e: any) {
          Swal.fire('Ops...!', 'Falha ao carregar solicitação... ', 'error')
        }
      }
    })
  }

  return (
    <>
      <a href='#' className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon svg-icon-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => {
              setControlCpModal({show: true})
            }}
          >
            Alterar Senha
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            // data-kt-users-table-filter='delete_row'
            onClick={() => {
              confirmActionRow(entity?.id)
            }}
          >
            {entity?.status == USERS_STATUS.ACTIVE ? 'Inativar' : 'Ativar'}
          </a>
        </div>
      </div>
      <ChangePasswordModal
        show={controlCpModal.show}
        user_data={entity}
        handleClose={() => {
          setControlCpModal({show: false})
        }}
      />
    </>
  )
}

export {ActionsCell}
