import styled from "styled-components";


export const Container = styled.div`
   display:flex;
   justify-content: space-between;
   background-color:white;
   border-radius:5px;
   flex-flow:wrap;
   padding:10px;
   width:90%;
   cursor:pointer;
   &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    margin-bottom:10px;
    @media (max-width: 1200px) {
        width: 100%;
     }
`;

export const StartIcon = styled.div`
align-self:center;
cursor:pointer;
padding-left:30px;
`;

export const CentralData = styled.div`
   
`;

export const EndIcon = styled.div`
align-self:center;
cursor:pointer;
padding-right:30px;
`;

export const FileName = styled.h5`
font-weight:bold;
color:#aaabac;
`;

export const FileInfo = styled.div`
color:#aaabac;
`;

