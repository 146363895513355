import { createContext, useContext, useReducer } from 'react';

export interface ImportModalContextModel {
  open: boolean
  importId: number | null
  entityId: number|null
  loading:boolean
}

const initialData = {
  open: false,
  importId: null,
  entityId:null,
  loading:false
};

const ImportModalContext = createContext<ImportModalContextModel>(initialData);
const ImportModalDispatchContext = createContext<any>(null);

export function ImportModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    importModalReducer,
    initialData
  );

  return (
    <ImportModalContext.Provider value={data}>
      <ImportModalDispatchContext.Provider value={dispatch}>
        {children}
      </ImportModalDispatchContext.Provider>
  </ImportModalContext.Provider>
  );
}

export function useImportModal() {
  return useContext(ImportModalContext);
}

export function useImportModalDispatch() {
  return useContext(ImportModalDispatchContext);
}

function importModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, entityId: action.data }
    }
    case 'reset':{
      return { ...data, open: true,  entityId: null, loading:false }
    }
    case 'close-modal': {
      return { ...data, open: false, entityId: null, loading:false}
    }
    case 'loading':{
      return { ...data, loading:true}
    }
    case 'not-loading':{
      return {...data , loading:false}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
