import {FC} from 'react'
import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  toggleRef: React.RefObject<HTMLDivElement>,
  toggleState: string,
  toggleType: string,
  toggleStateEnabled: boolean
}

const SidebarMenuItemToggle: FC<Props & WithChildren> = ({toggleRef, toggleState, toggleType, toggleStateEnabled}) => {
  const {pathname} = useLocation()
  const {config} = useLayout()
  const {app} = config
  
  return (
    
    <div className='menu-item'>
     <Link className={clsx('menu-link without-sub',{'mx-2': !toggleStateEnabled})} to="#">
     
      <div 
        ref={toggleRef}
        id='kt_app_sidebar_toggle'
        data-kt-toggle='true'
        data-kt-toggle-state={toggleState}
        data-kt-toggle-target='body'
        data-kt-toggle-name={`app-sidebar-${toggleType}`}
        className={'body-bg h-30px w-30px rotate'}
      >
    
        <KTIcon iconName='double-left' className='fs-2 rotate-180' /> <span style={{marginLeft:"3px"}}>{toggleType=='collapse' ?  "Recolher Menu" : ""}</span>
      </div>
   
     </Link>
    
    </div>
  )
}

export {SidebarMenuItemToggle}
