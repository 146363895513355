// @ts-nocheck
import clsx from 'clsx'
import { FC } from 'react'
import { Row } from 'react-table'
import { TerminationReason } from '../../core/_models'
import { useNavigate } from 'react-router-dom'

type Props = {
  row: Row<TerminationReason>
}

const CustomRow: FC<Props> = ({ row }) => {
  const navigate = useNavigate();

  const openDetailModal = (event) => {
    if (event.target?.localName == "td") //Para evitar abrir quando clicar no botão de opções da linha
    {
      if (row.original?.id) navigate(`/contacts/details/${row.original.id}`)
    }
  }

  return (
    <tr {...row.getRowProps()} style={{ cursor: "pointer"}} onClick={openDetailModal}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}

export { CustomRow }
