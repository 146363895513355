// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Company, TEAM_STATUS} from '../../core/_models'

const tableColumns: ReadonlyArray<Column<Company>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={false} />
    ),
    id: 'status',
    Cell: ({...props}) => {
      let status = props.data[props.row.index].status
      let statusText = ''

      switch (status) {
        case TEAM_STATUS.ACTIVE:
          statusText = <span className='badge badge-success-wl-custom-collor fw-bolder'>Ativo</span>
          break
        case TEAM_STATUS.INACTIVE:
          statusText = <span className='badge badge-danger-wl-custom-collor fw-bolder'>Inativo</span>
          break
      }

      return statusText
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell entity={props.data[props.row.index]} />,
  },
]

export {tableColumns}
