import { createContext, useContext, useReducer } from 'react';

export interface CardArchiveModalContextModel {
  open: boolean,
  card: any
}

const initialData = {
  open: false,
  card: null
};

const CardArchiveModalContext = createContext<CardArchiveModalContextModel>(initialData);
const CardArchiveModalDispatchContext = createContext<any>(null);

export function CardArchiveModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardArchiveReducer,
    initialData
  );

  return (
    <CardArchiveModalContext.Provider value={data}>
      <CardArchiveModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardArchiveModalDispatchContext.Provider>
  </CardArchiveModalContext.Provider>
  );
}

export function useCardArchiveModal() {
  return useContext(CardArchiveModalContext);
}

export function useCardArchiveModalDispatch() {
  return useContext(CardArchiveModalDispatchContext);
}

function cardArchiveReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-modal': {
      return { ...data, open: true, card: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, card: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
