import React, {useState, useContext} from 'react'
import {Card, Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {CARD_TYPES} from '../../../cards/core/_models'
const CardComponent: React.FC<any> = ({item}) => {
  console.log(item)
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  const buttonStyles: any = {
    display: 'inline-block',
    padding: '2px 17px',
    backgroundColor: isHovered
      ? 'var(--wl-btn-background-primary-collor)'
      : 'var(--wl-btn-text-primary-collor)',
    borderColor: isHovered
      ? 'var(--wl-btn-background-primary-collor)'
      : 'var(--wl-btn-text-primary-collor)',
    color: isHovered
      ? 'var(--wl-btn-text-primary-collor)'
      : 'var(--wl-btn-background-primary-collor)',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border-color 0.3s, color 0.3s',
    position: 'relative',
    top: '15px',
    left: '36px',
    fontWeight: 'bold',
  }
  const handleNavigate = () => {
    const entity = CARD_TYPES.OPPORTUNITY.id == item.type ? '/opportunities' : '/tickets'
    navigate(`${entity}/details/${item?.id}`)
  }

  return (
    <Card.Body
      style={{paddingTop: '5px', paddingLeft: '10px'}}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={() => {
        handleNavigate()
      }}
    >
      <Row>
        <Col sm='6' style={{color: 'white'}}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: '2px',
            }}
          >
            {' '}
            <i
              className='fas fa-user text-white'
              style={{position: 'relative', top: '2px'}}
            ></i>{' '}
            {item?.title ? (
              <strong>{`${item.id} - ${item?.title}`}</strong>
            ) : (
              <span>Não preenchido</span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: '2px',
            }}
          >
            {' '}
            <i
              className='fas fa-phone text-white'
              style={{position: 'relative', top: '4px'}}
            ></i>{' '}
            {item?.phone ? (
              <strong>{`${item.id} - ${item?.phone}`}</strong>
            ) : (
              <span>Não preenchido</span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: '2px',
            }}
          >
            {' '}
            <i
              className='fas fa-envelope text-white'
              style={{position: 'relative', top: '4px'}}
            ></i>{' '}
            {item?.email ? (
              <strong>{`${item.id} - ${item?.email}`}</strong>
            ) : (
              <span>Não preenchido</span>
            )}
          </div>
        </Col>
        <Col sm='6' align='center'>
          {' '}
          <div style={buttonStyles}>visualizar</div>
        </Col>
      </Row>
    </Card.Body>
  )
}

export default CardComponent
