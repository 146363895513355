import {useEffect, useState} from 'react'
import {getContacts} from '../../../contacts/core/_requests'
import debounce from 'debounce-promise'
import {getUsers} from '../../users/core/_requests'
import {components} from 'react-select'
import {getCompanies} from '../../../companies/core/_requests'
import {CARD_TYPES} from '../../../cards/core/_models'
import {getOpportunities, getTickets} from '../../../cards/core/_requests'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import SelectAsync from 'react-select/async'
import SelectInputCustomStyle from '../../../../modules/view/styles/SelectInputCustomStyle'
import {getTasksTypes} from '../../../tasks/core/_requests'
import {TASK_TYPES} from '../../../tasks/core/_models'
import FieldTypeInput from '../../custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    minHeight: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
}

interface Props {
  setState: (state: any) => void
  state?: any
}

const TaskForm = (props: Props) => {
  const [taskOptions, setTaskOptions] = useState<any>()
  const [task, setTask] = useState<any>({
    title: '',
    observation: '',
    status: 0,
    type: 0,
    subType: 0,
    customFields: [],
  })
  const [customFields, setCustomFields] = useState<any>([])
  // const [showSubType, setShowSubType] = useState(true)
  const [changedType, setChangedType] = useState(false)

  const loadInitData = async () => {
    let options = []
    try {
      let query = `status=1&page=1&perPage=500&include_default_types=true`
      let data = await getTasksTypes(query)
      for (let i = 0; i < data?.data?.length; i++) {
        let custom_fields: any = []
        let option = data?.data[i]
        let dataToPush = {
          id: option?.id,
          name: option?.name,
          custom_fields: custom_fields,
        }
        if (option?.customFields?.length > 0) {
          for (let j = 0; j < option?.customFields?.length; j++) {
            let customField = option?.customFields[j]
            custom_fields.push({
              ...customField,
              label: customField?.name,
              name: 'custom_' + customField?.integrationId,
              type: {key: customField?.type},
            })
          }
        }

        options.push(dataToPush)
      }
    } catch (e) {
      console.log(e)
    }

    setTaskOptions(options)

    if (props.state) {
      if (task.customFields && task.customFields.length == 0 && options.length > 0) {
        let fields = []
        for (let i = 0; i < options.length; i++) {
          if (options[i].id == props.state.type) {
            fields = options[i].custom_fields
          }
        }

        console.log(props.state.customFields)
        if (props.state.customFields && props.state.customFields.length) {
          fields = fields.map((field: any) => {
            let customField = props.state.customFields.find(
              (cf: any) => cf.integrationId === field.integrationId
            )
            if (customField) {
              field.value = customField.value
            }
            return field
          })

          setCustomFields(fields)
        }
      }
    }
  }

  useEffect(() => {
    if (props.state) {
      setTask(props.state)

      if (props.state.customFields && props.state.customFields.length) {
        if (!changedType) {
          setCustomFields(props.state.customFields)
        }
      }
    }
  }, [props.state])

  useEffect(() => {
    loadInitData()
  }, [])

  console.log(customFields)

  function changeTaskValues(value: any, field: string) {
    const newTask = {...task}

    if (field == 'title') {
      newTask.title = value
    } else if (field === 'type') {
      newTask.type = parseInt(value)
    } else if (field === 'subType') {
      newTask.subType = parseInt(value)
    } else if (field === 'observation') {
      newTask.observation = value
    } else if (field === 'status') {
      newTask.status = value
    } else {
      let fieldsMapped = customFields.map((cf: any) => {
        if (cf.name === field) {
          cf.value = value
        } else if (cf.name === 'custom_' + field) {
          cf.value = value
        }
        return cf
      })

      setCustomFields(fieldsMapped)

      newTask.customFields = fieldsMapped
    }

    setTask(newTask)
    console.log(newTask)
    props.setState(newTask)
  }

  function cleanCustomFields() {
    const tasksOptions = [...taskOptions]

    let tasks = tasksOptions.map((task: any) => {
      task.custom_fields = task.custom_fields.map((field: any) => {
        field.value = ''
        return field
      })
      return task
    })

    setTaskOptions(tasks)
  }

  return (
    <div>
      <div className='fv-row mb-7'>
        <InputFieldSet
          label={
            <>
              {'Assunto da Tarefa '}
              <i
                className='fa-solid fa-asterisk'
                style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
              ></i>
            </>
          }
        >
          <input
            placeholder=''
            type='text'
            name='title}'
            className={'form-control fieldset-input'}
            autoComplete='off'
            style={{paddingRight: '3.5rem'}}
            disabled={false}
            defaultValue={task.title}
            onChange={(e) => changeTaskValues(e.target.value, 'title')}
          />
        </InputFieldSet>
      </div>
      <div className='fv-row mb-7'>
        <InputFieldSet
          label={
            <>
              {'Tipo da Tarefa '}
              <i
                className='fa-solid fa-asterisk'
                style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
              ></i>
            </>
          }
        >
          <select
            name='type'
            onChange={(e: any) => {
              changeTaskValues(e.target.value ?? null, 'type')
              let option = taskOptions.find((op: any) => op?.id == e?.target?.value)
              if (e?.target?.value == 1) {
                // setShowSubType(true)
                setCustomFields([])
                cleanCustomFields()
              } else if (option && option.custom_fields) {
                // setShowSubType(false)
                setCustomFields(option.custom_fields)
              } else {
                // setShowSubType(false)
                setCustomFields([])
              }
              setChangedType(true)
            }}
            onBlur={() => {}}
            value={task.type}
            data-control='select2'
            data-hide-search='true'
            className={'form-control form-select form-select-sm form-select-white fieldset-input'}
            // disabled={formik.isSubmitting || isLoading || task?.id != undefined}
          >
            <option value='' selected>
              Selecione
            </option>
            {taskOptions?.map((tp: any) => (
              <option value={tp.id} key={tp.id}>
                {tp.name}
              </option>
            ))}
          </select>
        </InputFieldSet>
      </div>
      {customFields &&
        customFields.length > 0 &&
        customFields?.map((field: any, index: number) => (
          <div className='fv-row mb-7' key={'fieldtypeinput' + index}>
            <FieldTypeInput
              key={index}
              field={{...field}}
              onChange={(value: any, fieldName: any) => {
                if (value?.value) {
                  changeTaskValues(value, fieldName)
                } else {
                  changeTaskValues(value?.target.value, field.name)
                }
              }}
              value={field.value?.value ? field.value?.value : field.value}
              setFieldValue={(value: any) => {
                changeTaskValues(value, field.name)
              }}
              useSelectOptionLabel={true}
              selectOptions={field.value}
              useOnChange={true}
            />
          </div>
        ))}
      {task.type == 1 && (
        <div className='fv-row mb-7'>
          <InputFieldSet
            label={
              <>
                {'Categoria'}
                <i
                  className='fa-solid fa-asterisk'
                  style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                ></i>
              </>
            }
          >
            <select
              name='subType'
              onChange={(e: any) => {
                changeTaskValues(e.target.value, 'subType')
              }}
              onBlur={() => {}}
              value={task.subType}
              data-control='select2'
              data-hide-search='true'
              className={'form-control form-select form-select-sm form-select-white fieldset-input'}
            >
              <option value=''>Selecionar</option>
              {TASK_TYPES?.map((tp: any) => (
                <option value={tp.id} key={tp.id}>
                  {tp.value}
                </option>
              ))}
            </select>
          </InputFieldSet>
        </div>
      )}

      <div className='fv-row mb-7'>
        <InputFieldSet label={'Observação'}>
          <textarea
            placeholder=''
            value={task.observation}
            name='observation'
            className={'form-control fieldset-input'}
            autoComplete='off'
            style={{paddingRight: '3.5rem'}}
            disabled={false}
            onChange={(e) => changeTaskValues(e.target.value, 'observation')}
          />
        </InputFieldSet>
      </div>
    </div>
  )
}

export default TaskForm
