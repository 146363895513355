/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'
import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { CustomFieldsHeader } from './components/header/CustomFieldsHeader'
import { CustomFieldsTable } from './table/CustomFieldsTable'
import { CustomFieldsEditModal } from './edit-modal/CustomFieldsEditModal'
import {getCustomFields} from './core/_requests'
import { CustomFieldFormModalProvider } from './core/CustomFieldFormModalContext'

const CustomFieldsPage = () => {
  return (
    <>
      <CustomFieldsHeader />
      <KTCard>
        <CustomFieldsTable />
      </KTCard>
      <CustomFieldsEditModal />
    </>
  )
}


const CustomFields: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getCustomFields}>
          <ListViewProvider>
            <CustomFieldFormModalProvider>
              <CustomFieldsPage />
            </CustomFieldFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { CustomFields }
