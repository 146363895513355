import {FC} from 'react'

type Props = {
  status?: number
}

const StatusCell: FC<Props> = ({status}) => (
  <>
    {' '}
    {status == 1 ? (
      <div className='badge badge-light-success fw-bolder'>Ativo</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>Inativo</div>
    )}
  </>
)

export {StatusCell}
