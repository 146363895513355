import {useState} from 'react'
import {Container, FileInfo, Preview, PreviewContainerIcon, StrongText} from './styles'
import ExpandImageModal from '../../../expand-preview-imagem-component/Index'
import {getAttachment} from '../../../../../app/pages/cards/core/_requests'
import {Spinner} from 'react-bootstrap'
const FileList = ({files, deleteFile}: any) => {
  const [isHovered, setIsHovered] = useState(false)
  const [controlModalImage, setControlModalImage] = useState({
    url: '',
    show: false,
  })

  const [showLoading, setControlLoading] = useState({
    entity_id: null,
    show: false,
  })

  function shurtString(str: string, maxCumpriment = 20) {
    if (str?.length > maxCumpriment) {
      const mid = Math.floor((maxCumpriment - 3) / 2)
      const initial = str.slice(0, mid)
      const final = str.slice(-mid)
      return `${initial}...${final}`
    }

    // Se a string não for maior que o comprimento máximo, retorna a própria string
    return str
  }

  function formatDate(inputDate: any) {
    const months = [
      'jan.',
      'fev.',
      'mar.',
      'abr.',
      'mai.',
      'jun.',
      'jul.',
      'ago.',
      'set.',
      'out.',
      'nov.',
      'dez.',
    ]

    let date = new Date(inputDate)

    const day = String(date.getDate()).padStart(2, '0')
    const month = months[date.getMonth()]
    const year = date.getFullYear()

    let hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')

    const ampm = hours >= 12 ? 'PM' : 'AM'

    if (hours === 0) {
      hours = 12
    } else if (hours > 12) {
      hours = hours - 12
    }

    let formattedHours = String(hours).padStart(2, '0')
    if (ampm == 'AM') {
      if (formattedHours?.toString()?.includes('12')) {
        formattedHours = '00'
      }
    }

    return `${day} ${month}${year}, ${formattedHours}:${minutes} ${ampm}`
  }

  const renderIcon = (type: string) => {
    if (type?.includes('pdf')) {
      return 'fas fa-file-pdf'
    } else if (type?.includes('word')) {
      return 'fas fa-file-word'
    } else if (type?.includes('excel') || type?.includes('sheet')) {
      return 'fas fa-file-excel'
    }

    return 'fas fa-file'
  }
  const handleGetFile = async (file: any) => {
    setControlLoading({
      show: true,
      entity_id: file?.idFile,
    })
    try {
      if (!file?.old || file?.preview) {
        handleDownload(file?.preview, file?.name)
      } else {
        let response = await getAttachment(file?.idFile)
        handleDownloadBinary(response.data, file?.name)
      }
    } catch (e) {
      console.log(e)
    }
    setControlLoading({
      show: false,
      entity_id: null,
    })
  }
  const handleDownload = (url: any, name: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.target = '_blank'
    downloadLink.download = name
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handleDownloadBinary = (binaryData: any, fileName: any) => {
    const url = window.URL.createObjectURL(new Blob([binaryData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }
  const renderContent = (file: any) => {
    if (file?.file?.type?.includes('image')) {
      return <Preview src={file?.preview} />
    } else {
      return (
        <PreviewContainerIcon>
          <i
            className={renderIcon(file?.file?.type)}
            style={{fontSize: '34px', color: '#93a4ee'}}
          ></i>
        </PreviewContainerIcon>
      )
    }
  }

  return (
    <Container>
      {files.map((file: any) => (
        <li key={file?.idFile}>
          <FileInfo
            onClick={() => {
              if (file?.file?.type?.includes('image')) {
                setControlModalImage({
                  show: true,
                  url: file?.preview,
                })
              } else {
                handleGetFile(file)
              }
            }}
          >
            {renderContent(file)}
            <div>
              <StrongText style={{fontSize: '10px'}}>
                {shurtString(file?.name)}{' '}
                {showLoading.show && showLoading.entity_id == file?.idFile ? (
                  <Spinner size='sm' style={{position: 'relative', left: '10px'}} />
                ) : file?.file?.type?.includes('image') ? (
                  <i className='fas fa-eye'></i>
                ) : (
                  <i className='fas fa-download'></i>
                )}
              </StrongText>
              <span style={{fontSize: '10px'}}>
                {file.readableSize
                  ? file.readableSize
                  : file?.createdAt
                  ? formatDate(file?.createdAt)
                  : null}{' '}
                {deleteFile ? (
                  <button
                    onClick={(e) => {
                      deleteFile(file)
                      e?.stopPropagation()
                    }}
                    type='button'
                  >
                    Remover
                  </button>
                ) : null}
              </span>
            </div>
          </FileInfo>
        </li>
      ))}
      <ExpandImageModal
        show={controlModalImage.show}
        imageUrl={controlModalImage.url}
        onClose={() => {
          setControlModalImage({
            show: false,
            url: '',
          })
        }}
      />
    </Container>
  )
}

export default FileList
