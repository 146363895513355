import {ID, Response} from '../../../../../_metronic/helpers'
export type CustomForm = {
  id?: ID
  entity?: string
  type?: any
  funnel?: any
  funnelId?: any
  formFields?: Array<any>
}

export type CustomFormsQueryResponse = Response<Array<CustomForm>>

export const initialLabel: CustomForm = {
}

export const CUSTOM_FORM_TYPES = {
  INITIAL: { id: 1, name: 'Formulário Inicial' },
  PUBLIC: { id: 2, name: 'Formulário Público' },
  COMPLEMENT: { id: 3, name: 'Formulário Complementar' }
}