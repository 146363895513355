/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {initialQueryState, KTIcon, useDebounce} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../../modules/view/QueryRequestProvider'
import { InputFieldSet } from '../../../../../../_metronic/partials/inputs/InputFieldSet'

const TerminationReasonsListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({search: debouncedSearchTerm, ...initialQueryState})
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <InputFieldSet
            label={"Pesquisar"}
          >
            <div className='d-flex align-items-start position-relative'>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control w-250px fieldset-input'
                placeholder='Pesquisar...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ paddingRight: "1rem" }}
                />
             <KTIcon iconName='magnifier' className='fs-1' style={{position:"relative",left:"-6px",top:"-2px"}} />
            </div>
          </InputFieldSet>
  )
}

export {TerminationReasonsListSearchComponent}
