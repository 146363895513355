import React, {useState} from 'react'
import {Container, Form, Button, Row, Col, Card, Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import AuthInput from '../../../../_metronic/helpers/custom-inputs/AuthInput'
import { submitPasswordRecovery } from '../core/_requests'

export function RecoverPassword() {
  const navigate = useNavigate()
  const [dataForm, setDataForm] = useState<any>({
    password: '',
    confirmNewPassword: '',
    code: '',
    passwordChanged: false,
  })
  const [dirty, setDirty] = useState(false)

  const handleSubmit = async () => {
    if (!dataForm?.password || !dataForm.confirmNewPassword || !dataForm.code) {
      Swal.fire({
        title: 'Atenção!',
        text: 'É necessário preencher todos os campos.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
      return
    }
    if (dataForm?.password != dataForm.confirmNewPassword) {
      Swal.fire({
        title: 'Atenção!',
        text: 'As senhas não conferem.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
      return
    }
    setDirty(true)
    try {
      let data = {...dataForm}
      
      await submitPasswordRecovery(data.code, data.password);

      data.changedPassword = true
      setDataForm(data);
    } catch (e) {
      console.log(e)
      Swal.fire({
        title: 'Ops...',
        text: 'Ocorreu algo inesperado',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
    setDirty(false)
  }

  return (
    <Container>
      <Row style={{justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
        <Col md={5}>
          <Card
            style={{
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
              backgroundColor: 'white',
            }}
          >
            <Card.Body>
              {!dataForm.changedPassword ? (
                <>
                  {' '}
                  <h1 className='text-center text-dark fw-bolder' style={{marginBottom: '35px'}}>
                    Alterar senha
                    <p style={{fontSize: '13px', fontWeight: '500', alignItems: 'start'}}>
                      Digite o código que você recebeu no e-mail e insira sua nova senha.
                    </p>
                  </h1>
                  <Form.Group>
                    <label className='form-label fs-6 fw-bolder text-dark'>Código</label>
                    <AuthInput
                      name='code'
                      value={dataForm.code}
                      type='text'
                      placeholder='Código recebido por e-mail'
                      onChange={(e: any) => {
                        let data = {...dataForm}
                        data.code = e?.target?.value
                        setDataForm(data)
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mt-4'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Nova senha</label>
                    <AuthInput
                      name='password'
                      value={dataForm.password}
                      type='password'
                      placeholder='Nova senha'
                      onChange={(e: any) => {
                        let data = {...dataForm}
                        data.password = e?.target?.value
                        setDataForm(data)
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mt-4'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      Confirmar nova senha
                    </label>
                    <AuthInput
                      name='confirmNewPassword'
                      value={dataForm.confirmNewPassword}
                      type='password'
                      placeholder='Confirme nova senha'
                      onChange={(e: any) => {
                        let data = {...dataForm}
                        data.confirmNewPassword = e?.target?.value
                        setDataForm(data)
                      }}
                    />
                  </Form.Group>
                  <Button
                    disabled={dirty}
                    style={{width: '100%', marginTop: '15px', fontWeight: 'bold'}}
                    variant='wl-custom-primary-collor'
                    type='button'
                    onClick={handleSubmit}
                  >
                    {dirty ? (
                      <>
                        {' Processando solicitação...'}{' '}
                        <Spinner animation='border' size='sm' color='white' />
                      </>
                    ) : (
                      'Confirmar'
                    )}
                  </Button>
                </>
              ) : (
                <p className='text-center' style={{marginTop: '20px'}}>
                  <h1>Senha alterada com sucesso! 😁</h1>
                  <h3 className='mt-5'>
                    <Link to='/login'> Voltar ao login</Link>
                  </h3>
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
