import { useDrag } from 'react-dnd'

const FunnelStepCardDraggable = ({card, children, onClick}: {card: any; children: any; onClick: any}) => {
  const [{isDragging}, dragRef] = useDrag({
    type: 'card',
    item: card,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <div ref={dragRef} key={card.id} onClick={onClick}>
      {children}
    </div>
  )
}

export default FunnelStepCardDraggable
