/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { LabelListHeader } from './components/header/LabelListHeader'
import { LabelSettingsTable } from './table/LabelSettingsTable'
import { LabelEditModal } from './edit-modal/LabelEditModal'
import {getLabels} from './core/_requests'

const LabelSettingsPage = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <LabelListHeader />
      <KTCard>
        <LabelSettingsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <LabelEditModal />}
    </>
  )
}


const LabelSettings: FC = () => {
  return (
    <>
    {/*
      <h1>
        <strong>CRM</strong>
      </h1>
      <div className='breadcrumbs' style={{ marginBottom: "0.5rem" }}>
        Painel da Empresa / CRM / Configurações / Etiquetas
      </div>
  */}
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getLabels}>
          <ListViewProvider>
            <LabelSettingsPage />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { LabelSettings }
