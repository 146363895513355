import {useState} from 'react'
import {useQueryRequest} from '../../../../../modules/view/QueryRequestProvider'
import {useQueryResponse} from '../../../../../modules/view/QueryResponseProvider'
import {initialQueryState} from '../../../../../../_metronic/helpers'

const AutomaticActionsFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [entity, setEntity] = useState<string | undefined>()

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  return <></>
}

export {AutomaticActionsFilter}
