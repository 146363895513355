import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { request } from '../../../services/crm-api/request'



const  getDashboardOpportunities = async (query: string): Promise<any> => {
    return request(
        {
            method: 'GET',
            url: `/dashboards/opportunities/?${query}`,
        },
        {
            setAuth: true,
        }
    ).then((d: any) => d.data)
}

const getDashboardTickets = async ( query: string):Promise<any> => {
    return request(
        {
            method: 'GET',
            url: `/dashboards/tickets/?${query}`,
        },
        {
            setAuth: true,
        }
    ).then((d: any) => d.data)
}

export {
    getDashboardOpportunities,
    getDashboardTickets,

}
