import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../_metronic/helpers'
import { TicketCategory } from '../core/_models'
import clsx from 'clsx'
import { ListLoading } from '../../../../modules/view/ListLoading'
import { createTicketCategory, updateTicketCategory } from '../core/_requests'
import { useQueryResponse } from '../../../../modules/view/QueryResponseProvider'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import { useQuery } from '@tanstack/react-query'
import Creatable from 'react-select/creatable';
import { useTicketCategoryFormModalDispatch } from '../core/TicketCategoryFormModalContext'

type Props = {
  isLoading: boolean
  category: TicketCategory
}

const editDataSchema = Yup.object().shape({})

const TicketCategoriesForm: FC<Props> = ({ category, isLoading }) => {
  const { refetch } = useQueryResponse()

  const ticketCategoryFormModalDispatch = useTicketCategoryFormModalDispatch();

  const [dataForEdit] = useState<TicketCategory>({
    name: category ? category.name : "",
    description: category ? category.description : "",
    status: category ? category.status : 1,
  })

  const handleClose = () => {
    ticketCategoryFormModalDispatch({
      type: 'close-modal'
    })
    refetch()
  };

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true)

      let validErrorText = null;

      if (!values.name && !validErrorText) validErrorText = "Campo Nome é obrigatório."

      if (validErrorText) {
        return Swal.fire({
          title: 'Opss..',
          text: validErrorText,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let auxData: any = {
        id: category ? category.id : undefined,
        name: values.name,
        status: values.status,
        description: values.description
      };

      try {
        if (isNotEmpty(auxData.id)) {
          await updateTicketCategory(auxData)
        } else {
          await createTicketCategory(auxData)
        }

        Swal.fire('Registro Salvo!', '', 'success')

        handleClose();
        resetForm();

      } catch (ex: any) {
        console.error(ex)

        let errorDetected = "";
        if(ex.response?.data?.key && ex.response?.data?.message)
        {
          errorDetected = ex.response?.data?.message;
        }
        else if(ex.response?.data?.message && Array.isArray(ex.response?.data?.message))
        {
          if(ex.response?.data?.message.includes("name should not be empty")) errorDetected = "Campo Nome é Obrigatório"
        }

        Swal.fire({
          title: 'Opss..',
          text: errorDetected ? errorDetected  : 'Houve um problema ao salvar a categoria.',
          icon: 'error',
          confirmButtonText: 'OK'
        })

      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={<>{"Nome "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
            >

              <input
                placeholder=''
                {...formik.getFieldProps('name')}
                type='text'
                name="name"
                className={clsx(
                  'form-control fieldset-input'
                )}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>

          </div>

          {category?.id != undefined && (
            <div className='fv-row mb-7'>
              <InputFieldSet
                label={"Status"}
              >

                <select
                  {...formik.getFieldProps('status')}
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-sm form-select-white fieldset-input'
                  defaultValue='1'
                >
                  <option value=''>Selecione</option>
                  <option value='1'>Ativo</option>
                  <option value='0'>Inativo</option>
                </select>

              </InputFieldSet>
            </div>
          )}


          <div className='fv-row mb-7'>
            <InputFieldSet
              label={"Descrição"}
            >
              <textarea
                placeholder=''
                {...formik.getFieldProps('description')}
                name="description"
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
          </div>


        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>

          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export { TicketCategoriesForm }
