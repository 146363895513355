// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Company, TASK_STATUS} from '../../core/_models'

const tableColumns: ReadonlyArray<Column<Company>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Assunto' className='min-w-125px' />,
    id: 'title',
    accessor: 'title',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Tarefa' className='min-w-125px' sort={false} />
    ),
    id: 'type.name',
    accessor: 'type.name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={false} />
    ),
    id: 'status',
    Cell: ({...props}) => {
      let status = props.data[props.row.index].status
      let statusText = ''

      switch (status) {
        case TASK_STATUS.CANCELED:
          statusText = 'Cancelada'
          break
        case TASK_STATUS.COMPLETED:
          statusText = 'Concluída'
          break
        case TASK_STATUS.LATE:
          statusText = 'Atrasada'
          break
        case TASK_STATUS.SCHEDULED:
          statusText = 'Agendada'
          break
      }

      return statusText
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Data Hora' className='min-w-125px' sort={true} />
    ),
    id: 'date',
    Cell: ({...props}) => {
      return new Date(props.data[props.row.index].date).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
      })
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Responsável' className='min-w-125px' sort={false} />
    ),
    id: 'responsible',
    Cell: ({...props}) => props.data[props.row.index].responsible?.name,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell entity={props.data[props.row.index]} />,
  },
]

export {tableColumns}
