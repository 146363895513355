import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTIcon } from '../../../../../_metronic/helpers'
import { useQueryRequest } from '../../../../modules/view/QueryRequestProvider'
import { useQueryResponse } from '../../../../modules/view/QueryResponseProvider'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import SelectAsync from 'react-select/async';
import debounce from "debounce-promise";
import {getCompanies} from '../../core/_requests'

const ContactsListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [company, setCompany] = useState<string | undefined>()
  const [phone, setPhone] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setPhone("");
    setEmail("");
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { phone, email },
      ...initialQueryState,
    })
  }

  const companiesOptions = (inputValue: string) =>
  new Promise<any[]>(async (resolve) => {
    let list : Array<any> = [];
    let companies = await getCompanies("page=1&perPage=20&search="+encodeURIComponent(inputValue));
    if(companies && companies.data != undefined)
    {
      for(let i = 0; i < companies?.data?.length; i++)
      {
        list.push({label: companies.data[i].name, value: companies.data[i].id});
      }
    }
    resolve(list);
  });

  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-sm btn-wl-custom-primary-collor-inverse me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <i className="fa-solid fa-filter fs-2"></i>
        Filtros
      </button>

      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}

          <div className='mb-10'>
            <InputFieldSet
            label={"Telefone"}
            >
              <input
                placeholder=''
                type='text'
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className={ 'form-control fieldset-input'}
                autoComplete='off'
              />
            </InputFieldSet>
          </div>

          <div className='mb-10'>
            <InputFieldSet
            label={"Email"}
            >
              <input
                placeholder=''
                type='text'
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={ 'form-control fieldset-input'}
                autoComplete='off'
              />
            </InputFieldSet>
          </div>
    {/*
          <div className='mb-10'>
          <InputFieldSet
            label={"Empresa"}
          >
          <SelectAsync
              placeholder={""}
              name="company"
              loadOptions={debounceCompaniesOptions}
              value={company}
              cacheOptions 
              defaultOptions
              onChange={selectedOption  => 
                setCompany(selectedOption ? selectedOption : "")
              }
              className="fieldset-input"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderStyle: "none",
                  backgroundColor: undefined,
                  boxShadow: "none",
                })
              }}
          />
          </InputFieldSet>
            </div>*/}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Limpar
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Aplicar
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { ContactsListFilter }
