import {FC} from 'react'

type Props = {
  status?: number
}

const StatusCell: FC<Props> = ({status}) => (
  <> {status ? <div className='badge badge-success-wl-custom-collor fw-bolder'>Ativo</div> : <div className='badge badge-danger-wl-custom-collor fw-bolder'>Inativo</div>}</>
)

export {StatusCell}
