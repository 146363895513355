/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider} from '../../modules/view/ListViewProvider'

import {QueryRequestProvider} from '../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../modules/view/QueryResponseProvider'
import {getOpportunities} from './core/_requests'
import {CardsPage} from './CardsPage'
import {CARD_TYPES} from './core/_models'
import {CardFormModalProvider} from './core/CardFormModalContext'
const Opportunities = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getOpportunities}>
          <ListViewProvider>
            <CardFormModalProvider>
              <CardsPage type={CARD_TYPES.OPPORTUNITY.id} />
            </CardFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {Opportunities}
