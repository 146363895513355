/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useQueryResponse} from '../../../../../modules/view/QueryResponseProvider'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {deleteTeam} from '../../core/_requests'
type Props = {
  entity: any
}

const ActionsCell: FC<Props> = ({entity}) => {
  const {query, refetch} = useQueryResponse()
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    navigate(`/teams/edit/${entity.id}`)
  }

  const confirmDeleteRow = async (id: any) => {
    Swal.fire({
      title: `Remover Equipe`,
      text: `Tem certeza que deseja excluir este registro?`,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteTeam(id)
          refetch()
          Swal.fire('Sucesso!', 'Registro excluído com sucesso!', 'success')
        } catch (e: any) {
          if (e?.response?.data?.message) {
            return Swal.fire('Ops...!', e?.response?.data?.message, 'error')
          }
          Swal.fire('Ops...!', 'Falha ao carregar solicitação... ', 'error')
        }
      }
    })
  }

  return (
    <>
      <a href='#' className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon svg-icon-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => {
              confirmDeleteRow(entity?.id)
            }}
          >
            Excluir
          </a>
        </div>
      </div>
    </>
  )
}

export {ActionsCell}
