import { createContext, useContext, useReducer } from 'react';

export interface CompanyFormModalContextModel {
  open: boolean
  form_action: string | null
  companyId: number | null
}

const initialData = {
  open: false,
  companyId: null,
  form_action: null
};

const CompanyFormModalContext = createContext<CompanyFormModalContextModel>(initialData);
const CompanyFormModalDispatchContext = createContext<any>(null);

export function CompanyFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    companyFormModalReducer,
    initialData
  );

  return (
    <CompanyFormModalContext.Provider value={data}>
      <CompanyFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </CompanyFormModalDispatchContext.Provider>
  </CompanyFormModalContext.Provider>
  );
}

export function useCompanyFormModal() {
  return useContext(CompanyFormModalContext);
}

export function useCompanyFormModalDispatch() {
  return useContext(CompanyFormModalDispatchContext);
}

function companyFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true, form_action: "add" }
    }
    case 'open-edit-modal': {
      return { ...data, open: true, companyId: action.data, form_action: "edit" }
    }
    case 'open-detail-modal': {
      return { ...data, open: true, companyId: action.data, form_action: "detail" }
    }
    case 'close-modal': {
      return { ...data, open: false, companyId: null, form_action: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
