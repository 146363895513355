import CountUp from 'react-countup';
import { Card, Col, Dropdown, Row } from "react-bootstrap"
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet"
import { useFunnel, useFunnelDispatch } from "../../../funnel/core/FunnelContext"
import styled from "styled-components";
import { KTCard } from "../../../../../_metronic/helpers";
type Props = {
    step: string,
    cards: number,
    open: number,
    close: number,
    discard: number,
    lost: number,
    index: number,
    total: number,
    ticket: boolean
}
const cardData: any[] = [
    { etapa: "Etapa 1", cards: 20, abertos: 16, fechados: 4, descartado: 0 },
    { etapa: "Etapa 2", cards: 13, abertos: 10, fechados: 2, descartado: 1 },
    { etapa: "Etapa 3", cards: 6, abertos: 5, fechados: 1, descartado: 0 },




];

type Icard = {
    ticket: boolean
    data: any,
    funnelName: string
}
const CardStep = ({ step, cards, open, close, discard, index, total, lost, ticket }: Props) => {

    const isLastCardStep = index === total - 1 || index % 3 === 2;

    return (
        <div className="py-2" style={{
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center',
            borderRight: isLastCardStep ? 'none' : '1px solid #d3d3d3'
        }}>
            <h2 className="mb-6">{step}</h2>
            <div>
                <h1 className="mt-2 mb-0 text-wl-custom-title-collor">
                    <CountUp
                        start={0}
                        end={cards}
                        duration={2.75}
                        className="text-bold"
                        separator="."
                    />
                </h1>
                <h5>Cards</h5>
            </div>
            <div className="pt-4">
                <h5 className="mb-3">
                    <CountUp
                        start={0}
                        end={open}
                        duration={2.75}
                        className="text-bold"
                        separator="."
                    />
                    <span> Aberto(s)</span>
                </h5>
                <h5 className="mb-3">
                    <CountUp
                        start={0}
                        end={close}
                        duration={2.75}
                        className="text-bold"
                        separator="."
                    />
                    <span> Fechado(s)</span>

                </h5>
                {ticket ? (
                    <h5 className="mb-3">
                        <CountUp
                            start={0}
                            end={discard}
                            duration={2.75}
                            className="text-bold"
                            separator="."
                        />
                        <span> Descartado(s)</span>

                    </h5>
                ) : (
                    <h5 className="mb-3">
                        <CountUp
                            start={0}
                            end={lost}
                            duration={2.75}
                            className="text-bold"
                            separator="."
                        />
                        <span> Perdido(s)</span>

                    </h5>
                )}

            </div>

        </div>
    );
}
const DashboardCardStep = ({ data, ticket, funnelName }: Icard) => {
    return (

        <Card style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', overflowY: 'scroll', height: '310px' }}>
            <Card.Title className='px-6 py-6'>{funnelName}</Card.Title>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {data.length > 0 ? data.map((item: any, index: any) => (
                    <div style={{ flex: data.length >= 3 ? '0 0 calc(33.33% - 10px)' : '1 0 auto', margin: '5px' }} key={index}>
                        <CardStep
                            step={item.name}
                            cards={item.total}
                            open={item.open}
                            close={item.closed}
                            discard={item.discard}
                            index={index}
                            total={data.length}
                            lost={item.lost}
                            ticket={ticket}
                        />
                    </div>
                )) : <p className="my-2 py-2" style={{ textAlign: 'center', width: '100%' }}>Sem cards para mostrar</p>}
            </div>
        </Card>

    )


}
export { DashboardCardStep }
