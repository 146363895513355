import { createContext, useContext, useReducer } from 'react';

export interface CardFreezeModalContextModel {
  open: boolean,
  opportunity: any
}

const initialData = {
  open: false,
  opportunity: null
};

const CardFreezeModalContext = createContext<CardFreezeModalContextModel>(initialData);
const CardFreezeModalDispatchContext = createContext<any>(null);

export function CardFreezeModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardFreezeReducer,
    initialData
  );

  return (
    <CardFreezeModalContext.Provider value={data}>
      <CardFreezeModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardFreezeModalDispatchContext.Provider>
  </CardFreezeModalContext.Provider>
  );
}

export function useCardFreezeModal() {
  return useContext(CardFreezeModalContext);
}

export function useCardFreezeModalDispatch() {
  return useContext(CardFreezeModalDispatchContext);
}

function cardFreezeReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-modal': {
      return { ...data, open: true, opportunity: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, opportunity: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
