import { createContext, useContext, useReducer } from 'react';

export interface FunnelSettingsModalContextModel {
  open: boolean
  funnelId: number | null
}

const initialData = {
  open: false,
  funnelId: null,
};

const FunnelSettingsModalContext = createContext<FunnelSettingsModalContextModel>(initialData);
const FunnelSettingsModalDispatchContext = createContext<any>(null);

export function FunnelSettingsModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    funnelSettingsModalReducer,
    initialData
  );

  return (
    <FunnelSettingsModalContext.Provider value={data}>
      <FunnelSettingsModalDispatchContext.Provider value={dispatch}>
        {children}
      </FunnelSettingsModalDispatchContext.Provider>
  </FunnelSettingsModalContext.Provider>
  );
}

export function useFunnelSettingsModal() {
  return useContext(FunnelSettingsModalContext);
}

export function useFunnelSettingsModalDispatch() {
  return useContext(FunnelSettingsModalDispatchContext);
}

function funnelSettingsModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, funnelId: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, funnelId: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
