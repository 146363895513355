import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'


import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import { Formik, useFormikContext } from 'formik'

import { getFunnels } from '../../funnels/core/_requests';
import Swal from 'sweetalert2'
import { CARD_TYPES } from '../../../cards/core/_models'

import { useImportModalDispatch } from '../core/ImportModalContext';
import DragDropFiles from '../components/DrapDrogFiles';
import { getFunnelStages } from '../../../cards/core/_requests';

const SelectEntityForm = ({ onSelectEntity }: { onSelectEntity: any }) => {

  const { isError: funnelsIsError, data: funnels, error: funnelsError } = useQuery({
    queryKey: ['custom-forms', 'funnels'],
    queryFn: () => {
      return getFunnels("page=1&perPage=200")
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const [stages, setStages] = useState<any[]>([])

 


  type initialValuesType = {
    entity: string
    funnel: number | null
    funnelStageId: number | null
    entityType: number | null
  }
  var initialValues: initialValuesType = { entity: "", funnel: null, funnelStageId: null, entityType: null }


  const [form, setForm] = useState(initialValues)

  useEffect(() => {
    const funnelId = form.funnel;

    if (funnelId) {
      loadStages(funnelId);
    } else {
      setStages([]);
    }
  }, [form.funnel]);

  let loadStages = async (funnel_id:any) => {
    try {
      let list: any[] = [];
      let funnelStages = await getFunnelStages(funnel_id, 'page=1&perPage=100');
      if (funnelStages) {
        funnelStages.forEach(stage => {
          list.push({
            label: stage.title,
            value: stage.id,
            funnel: funnel_id,
          });
        });
      }
      setStages(list);
    } catch (e) {
      console.error(e);
      setStages([]);
    }
  };

  return <>
    <div style={{
      backgroundColor: "",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      justifyContent: "center"
    }}>
      <p>
        Selecione a Entidade para realizar a importação
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={async (formValues, { setSubmitting, resetForm }) => {
          //console.log("formValues", formValues);

          let values = { ...form };

          if (!values.entity) return Swal.fire({
            title: 'Opss..',
            text: 'Informe a entidade para importação.',
            icon: 'error',
            confirmButtonText: 'OK',
          })


          if (values.entity?.includes("card:")) {
            if (!values.funnel) return Swal.fire({
              title: 'Opss..',
              text: 'Informe o funil para importação.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
            if (!values.funnelStageId) return Swal.fire({
              title: 'Opss..',
              text: 'Informe a etapa do funil para importação.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
            let subType = values.entity.replace("card:", "");
            values.entity = "card";

            values.entityType = parseInt(subType.toString());
          }

          onSelectEntity(values);

          resetForm();
        }}
      >
        {props => (

          <form id='select_entity' className='form' onSubmit={props.handleSubmit} noValidate style={{ display: "flex", flexDirection: "column", gap: "8px" }}>



            <InputFieldSet label={'Entidade'}>
              <select
                name='entity'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-lg fieldset-input'
                onChange={(e) => {
                  setForm(prevForm => ({
                    ...prevForm,
                    entity: e.target.value,
                    funnel:null,
                    funnelStageId:null,
                  }));
                }}
                value={form.entity}
              >
                <option value={""}>Selecione</option>
                {/* <option value={"contact"}>Contato</option> */}
                <option value={"company"}>Empresa</option>
                <option value={`card:${CARD_TYPES.OPPORTUNITY.id}`}>Oportunidade</option>
                <option value={`card:${CARD_TYPES.TICKET.id}`}>Tícket</option>
              </select>
            </InputFieldSet>


            {form.entity?.includes("card:") &&
              <InputFieldSet label={'Funil'}>
                <select
                  name='funnel'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-lg fieldset-input'
                  onChange={(e) => {
                    setForm(prevForm => ({
                      ...prevForm,
                      funnel: Number(e.target.value),
                      funnelStageId:null,
                    }));
                  }}
                  onBlur={props.handleBlur}
                  value={form.funnel ? form.funnel : ""}
                >
                  <option value={""}>Selecione</option>
                  {funnels && funnels.data && funnels.data.map((el: any, index: number) => {
                    if (el.type == form.entity.replace("card:", "")) return <option value={el.id} key={index}>{el.name}</option>
                  })}
                </select>
              </InputFieldSet>}

            {form.funnel && (

              <InputFieldSet label={'Etapa do Funil'}>
                <select
                  placeholder={'Selecionar etapa do funil'}
                  name='funnelStageId'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-lg fieldset-input'
                  onChange={(e) => {
                    setForm(prevForm => ({
                      ...prevForm,
                      funnelStageId: Number(e.target.value),
                    }));
                  }}
                  

                >
                  <option value={""}>Selecione</option>
                  {stages && stages.length > 0 && stages.map((stage, index) => (
                    <option key={index} value={stage?.value}>{stage?.label}</option>
                  ))}
                </select>
              </InputFieldSet>

            )}

            <button
              type='submit'
              className='btn  btn-primary'
              data-kt-users-modal-action='submit'
              disabled={props.isSubmitting || !props.isValid || !props.touched}
              style={{ marginTop: "5px" }}
            >
              <span className='indicator-label'>Confirmar</span>
            </button>
          </form>
        )}
      </Formik>
    </div>
  </>
}

const CustomImportModal = ({ onClose, formId }: { onClose: any, formId: any }) => {


  const systemImportModalDispatch = useImportModalDispatch()
  const [entityForm, setEntityForm] = useState<any>(null)

  const handleSelectEntity = (selected: any) => {
    setEntityForm(selected);



  }

  const handleClose = () => {
    onClose();
    // systemImportModalDispatch({
    //   type: 'reset'
    // });
    setTimeout(() => setEntityForm(null), 400);
  };


  return (
    <div style={{ height: "100%" }}>
      {(entityForm?.entity) && <DragDropFiles funnel={entityForm?.funnel} funnelStage={entityForm?.funnelStageId} entity={entityForm?.entity} entityType={entityForm?.entityType} onClose={handleClose} />}
      {!entityForm?.entity && !formId && <SelectEntityForm onSelectEntity={handleSelectEntity} />}
    </div>)
}

export default CustomImportModal;
