import { Card, Col, Dropdown, Row, Spinner } from "react-bootstrap"
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet"
import { useFunnel, useFunnelDispatch } from "../../../funnel/core/FunnelContext"
import styled from "styled-components";
import { KTCard } from "../../../../../_metronic/helpers";
import Chart from 'react-apexcharts';
import { useEffect, useRef, useState } from "react";
import { IconButtonClassKey } from "@mui/material";
import { useLocation } from "react-router";
type ICard = {
    ticket?: boolean
    funnelId?: any
    data: any
}
type IChart = {
    quantities: number[],
    months: string[],
    totalSale: any[],
    ticket?: boolean
}
const MonthlyChart = ({ ticket, months, quantities, totalSale }: IChart) => {
    let series_name = ticket ? 'Atendimentos mensais' : 'Vendas mensais'
    let data = quantities

    const chartColor = getComputedStyle(document.documentElement).getPropertyValue('--wl-first').trim()

    const series = [{
        name: series_name,
        data: data,
        color: chartColor
    }];

    const tooltipStyle = {
        backgroundColor: '#2f365f',
        borderRadius: '5px',
        padding: '10px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.2)',
        color: chartColor,
        fontWeight: 'bold',
        fontSize: '12px', 
        width: '30'
    };

    const totalSaleStyle = {
        color: chartColor,
        marginTop: '8px',
        display: 'block', 
    };

    return (
        <Chart options={{
            chart: {
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                colors: [chartColor]
            },
            xaxis: {
                categories: months,
            },
            fill: {
                type: 'gradient',
                colors: ['#FFF']
            },
            yaxis: {
                opposite: false
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        `<div style="${Object.entries(tooltipStyle).map(([key, value]) => `${key}: ${value}`).join(';')}">
                            <span style="display: block;">${w.globals.categoryLabels[dataPointIndex]}</span>
                            ${totalSale?.length > 0 ? (
                            `<span style="${Object.entries(totalSaleStyle).map(([key, value]) => `${key}: ${value}`).join(';')}">R$ ${parseFloat(totalSale[dataPointIndex]).toFixed(2).replace('.', ',')}</span>`

                        ) : (
                            `<span style="${Object.entries(totalSaleStyle).map(([key, value]) => `${key}: ${value}`).join(';')}">${series[seriesIndex][dataPointIndex]}</span>`
                        )}
                            
                        </div>`
                    );
                }
            }





        }} series={series} type="area" height={236} />
    )

};

const DashboardChart = ({ ticket, funnelId, data }: ICard) => {
    const monthMapping:any = {
        'Jan': 1,
        'Fev': 2,
        'Mar': 3,
        'Abr': 4,
        'Mai': 5,
        'Jun': 6,
        'Jul': 7,
        'Ago': 8,
        'Set': 9,
        'Out': 10,
        'Nov': 11,
        'Dez': 12
      };
    data.sort((a:any, b:any) => monthMapping[a.month] - monthMapping[b.month]);
    const quantities = data.map((item: { quantity: any; }) => item.quantity);
    const months = data.map((item: { month: any; }) => item.month);
    const totalSaleExists = data.some((item: any) => 'totalSale' in item);
    const totalSale = totalSaleExists ? data.map((item: { totalSale: any; }) => item.totalSale) : [];
    return (
        <Card style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <Card.Title className='px-6 py-6'>{ticket ? 'Atendimentos' : 'Vendas'} Mensais</Card.Title>
            <MonthlyChart key={funnelId} ticket={ticket ? true : false} months={months} quantities={quantities} totalSale={totalSale} />





        </Card>

    )


}
export { DashboardChart }
