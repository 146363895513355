import React, {useEffect, useState} from 'react'
import {Container, StartIcon, EndIcon, CentralData, FileName, FileInfo} from './styles'
import {getAttachment} from '../../core/_request'
import {Spinner} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {deleteAttachment} from '../../core/_request'

const AttachmentCard = ({
  key,
  attachmentId,
  FileNameData,
  sizeAndInfoUser,
  CreatedAt,
  loadDataPage,
  attachmentsData,
  setAttachmentsData,
}: any) => {
  const [controlLoading, setControlLoading] = useState({
    show: false,
  })
  function shurtString(str: string, maxCumpriment = 30) {
    if (str?.length > maxCumpriment) {
      const mid = Math.floor((maxCumpriment - 3) / 2)
      const initial = str?.slice(0, mid)
      const final = str?.slice(-mid)
      return `${initial}...${final}`
    }

    // Se a string não for maior que o comprimento máximo, retorna a própria string
    return str
  }
  function formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString)

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear() % 100
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const formattedDay = day.toString().padStart(2, '0')
    const formattedMonth = month.toString().padStart(2, '0')
    const formattedYear = year.toString().padStart(2, '0')
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')

    const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`
    const formattedTime = `${formattedHours}:${formattedMinutes}h`

    return `${formattedDate} às ${formattedTime}`
  }
  const handleGetFile = async () => {
    setControlLoading({
      show: true,
    })
    try {
      let response = await getAttachment(attachmentId)
      handleDownloadBinary(response.data, FileNameData)
    } catch (e) {
      console.log(e)
    }
    setControlLoading({
      show: false,
    })
  }
  const handleDownloadBinary = (binaryData: any, fileName: any) => {
    const url = window.URL.createObjectURL(new Blob([binaryData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }
  const confirmDelete = () => {
    Swal.fire({
      title: 'Excluir Anexo',
      text: 'Tem certeza que deseja excluir este anexo?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          await deleteAttachment(attachmentId)
          Swal.fire({
            title: 'Anexo removido com sucesso',
            icon: 'success',
          })
          removeOnState(attachmentId)
        } catch (e) {
          console.log(e)
        }
      } else if (result.isDenied) {
      }
    })
  }
  const removeOnState = (attachmentId: number) => {
    let newData = {...attachmentsData}
    newData.data = newData?.data?.filter((e: any) => e?.id != attachmentId)
    setAttachmentsData(newData)
  }
  return (
    <Container
      key={key}
      onClick={() => {
        handleGetFile()
      }}
    >
      <StartIcon>
        {controlLoading.show ? (
          <Spinner style={{color: '#93a4ee', fontWeight: 'bold'}} />
        ) : (
          <i className='fa-solid fa-file-export' style={{fontSize: '24px', color: '#0000C8'}}></i>
        )}
      </StartIcon>
      <CentralData>
        <FileName>{shurtString(FileNameData)}</FileName>
        <FileInfo>{sizeAndInfoUser}</FileInfo>
        <FileInfo>{formatDateTime(CreatedAt)}</FileInfo>
      </CentralData>
      <EndIcon
        onClick={(e: any) => {
          confirmDelete()
          e.stopPropagation()
        }}
      >
        <i className='fas fa-times' style={{fontSize: '20px', color: '#606161'}}></i>
      </EndIcon>
    </Container>
  )
}

export default AttachmentCard
