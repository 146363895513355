import {createContext, useContext, useReducer} from 'react'

export interface TaskFormModalContextModel {
  open: boolean
  form_action: string | null
  taskId: number | null
  entity_data?: any
}

const initialData = {
  open: false,
  taskId: null,
  form_action: null,
}

const TaskFormModalContext = createContext<TaskFormModalContextModel>(initialData)
const TaskFormModalDispatchContext = createContext<any>(null)

export function TaskFormModalProvider({children}: any) {
  const [data, dispatch] = useReducer(taskFormModalReducer, initialData)

  return (
    <TaskFormModalContext.Provider value={data}>
      <TaskFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </TaskFormModalDispatchContext.Provider>
    </TaskFormModalContext.Provider>
  )
}

export function useTaskFormModal() {
  return useContext(TaskFormModalContext)
}

export function useTaskFormModalDispatch() {
  return useContext(TaskFormModalDispatchContext)
}

function taskFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {...data, open: true, form_action: 'add', entity_data: action?.entity_data}
    }
    case 'open-edit-modal': {
      return {...data, open: true, taskId: action.data, form_action: 'edit'}
    }
    case 'close-modal': {
      return {...data, open: false, taskId: null, form_action: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
