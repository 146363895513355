// @ts-nocheck
import {Column} from 'react-table'
import {StatusCell} from './StatusCell'
import {TypeCell} from './TypeCell'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Funnel} from '../../core/_models'

const tableColumns: ReadonlyArray<Column<Funnel>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Tipo' className='min-w-125px' sort={false} />
    ),
    id: 'type',
    Cell: ({...props}) => <TypeCell type={props.data[props.row.index].type} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={false}/>
    ),
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].status} />,
  },
 
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {tableColumns}
