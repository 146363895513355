

import { Card, Col, Dropdown, Row, Table } from "react-bootstrap"
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet"
import { useFunnel, useFunnelDispatch } from "../../../funnel/core/FunnelContext"
import styled from "styled-components";
import { KTCard } from "../../../../../_metronic/helpers";
type Props = {
    step: string,
    cards: number,
    open: number,
    close: number,
    discard: number,
    index: number,
    total: number
}
const tableData: any[] = [
    { img: "https://picsum.photos/300/200", name: 'Aline Silva', valor: 25500, },
    { img: "https://picsum.photos/300/200", name: 'Ana Lima', valor: 25500, },
    { img: "https://picsum.photos/300/200", name: 'Carolina Oliveira', valor: 25500, },
    { img: "https://picsum.photos/300/200", name: 'Felipe Rodrigues', valor: 25500, },
    { img: "https://picsum.photos/300/200", name: 'Ricardo Santos', valor: 25500, },








];
type ICard = {
    ticket: boolean
    data: any
}
const getInitials = (name: string) => {
    const names = name.split(' ');
    const firstInitial = names[0] ? names[0][0].toUpperCase() : '';
    const secondInitial = names[1] ? names[1][0].toUpperCase() : '';
    return firstInitial + secondInitial;
}
const DashboardBestUsers = ({ ticket, data }: ICard) => {
    return (

        <Card style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <Card.Title className='px-6 pt-6 pb-0'>Melhores {ticket ? 'operadores' : 'vendedores'}</Card.Title>
            <div style={{
                position: "relative",
                height: "270px",
                overflow: "auto",
                paddingLeft: 20,
                paddingRight: 20
            }}>
                <Table hover >
                    <thead>
                        <tr>
                            <th></th>
                            <th ></th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>
                                        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                width: '30px',
                                                height: '30px',
                                                marginRight: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#EEE', 
                                                fontSize: '16px', 
                                                fontWeight: 'bold' 
                                            }}>
                                                {getInitials(item.name || '')}
                                            </div>
                                            {item.name}
                                        </div>
                                    </td>


                                    <td align="right">
                                        <h3 className="text-wl-custom-title-collor">
                                            {ticket ? (
                                                <strong>{item?.quantity}</strong>
                                            ) : (
                                                <>
                                                    <span>R$</span>
                                                    <strong>{parseFloat(item.value).toFixed(2).replace('.', ',')}</strong>
                                                </>
                                            )}
                                        </h3>
                                    </td>

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        Nenhum registro encontrado
                                    </div>
                                </td>

                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>


        </Card>
    )


}
export { DashboardBestUsers }
