import {FC} from 'react'
import { IMPORT_STATUS } from '../../core/_models'

type Props = {
  status?: number
}


const StatusCell: FC<Props> = ({status}) => (
  <> {status == IMPORT_STATUS.COMPLETED ? <div className='badge badge-light-success fw-bolder'>Completo</div> : <div className='badge badge-light-danger fw-bolder'>Desfeito</div>}</>
)

export {StatusCell}
