import React, {useState} from 'react'
import {Container, Form, Button, Row, Col, Card, Spinner} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import AuthInput from '../../../../_metronic/helpers/custom-inputs/AuthInput'

import {requestPasswordRecovery} from '../core/_requests'

const getDomain = () => {
  let url = window.location.hostname
  url = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0]
  }

  return url
}

export function ForgotPassword() {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [dirty, setDirty] = useState(false)
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  function isValid(email: string) {
    // Expressão regular para validar o formato do email
    var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return re.test(email)
  }
  const handleSubmit = async () => {
    let mailIsValid = isValid(email)
    if (!mailIsValid) {
      Swal.fire({
        title: 'Atenção!',
        text: 'O E-Mail inserido não é válido.',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
      return
    }
    setDirty(true)
    try {
      await requestPasswordRecovery(email, getDomain())
      navigate(`/recover-password`)
    } catch (e) {
      console.log(e)
      Swal.fire({
        title: 'Ops...',
        text: 'Ocorreu algo inesperado',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
    setDirty(false)
  }

  return (
    <Container>
      <Row style={{justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
        <Col md={5}>
          <Card
            style={{
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
              backgroundColor: 'white',
            }}
          >
            <Card.Body>
              <h1
                className='text-center text-dark fw-bolder'
                style={{
                  marginBottom: '35px',
                }}
              >
                Esqueceu sua senha?🤔
                <p style={{fontSize: '13px', fontWeight: '500', alignItems: 'start'}}>
                  Digite seu endereço de e-mail para redefinir a senha.
                </p>
              </h1>

              <label className='form-label fs-6 fw-bolder text-dark'>E-Mail</label>
              <AuthInput
                name='email'
                value={email}
                type='email'
                placeholder='Digite seu E-Mail'
                onChange={handleEmailChange}
              />
              <Button
                disabled={dirty}
                style={{width: '100%', marginTop: '15px', fontWeight: 'bold'}}
                variant='wl-custom-primary-collor'
                type='button'
                onClick={handleSubmit}
              >
                {dirty ? (
                  <>
                    {' Processando solicitação...'}{' '}
                    <Spinner animation='border' size='sm' color='white' />
                  </>
                ) : (
                  'Enviar E-Mail'
                )}
              </Button>
              <p className='text-center' style={{marginTop: '35px'}}>
                Lembrou da senha? <Link to='/login'>Voltar ao login</Link>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
