import {useEffect} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useCompanyFormModal, useCompanyFormModalDispatch} from '../core/CompanyFormModalContext'
import {getCompanyById} from '../core/_requests'
import {CompaniesForm} from './CompaniesForm'
import {useNavigate} from 'react-router-dom'
import {getCustomForms} from '../../settings/custom-forms/core/_requests'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCardCustomForm} from '../../settings/custom-forms/core/_requests'
const CompaniesFormModal = () => {
  const companyFormModal = useCompanyFormModal()
  const companyFormModalDispatch = useCompanyFormModalDispatch()
  const navigate = useNavigate()
  const handleClose = () => {
    companyFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/companies')
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['companies', 'edit', companyFormModal.companyId],
    queryFn: () => {
      return getCompanyById(companyFormModal.companyId)
    },
    enabled:
      companyFormModal.open &&
      companyFormModal.form_action != 'detail' &&
      companyFormModal.companyId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })
  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['companies', 'detail', 'complement-custom-forms', companyFormModal.companyId],
    queryFn: async () => {
      let auxData = await getCustomForms(`entity=company&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}`) //Complemento

      return auxData.data
    },
    enabled: companyFormModal.open && companyFormModal.form_action == 'edit' ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['companies', 'detail', 'initial-custom-forms', companyFormModal.companyId],
    queryFn: () => {
      return getCardCustomForm('company', null, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled: companyFormModal.open ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (companyFormModal.open && companyFormModal.form_action != 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [companyFormModal.open])

  if (!companyFormModal?.open) return null
  if (isError || (companyFormModal.companyId && !data) || companyFormModal.form_action == 'detail')
    return <></>

  return (
    <>
      <Modal
        show={companyFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{fontSize: '1.5rem', fontWeight: '700'}}>
            {companyFormModal.companyId ? 'Alterar ' : 'Adicionar '} Empresa
          </div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <Row>
            <Col>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail'>
                    Cadastro{' '}
                  </a>
                </li>
                {companyFormModal?.form_action == 'edit' &&
                  complementCustomForms?.map((form: any, index: number) => {
                    return (
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-bs-toggle='tab'
                          href={'#company_tab_pane_complement' + form.id}
                        >
                          {form.name}
                        </a>
                      </li>
                    )
                  })}
              </ul>
              <div className='tab-content'>
                {initialCustomForm?.fields?.length > 0 ? (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <CompaniesForm
                      customFormId={initialCustomForm?.id}
                      isLoading={false}
                      company={data}
                    />{' '}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <CompaniesForm isLoading={false} company={data} />
                  </div>
                )}
                {/* <div
                  className='tab-pane fade show active overflow-hidden'
                  id='card_tab_pane_detail'
                  role='tabpanel'
                >
                  {' '}
                  <CompaniesForm isLoading={false} company={data} />
                </div> */}
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <div
                      className={'tab-pane fade show'}
                      id={'company_tab_pane_complement' + form.id}
                      role='tabpanel'
                      key={index}
                    >
                      <CompaniesForm isLoading={false} customFormId={form.id} company={data} />
                    </div>
                  )
                })}
              </div>
              {/* <CompaniesForm isLoading={false} company={data} /> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CompaniesFormModal}
