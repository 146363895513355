import React from 'react';
import MaskedInput from 'react-text-mask';

const phoneMask = (rawValue: string) => {
  return rawValue.split('').map(() => /[^\s]/);
};

const PhoneFieldNT = ({ field, form, ...props }: any) => {
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    form.setFieldValue(field.name, value);
  };

  return (
    <MaskedInput
      {...field}
      {...props}
      guide={false}
      mask={phoneMask}
      onBlur={onBlur}
      onChange={() => {}}
      placeholder="Enter phone number"
      className="form-control fieldset-input"
      render={(ref: any, renderProps: any) => <input ref={ref} {...renderProps} />}
    />
  );
};

export default PhoneFieldNT;
