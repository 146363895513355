import {useCallback, useEffect} from 'react'
import {useDrop} from 'react-dnd'
import FieldInputDraggable from './FieldInputDraggable'
import FieldTypeInput from './FieldTypeInput'
import { useCustomFormEditor, useCustomFormEditorDispatch } from '../../core/CustomFormEditorContext'
import { CUSTOM_FORM_TYPES } from '../../core/_models'

const FormDroppable = ({
  fieldTypes,
  defaultRequiredFields,
  formIternalId
}: {
  fieldTypes: any
  defaultRequiredFields: any
  formIternalId: any
}) => {

  const customFormEditor = useCustomFormEditor()
  const customFormEditorDispatch = useCustomFormEditorDispatch()

  const currentForm = customFormEditor?.forms?.find((e:any)=>e?.internalId == formIternalId);

 // console.log('render FormDroppable currentForm customFormEditor', currentForm, customFormEditor)

  const [{itemIsOVer, itemType}, dropRef] = useDrop({
    accept: ['defaultfield', 'fieldtype', 'customfield'],
    drop: (item: any) => {
      if (itemType == 'fieldtype') {
        customFormEditorDispatch({
          type: 'open-add-field-modal',
          data: {
            formInternalId: currentForm?.internalId,
            formType: currentForm?.type,
            label: null,
            status: true,
            source: item.source,
            type: item,
          },
        })
      } else if (itemType == 'defaultfield') {
        let findType = fieldTypes.find((e: any) => e.key == item.type)
        customFormEditorDispatch({
          type: 'open-add-field-modal',
          data: {
            ...item,
            formInternalId: currentForm?.internalId,
            formType: currentForm?.type,
            status: true,
            type: findType
              ? findType
              : {
                  key: item.type,
                },
          },
        })
      } else if (itemType == 'customfield') {
        customFormEditorDispatch({
          type: 'open-add-field-modal',
          data: {
            ...item,
            formInternalId: currentForm?.internalId,
            formType: currentForm?.type,
            status: true,
          },
        })
      }
    },
    collect: (monitor) => ({
      itemIsOVer: monitor.isOver(),
      itemType: monitor.getItemType(),
    }),
  })

  

  const renderDraggableInputField = useCallback((field: any, index: number) => {
    let input = (
      <FieldTypeInput
        field={field}
        disabled={true}
        renderMode={'editor'}
        className='form-mount-input-field'
      />
    )

    return (
      <FieldInputDraggable
        key={field.internalId}
        index={index}
        id={field.internalId}
        field={field}
        input={input}
      />
    )
  }, [])

  useEffect(() => {
   
    if(!currentForm || currentForm.type != CUSTOM_FORM_TYPES.INITIAL.id) return;

    for (let i = 0; i < defaultRequiredFields?.length; i++) {
      let useField = defaultRequiredFields[i]
      let auxData: any = {
        formInternalId: currentForm?.internalId,
        id: useField?.id,
        internalId: Date.now()+i,
        source: useField.source,
        type: {
          key: useField.type,
        },
        name: useField.name,
        label: useField.label,
        status: 1,
        required: true,
      }

      let verifyExistent = currentForm?.fields?.find((e:any)=>e?.name == auxData?.name);
      if(verifyExistent) continue;

      customFormEditorDispatch({
        type: 'add-field',
        data: auxData,
      })
    }
  }, [])

  return (
    <div
      ref={dropRef}
      key={'form_manage'+currentForm?.internalId}
      style={{
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 4rem 4rem',
        gap: '15px',
      }}
    >
      <div style={{wordBreak:'break-word'}}>
        {currentForm?.fields.map((field: any, i: number) =>
          renderDraggableInputField(field, i)
        )}
      </div>
    </div>
  )
}
export default FormDroppable
