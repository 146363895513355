import { createContext, useContext, useReducer } from 'react';

export interface CustomFieldFormModalContextModel {
  open: boolean
  customFieldId: number | null
}

const initialData = {
  open: false,
  customFieldId: null,
};

const CustomFieldFormModalContext = createContext<CustomFieldFormModalContextModel>(initialData);
const CustomFieldFormModalDispatchContext = createContext<any>(null);

export function CustomFieldFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    customFieldFormModalReducer,
    initialData
  );

  return (
    <CustomFieldFormModalContext.Provider value={data}>
      <CustomFieldFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </CustomFieldFormModalDispatchContext.Provider>
  </CustomFieldFormModalContext.Provider>
  );
}

export function useCustomFieldFormModal() {
  return useContext(CustomFieldFormModalContext);
}

export function useCustomFieldFormModalDispatch() {
  return useContext(CustomFieldFormModalDispatchContext);
}

function customFieldFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, customFieldId: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, customFieldId: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
