// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Contact} from '../../core/_models'
import {EmailCell} from './EmailCell'
import {PhoneCell} from './PhoneCell'
import {CompanyCell} from './CompanyCell'

const tableColumns: ReadonlyArray<Column<Contact>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Email' className='min-w-125px' sort={false} />
    ),
    id: 'email',
    Cell: ({...props}) => <EmailCell emails={props.data[props.row.index].emails} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Telefone' className='min-w-125px' sort={false} />
    ),
    id: 'phone',
    Cell: ({...props}) => <PhoneCell phones={props.data[props.row.index].phones} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Empresa' className='min-w-125px' sort={false} />
    ),
    id: 'company',
    Cell: ({...props}) => <CompanyCell company={props.data[props.row.index].company} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Cargo' className='min-w-125px' sort={false}/>,
    id: 'role',
    accessor: 'role',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {tableColumns}
