import { useState, useRef } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet";
import { useQuery } from "react-query";
import { getCustomFields, getDefaultFields } from "../../custom-forms/core/_requests";
import { createImport, undoneImports } from "../core/_requests";
import { IMPORT_ENTITY, IMPORT_ENTITY_TYPE } from "../core/_models";
import { useImportModalDispatch } from "../core/ImportModalContext";

const DragDropFiles = ({ funnel, funnelStage, entity, entityType, onClose }: { funnel: any, funnelStage: any, entity: any, entityType: any, onClose: any }) => {
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false)
    const [secondPart, setSecondPart] = useState<any>({
        show: false,
        data: null,
        filter: null
    })
    const inputRef: any = useRef();
    const [tableData, setTableData] = useState<any>([]);
    const validateFile = (file: any) => {
        const validTypes = ['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        const maxSize = 3 * 1024 * 1024; // 3MB

        return validTypes.includes(file.type) && file.size <= maxSize;
    };
    const [selectedFieldType, setSelectedFieldType] = useState<any>({});
    const systemImportModalDispatch = useImportModalDispatch()
    const {
        isLoading: defaultSystemFieldsIsLoading,
        isError: defaultSystemFieldsIsError,
        data: defaultSystemFields,
        error: defaultSystemFieldsError,
    } = useQuery({
        queryKey: [entity, 'custom-forms', 'default-fields', entityType],
        queryFn: async () => {
            let dfFields = await getDefaultFields(entity)
            let workDfFields: any = []
            if (funnel) {
                dfFields.forEach((e: any) => {
                    if (e.cardTypes && !e.cardTypes.includes(entityType)) return
                    if (e.name == 'funnelId' || e.name == 'funnelStepId') return
                    workDfFields.push({ ...e, type: { key: e.type } })
                })
                return workDfFields
            }
            dfFields.forEach((e: any) => {
                workDfFields.push({ ...e, type: { key: e.type } })
            })
            return workDfFields
        },
        enabled: true,
        cacheTime: 60 * 1000, //1 minutes
        staleTime: 60 * 1000, //1 minutes
        retry: 3,
        refetchOnWindowFocus: false,
    });

    const {
        isLoading: customFieldsIsLoading,
        isError: customFieldsIsError,
        data: customFields,
        error: customFieldsError,
    } = useQuery({
        queryKey: [entity, 'custom-forms', 'custom-fields', entityType],
        queryFn: async () => {
            let cfields = funnel ? await getCustomFields('card', entityType) : await getCustomFields(entity)
            let workCFields: any = []
            cfields.forEach((e: any) => {
                workCFields.push({ ...e, name: 'custom_' + e.id })
            })
            return workCFields
        },
        enabled: true,
        cacheTime: 60 * 1000, //1 minutes
        staleTime: 60 * 1000, //1 minutes
        retry: 3,
        refetchOnWindowFocus: false,
    });

    const handleFileSelection = (event: any) => {
        event.preventDefault();
        const selectedFiles = event.target.files || event.dataTransfer.files;

        if (selectedFiles.length > 1) {
            return Swal.fire({
                title: 'Opss..',
                text: 'Por favor, selecione apenas um arquivo.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
        const file = selectedFiles[0];


        if (validateFile(file)) {
            setFile(file);
            processFile(file)
        } else {
            return Swal.fire({
                title: 'Opss..',
                text: 'Arquivo não suportado ou tamanho excede 3MB.',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleDrop = (event: any) => {
        handleFileSelection(event);
    };

    const getExampleByFieldType = (fieldType: string, fieldName: string) => {
        let example;
        if (fieldName == 'emails') fieldType = 'email'
        if (fieldName == 'phones') fieldType = 'phone'
        const today = new Date();
        const dateFormat = today.toLocaleDateString('pt-BR');
        const timeFormat = today.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

        switch (fieldType) {
            case 'number':
                example = 'Número, exemplo: 10';
                break;
            case 'short_text':
                example = 'Texto curto';
                break;
            case 'long_text':
                example = 'Texto longo';
                break;
            case 'date':
                example = `Data, exemplo: ${dateFormat}`;
                break;
            case 'datetime':
                example = `Data e hora, exemplo: ${dateFormat} ${timeFormat}`;
                break;
            case 'time':
                example = `Horário, exemplo: ${timeFormat}`;
                break;
            case 'phone':
                example = 'Telefone, exemplo: 5562999999999';
                break;
            case 'email':
                example = 'Email, exemplo: email@email.com';
                break;
            case 'monetary':
                example = 'Dinheiro, exemplo: R$ 10,00';
                break;
            case 'radio':
                example = 'Informe valor da seleção';
                break;
            case 'checkbox':
                example = 'Informe valor do checkbox';
                break;
            case 'switch':
                example = 'Sim ou Não';
                break;
            case 'select':
                example = 'Informe valor da seleção';
                break;
            case 'connection':
                example = 'Informe o id';
                break;
            case 'person':
                example = 'Informe id do usuário';
                break;
            default:
                example = '';
        }
        return example;
    }





    const handleUpload = async () => {
        const form = JSON.parse(JSON.stringify(tableData))

        let filteredArray = form.filter((item: { check: boolean; }) => item.check == true);
        if (filteredArray.length == 0) {
            return Swal.fire({
                title: 'Opss..',
                text: 'Selecione pelo menos um campo',
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }
        if ((entity == IMPORT_ENTITY.CONTACT || entity == IMPORT_ENTITY.COMPANY) && !filteredArray.find((e: { fieldName: string; }) => e.fieldName == 'name')) {
            return Swal.fire({
                title: 'Opss..',
                text: `Entidade ${entity == IMPORT_ENTITY.COMPANY ? 'Empresa' : 'Contato'} precisa ter pelo menos o campo ${entity == IMPORT_ENTITY.COMPANY ? 'Nome Fantasia' : 'Nome'}`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }

        if ((entity == IMPORT_ENTITY.CARD) && !filteredArray.find((e: { fieldName: string; }) => e.fieldName == 'title')) {
            return Swal.fire({
                title: 'Opss..',
                text: `Entidade ${entity == IMPORT_ENTITY.CARD && entityType == IMPORT_ENTITY_TYPE.OPPORTUNITY ? 'Oportunidade' : 'Tícket'} precisa ter pelo menos o campo Título`,
                icon: 'error',
                confirmButtonText: 'OK',
            });
        }

        for (let i = 0; i < filteredArray.length; i++) {
            const item = filteredArray[i];

            if (!item.fieldName || item.fieldName.trim() == '') {
                return Swal.fire({
                    title: 'Opss..',
                    text: 'Campo vazio detectado.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }


            if (filteredArray.find((element: any, index: any) => element.fieldName == item.fieldName && index != i)) {
                return Swal.fire({
                    title: 'Opss..',
                    text: 'Campo duplicado detectado.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            }
            delete item.check
            delete item.preview
            item.entityType = entityType
            item.funnel = funnel
            item.funnelStageId = funnelStage
        }



        try {
            setLoading(true)
            systemImportModalDispatch({
                type: 'loading'
            })
            const formData = new FormData()
            formData.append('filter', JSON.stringify(filteredArray));
            formData.append('entity', String(entity));
            formData.append('file', file)
            formData.append('forcing', String(false))
            const result = await createImport(formData)
            setSecondPart({
                show: true,
                data: result ? JSON.parse(result) : null,
                filter: filteredArray
            })
            setLoading(false)
            systemImportModalDispatch({
                type: 'not-loading'
            })
        } catch (error: any) {
            setLoading(false)
            systemImportModalDispatch({
                type: 'not-loading'
            })
            let errorDetected = ''
            if (error.response?.data?.key && error.response?.data?.message) {
                errorDetected = error.response?.data?.message
            }

            Swal.fire({
                title: 'Opss..',
                text: 'Houve um problema ao importar. ' + errorDetected,
                icon: 'error',
                confirmButtonText: 'OK',
            })
            console.log(error)
        }

    };

    const processFile = (file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const content = e.target.result;
            const workbook = XLSX.read(content, { type: 'binary', cellDates: true });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headers: any = data[0];
            const firstRow: any = data[1] || [];
            const formattedData = headers.map((header: any, index: number) => {
                let previewValue = firstRow[index] || '';

                if (previewValue instanceof Date) {
                    if (previewValue.toISOString().startsWith("1899-12-30")) {
                        previewValue = previewValue.toLocaleTimeString("pt-BR", {
                            hour: "2-digit",
                            hour12: false,
                            minute: "2-digit",
                            second: "2-digit",
                        });
                    } else if (previewValue.toISOString().endsWith('03:00:28.000Z')) {
                        previewValue = previewValue.toLocaleDateString("pt-BR");
                    } else {

                        previewValue = previewValue.toLocaleDateString("pt-BR", {
                            hour: "2-digit",
                            hour12: false,
                            minute: "2-digit",
                            second: "2-digit",
                        });
                    }
                }

                return {
                    header: header,
                    preview: previewValue,
                    fieldName: '',
                    check: true,
                    type: '',
                    field: null,
                    customFieldId: null,
                };
            });

            setTableData(formattedData);
        };
        reader.readAsBinaryString(file);
    };

    if (secondPart.show) {

        if (loading) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}>
                    <Spinner style={{ fontWeight: 'bold' }} />
                </div>
            );
        }
        return (
            <>
                {secondPart.data?.errors?.length > 0 ? (
                    <>
                        <h4 style={{ padding: '15px' }}>Foram encontrados os seguintes alertas ao importar o arquivo. Você tem a opção de voltar e corrigir esses alertas ou prosseguir e forçar a importação do arquivo conforme está.</h4>

                        <Table striped bordered responsive>

                            <tbody>
                                {secondPart.data.errors.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>{item.message}</td>


                                    </tr>

                                ))}
                            </tbody>
                        </Table>

                    </>
                ) : (
                    <h4 style={{ padding: '15px' }}>Nenhum erro foi encontrado durante a importação do arquivo. Tudo está pronto para prosseguir.</h4>
                )}


                <div style={{ marginTop: '15px', paddingBottom: '15px', textAlign: 'center' }}>
                    <Button disabled={loading} variant='primary' onClick={() => {


                        setSecondPart((prevState: any) => ({
                            ...prevState,
                            show: false
                        }));

                    }} size='sm'>
                        Voltar
                    </Button>
                    {' '}
                    <Button disabled={loading} variant='primary' onClick={async () => {
                        let result = null
                        setLoading(true)
                        systemImportModalDispatch({
                            type: 'loading'
                        })
                        const formData = new FormData()
                        formData.append('filter', JSON.stringify(secondPart.filter));
                        formData.append('entity', String(entity));
                        formData.append('file', file)
                        formData.append('forcing', String(true))
                        try {
                            result = await createImport(formData)
                            setLoading(false)
                            systemImportModalDispatch({
                                type: 'not-loading'
                            })
                            onClose()
                            Swal.fire('Importação feita com Sucesso!!', '', 'success')
                        } catch (error: any) {
                            if (result) {
                                await undoneImports(result.id)
                            }
                            setLoading(false)
                            systemImportModalDispatch({
                                type: 'not-loading'
                            })
                            let errorDetected = ''
                            if (error.response?.data?.key && error.response?.data?.message) {
                                errorDetected = error.response?.data?.message
                            }

                            Swal.fire({
                                title: 'Opss..',
                                text: 'Houve um problema ao importar. ' + errorDetected,
                                icon: 'error',
                                confirmButtonText: 'OK',
                            })
                        }
                    }} size='sm'>
                        Confirmar Importação
                    </Button>
                </div>

            </>
        )


    }

    if (file && tableData.length > 0) {
        if (loading) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh'
                }}>
                    <Spinner style={{ fontWeight: 'bold' }} />
                </div>
            );
        }

        return (
            <>

                <h4 style={{ padding: '15px' }}>Relacione os campos de acordo com o nome das colunas fornecidas em seu arquivo para completar a importação corretamente: </h4>
                <div style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // minHeight:'65%'
                }}>
                    <Table striped bordered responsive>
                        <thead>
                            <tr>
                                <th>Nome da Coluna</th>
                                <th>Valor da 1º Linha</th>
                                <th>Campo</th>
                                <th>Tipo</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item: any, index: any) => (
                                <tr key={index}>
                                    <td>{item.header}</td>
                                    <td>{item.preview}</td>
                                    <td>
                                        <InputFieldSet label={'Tipo de Campo'}>
                                            <select
                                                name='entity'
                                                value={item.field}
                                                className='form-control form-select form-select-lg fieldset-input'
                                                onChange={(e) => {
                                                    const updatedTableData = tableData.map((item: any, i: any) =>
                                                        i === index ? { ...item, fieldName: e.target.value } : item
                                                    );


                                                    const selectedField = [...defaultSystemFields, ...customFields].find(field => field.name === e.target.value);

                                                    if (selectedField) {
                                                        updatedTableData[index].type = selectedField.type.key;
                                                        updatedTableData[index].field = e.target.value;
                                                        setSelectedFieldType((prevState: any) => ({
                                                            ...prevState,
                                                            [index]: selectedField.type.key
                                                        }));

                                                        if (customFields.some((field: { name: string; }) => field.name === e.target.value)) {
                                                            updatedTableData[index].customFieldId = selectedField.id;
                                                        }
                                                    }

                                                    setTableData(updatedTableData);
                                                }}
                                            >
                                                <option value={""}>Selecione</option>
                                                {defaultSystemFields && defaultSystemFields.map((field: any) => (
                                                    <option key={field.id} value={field.name}>{field.label}</option>
                                                ))}
                                                {customFields && customFields.map((field: any) => (
                                                    <option key={field.id} value={field.name}>{`${field.label} - Campo Customizado`}</option>
                                                ))}

                                            </select>
                                        </InputFieldSet>
                                    </td>
                                    <td align="center">
                                        <div style={{ width: "100px" }}>
                                            {getExampleByFieldType(selectedFieldType[index], tableData[index].fieldName)}
                                        </div>
                                    </td>
                                    <td align="center">
                                        <button
                                            onClick={() => {
                                                const updatedTableData = tableData.map((item: any, i: any) =>
                                                    i === index ? { ...item, check: !item.check } : item
                                                );
                                                setTableData(updatedTableData);
                                            }}
                                            style={{
                                                backgroundColor: item.check ? "#0000c8" : "transparent",
                                                color: item.check ? "white" : "#0000c8",
                                                border: "1px solid #9d9dea",
                                                borderRadius: "4px",
                                                padding: "5px",
                                                cursor: "pointer",
                                                width: "100px"
                                            }}
                                        >
                                            {item.check ? 'Desabilitar' : 'Habilitar'}
                                        </button>

                                    </td>

                                </tr>

                            ))}
                        </tbody>
                    </Table>
                </div>
                <div style={{ marginTop: '15px', paddingBottom: '15px', textAlign: 'center' }}>
                    <Button variant='primary' onClick={() => {
                        setFile(null)
                        setSelectedFieldType({})
                        setTableData([])
                    }} size='sm'>
                        Voltar
                    </Button>
                    {' '}
                    <Button variant='primary' onClick={handleUpload} size='sm'>
                        Salvar Importação
                    </Button>
                </div>

            </>

        )
    }



    return (
        <>
            <h4 style={{ padding: '15px' }}>Faça a importação dos dados de CRM antigos: </h4>
            <div
                className="dropzone"
                style={{ margin: '45px' }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => inputRef.current.click()}
            >
                <i className="fas fa-file-arrow-up fs-2 text-primary p-5" style={{ display: 'block', margin: '0 auto' }}></i>
                <h1 className="pt-4"><Link className="text-decoration-underline" to='#' >Clique para carregar</Link> ou arraste aqui</h1>
                <h5 className="mt-4 pb-4">Excel (.xls e .xlsx) ou .CSV (máx. 3MB)</h5>
                <input
                    type="file"
                    onChange={(event: any) => handleFileSelection(event)}
                    hidden
                    accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ref={inputRef}
                />
            </div>
        </>
    );
};

export default DragDropFiles;