/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {QueryRequestProvider} from '../../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../../modules/view/QueryResponseProvider'
import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider} from '../../../modules/view/ListViewProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {UserFormModal} from './edit-modal/UserFormModal'
import {getUsers} from './core/_requests'
import {UserFormModalProvider, useUserFormModalDispatch} from './core/UserFormModalContext'
import {useLocation} from 'react-router-dom'

const UserPage = () => {
  const userFormModalDispatch = useUserFormModalDispatch()
  const location = useLocation()

  //Analisar URL
  useEffect(() => {
    const regex = /^\/users\/(details|edit)\/([^/]+)$/
    const match = location?.pathname?.match(regex)

    if (match) {
      const action =
        match[1] === 'details' ? 'open-detail-modal' : match[1] === 'edit' ? 'open-edit-modal' : ''
      const parameter = match[2]
      if (parameter && parameter !== '') {
        userFormModalDispatch({
          type: action,
          data: parameter,
        })
      }
    }
    if (location?.pathname == '/users/add') {
      userFormModalDispatch({
        type: 'open-add-modal',
      })
    }
  }, [location?.pathname])

  return (
    <>
      <UsersListHeader />
      <KTCard>
        <UsersTable />
      </KTCard>
      <UserFormModal />
    </>
  )
}

const Users: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getUsers}>
          <ListViewProvider>
            <UserFormModalProvider>
              <UserPage />
            </UserFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {Users}
