import { createContext, useContext, useReducer } from 'react';

export interface CustomFormFormModalContextModel {
  open: boolean
  formId: number | null
}

const initialData = {
  open: false,
  formId: null,
};

const CustomFormFormModalContext = createContext<CustomFormFormModalContextModel>(initialData);
const CustomFormFormModalDispatchContext = createContext<any>(null);

export function CustomFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    customFormFormModalReducer,
    initialData
  );

  return (
    <CustomFormFormModalContext.Provider value={data}>
      <CustomFormFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </CustomFormFormModalDispatchContext.Provider>
  </CustomFormFormModalContext.Provider>
  );
}

export function useCustomFormFormModal() {
  return useContext(CustomFormFormModalContext);
}

export function useCustomFormFormModalDispatch() {
  return useContext(CustomFormFormModalDispatchContext);
}

function customFormFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true}
    }
    case 'open-edit-modal': {
      return { ...data, open: true, formId: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, formId: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
