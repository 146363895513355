import { FC, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'
import { getFunnels } from '../core/_requests'
import debounce from 'debounce-promise'
import { InputFieldSet } from '../../../../_metronic/partials/inputs/InputFieldSet'
import SelectAsync from 'react-select/async'
import { getFunnelStages, getContactById } from '../core/_requests'
import { CARD_TYPES } from '../core/_models'
import Select from 'react-select'
import { Button } from 'react-bootstrap'

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  //   dropdownIndicator: (base:any) => ({
  //     ...base,
  //     padding: '3px',
  //     fontSize: '1rem'
  //   })
}

type Props = {
  isEmbed?: boolean
  onSave: any
  data: any
}

const CardFormAddPrimaryData: FC<Props> = ({
  isEmbed,
  onSave,
  data
}) => {

  const [controlDataModal, setControlDataModal] = useState<any>({
    funnel_id: null,
    funnel_stage_id: null,
  })

  const [stages, setStages] = useState<any>()

  const funnelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve, reject) => {
      try {
        let list: Array<any> = [];
        let funnels = await getFunnels(`page=1&perPage=20&type=${data.cardType}&search=` + encodeURIComponent(inputValue));

        if (funnels && funnels !== undefined) {
          for (let i = 0; i < funnels?.length; i++) {
            list.push({ label: funnels[i].name, value: funnels[i].id });
          }
        }

        resolve(list);
      } catch (error) {
        console.log(error)
      }
    });
  const debounceFunnelsOptions = debounce(funnelsOptions, 500)

  useEffect(() => {
    setControlDataModal({
      funnel_id: null,
      funnel_stage_id: null,
    })
    setStages([])
  }, [data])

  useEffect(() => {
    loadStages(controlDataModal?.funnel_id?.value)
  }, [controlDataModal?.funnel_id?.value])

  let loadStages = async (funnel_id: number) => {
    try {
      let list: Array<any> = []
      if (funnel_id) {
        let funnelStages = await getFunnelStages(funnel_id, 'page=1&perPage=100')
        if (funnelStages && funnelStages != undefined) {
          for (let i = 0; i < funnelStages?.length; i++) {
            list.push({
              label: funnelStages[i].title,
              value: funnelStages[i].id,
              funnel: funnel_id,
            })
          }
        }
      }

      setStages(list)
    } catch (e) {
      console.log(e)
      setStages([]);
    }
  }

  const handleApply = async () => {
    try {
      let customAddData: any = {};

      if (data?.customData?.contactId) {
        customAddData.contactId = data?.customData.contactId;
        let contact = await getContactById(data?.customData.contactId);
        if (contact) {
          customAddData.contactId = {
            label: contact.name,
            value: contact.id
          }
        }
      }

      onSave({
        type: data.cardType,
        addData: {
          ...customAddData,
          funnelId: { ...controlDataModal.funnel_id },
          funnelStepId: { ...controlDataModal.funnel_stage_id },
        },
        funnelId: controlDataModal.funnel_id?.value,
      })

    }
    catch (e) {
      console.log(e);
    }

  }

  return (
    <>
      <Row>
        <Col>
          <InputFieldSet label={'Funil'}>
            <SelectAsync
              menuPortalTarget={document.body}
              placeholder={'Selecionar funil'}
              name={'funnel_id'}
              loadOptions={debounceFunnelsOptions}
              cacheOptions
              isClearable
              defaultOptions
              onChange={(e: any) => {
                let data = { ...controlDataModal }
                data.funnel_id = e
                data.funnel_stage_id = null
                setControlDataModal(data)
              }}
              className='fieldset-input'
              styles={customStyles}
              noOptionsMessage={() => 'Sem Funil'}
            />
          </InputFieldSet>
        </Col>
      </Row>
      {controlDataModal?.funnel_id ? (
        <Row className='mt-5'>
          <Col>
            <InputFieldSet label={'Etapa do Funil'}>
              <Select
                menuPortalTarget={document.body}
                placeholder={'Selecionar etapa do funil'}
                name={'funnel_stage_id'}
                options={stages}
                value={controlDataModal.funnel_stage_id}
                className='fieldset-input'
                onChange={(e: any) => {
                  let data = { ...controlDataModal }
                  data.funnel_stage_id = e
                  setControlDataModal(data)
                }}
                styles={customStyles}
                noOptionsMessage={() => 'Sem Etapa Funil'}
              />
            </InputFieldSet>
          </Col>
        </Row>
      ) : null}

      {controlDataModal?.funnel_id && controlDataModal?.funnel_stage_id ? (
        <Row className='mt-5'>
          <Col style={{ textAlign: "right" }}> <Button
            type='button'
            variant='wl-custom-primary-collor'
            onClick={() => {
              handleApply()
            }}
            size='sm'
          >
            Prosseguir{' '}
            <i className='fas fa-arrow-right' style={{ position: 'relative', left: '8px' }}></i>
          </Button></Col>
        </Row>
      ) : null}


    </>
  )
}

export { CardFormAddPrimaryData }
