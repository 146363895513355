import {FC} from 'react'

type Props = {
  status?: number
}

const StatusCell: FC<Props> = ({status}) => {
  // let dataReturn : string = "";
  /*switch(status){
    case 1:
      dataReturn = "Aberta"
      break;
    case 2:
      dataReturn = "Perda"
      break;
    case 3:
      dataReturn = "Descarte"
      break;
  }*/
  return <>{status}</>
}

export {StatusCell}
