import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CustomField, CustomFieldsQueryResponse} from './_models'
import {request} from "../../../../services/crm-api/request"

const getCustomFields = async (query: string):  Promise<CustomFieldsQueryResponse> => {

  return request({
    method: "GET",
    url: `/forms/custom-fields?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<CustomFieldsQueryResponse>) => d.data);
 /* .then((d: any) => {
    return {
      data: d.data,
      "pagination": {
          "page": 1,
          "pagesCount": 1,
          "perPage": 10
      }
    }
  });*/
}

const getCustomFieldById = async (id: ID): Promise<any | undefined> => {

  return request({
    method: "GET",
    url: `/forms/custom-fields/${id}`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const createCustomField = (dataCreate: CustomField[]): Promise<CustomField | undefined> => {

  return request({
    method: "post",
    url: `/forms/custom-fields`,
    data: dataCreate
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<CustomField>>) => response.data)
    .then((response: Response<CustomField>) => response.data)
}

const updateCustomField = (dataUpdate: CustomField): Promise<CustomField | undefined> => {

  let data = {...dataUpdate};

  return request({
    method: "patch",
    url: `/forms/custom-fields/${dataUpdate.id}`,
    data: data
  },{
    setAuth: true
  })
    .then((response: AxiosResponse<Response<CustomField>>) => response.data)
    .then((response: Response<CustomField>) => response.data)
}

const deleteCustomField = (id: ID): Promise<CustomField | undefined> => {

  return request({
    method: "DELETE",
    url: `/forms/custom-fields/${id}`
  },{
    setAuth: true
  })
  .then((response: any) => response.data)
}

const getFieldtypes = async ():  Promise<any> => {

  return request({
    method: "GET",
    url: `/forms/field-types`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

export {deleteCustomField, updateCustomField, createCustomField, getCustomFieldById, getCustomFields, getFieldtypes}
