import {ID, Response} from '../../../../../_metronic/helpers'
export type Label = {
  id?: ID
  name?: string
  status?: number
  description?: string
  color?: string
}

export type LabelsQueryResponse = Response<Array<Label>>

export const initialLabel: Label = {
  name: '',
  status: 1,
  color: "#FF0000"
}
