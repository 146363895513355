import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ListLoading } from '../../../../modules/view/ListLoading'
import Swal from 'sweetalert2'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import { transferCardResponsible } from '../../core/_requests'
import SelectAsync from 'react-select/async'
import { useFunnelDispatch } from '../../../funnel/core/FunnelContext'
import { useCardMarkModalDispatch } from '../../core/CardMarkModalContext'
import {
  markLostCard,
  markWinCard,
  markSolveCard,
  markUnsolveCard,
} from '../../core/_requests'
import {
  getTerminationReasons
} from '../../../settings/termination-reasons/core/_requests'
import debounce from 'debounce-promise'
import { TERMINATION_REASON_TYPES } from '../../../settings/termination-reasons/core/_models'

type Props = {
  opportunity: any
  status: any
}

const editDataSchema = Yup.object().shape({})

const CardMarkModalForm: FC<Props> = ({ opportunity, status }) => {
  const funnelDispatch = useFunnelDispatch()
  const cardMarkModalDispatch = useCardMarkModalDispatch()
  const [terminationSubReasons, setTerminationSubReasons] = useState<any>([])

  const [dataForEdit] = useState<any>({
    terminationReason: null,
    terminationSubReason: null,
  })
  const getLabel = (status: number) => {
    switch (status) {
      case TERMINATION_REASON_TYPES.WIN:
        return 'Ganha'
      case TERMINATION_REASON_TYPES.LOST:
        return 'Perdida'
      case TERMINATION_REASON_TYPES.SOLVED:
        return 'Resolvido'
      case TERMINATION_REASON_TYPES.UNSOLVED:
        return 'Não Resolvido'
      default:
        return ''
    }
  }
  const terminationReasonsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let terminationReasons = await getTerminationReasons(
        `page=1&perPage=20&include_funnels=true&include_subreasons=true&type=${status}&search=${encodeURIComponent(inputValue)}&funnelIds=${opportunity.funnelId}&status=1`
      )
      if (terminationReasons && terminationReasons?.data != undefined) {
        for (let i = 0; i < terminationReasons.data.length; i++) {
          let reason = terminationReasons.data[i]
          let subReasons = []
          if (reason?.subreasons?.length) {
            for (let j = 0; j < reason.subreasons.length; j++) {
              let subReason = reason.subreasons[j]
              subReasons.push({ label: subReason.name, value: subReason.id })
            }
          }

          list.push({ label: reason.name, value: reason.id, subReasons: subReasons })
        }
      }
      resolve(list)
    })
  const debounceTerminationReasonsOptions = debounce(terminationReasonsOptions, 500)

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      const stringMsg = getLabel(status)

      /*if (!values.terminationReason || !values.terminationReason.value) return Swal.fire({
        title: 'Opss..',
        text: 'Preencha os Campos corretamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      })*/

      try {
        let opUpdated = null
        if (status == TERMINATION_REASON_TYPES.WIN) {
          opUpdated = await markWinCard(opportunity.id, {
            terminationReasonId: values.terminationReason?.value
              ? values.terminationReason?.value
              : null,
            terminationSubreasonId:
              values.terminationSubReason?.value && values.terminationReason?.value
                ? values.terminationSubReason.value
                : null,
          })
        } else if (status == TERMINATION_REASON_TYPES.LOST) {
          opUpdated = await markLostCard(opportunity.id, {
            terminationReasonId: values.terminationReason?.value
              ? values.terminationReason?.value
              : null,
            terminationSubreasonId:
              values.terminationSubReason?.value && values.terminationReason?.value
                ? values.terminationSubReason.value
                : null,
          })
        } else if (status == TERMINATION_REASON_TYPES.SOLVED) {
          opUpdated = await markSolveCard(opportunity.id, {
            terminationReasonId: values.terminationReason?.value
              ? values.terminationReason?.value
              : null,
            terminationSubreasonId:
              values.terminationSubReason?.value && values.terminationReason?.value
                ? values.terminationSubReason.value
                : null,
          })
        } else if (status == TERMINATION_REASON_TYPES.UNSOLVED) {
          opUpdated = await markUnsolveCard(opportunity.id, {
            terminationReasonId: values.terminationReason?.value
              ? values.terminationReason?.value
              : null,
            terminationSubreasonId:
              values.terminationSubReason?.value && values.terminationReason?.value
                ? values.terminationSubReason.value
                : null,
          })
        } else opUpdated = null


        if (funnelDispatch) {
          if (opUpdated) {
            opportunity.status = opUpdated.status
          }

          funnelDispatch({
            type: 'update-step-card',
            data: opportunity,
          })
        }

        cardMarkModalDispatch({
          type: 'close-modal',
        })

        Swal.fire(`Oportunidade marcada como ${stringMsg}!`, '', 'success')
      } catch (ex: any) {
        console.error(ex)

        let errorDetected = ''
        if (ex.response?.data?.key && ex.response?.data?.message) {
          errorDetected = ex.response?.data?.message
        }

        Swal.fire({
          title: 'Opss..',
          text: errorDetected
            ? errorDetected
            : `Houve um problema ao marcar oportunidade como ${stringMsg}`,
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_mark_opportunity'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_mark_opportunity_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_mark_opportunity_header'
          data-kt-scroll-wrappers='#kt_modal_mark_opportunity_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            <InputFieldSet label={'Motivo de Finalização'}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name='terminationReason'
                loadOptions={debounceTerminationReasonsOptions}
                value={formik.values.terminationReason}
                isClearable
                //cacheOptions
                defaultOptions
                onChange={(selectedOption) => {
                  if (selectedOption?.value != formik.values.terminationReason?.value)
                    formik.setFieldValue('terminationSubReason', null)
                  formik.setFieldValue('terminationReason', selectedOption ? selectedOption : null)
                  if (selectedOption?.subReasons)
                    setTerminationSubReasons(selectedOption.subReasons)
                }}
                className='fieldset-input'
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderStyle: 'none',
                    backgroundColor: undefined,
                    boxShadow: 'none',
                  }),
                }}
              />
            </InputFieldSet>
          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet label={'Sub Motivo de Finalização'}>
              <SelectAsync
                menuPortalTarget={document.body}
                placeholder={''}
                name='terminationSubReason'
                value={formik.values.terminationSubReason}
                isClearable
                cacheOptions
                defaultOptions={terminationSubReasons}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    'terminationSubReason',
                    selectedOption ? selectedOption : null
                  )
                }}
                className='fieldset-input'
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderStyle: 'none',
                    backgroundColor: undefined,
                    boxShadow: 'none',
                  }),
                }}
              />
            </InputFieldSet>
          </div>
        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-10'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}

export { CardMarkModalForm }
