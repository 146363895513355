/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { ImportHeader } from './components/header/ImportHeader'
import { ImportTable } from './table/ImportTable'
import { getImports } from './core/_requests'
import { ImportModalProvider } from './core/ImportModalContext'
import { ImportModal } from './manage-modal.tsx/ImportModal'

const ImportPage = () => {
  return (
    <>
      <ImportHeader />
      <KTCard>
        <ImportTable />
      </KTCard>
      <ImportModal/>
    </>
  )
}


const Import: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getImports}>
          <ListViewProvider>
            <ImportModalProvider>
              <ImportPage />
            </ImportModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { Import }
