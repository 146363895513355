import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl, KTIcon} from '../../../../_metronic/helpers'
import {initialTask, Task, TASK_TYPES} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../../../modules/view/ListViewProvider'
import {ListLoading} from '../../../modules/view/ListLoading'
import {
  createTask,
  updateTask,
  getUsers,
  getCompanies,
  getContacts,
  getOpportunities,
  getTickets,
} from '../core/_requests'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../_metronic/partials/inputs/InputFieldSet'
import Select from 'react-select'
import Swal from 'sweetalert2'
import SelectAsync from 'react-select/async'
import debounce from 'debounce-promise'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import format from 'date-fns/format'
import {CARD_TYPES} from '../../cards/core/_models'
import {useTaskFormModalDispatch} from '../core/TaskFormModalContext'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import FieldTypeInput from '../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'
import {getTasksTypes} from '../core/_requests'
import {useLocation} from 'react-router-dom'
import {add} from 'date-fns'
const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    height: 18px;
    padding: 6px 0;
    padding-left: 14px;
    font-size: 14px;
  }

  & .MuiInputBase-root {
    height: auto;
    min-height: 32px;
  }
`

const StyledMobileTimePicker = styled(MobileTimePicker)`
  & .MuiInputBase-input {
    height: 18px;
    padding: 6px 0;
    padding-left: 14px;
    font-size: 14px;
  }

  & .MuiInputBase-root {
    height: auto;
    min-height: 32px;
  }
`
type Props = {
  isLoading: boolean
  add_data?: any
  task?: Task
  needRefetch?: any
}

type FormTask = {
  dateField?: Date | null
  timeField?: Date | null
  entity_type?: any | null
}
const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  //   dropdownIndicator: (base:any) => ({
  //     ...base,
  //     padding: '3px',
  //     fontSize: '1rem'
  //   })
}
const ENTITY_TYPES = [
  {
    id: 'TICK',
    name: 'Tícket',
  },
  {
    id: 'OPOR',
    name: 'Oportunidade',
  },
  {
    id: 'CONT',
    name: 'Contato',
  },
  {
    id: 'EMPR',
    name: 'Empresa',
  },
]

const editDataSchema = Yup.object().shape({})

const TasksForm: FC<Props> = ({task, isLoading, add_data, needRefetch}) => {
  const location = useLocation()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const taskFormModalDispatch = useTaskFormModalDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    taskFormModalDispatch({
      type: 'close-modal',
    })
    if (location?.pathname?.includes('tasks')) {
      navigate('/tasks')
      refetch()
    } else {
      needRefetch()
    }
  }

  const loadEntityType = (task: any) => {
    if (!task) return

    if (task.card) {
      if (task.card.type == CARD_TYPES.OPPORTUNITY.id) {
        return 'OPOR'
      } else if (task.card.type == CARD_TYPES.TICKET.id) {
        return 'TICK'
      }
    } else if (task.contact) {
      return 'CONT'
    } else if (task.company) {
      return 'EMPR'
    }
  }

  const initialEntityType = loadEntityType(task)
  const optionInitialEntityType = initialEntityType
    ? ENTITY_TYPES.find((e) => e.id == initialEntityType)
    : null

  const [dataForEdit, setDataForEdit] = useState<Task & FormTask>({
    ...task,
    dateField: task?.date ? new Date(task.date) : new Date(),
    timeField: task?.date ? new Date(task.date) : new Date(),
    entity_type: optionInitialEntityType ? optionInitialEntityType.id : '',
    responsibleId: task?.responsible
      ? {label: task.responsible.name, value: task.responsible.id}
      : '',
    companyId: task?.company ? {label: task.company.name, value: task.company.id} : '',
    contactId: task?.contact ? {label: task.contact.name, value: task.contact.id} : '',
    cardId: task?.card ? {label: task.card.title, value: task.card.id} : '',
    type: task?.type?.id ? task.type?.id : null,
    customFields: task?.customFields ? task?.customFields : [],
    subType: task?.subType ? task?.subType : null,
    showSubType: true,
  })

  const [taskOptions, setTaskOptions] = useState<any>()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const ticketsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let opps = await getTickets('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (opps && opps.data != undefined) {
        for (let i = 0; i < opps?.data?.length; i++) {
          list.push({label: opps.data[i].title, value: opps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceTicketsOptions = debounce(ticketsOptions, 500)

  const opportunitiesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let opps = await getOpportunities(
        'page=1&perPage=20&search=' + encodeURIComponent(inputValue)
      )
      if (opps && opps.data != undefined) {
        for (let i = 0; i < opps?.data?.length; i++) {
          list.push({label: opps.data[i].title, value: opps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceOpportunitiesOptions = debounce(opportunitiesOptions, 500)

  const companiesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let companies = await getCompanies(
        'page=1&perPage=20&search=' + encodeURIComponent(inputValue)
      )
      if (companies && companies.data != undefined) {
        for (let i = 0; i < companies?.data?.length; i++) {
          list.push({label: companies.data[i].name, value: companies.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  const contactsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let contacts = await getContacts('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (contacts && contacts.data != undefined) {
        for (let i = 0; i < contacts?.data?.length; i++) {
          list.push({label: contacts.data[i].name, value: contacts.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceContactsOptions = debounce(contactsOptions, 500)

  const formik: any = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values: any, {setSubmitting, resetForm}) => {
      setSubmitting(true)

      let auxValues: any = {
        id: values.id,
        title: values.title,
        observation: values.observation,
        responsibleId: values.responsibleId,
      }

      if (!auxValues.title)
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar o assunto da Tarefa.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!values.type)
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar o Tipo da Tarefa.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (values?.type == 1 && !values?.subType) {
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar a Categoria da Tarefa.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
      }

      if (!values.dateField || !values.timeField)
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar a Data e Hora.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!auxValues.responsibleId || !auxValues.responsibleId.value)
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar o Responsável.',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!values.cardId?.value && !values.contactId?.value && !values.companyId?.value) {
        return Swal.fire({
          title: 'Atenção',
          text: 'Necessário Informar uma entidade para a Tarefa. Oportunidade, Contato ou Empresa',
          icon: 'warning',
          confirmButtonText: 'OK',
        })
      }

      auxValues.date = new Date(
        format(values.dateField, 'yyyy-MM-dd') + ' ' + format(values.timeField, 'HH:mm') + ':00'
      ).toISOString()
  
      auxValues.responsibleId = values.responsibleId.value
      auxValues.type = parseInt(values.type)

      switch (values.entity_type) {
        case 'OPOR':
          auxValues.cardId = values.cardId.value
          break
        case 'TICK':
          auxValues.cardId = values.cardId.value
          break
        case 'CONT':
          auxValues.contactId = values.contactId.value
          break
        case 'EMPR':
          auxValues.companyId = values.companyId.value
          break
      }
      let customFields = []
      let objKeys = Object.keys(values)
      for (let i = 0; i < objKeys.length; i++) {
        if (objKeys[i].includes('custom_')) {
          let useField = values[objKeys[i]]
          let idCustom = objKeys[i].replace('custom_', '')

          if (
            values?.customFields?.find((e: any) => e?.integrationId == idCustom)?.required &&
            !useField
          ) {
            Swal.fire({
              title: 'Atenção!',
              text: `Preencha o campo "${
                values?.customFields?.find((e: any) => e?.integrationId == idCustom)?.label
              }".`,
              icon: 'error',
              confirmButtonText: 'OK',
            })
            return
          }
          customFields.push({
            integrationId: idCustom,
            value: {
              label: values?.customFields?.find((e: any) => e?.integrationId == idCustom)?.label,
              value: useField,
            },
          })
        }
      }

      auxValues.subType = values?.subType ? parseFloat(values?.subType) : null
      auxValues.customFields = customFields

      try {
        if (isNotEmpty(auxValues.id)) {
          await updateTask(auxValues)
        } else {
          await createTask(auxValues)
        }

        Swal.fire('Registro salvo com Sucesso!!', '', 'success')

        resetForm()
        handleClose()
      } catch (ex: any) {
        if(ex?.response?.status == 403)
        {
          Swal.fire({
            title: 'Opss..',
            text: 'Acesso não autorizado para realizar esta operação com os dados informados.',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
        else
        {
          console.error(ex)
          Swal.fire({
            title: 'Opss..',
            text: 'Houve um problema ao salvar a tarefa.',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
        
      } finally {
        setSubmitting(false)
      }
    },
  })

  const loadInitData = async () => {
    let options = []
    try {
      let query = `status=1&page=1&perPage=500&include_default_types=true`
      let data = await getTasksTypes(query)
      for (let i = 0; i < data?.data?.length; i++) {
        let custom_fields: any = []
        let option = data?.data[i]
        let dataToPush = {
          id: option?.id,
          name: option?.name,
          custom_fields: custom_fields,
        }
        if (option?.customFields?.length > 0) {
          for (let j = 0; j < option?.customFields?.length; j++) {
            let customField = option?.customFields[j]
            custom_fields.push({
              ...customField,
              label: customField?.name,
              name: 'custom_' + customField?.integrationId,
              type: {key: customField?.type},
            })
          }
        }

        options.push(dataToPush)
      }
    } catch (e) {
      console.log(e)
    }

    setTaskOptions(options)
    loadDataEdit(options)
  }
  const loadDataEdit = (taskOptions: any) => {
    if (!task?.id) return

    let findOption = taskOptions.find((e: any) => e?.id == task?.type?.id)
    let customFields: any = []
    for (let i = 0; i < findOption?.custom_fields?.length; i++) {
      let use = findOption?.custom_fields[i]
      let find = task?.customFields?.find((e: any) => e?.integrationId == use?.integrationId)
      customFields.push(use)
      formik.setFieldValue(
        `custom_${use?.integrationId}`,
        find?.value?.value ? find?.value?.value : null
      )
    }
    if (task?.type?.id != 1) {
      formik.setFieldValue(`showSubType`, false)
    }

    formik.setFieldValue(`customFields`, customFields)
  }

  useEffect(() => {
    loadInitData()
  }, [])

  const loadDataAdd = () => {
    let data = {...dataForEdit}
    if (add_data.entity_type == 'contact') {
      data.contactId = {label: add_data?.entityData?.name, value: add_data?.entityData?.id}
      data.entity_type = 'CONT'
    } else if (add_data.entity_type == 'company') {
      data.companyId = {label: add_data?.entityData?.name, value: add_data?.entityData?.id}
      data.entity_type = 'EMPR'
    } else {
      data.cardId = {label: add_data?.entityData?.title, value: add_data?.entityData?.id}
      if (add_data?.entityData?.type == CARD_TYPES.OPPORTUNITY.id) {
        data.entity_type = 'OPOR'
      } else if (add_data?.entityData?.type == CARD_TYPES.TICKET.id) {
        data.entity_type = 'TICK'
      }
    }
    formik.setValues(data)
  }

  useEffect(() => {
    if (add_data?.entity_type) loadDataAdd()
  }, [add_data])
  return (
    <>
      <form id='kt_modal_add_task_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_task_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_task_header'
          data-kt-scroll-wrappers='#kt_modal_add_task_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Assunto '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <input
                placeholder=''
                {...formik.getFieldProps('title')}
                type='text'
                name='title'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
          </div>
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Responsável '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <SelectAsync
                placeholder={''}
                name='responsibleId'
                loadOptions={debounceResponsiblesOptions}
                value={formik.values.responsibleId}
                cacheOptions
                defaultOptions
                onChange={(selectedOption) =>
                  formik.setFieldValue('responsibleId', selectedOption ? selectedOption : null)
                }
                className='fieldset-input'
                styles={customStyles}
              />
            </InputFieldSet>
          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Tipo da Tarefa '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <select
                name='type'
                onChange={(e: any) => {
                  formik.setFieldValue('type', e?.target?.value)
                  let option = taskOptions.find((op: any) => op?.id == e?.target?.value)
                  if (e?.target?.value == 1) {
                    formik.setFieldValue('showSubType', true)
                    formik.setFieldValue('customFields', [])
                  } else if (option && option.custom_fields) {
                    formik.setFieldValue('customFields', option?.custom_fields)
                    formik.setFieldValue('showSubType', false)
                  } else {
                    formik.setFieldValue('customFields', [])
                    formik.setFieldValue('showSubType', false)
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                data-control='select2'
                data-hide-search='true'
                className={
                  'form-control form-select form-select-sm form-select-white fieldset-input'
                }
                // disabled={formik.isSubmitting || isLoading || task?.id != undefined}
              >
                <option value='' selected>
                  Selecione
                </option>
                {taskOptions?.map((tp: any) => (
                  <option value={tp.id} key={tp.id}>
                    {tp.name}
                  </option>
                ))}
              </select>
            </InputFieldSet>
          </div>
          {formik?.values?.showSubType ? (
            <div className='fv-row mb-7'>
              <InputFieldSet
                label={
                  <>
                    {'Categoria'}
                    <i
                      className='fa-solid fa-asterisk'
                      style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                    ></i>
                  </>
                }
              >
                <select
                  name='subType'
                  onChange={(e: any) => {
                    formik.setFieldValue('subType', e?.target?.value)
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.subType}
                  data-control='select2'
                  data-hide-search='true'
                  className={
                    'form-control form-select form-select-sm form-select-white fieldset-input'
                  }
                >
                  <option value=''>Selecionar</option>
                  {TASK_TYPES?.map((tp: any) => (
                    <option value={tp.id} key={tp.id}>
                      {tp.value}
                    </option>
                  ))}
                </select>
              </InputFieldSet>
            </div>
          ) : null}
          {formik?.values?.customFields?.map((field: any, index: number) => (
            <div className='fv-row mb-7' key={'fieldtypeinput' + index}>
              <FieldTypeInput
                key={index}
                field={{...field}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[field.name]}
                setFieldValue={formik.setFieldValue}
                useSelectOptionLabel={true}
              />
            </div>
          ))}
          <StyledDatePicker
            {...formik.getFieldProps('dateField')}
            label={
              <>
                {'Data '}
                <i
                  className='fa-solid fa-asterisk'
                  style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                ></i>
              </>
            }
            className='mb-7'
            value={formik.values.dateField}
            onChange={(value: any) => formik.setFieldValue('dateField', value)}
          />
          {/*           
          <DatePicker
            {...formik.getFieldProps('dateField')}
            label="Data *"
            className='mb-7'
            value={formik.values.dateField}
            onChange={value =>
              formik.setFieldValue("dateField", value)
            }
          /> */}

          <StyledMobileTimePicker
            value={formik.values.timeField}
            onChange={(value: any) => formik.setFieldValue('timeField', value)}
            label={
              <>
                {'Hora '}
                <i
                  className='fa-solid fa-asterisk'
                  style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                ></i>
              </>
            }
            //disablePast={true}
            localeText={{
              cancelButtonLabel: 'Cancelar',
              okButtonLabel: 'OK',
              toolbarTitle: 'Selecionar Hora',
            }}
          />
          {/* <MobileTimePicker
            value={formik.values.timeField}
            onChange={value =>
              formik.setFieldValue("timeField", value)
            }
            label="Hora *"
            //disablePast={true}
            localeText={{ cancelButtonLabel: "Cancelar", okButtonLabel: "OK", toolbarTitle: "Selecionar Hora" }}
          /> */}

          <div className='fv-row mb-7 mt-7'>
            <InputFieldSet
              label={
                <>
                  {'Entidade da Tarefa '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <select
                name='entity_type'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.entity_type}
                data-control='select2'
                data-hide-search='true'
                className={
                  'form-control form-select form-select-sm form-select-white fieldset-input'
                }
                disabled={
                  formik.isSubmitting || isLoading || task?.id != undefined || add_data?.entity_type
                    ? true
                    : false
                }
              >
                <option value=''></option>
                {ENTITY_TYPES.map((tp) => (
                  <option value={tp.id} key={tp.id}>
                    {tp.name}
                  </option>
                ))}
              </select>
            </InputFieldSet>
          </div>

          {formik.values.entity_type == 'OPOR' && (
            <div className='fv-row mb-7'>
              <InputFieldSet label={'Oportunidade'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name='cardId'
                  loadOptions={debounceOpportunitiesOptions}
                  value={formik.values.cardId}
                  cacheOptions
                  defaultOptions
                  onChange={(selectedOption) =>
                    formik.setFieldValue('cardId', selectedOption ? selectedOption : null)
                  }
                  isDisabled={task?.id != undefined || add_data?.entity_type ? true : false}
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
          )}

          {formik.values.entity_type == 'TICK' && (
            <div className='fv-row mb-7'>
              <InputFieldSet label={'Tícket'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name='cardId'
                  loadOptions={debounceTicketsOptions}
                  value={formik.values.cardId}
                  cacheOptions
                  defaultOptions
                  isDisabled={task?.id != undefined || add_data?.entity_type ? true : false}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('cardId', selectedOption ? selectedOption : null)
                  }
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
          )}

          {formik.values.entity_type == 'CONT' && (
            <div className='fv-row mb-7'>
              <InputFieldSet label={'Contato'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name='contactId'
                  loadOptions={debounceContactsOptions}
                  value={formik.values.contactId}
                  cacheOptions
                  defaultOptions
                  isDisabled={add_data?.entity_type ? true : false}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('contactId', selectedOption ? selectedOption : null)
                  }
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
          )}

          {formik.values.entity_type == 'EMPR' && (
            <div className='fv-row mb-7'>
              <InputFieldSet label={'Empresa'}>
                <SelectAsync
                  menuPortalTarget={document.body}
                  placeholder={''}
                  name='companyId'
                  loadOptions={debounceCompaniesOptions}
                  value={formik.values.companyId}
                  cacheOptions
                  defaultOptions
                  isDisabled={add_data?.entity_type ? true : false}
                  onChange={(selectedOption) =>
                    formik.setFieldValue('companyId', selectedOption ? selectedOption : null)
                  }
                  className='fieldset-input'
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
          )}

          <div className='fv-row mb-7'>
            <InputFieldSet label={'Observação'}>
              <textarea
                placeholder=''
                {...formik.getFieldProps('observation')}
                name='observation'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
              />
            </InputFieldSet>
          </div>
        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {TasksForm}
