import {Card} from 'react-bootstrap'
type ICard = {
  ticket?: boolean
  data: number
}

function analyzeValue(data:any) {
  let value, unit;
  const hours = data / 60;

  if (hours >= 24) {
    value = Math.round(hours / 24);
    unit = 'dias';
  } 
  else if(hours >= 1)
  {
    value = Math.round(hours);
    unit = 'horas';
  }
  else
  {
    value = data;
    unit = 'minutos';
  }

  //console.log("value unit", {value, unit});

  return {value, unit}
}

const DashboardCardDuration = ({ticket, data}: ICard) => {
  if(!data)
  {
    data = 0;
  }
  return (
    <Card className='p-6' style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{flex: '0 0 auto', whiteSpace: 'nowrap', marginRight: '2rem'}}>
          <h1 className='mb-0 text-wl-custom-title-collor' style={{paddingLeft: 6}}>
            <strong> {analyzeValue(data)?.value?.toFixed(2)}</strong>
          </h1>
          <h2 className='text-wl-custom-title-collor' style={{paddingLeft: 4}}>{analyzeValue(data)?.unit}</h2>
        </div>

        <div style={{flex: '1 0 auto', textAlign: 'left'}}>
          <h3>{ticket ? 'Tempo de Resolução' : 'Duração do Negócio'}</h3>
        </div>
      </div>
    </Card>
  )
}
export {DashboardCardDuration}
