import MaskedInput from 'react-text-mask'
const phoneMask = (rawValue: string) => {
  let cleanedValue = rawValue.replace(/[^\d]/g, '')

  // Removendo o DDI (+55) do comprimento, se presente
  const localNumberLength = cleanedValue.startsWith('55')
    ? cleanedValue.length - 2
    : cleanedValue.length

  if (localNumberLength === 10) {
    // Formato para números com 10 dígitos (sem o nono dígito): +55 (XX) XXXX-XXXX
    return [
      '+',
      '5',
      '5',
      ' ',
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  } else {
    // Formato para números com 11 dígitos (com o nono dígito): +55 (XX) XXXXX-XXXX
    return [
      '+',
      '5',
      '5',
      ' ',
      '(',
      /[1-9]/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  }
}
const countInitialConsecutiveFives = (str: string) => {
  const match = str.match(/^5+/)
  return match ? match[0].length : 0
}
const PhoneField = ({field, form, ...props}: any) => {
  // field.value = field?.value?.replace(/\((\d)5/g, "($1");

  const onBlur = (event: any) => {
    const value = event?.target?.value
    form.setFieldValue(field.name, value)
  }

  // const onChange = (event: any) => {
  //   let value = event?.target?.value

  //   form.setFieldValue(field.name, value)
  // }

  if (field?.value?.includes('55') && countInitialConsecutiveFives(field.value) <= 2) {
    if (!field?.value?.includes('+')) {
      field.value = field?.value?.replace('55', '')
    }
  }
  return (
    <MaskedInput
      {...field}
      {...props}
      guide={false}
      mask={phoneMask}
      onBlur={onBlur}
      onChange={() => {}}
      //onKeyUp={onChange}
      placeholderChar={'\u2000'}
      className='form-control fieldset-input'
      render={(ref: any, renderProps: any) => <input ref={ref} {...renderProps} />}
    />
  )
}

export default PhoneField
