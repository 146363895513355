import { createContext, useContext, useReducer } from 'react';

export interface CardMoveModalContextModel {
  open: boolean,
  card: any
}

const initialData = {
  open: false,
  card: null
};

const CardMoveModalContext = createContext<CardMoveModalContextModel>(initialData);
const CardMoveModalDispatchContext = createContext<any>(null);

export function CardMoveModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardMoveReducer,
    initialData
  );

  return (
    <CardMoveModalContext.Provider value={data}>
      <CardMoveModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardMoveModalDispatchContext.Provider>
  </CardMoveModalContext.Provider>
  );
}

export function useCardMoveModal() {
  return useContext(CardMoveModalContext);
}

export function useCardMoveModalDispatch() {
  return useContext(CardMoveModalDispatchContext);
}

function cardMoveReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-modal': {
      return { ...data, open: true, card: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, card: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
