/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQuery} from '@tanstack/react-query'
import {FC, useEffect, useRef, useState} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {ListLoading} from '../../modules/view/ListLoading'
import {useCardFormModal, useCardFormModalDispatch} from '../cards/core/CardFormModalContext'
import {FunnelStage} from './core/_models'
import {getFunnels} from './core/_requests'
import {useFunnel, useFunnelDispatch} from './core/FunnelContext'
import {FunnelsEditModal} from './funnel-step-modal/FunnelsEditModal'

import {format} from 'date-fns'
import {io} from 'socket.io-client'
import FunnelPageToolbar from './FunnelPageToolbar'
import FunnelStepColumn from './FunnelStepColumn'

const FunnelPage: FC<any> = ({location}: any) => {
  const funnelDispatch = useFunnelDispatch()
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false)
  const [funnelStageEdit, setFunnelStageEdit] = useState<FunnelStage>()
  const funnel = useFunnel()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const cardFormModalData = useCardFormModal()
  const [isConnected, setConnected] = useState(false)
  const socketUrl: any = process.env.REACT_APP_WS_URL
  const socket = useRef<any>(null)

  useEffect(() => {
    if (!isConnected) {
      connectToSocket()
    }

    return () => {
      if (socket.current && socket.current?.connected) {
        socket.current?.disconnect()
      }
    }
  }, [])

  function connectToSocket() {
    const storage: any = localStorage.getItem('auth-crm-token-tric')
    const token = JSON.parse(storage)?.accessToken

    socket.current = io(socketUrl, {
      transports: ['websocket'],
      auth: {
        token,
      },
    })

    socket.current?.on('connect', () => {
      console.info(`Successfully connected to socket at ${socketUrl}`)
      setConnected(true)
    })

    socket.current?.on('disconnect', () => {
      console.info(`Successfully disconnected`)
      setConnected(false)
    })

    socket.current?.on('error', (err: any) => {
      console.log('Socket Error:', err.message)
    })

    socket.current?.on('FUNNEL_EVENTS', function (event: any) {
      funnelEventHandler(event)
    })
  }

  const {
    isLoading: funnelsIsLoading,
    isError: funnelsIsError,
    data: funnels,
    error: funnelsError,
  } = useQuery({
    queryKey: ['funnel', 'funnels'],
    queryFn: async () => {
      let resFunnels = await getFunnels('page=1&perPage=200&status=1&include_steps=true')

      funnelDispatch({
        type: 'init',
        data: {
          funnels: resFunnels,
          currentFunnel: resFunnels[0],
        },
      })

      return resFunnels
    },
    enabled: true,
    cacheTime: 0,
    staleTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const handleUrlParameters = () => {
    if (cardFormModalData?.open) return
    const query = new URLSearchParams(location.search)
    const op_id = query.get('card')

    if (!op_id) return
    cardFormModalDispatch({
      type: 'open-detail-modal',
      data: {id: op_id},
    })
  }

  useEffect(() => {
    handleUrlParameters()
  }, [cardFormModalData])

  useEffect(() => {
    if (!funnel?.currentFunnel?.id || !isConnected) return

    socket.current?.emit('FUNNEL_CHANGED', {
      funnel_id: funnel.currentFunnel.id,
    })
  }, [funnel?.currentFunnel])

  useEffect(() => {
    emitFunnelChangedFilter()
  }, [funnel?.filters])

  const funnelEventHandler = (event: any) => {
    if (event?.data?.card) {
      handleDispatchCardEvents(event.key, event.data?.card)
    } else if (event?.data?.step && event.key.startsWith('STEP')) {
      handleDispatchFunnelEvents(event.key, event?.data?.step)
    }
  }

  const handleDispatchCardEvents = (key: any, newData: any) => {
    let type = ''

    if (key.startsWith('MARKED') || ['UPDATED', 'RESPONSIBLE_CHANGED'].includes(key)) {
      type = 'update-step-card'
    } else {
      switch (key) {
        case 'CREATED':
          type = 'add-step-card'
          break
        case 'DELETED':
          type = 'delete-step-card'
          break
        case 'MOVED':
          type = 'move-step-card'
          break
      }
    }

    if (!type) return

    funnelDispatch({
      type: type,
      data: newData,
    })
  }

  const handleDispatchFunnelEvents = (key: any, dataEvent: any) => {
    switch (key) {
      case 'STEPS_REORDERED':
        funnelDispatch({
          type: 'move-funnel-step',
          data: {...dataEvent, newPosition: dataEvent?.stepNumber},
        })
        break
      case 'STEP_UPDATED':
        funnelDispatch({
          type: 'update-funnel-step',
          data: dataEvent,
        })
        break
      case 'STEP_DELETED':
        funnelDispatch({
          type: 'delete-funnel-step',
          data: dataEvent,
        })
        break
      case 'STEP_CREATED':
        funnelDispatch({
          type: 'add-funnel-step',
          data: dataEvent,
        })
        break
      default:
        console.log('Unknown FUNNEL event type:', key)
    }
  }

  function emitFunnelChangedFilter() {
    const filter = funnel.filters

    let createdAtStart = undefined
    let createdAtEnd = undefined
    let updatedAtStart = undefined
    let updatedAtEnd = undefined
    let toCloseDateStart = undefined
    let toCloseDateEnd = undefined
    let labelIdss = []

    if (filter?.labelIds && filter?.labelIds.length > 0) {
      labelIdss = filter.labelIds.map((l: any) => l.value)
    }

    if (filter.created_at_start) {
      createdAtStart = format(filter.created_at_start, 'yyyy-MM-dd 00:00:00')
    }

    if (filter.created_at_end) {
      createdAtEnd = format(filter.created_at_end, 'yyyy-MM-dd 00:00:00')
    }

    if (filter.update_at_start) {
      updatedAtStart = format(filter.update_at_start, 'yyyy-MM-dd 00:00:00')
    }

    if (filter.update_at_end) {
      updatedAtEnd = format(filter.update_at_end, 'yyyy-MM-dd 00:00:00')
    }

    if (filter.close_date_start) {
      toCloseDateStart = format(filter.close_date_start, 'yyyy-MM-dd 00:00:00')
    }
    if (filter.close_date_end) {
      toCloseDateEnd = format(filter.close_date_end, 'yyyy-MM-dd 00:00:00')
    }

    let status = filter.status?.value ?? 1

    if (typeof status == 'string') status = undefined

    let filterWS = {
      status,
      responsibleId: filter.responsible?.value,
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
      companyId: filter.companyId?.value,
      labelIds: labelIdss,
      toCloseDateStart,
      toCloseDateEnd,
    }

    if (isConnected && socket.current) {
      socket.current?.emit('FUNNEL_FILTERS_CHANGED', filterWS)
    }
  }

  if (funnelsIsLoading) return <ListLoading />

  if (funnelsIsError) return <>Ops.. Não foi possível carregar o funil. Tente atualizar a página.</>

  if (!funnels || funnels.length == 0)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <div style={{marginBottom: '20px'}}>Ainda não há Funis cadastrados.</div>
        <div>
          <a
            type='button'
            href='/settings/funnels'
            className='btn btn-sm btn-wl-custom-primary-collor fw-bold me-2 px-6'
            data-kt-menu-dismiss='true'
            data-kt-user-table-filter='reset'
          >
            Criar Funil
          </a>
        </div>
      </div>
    )

  return (
    <>
      <FunnelPageToolbar />

      <div className='card funnel'>
        <div className='card-body d-flex'>
          <DndProvider backend={HTML5Backend}>
            {funnel?.currentFunnel?.steps.map((st: any, index: number) => (
              <FunnelStepColumn
                key={index}
                step={st}
                setShowModalEdit={setShowModalAdd}
                setFunnelStageEdit={setFunnelStageEdit}
              />
            ))}
          </DndProvider>

          <div
            className='card stage-add'
            onClick={() => {
              setShowModalAdd(true)
            }}
          >
            <div className='stage-header'>
              <div className='stage-title'>
                <i className='fa-solid fa-plus' style={{fontSize: '1.3rem'}}></i> Nova Etapa
              </div>
            </div>
          </div>
        </div>
      </div>

      {(showModalAdd || funnelStageEdit) && funnel?.currentFunnel?.id && (
        <FunnelsEditModal
          funnel={funnel.currentFunnel}
          funnelStage={funnelStageEdit}
          onCloseModal={() => {
            setShowModalAdd(false)
            setFunnelStageEdit(undefined)
          }}
        />
      )}
    </>
  )
}

export default FunnelPage
