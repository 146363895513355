import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getMe } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLocation } from 'react-router'

type AuthContextProps = {
  auth: AuthModel | undefined
  registerAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  registerCurrentUser: (user: UserModel | undefined) => void
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  registerAuth: () => { },
  currentUser: undefined,
  registerCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const registerAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const registerCurrentUser = (user: UserModel | undefined) => {
    if(window)
    {
      if(user)
      {
        window.CRMUser = {
          id: user.id,
          externalId: user.externalId,
          name: user.name,
          email: user.email,
          createdAt: user.createdAt,
          status: user.status,
          role: {id: user.role?.id, name: user.role?.name, key: user.role?.key}
        };
      }
      else
      {
        window.CRMUser = user;
      }
    }

    setCurrentUser(user)
  }

  const logout = () => {
    registerAuth(undefined)
    registerCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, registerAuth, currentUser, registerCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, registerAuth, logout, registerCurrentUser, currentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tk = searchParams.get('tk');

  useEffect(() => {

    //se ativar setShowSplashScreen denovo, os dropdown para de funcionar

    const requestUser = async (apiToken: string) => {
      let userData = null;

      try {

        if (!didRequest.current) {
          const { data } = await getMe(apiToken)
          if (data) {
            userData = data;
            registerCurrentUser(data)
            didRequest.current = true
          }
          else {
            throw new Error('Auth token is invalid')
          }
        }

      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      
      return userData;
      //return () => (didRequest.current = true)
    }

    const loadUserByUrlToken = async (tk: string) => {
      let userData = await requestUser(tk)

      if (userData) {
        let tkauth: any = {
          accessToken: tk
        }

        registerAuth(tkauth);
      }

    }

    //Só vai entrar aqui se está tentando se autenticar sem ser pela tela do login. Ex: usando o token na url, ou atualizando o site usando o token do localstorage.
    if(!currentUser)
    {
      if (auth && auth.accessToken) {
        if(tk && tk != auth.accessToken) //CRM está carregando outro token na url, necessário re-autenticar
        {
          loadUserByUrlToken(tk);
        }
        else
        {
          requestUser(auth.accessToken)
        }
      }
      else if (tk) {
        loadUserByUrlToken(tk);
      }
      else {
        logout()
        setShowSplashScreen(false)
      }
    }
    

    // eslint-disable-next-line
  }, [auth])

  return showSplashScreen ? <Loading /> : <>{children}</>
}

const Loading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Inicializando...</div>
}

export { AuthProvider, AuthInit, useAuth }
