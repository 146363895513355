import { createContext, useContext, useReducer } from 'react';

export interface CardTransferResponsibleModalContextModel {
  open: boolean,
  card: any
}

const initialData = {
  open: false,
  card: null
};

const CardTransferResponsibleModalContext = createContext<CardTransferResponsibleModalContextModel>(initialData);
const CardTransferResponsibleModalDispatchContext = createContext<any>(null);

export function CardTransferResponsibleModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardTransferResponsibleReducer,
    initialData
  );

  return (
    <CardTransferResponsibleModalContext.Provider value={data}>
      <CardTransferResponsibleModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardTransferResponsibleModalDispatchContext.Provider>
  </CardTransferResponsibleModalContext.Provider>
  );
}

export function useCardTransferResponsibleModal() {
  return useContext(CardTransferResponsibleModalContext);
}

export function useCardTransferResponsibleModalDispatch() {
  return useContext(CardTransferResponsibleModalDispatchContext);
}

function cardTransferResponsibleReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-modal': {
      return { ...data, open: true, card: action.data }
    }
    case 'close-modal': {
      return { ...data, open: false, card: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
