import axios, {AxiosResponse} from 'axios'
import {request} from "./request"

const getNotifications = async (query: string):  Promise<any> => {

  return request({
    method: "GET",
    url: `/notifications?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<any>) => d.data);
}

const markNotificationAsRead = (id: number): Promise<any> => {

  return request({
    method: "patch",
    url: `/notifications/${id}/markAsRead`,
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<any>) => d.data);
}

export { getNotifications, markNotificationAsRead }
