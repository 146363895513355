const FieldTypeIcon = ({ type }: { type: string }) => {

    let icon = <i className="fa-solid fa-circle-nodes"></i>;

    switch (type) {
        case "number":
            icon = <i className="fa-solid fa-3"></i>;
            break;
        case "short_text":
            icon = <i className="fa-solid fa-font"></i>;
            break;
        case "long_text":
            icon = <i className="fa-solid fa-text-width"></i>;
            break;
        case "date":
            icon = <i className="fa-regular fa-calendar-days"></i>;
            break;
        case "datetime":
            icon = <i className="fa-regular fa-calendar"></i>;
            break;
        case "time":
            icon = <i className="fa-regular fa-clock"></i>;
            break;
        case "phone":
            icon = <i className="fa-solid fa-phone"></i>;
            break;
        case "email":
            icon = <i className="fa-regular fa-envelope"></i>;
            break;
        case "monetary":
            icon = <i className="fa-solid fa-dollar-sign"></i>;
            break;
        case "radio":
            icon = <i className="fa-regular fa-circle-dot"></i>;
            break;
        case "checkbox":
            icon =<i className="fa-regular fa-square-check"></i>;
            break;
        case "switch":
            icon = <i className="fa-solid fa-toggle-off"></i>;
            break;
        case "select":
            icon = <i className="fa-regular fa-square-caret-down"></i>;
            break;
    }

    return icon;
}

export default FieldTypeIcon;