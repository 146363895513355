/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider} from '../../../modules/view/ListViewProvider'
import {QueryRequestProvider} from '../../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../../modules/view/QueryResponseProvider'
import {AutomaticActionsHeader} from './components/header/AutomaticActionsHeader'
import {AutomaticActionFormModalProvider} from './core/AutomaticActionsFormModalContext'
import {getAutomaticActionsConfig} from './core/_requests'
import MultiStepForm from './multi-step-form/MultiStepForm'
import {AutomaticActionsTable} from './table/AutomaticActionsTable'

const AutomaticActionsPage = () => {
  return (
    <>
      <AutomaticActionsHeader />
      <KTCard>
        <AutomaticActionsTable />
        <MultiStepForm />
      </KTCard>
      {/* <AutomaticActionsEditModal /> */}
    </>
  )
}

const AutomaticActions: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getAutomaticActionsConfig}>
          <ListViewProvider>
            <AutomaticActionFormModalProvider>
              <AutomaticActionsPage />
            </AutomaticActionFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {AutomaticActions}
