import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal';
import { useCustomFormFormModal, useCustomFormFormModalDispatch } from '../core/CustomFormFormModalContext'
import { getCustomFormById, getFieldtypes, getCustomForms } from '../core/_requests';
import { CustomFormsEditorWorkArea } from './CustomFormsEditorWorkArea'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import { Formik } from 'formik'
import { useQueryResponse } from '../../../../modules/view/QueryResponseProvider'
import { getFunnels } from '../../funnels/core/_requests';
import Swal from 'sweetalert2'
import { CARD_TYPES } from '../../../cards/core/_models'
import { useCustomFormEditor, useCustomFormEditorDispatch } from '../core/CustomFormEditorContext'
import { CUSTOM_FORM_TYPES } from '../core/_models'

const SelectEntityForm = ({ onSelectEntity }: { onSelectEntity: any }) => {

  const { isError: funnelsIsError, data: funnels, error: funnelsError } = useQuery({
    queryKey: ['custom-forms', 'funnels'],
    queryFn: () => {
      return getFunnels("page=1&perPage=200")
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  type initialValuesType = {
    entity: string
    funnel: number | null
    entityType: number | null
    type: string | number | readonly string[] | undefined
  }
  var initialValues: initialValuesType = { entity: "", funnel: null, entityType: null, type: undefined }

  return <>
    <div style={{
      backgroundColor: "",
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      justifyContent: "center"
    }}>
      <p>
        Selecione a Entidade para criar o Formulário de Sistema
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={async (formValues, { setSubmitting, resetForm }) => {
          //console.log("formValues", formValues);

          let values = { ...formValues };

          if (!values.entity) return Swal.fire({
            title: 'Opss..',
            text: 'Informe a entidade para criar o formulário.',
            icon: 'error',
            confirmButtonText: 'OK',
          })

          if (values.entity.includes("card:") && !values.funnel) return Swal.fire({
            title: 'Opss..',
            text: 'Informe o funil a que o novo formulário pertencerá.',
            icon: 'error',
            confirmButtonText: 'OK',
          })

          if (values.entity?.includes("card:")) {
            let subType = values.entity.replace("card:", "");
            values.entity = "card";

            values.entityType = parseInt(subType.toString());
          }

          try {
            let systemFormsEntity = await getCustomForms(`entity=${values.entity}&type=${values.type}`); //Cadastro
            if (systemFormsEntity?.data) {

              if (values.entity == "card" && systemFormsEntity?.data.find(el => el.funnel.id == values.funnel)) return Swal.fire({
                title: 'Opss..',
                text: 'Já existe um formulário para esta entidade e funil.',
                icon: 'error',
                confirmButtonText: 'OK',
              })

              if (values.entity == "company" && systemFormsEntity?.data.length > 0) return Swal.fire({
                title: 'Opss..',
                text: 'Já existe um formulário para esta entidade',
                icon: 'error',
                confirmButtonText: 'OK',
              })

              if (values.entity == "contact" && systemFormsEntity?.data.length > 0) return Swal.fire({
                title: 'Opss..',
                text: 'Já existe um formulário para esta entidade',
                icon: 'error',
                confirmButtonText: 'OK',
              })

            }
          }
          catch (e) {
            console.log("error", e);
            return Swal.fire({
              title: 'Opss..',
              text: 'Não foi possível verificar as opções. Tente Novamente mais tarde.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          }

          onSelectEntity(values);

          resetForm();
        }}
      >
        {props => (

          <form id='select_entity' className='form' onSubmit={props.handleSubmit} noValidate style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

            <InputFieldSet label={'Tipo de Formulário'}>
              <select
                name='type'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-lg fieldset-input'
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.type}
              >
                <option value={""}>Selecione</option>
                <option value={CUSTOM_FORM_TYPES.INITIAL.id}>Cadastro</option>
                {/* <option value={CUSTOM_FORM_TYPES.PUBLIC.id}>Público</option>*/}
              </select>
            </InputFieldSet>

            {props.values.type == CUSTOM_FORM_TYPES.INITIAL.id &&
              <InputFieldSet label={'Entidade'}>
                <select
                  name='entity'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-lg fieldset-input'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.entity}
                >
                  <option value={""}>Selecione</option>
                  <option value={"contact"}>Contato</option>
                  <option value={"company"}>Empresa</option>
                  <option value={`card:${CARD_TYPES.OPPORTUNITY.id}`}>Oportunidade</option>
                  <option value={`card:${CARD_TYPES.TICKET.id}`}>Tícket</option>
                </select>
              </InputFieldSet>
            }

            {props.values.type == CUSTOM_FORM_TYPES.INITIAL.id && props.values.entity?.includes("card:") &&
              <InputFieldSet label={'Funil'}>
                <select
                  name='funnel'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-control form-select form-select-lg fieldset-input'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.funnel ? props.values.funnel : ""}
                >
                  <option value={""}>Selecione</option>
                  {funnels && funnels.data && funnels.data.map((el: any, index: number) => {
                    if (el.type == props.values.entity.replace("card:", "")) return <option value={el.id} key={index}>{el.name}</option>
                  })}
                </select>
              </InputFieldSet>}

            <button
              type='submit'
              className='btn btn-wl-custom-primary-collor'
              data-kt-users-modal-action='submit'
              disabled={props.isSubmitting || !props.isValid || !props.touched}
              style={{ marginTop: "5px" }}
            >
              <span className='indicator-label'>Confirmar</span>
            </button>
          </form>
        )}
      </Formik>
    </div>
  </>
}

const CustomFormsEditor = ({ onClose, formId }: { onClose: any, formId: any }) => {

  const customFormEditor = useCustomFormEditor();
  const customFormEditorDispatch = useCustomFormEditorDispatch()

  const [entityForm, setEntityForm] = useState<any>(null)

  const handleSelectEntity = (selected: any) => {
    setEntityForm(selected);

    let internalId = Date.now();

    customFormEditorDispatch({
      type: 'init',
      data: {
        entity: selected.entity,
        entityType: selected.entityType,
        funnel: selected.funnel,
        activeForm: internalId,
        forms: [{
          id: null,
          internalId: internalId,
          type: CUSTOM_FORM_TYPES.INITIAL.id,
          name: "Formulário Inicial",
          fields: [],
        }]
      }
    })

  }

  const handleClose = () => {
    onClose();
    customFormEditorDispatch({
      type: 'reset'
    });
    setTimeout(() => setEntityForm(null), 400);
  };

  const { isLoading, isError, data: systemFormData, error } = useQuery({
    queryKey: ['custom-forms', formId],
    queryFn: async () => {
      let dataRes = await getCustomFormById(formId);
      let fields = [];
      let formInternalId = Date.now();

      for (let i = 0; i < dataRes.fields.length; i++) {
        let useField = dataRes.fields[i];

        let sourceField = useField.defaultFieldId ? "defaultfield" : (useField.customFieldId ? "customfield" : null);

        let mountField = {
          formInternalId: formInternalId,
          source: sourceField,
          internalId: Date.now()+i,
          id: useField.defaultFieldId ? useField.defaultFieldId : (useField.customFieldId ? useField.customFieldId : null),
          label: useField.label,
          name: useField.name,
          status: useField.status,
          required: useField.required,
          type: useField.type,
          options: []
        }

        if(sourceField == "customfield" && useField.customField && useField.customField.options && useField.customField.options.length > 0)
        {
          mountField.options = useField.customField.options;
        }

        fields.push(mountField)
      }

      customFormEditorDispatch({
        type: 'init',
        data: {
          entity: dataRes.entity,
          entityType: dataRes.entityType,
          funnel: dataRes.funnel?.id,
          activeForm: formInternalId,
          forms: [{
            internalId: formInternalId,
            type: dataRes.type,
            name: dataRes.name,
            id: dataRes.id,
            fields: fields,
            saved: true
          }]
        }
      })

      if (dataRes.type == CUSTOM_FORM_TYPES.INITIAL.id) {
        let auxCustomForms = await getCustomForms(`entity=${dataRes.entity}&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}`); //Complemento
        let complementCustomForms = auxCustomForms?.data?.filter((e: any) => (!dataRes.funnel || (dataRes.funnel?.id && e.funnel.id == dataRes.funnel.id)) && (!dataRes.entityType || (dataRes.entityType && e.entityType == dataRes.entityType)));
        if (complementCustomForms) {
          for (let i = 0; i < complementCustomForms.length; i++) {
            let useComplementForm : any = complementCustomForms[i];
            let complementFields : any = [];
            let formInternalId = Date.now()+i+1;
            for (let j = 0; j < useComplementForm?.fields.length; j++) {
              let useField = useComplementForm.fields[j];

              let sourceField = useField.defaultFieldId ? "defaultfield" : (useField.customFieldId ? "customfield" : null);

              let mountField = {
                formInternalId: formInternalId,
                source: sourceField,
                internalId: Date.now()+j,
                id: useField.defaultFieldId ? useField.defaultFieldId : (useField.customFieldId ? useField.customFieldId : null),
                label: useField.label,
                name: useField.name,
                status: useField.status,
                required: useField.required,
                type: useField.type,
                options: []
              }

              if(sourceField == "customfield" && useField.customField && useField.customField.options && useField.customField.options.length > 0)
              {
                mountField.options = useField.customField.options;
              }

              complementFields.push(mountField)

            }
            customFormEditorDispatch({
              type: 'load-form',
              data: {
                internalId: formInternalId,
                funnel: dataRes.funnel?.id,
                type: useComplementForm.type,
                name: useComplementForm.name,
                id: useComplementForm.id,
                fields: complementFields,
                saved: true
              }
            })

          }
        }

      }

      return dataRes;
    },
    enabled: formId ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  return (
    <div style={{ height: "100%" }}>
      {(entityForm?.entity || systemFormData) && <CustomFormsEditorWorkArea funnel={entityForm?.funnel} entity={entityForm?.entity ? entityForm.entity : systemFormData.entity} entityType={entityForm?.entityType ? entityForm.entityType : (systemFormData?.entityType ? systemFormData.entityType : undefined)} isLoading={false} onClose={handleClose} />}
      {!entityForm?.entity && !formId && <SelectEntityForm onSelectEntity={handleSelectEntity} />}
    </div>)
}

export default CustomFormsEditor;
