import React from 'react';
import MaskedInput from 'react-text-mask';
const cpfCnpjMask = (rawValue: string) => {
    const cleanedValue = rawValue.replace(/[^\d]/g, '');

    if (cleanedValue.length <= 11) { // CPF
        return [
            /\d/, /\d/, /\d/, '.', 
            /\d/, /\d/, /\d/, '.', 
            /\d/, /\d/, /\d/, '-', 
            /\d/, /\d/
        ];
    } else { // CNPJ
        return [
            /\d/, /\d/, '.', 
            /\d/, /\d/, /\d/, '.', 
            /\d/, /\d/, /\d/, '/', 
            /\d/, /\d/, /\d/, /\d/, '-', 
            /\d/, /\d/
        ];
    }
};
  
  const CpfCnpjField = ({field, form, ...props}: any) =>
{
 return(
    <MaskedInput
      {...field}
      {...props}
      guide={false}
      mask={cpfCnpjMask}
      placeholderChar={'\u2000'}
      className='form-control fieldset-input'
      render={(ref: any, renderProps: any) => <input ref={ref} {...renderProps} />}
    />
  )
} 

  export default CpfCnpjField;