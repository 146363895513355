import {KTIcon} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/view/ListViewProvider'

const TerminationReasonEditModalHeader = () => {
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{itemIdForUpdate ? "Alterar" : "Adicionar"} Motivo de Finalização</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm '
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' iconType='solid' className='fs-1' style={{color:"#000"}}/>
      </div>
      {/* end::Close */}
    </div>
  )
}

export {TerminationReasonEditModalHeader}
