import {FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {Contact} from '../core/_models'
import {ListLoading} from '../../../modules/view/ListLoading'
import {createContact, updateContact, getLabels, getUsers, getCompanies} from '../core/_requests'
import {
  getCustomFormByEntity,
  getDefaultFields,
  getCustomFields,
} from '../../settings/custom-forms/core/_requests'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import Swal from 'sweetalert2'
import debounce from 'debounce-promise'
import {useQuery, useMutation} from '@tanstack/react-query'
import FieldTypeInput from '../../settings/custom-forms/manage-modal/CustomFormsFormComponents/FieldTypeInput'
import {useContactFormModalDispatch} from '../core/ContactFormModalContext'
import {useNavigate} from 'react-router-dom'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCustomFormById} from '../../settings/custom-forms/core/_requests'
import {formatCustomFieldInputDataToDb} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldInputDataToDb'
import {formatCustomFieldDbDataToInput} from '../../../../_metronic/helpers/custom-inputs/formatCustomFieldDbDataToInput'

type Props = {
  isLoading: boolean
  contact?: Contact
  embed?: boolean
  onSave?: any
  customFormId?: number
}

type OptionSelect = {
  label: string
  value: any
}

const editDataSchema = Yup.object().shape({})

const ContactsForm: FC<Props> = ({contact, isLoading, embed, onSave, customFormId}) => {
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  const contactFormModalDispatch = useContactFormModalDispatch()

  const handleClose = () => {
    if (onSave) return onSave()
    if (embed) return
    contactFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/contacts')
    refetch()
  }

  const {
    isLoading: systemFormIsLoading,
    isError: systemFormIsError,
    data: systemFormData,
    error: systemFormError,
  } = useQuery({
    queryKey: ['contacts', 'custom-forms', 'details', 'contact', 'customform', customFormId],
    queryFn: async () => {
      let dataRes = null
      if (customFormId) {
        dataRes = await getCustomFormById(customFormId)
      }

      if (dataRes) {
        let workFields: any = []
        dataRes.fields.forEach((e: any) => {
          if (e.customFieldId) {
            let options = e.customField?.options ? e.customField.options : [];
            workFields.push({...e, name: 'custom_' + e.customFieldId, options: options })
          } else { 
            workFields.push(e)
          }
        })

        dataRes.fields = workFields
      }

      return dataRes
    },
    enabled: true,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: defaultSystemFieldsIsLoading,
    isError: defaultSystemFieldsIsError,
    data: defaultSystemFields,
    error: defaultSystemFieldsError,
  } = useQuery({
    queryKey: ['contacts', 'custom-forms', 'default-fields', 'contact'],
    queryFn: async () => {
      let dfFields = await getDefaultFields('contact')
      let workDfFields: any = []
      dfFields.forEach((e: any) => {
        workDfFields.push({...e, type: {key: e.type}})
      })
      return workDfFields
    },
    enabled: !systemFormData && !systemFormIsError,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: customFieldsIsLoading,
    isError: customFieldsIsError,
    data: customFields,
    error: customFieldsError,
  } = useQuery({
    queryKey: ['contacts', 'custom-forms', 'custom-fields', 'contact'],
    queryFn: async () => {
      let cfields = await getCustomFields('contact')
      let workCFields: any = []
      cfields.forEach((e: any) => {
        workCFields.push({...e, name: 'custom_' + e.id})
      })
      return workCFields
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadEmails = () => {
    let emails: Array<any> = []
    if (!contact) return emails

    if (contact.emails == undefined) return emails
    for (let i = 0; i < contact.emails?.length; i++) {
      emails.push(contact.emails[i].email)
    }
    return emails
  }

  const loadPhones = () => {
    let phones: Array<any> = []
    if (!contact) return phones

    if (contact.phones == undefined) return phones
    for (let i = 0; i < contact.phones?.length; i++) {
      phones.push(contact.phones[i].number)
    }
    return phones
  }

  const loadLabels = () => {
    let labels: Array<any> = []
    if (!contact) return labels
    if (contact.labels == undefined) return labels
    for (let i = 0; i < contact.labels?.length; i++) {
      labels.push({label: contact.labels[i].name, value: contact.labels[i].id})
    }
    return labels
  }

  const loadCustomFields = (entityCustomFields: any, customFieldsCheck: any) => {
    let fields: any = {}
    if (!entityCustomFields) return fields

    for (let i = 0; i < entityCustomFields.length; i++) {
      let useCField = entityCustomFields[i]

      let formatReturn = formatCustomFieldDbDataToInput(useCField, customFieldsCheck)

      fields['custom_' + useCField.id] = formatReturn
    }

    return fields
  }

  const [dataForEdit, setDataForEdit] = useState<any>(null)

  const labelsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let labels = await getLabels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (labels && labels != undefined) {
        for (let i = 0; i < labels.length; i++) {
          list.push({label: labels[i].name, value: labels[i].id})
        }
      }
      resolve(list)
    })
  const debounceLabelsOptions = debounce(labelsOptions, 500)

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const peopleOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let peop = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (peop && peop.data != undefined) {
        for (let i = 0; i < peop?.data?.length; i++) {
          list.push({label: peop.data[i].name, value: peop.data[i].id})
        }
      }
      resolve(list)
    })
  const debouncePeopleOptions = debounce(peopleOptions, 500)

  const companiesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let companies = await getCompanies('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (companies && companies.data != undefined) {
        for (let i = 0; i < companies?.data?.length; i++) {
          list.push({label: companies.data[i].name, value: companies.data[i].id})
        }
      }
      resolve(list)
    })

  const debounceCompaniesOptions = debounce(companiesOptions, 500)

  const mountSelectLoadOptions = (field: any) => {
    switch (field.name) {
      case 'labelIds':
        return debounceLabelsOptions
      case 'responsibleId':
        return debounceResponsiblesOptions
      case 'companyId':
        return debounceCompaniesOptions
    }

    switch (field.type?.key) {
      case 'person':
        return debouncePeopleOptions
      default:
        return undefined
    }
  }

  useEffect(() => {
    if (customFields === undefined) return undefined //Aguardar para ver se tem campos customizados
    if (dataForEdit) return undefined

    setDataForEdit({
      ...contact,
      emails: loadEmails(),
      phones: loadPhones(),
      companyId: contact?.company
        ? {label: contact.company.name, value: contact.company.id}
        : undefined,
      responsibleId: contact?.responsible
        ? {label: contact.responsible.name, value: contact.responsible.id}
        : undefined,
      labelIds: loadLabels(),
      ...loadCustomFields(contact?.customFields, customFields),
    })
  }, [systemFormIsLoading, defaultSystemFieldsIsLoading, customFieldsIsLoading])

  if (systemFormIsLoading || defaultSystemFieldsIsLoading || customFieldsIsLoading || !dataForEdit)
    return <ListLoading />

  if (systemFormIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  if (defaultSystemFieldsIsError) return <>Ops.. Falha ao carregar formulário. Tente novamente.</>

  return (
    <>
      <Formik
        initialValues={dataForEdit}
        validationSchema={editDataSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true)

          let auxValues: any = {...values}
          
          if (!auxValues.name)
            return Swal.fire({
              title: 'Atenção',
              text: 'Campo Nome é Obrigatório.',
              icon: 'warning',
              confirmButtonText: 'OK',
            })

          if (auxValues.companyId && auxValues.companyId.value) {
            auxValues.companyId = auxValues.companyId.value
          }

          if (auxValues.responsibleId && auxValues.responsibleId.value) {
            auxValues.responsibleId = auxValues.responsibleId.value
          }

          if (auxValues.status != undefined) {
            auxValues.status = auxValues.status ? 1 : 0
          }

          if (auxValues.labelIds) {
            let auxLabels: Array<number> = []
            for (let i = 0; i < auxValues.labelIds.length; i++) {
              auxLabels.push(auxValues.labelIds[i].value)
            }
            auxValues.labelIds = auxLabels
          }

          let customFieldsValue = []
          let objKeys = Object.keys(auxValues)
          for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i].includes('custom_')) {
              let useField = auxValues[objKeys[i]]
              let idCustom = objKeys[i].replace('custom_', '')

              let valueCustom = formatCustomFieldInputDataToDb(
                {
                  id: idCustom,
                  value: useField,
                },
                customFields
              )

              if (valueCustom === undefined) {
                console.log('Falha ao formatar valor do campo customizado')
                continue
              }

              customFieldsValue.push({
                id: idCustom,
                value: valueCustom,
              })
            }
          }
          auxValues.customFields = customFieldsValue

          let phones = []
          let emails = []
          for (let i = 0; i < auxValues?.phones?.length; i++) {
            let use = auxValues?.phones[i]
            if (typeof use === 'object' && use.number !== '') {
              phones.push(use?.number)
            } else if (typeof use === 'string' && use !== '') {
              phones.push(use)
            }
          }
          for (let i = 0; i < auxValues?.emails?.length; i++) {
            let use = auxValues?.emails[i]
            if (typeof use === 'object' && use.number !== '') {
              emails.push(use?.email)
            } else if (typeof use === 'string' && use !== '') {
              emails.push(use)
            }
          }
          auxValues.phones = phones
          auxValues.emails = emails

          try {
            if (isNotEmpty(contact?.id)) {
              await updateContact(auxValues)
            } else {
              await createContact(auxValues)
            }

            Swal.fire('Registro salvo com Sucesso!!', '', 'success')
            //resetForm();
            handleClose()
          } catch (ex: any) {
            console.error(ex)

            let errorDetected = ''
            if (ex.response?.data?.key && ex.response?.data?.message) {
              errorDetected = ex.response?.data?.message
            } else if (ex.response?.data?.message && Array.isArray(ex.response?.data?.message)) {
              if (ex.response?.data?.message.includes('name should not be empty'))
                errorDetected = 'Campo Nome é Obrigatório'
            }

            Swal.fire({
              title: 'Opss..',
              text: errorDetected ? errorDetected : 'Houve um problema ao salvar o contato.',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <form
            id='kt_modal_add_user_form'
            className='form'
            onSubmit={props.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            {/* begin::Scroll 
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 gap-8'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          */}
            <div className='d-flex flex-column me-n7 pe-7 gap-8' id='kt_modal_add_user_scroll'>
              {systemFormData &&
                systemFormData.fields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}

              {!systemFormData &&
                defaultSystemFields &&
                defaultSystemFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}

              {!systemFormData &&
                customFields &&
                customFields.map((field: any, index: number) => {
                  return (
                    <FieldTypeInput
                      key={index}
                      field={field}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[field.name]}
                      selectLoadOptions={mountSelectLoadOptions(field)}
                      setFieldValue={props.setFieldValue}
                    />
                  )
                })}
            </div>
            {/* end::Scroll */}

            {/* begin::Actions */}
            <div className='text-center pt-15'>
              <button
                type='submit'
                className='btn btn-sm btn-wl-custom-primary-collor'
                data-kt-users-modal-action='submit'
                disabled={isLoading || props.isSubmitting || !props.isValid || !props.touched}
              >
                <span className='indicator-label'>Salvar</span>
                {(props.isSubmitting || isLoading) && (
                  <span className='indicator-progress'>
                    Por Favor Aguarde...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
            {(props.isSubmitting || isLoading) && <ListLoading />}
          </form>
        )}
      </Formik>
    </>
  )
}

export {ContactsForm}
