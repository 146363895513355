import {useEffect} from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCustomFieldFormModal, useCustomFieldFormModalDispatch } from '../core/CustomFieldFormModalContext'
import { getCustomFieldById } from '../core/_requests';
import { CustomFieldsForm } from './CustomFieldsForm'

const CustomFieldsEditModal = () => {
  const customFieldFormModal = useCustomFieldFormModal();
  const customFieldFormModalDispatch = useCustomFieldFormModalDispatch()

  const handleClose = () => {
    customFieldFormModalDispatch({
      type: 'close-modal'
    })
  };

  //console.log("customFieldFormModal", customFieldFormModal);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['custom-fields', customFieldFormModal.customFieldId],
    queryFn: () => {
      return getCustomFieldById(customFieldFormModal.customFieldId);
    },
    enabled: customFieldFormModal.customFieldId ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {

    if (customFieldFormModal.open) {

      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [customFieldFormModal.open])


  if (isError || (customFieldFormModal.customFieldId && !data)) return <></>

  return (<>

    <Modal
      show={customFieldFormModal.open}
      onHide={handleClose}
      centered
      scrollable
      contentClassName={""}
      dialogClassName={"modal-md"}
    >
      <Modal.Header closeButton>
        <div style={{fontSize: "1.5rem", fontWeight: "700"}}>{customFieldFormModal.customFieldId? "Alterar " : "Adicionar "} Campo Customizado</div>
      </Modal.Header>
      <Modal.Body className='mx-5 mx-xl-5'>
        <Row>
          <Col>
          <CustomFieldsForm isLoading={false} customField={data} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal></>
  )
}

export {CustomFieldsEditModal}
