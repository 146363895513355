import styled from "styled-components";

export const StrongText = styled.strong`
  color:#93a4ee;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: blue;
    > i {
      color: blue;
      display: inline;
    }
  }

  > i {
    font-size: 15px;
    margin-left: 5px;
    color: black;
    display: none;
  }
`;

export const Container = styled.ul`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  padding: 0;
  li {
    margin-top: 10px;
     margin-right: 20px;
    display: flex;
    align-items: center;
    color: #93a4ee;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    ${StrongText} {
      color: blue;
      > i {
        color: blue;
        display: inline;
      }
    }
  }
  cursor:pointer;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 10px;
      color: #93a4ee;
      margin-top: 3px;
      button {
        border: 0;
        background: transparent;
        color: #fb848a;
        margin-left: 3px;
        cursor: pointer;
        font-size:10px;
      }
      button:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Preview = styled.div<{ src: string }>`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-image: url(${(props) => (props.src ? props.src : "")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
  cursor:pointer;
`;
export const PreviewContainerIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

export const OverviewContainerIcon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  background-color:white;
  border:1px solid #93a4ee;
`;

