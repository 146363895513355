import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import SelectAsync from 'react-select/async'
import debounce from 'debounce-promise'
import {useUserFormModalDispatch} from '../core/UserFormModalContext'
import {useNavigate} from 'react-router-dom'
import {getTeams} from '../../funnels/core/_requests'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {isNotEmpty} from '../../../../../_metronic/helpers'

import {updateUser, createUser} from '../core/_requests'

const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  //   dropdownIndicator: (base:any) => ({
  //     ...base,
  //     padding: '3px',
  //     fontSize: '1rem'
  //   })
}
const ROLE_TYPES = [
  {
    id: 1,
    name: 'Administrador',
  },
  {
    id: 2,
    name: 'Supervisor',
  },
  {
    id: 3,
    name: 'Operador',
  },
  {
    id: 4,
    name: 'Analista',
  },
]

const editDataSchema = Yup.object().shape({})

const UsersForm: FC<any> = ({user, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const userFormModalDispatch = useUserFormModalDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    userFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/users')
    refetch()
  }

  const handleFormatTeams = () => {
    let teams = []
    for (let i = 0; i < user?.teams?.length; i++) {
      let use = user?.teams[i]
      teams.push({label: use?.name, value: use?.id})
    }

    return teams
  }

  const [dataForEdit] = useState<any>({
    name: user?.name,
    email: user?.email ? user?.email : null,
    external_id: user?.externalId ? user?.externalId : null,
    teams: user?.teams ? handleFormatTeams() : [],
    role: user?.role ? user?.role?.id : null,
  })

  const teamsOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps:any = await getTeams('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      resps = resps?.data;
      if (resps && resps != undefined) {
        for (let i = 0; i < resps?.length; i++) {
          list.push({label: resps[i].name, value: resps[i].id})
        }
      }
      resolve(list)
    })
  const debounceTeamsOptions = debounce(teamsOptions, 500)

  const formik: any = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values: any, {setSubmitting, resetForm}) => {
      setSubmitting(true)

      let auxValues: any = {
        name: values.name,
        externalId: values?.external_id,
        roleId: values.role,
        teamIds: values.teams?.map((e: any) => e?.value),
        password: values?.password,
        email: values?.email,
      }

      if (!auxValues.name)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Necessário Informar "Nome".',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!auxValues.roleId)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Necessário Informar "Papel".',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!values.email && !user?.id)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Necessário Informar "E-Mail".',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      if (!values.password && !user?.id)
        return Swal.fire({
          title: 'Atenção!',
          text: 'Necessário Informar "Senha".',
          icon: 'warning',
          confirmButtonText: 'OK',
        })

      try {
        if (isNotEmpty(user?.id)) {
          await updateUser(user?.id, auxValues)
        } else {
          await createUser(auxValues)
        }

        Swal.fire('Registro salvo com Sucesso!', '', 'success')

        resetForm()
        handleClose()
      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao salvar o registro.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Nome '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <input
                placeholder=''
                type='text'
                name='name'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={formik.isSubmitting || isLoading}
                defaultValue={formik?.values['name']}
                onChange={formik.handleChange}
              />
            </InputFieldSet>
          </div>
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'E-Mail '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <input
                placeholder=''
                type='text'
                name='email'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                disabled={user?.id ? true : false}
                defaultValue={formik?.values['email']}
                onChange={formik.handleChange}
              />
            </InputFieldSet>
          </div>
          {!user?.id ? (
            <div className='fv-row mb-7'>
              <InputFieldSet
                label={
                  <>
                    {'Senha '}{' '}
                    <i
                      className='fa-solid fa-asterisk'
                      style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                    ></i>
                  </>
                }
              >
                <input
                  placeholder=''
                  type='password'
                  name='password'
                  className={'form-control fieldset-input'}
                  autoComplete='off'
                  style={{paddingRight: '3.5rem'}}
                  defaultValue={formik?.values['password']}
                  onChange={formik.handleChange}
                />
              </InputFieldSet>
            </div>
          ) : null}
          <div className='fv-row mb-7'>
            <InputFieldSet label={<>{'ID Externo '}</>}>
              <input
                placeholder=''
                type='text'
                name='external_id'
                className={'form-control fieldset-input'}
                autoComplete='off'
                style={{paddingRight: '3.5rem'}}
                defaultValue={formik?.values['external_id']}
                onChange={formik.handleChange}
              />
            </InputFieldSet>
          </div>
          <div className='fv-row mb-7'>
            <InputFieldSet
              label={
                <>
                  {'Papel '}
                  <i
                    className='fa-solid fa-asterisk'
                    style={{color: 'red', fontSize: '0.65rem', verticalAlign: 'top'}}
                  ></i>
                </>
              }
            >
              <select
                name='role'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-control='select2'
                data-hide-search='true'
                className={
                  'form-control form-select form-select-sm form-select-white fieldset-input'
                }
                defaultValue={formik?.values['role']}
              >
                <option value=''>Selecionar</option>
                {ROLE_TYPES.map((tp) => (
                  <option value={tp.id} key={tp.id}>
                    {tp.name}
                  </option>
                ))}
              </select>
            </InputFieldSet>
          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet label={'Times'}>
              <SelectAsync
                menuPortalTarget={document.body}
                isMulti={true}
                placeholder={''}
                name='teams'
                loadOptions={debounceTeamsOptions}
                cacheOptions
                defaultOptions
                className='fieldset-input'
                onChange={(e: any) => {
                  formik.setFieldValue(`teams`, e)
                }}
                styles={customStyles}
                defaultValue={formik?.values['teams']}
                noOptionsMessage={() => 'Sem registros...Digite para buscar'}
              />
            </InputFieldSet>
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {UsersForm}
