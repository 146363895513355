import { useCustomFormEditor, useCustomFormEditorDispatch } from '../../core/CustomFormEditorContext'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik, FieldArray, Field } from 'formik'
import { InputFieldSet } from '../../../../../../_metronic/partials/inputs/InputFieldSet'
import { CUSTOM_FORM_TYPES } from '../../core/_models'
import Swal from 'sweetalert2'
import { useCustomFieldFormModalDispatch } from '../../../custom-fields/core/CustomFieldFormModalContext'

const FieldInputEditModal = (defaultInputs: any) => {

    const customFormEditor = useCustomFormEditor();
    const customFormEditorDispatch = useCustomFormEditorDispatch();

    const customFieldFormModalDispatch = useCustomFieldFormModalDispatch()

    const handleClose = () => {
        customFormEditorDispatch({
            type: 'close-edit-field-modal'
        })
    };

    if (!customFormEditor.fieldEdit?.field) return <></>;

    // console.log("customFormEditor.fieldEdit.field", customFormEditor.fieldEdit);

    let title = "";
    let subTitle = "";
    let flagRequired: any = null
    if (customFormEditor.fieldEdit.field.source == "defaultfield") {
        const matchingInput = defaultInputs.default.find((e: { name: any; required: boolean; }) => e.name == customFormEditor.fieldEdit.field?.name && e?.required === true);
        flagRequired = matchingInput ? matchingInput : null
    }
    if (customFormEditor.fieldEdit.field.type?.name) {
        title = customFormEditor.fieldEdit.field.type.name;
        subTitle = customFormEditor.fieldEdit.field.type.description;
    }
    else if (customFormEditor.fieldEdit.field.source == "defaultfield") {
        title = customFormEditor.fieldEdit.field.label;
        subTitle = "Campo padrão do sistema";
    }

    return <Modal
        size="lg"
        show={customFormEditor.fieldEdit.open}
        onHide={handleClose}
        centered
        scrollable
        dialogClassName={"modal-form-edit-field"}
    >
        <Modal.Body style={{ padding: "32px 48px 20px" }}>
            <div style={{ marginBottom: "2rem" }}>
                <div style={{ fontSize: "1.5rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}><strong>{title}</strong>
                    <div>
                        <span
                            key={'fieldtype'}
                            className='badge badge-square'
                            style={{
                                backgroundColor: "#b1b1b1a3",
                                fontSize: '0.85rem',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                color: "#FFFFFF"
                            }}
                        >
                            {customFormEditor.fieldEdit?.field?.source == "defaultfield" ? "Padrão" : (customFormEditor.fieldEdit?.field?.source == "customfield" ? "Customizado" : (customFormEditor.fieldEdit?.field?.source == "fieldtype" ? "Novo Customizado" : ""))}
                        </span> {customFormEditor.fieldEdit?.field?.source == "customfield" && customFormEditor.fieldEdit.field && <i className="fa-solid fa-eye" style={{ marginLeft: "5px", fontSize: "2rem", cursor: "pointer", border: "1px solid", borderRadius: "5px", padding: "4px" }} onClick={() => {
                            customFieldFormModalDispatch({
                                type: 'open-edit-modal',
                                data: customFormEditor.fieldEdit.field?.id
                            })
                        }}></i>}</div>



                </div>{subTitle}
            </div>
            <Formik
                initialValues={{
                    label: customFormEditor.fieldEdit?.field?.label ?? "",
                    required: customFormEditor.fieldEdit.field.required ? true : false,
                    options: customFormEditor.fieldEdit?.field?.options ? customFormEditor.fieldEdit?.field?.options : []
                }}
                validationSchema={null}
                onSubmit={async (valuesForm, { setSubmitting, resetForm }) => {

                    let values = Object.assign({}, valuesForm);

                    // console.log("input modal save", values);

                    if (!values.label || values.label.trim().length == 0) {
                        return Swal.fire({
                            title: 'Opss..',
                            text: "Informe o Título do campo.",
                            icon: 'error',
                            confirmButtonText: 'OK'
                        })
                    }

                    let isNew = customFormEditor.fieldEdit.field?.internalId ? false : true;

                    let options: any = [];
                    if (values.options && values.options.length > 0) {
                        for (let i = 0; i < values.options.length; i++) {
                            values.options[i].id = i + 1;
                        }
                        options = values.options;
                    }

                    let auxData: any = {
                        ...customFormEditor.fieldEdit.field,
                        formInternalId: customFormEditor.fieldEdit.field?.formInternalId,
                        id: customFormEditor.fieldEdit.field?.id,
                        internalId: customFormEditor.fieldEdit.field?.internalId ? customFormEditor.fieldEdit.field?.internalId : Date.now(),
                        source: customFormEditor.fieldEdit.field?.source,
                        type: customFormEditor.fieldEdit.field?.type,
                        name: customFormEditor.fieldEdit.field?.name,
                        description: customFormEditor.fieldEdit.field?.description, //Depois criar um campo de descrição para aparecer em tooltip
                        label: values.label.trim(),
                        required: flagRequired ? true : values.required,
                        options: options,
                    };

                    // console.log("values", JSON.stringify(customFormEditor.fieldEdit), JSON.stringify(valuesForm), auxData);

                    if (isNew) {
                        customFormEditorDispatch({
                            type: 'add-field',
                            data: auxData
                        })
                    }
                    else {

                        customFormEditorDispatch({
                            type: 'update-field',
                            data: auxData
                        })

                    }
                    resetForm();
                    handleClose();
                }}
            >
                {props => (
                    <>
                        <form id='edit_field' className='form' onSubmit={props.handleSubmit} noValidate style={{}}>

                            <div style={{ marginBottom: "8px" }}><strong>
                                Configurações do Campo neste Formulário
                            </strong>
                            </div>

                            <div>
                                <InputFieldSet
                                    label={"Título Personalizado do Campo"}
                                >
                                    <input
                                        placeholder=''
                                        {...props.getFieldProps('label')}
                                        type='text'
                                        name="label"
                                        className={'form-control fieldset-input'}
                                        autoComplete='off'
                                        style={{ paddingRight: "3.5rem" }}
                                        disabled={props.isSubmitting}
                                        autoFocus
                                    />
                                </InputFieldSet>

                                {customFormEditor.fieldEdit?.field?.source == "fieldtype" && ["radio", "select", 'checkbox'].includes(customFormEditor.fieldEdit.field?.type?.key) &&
                                    <div style={{ marginTop: "10px", marginBottom: "25px", color: "black" }}>
                                        <p>
                                            Opções do Campo
                                        </p>
                                        <FieldArray
                                            name="options"
                                            render={arrayHelpers => (
                                                <div>
                                                    {props.values.options && props.values.options.length > 0 && props.values.options.map((opt: any, optIndex: any) => (
                                                        <div key={optIndex} className='fv-row mb-4 d-flex align-items-center'>

                                                            <div style={{ marginRight: "8px" }}> {optIndex + 1}</div>
                                                            <Field
                                                                name={`options.${optIndex}.value`}
                                                                className={'form-control fieldset-input'}
                                                                style={{ paddingRight: "3.5rem" }}
                                                                type={"text"}
                                                            />
                                                            {customFormEditor.fieldEdit?.field?.source == "fieldtype" && (
                                                                <button
                                                                    type="button"
                                                                    className='btn btn-sm'
                                                                    style={{ paddingRight: "0px" }}
                                                                    onClick={() => arrayHelpers.remove(optIndex)}
                                                                >
                                                                    <i className="fa-solid fa-trash" style={{ fontSize: "1.2rem" }}></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}

                                                    {customFormEditor.fieldEdit?.field?.source == "fieldtype" && (
                                                        <a
                                                            href="#"
                                                            className=''
                                                            onClick={() => arrayHelpers.push('')}
                                                        >
                                                            <span className='indicator-label'>+ Adicionar Opção</span>
                                                        </a>
                                                    )}
                                                </div>

                                            )}
                                        />

                                    </div>
                                }

                                {customFormEditor.fieldEdit.field?.formType != CUSTOM_FORM_TYPES.COMPLEMENT.id &&
                                    <Form.Check type={"switch"} id={`required`} reverse style={{ textAlign: "left", marginTop: "25px" }}>
                                        <Form.Check.Label style={{ color: "black" }}>Campo é obrigatório</Form.Check.Label>
                                        <Form.Check.Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={flagRequired ? "true" : (props.values.required ? "true" : "false")}
                                            checked={flagRequired ? true : (props.values.required ? true : false)}
                                            disabled={flagRequired ? true : false}
                                            type={"checkbox"}
                                        />
                                    </Form.Check>
                                }

                            </div>

                        <hr style={{ marginTop: "30px" }}></hr>
                        <div style={{ textAlign: "right", marginTop: "20px" }}>

                            <button
                                type='reset'
                                onClick={handleClose}
                                className='btn btn-sm btn-wl-custom-link btn-wl-custom-hover-danger me-3'
                                disabled={props.isSubmitting}
                            >
                                Cancelar
                            </button>
                            <button
                                type='submit'
                                className='btn btn-sm btn-wl-custom-primary-collor'
                                disabled={props.isSubmitting || !props.isValid || !props.touched}
                                style={{ marginLeft: "20px", marginTop: "5px" }}
                            >
                                <span className='indicator-label'>Salvar</span>
                            </button>
                        </div>

                    </form>
         </>

                )}
            </Formik>
        </Modal.Body>
    </Modal>
}

export default FieldInputEditModal;