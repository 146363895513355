import {FC} from 'react'

type Props = {
  responsible?: any
}

const ResponsibleCell: FC<Props> = ({responsible}) => {

  let text = "";

  if(responsible != undefined)
  {
    text = responsible.name;
  }
  
  return <>{text}</>;
}

export {ResponsibleCell}
