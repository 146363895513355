import {ID, Response} from '../../../../../_metronic/helpers'
export type TerminationReason = {
  id?: ID
  name?: string
  status?: number
  type?: number
  funnels?: Array<any>
  funnelIds?: Array<any>
  subreasons?: Array<any>
}

export type TerminationReasonsQueryResponse = Response<Array<TerminationReason>>

export const initialData: TerminationReason = {
  name: '',
  status: 1,
  type: 2,
}

export const TERMINATION_REASON_TYPES = {
  WIN: 1, // tipo do funil = vendas
  LOST: 2, // tipo do funil = vendas
  DISCARD: 3, // tipo do funil = vendas
  SOLVED: 4, // tipo do funil = suporte
  UNSOLVED: 5, // tipo do funil = suporte
}
