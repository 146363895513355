import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useCustomFormFormModal, useCustomFormFormModalDispatch } from '../core/CustomFormFormModalContext'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import CustomFormsEditor from './CustomFormsEditor'

const CustomFormsEditorModal = () => {
  const systemFormFormModal = useCustomFormFormModal();
  const systemFormFormModalDispatch = useCustomFormFormModalDispatch()

  const {refetch} = useQueryResponse()

  const handleClose = () => {
    systemFormFormModalDispatch({
      type: 'close-modal'
    })
    refetch();
  };

  useEffect(() => {

    if (systemFormFormModal.open) {

      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [systemFormFormModal.open])


  if (!systemFormFormModal.open) return <></>

  return (<>

    <Modal
      size="xl"
      show={systemFormFormModal.open}
      onHide={handleClose}
      centered
      scrollable
      contentClassName={"modal-form-manage"}
    >
      <Modal.Header closeButton>
        <h3><strong>Configuração de Formulário Customizado</strong></h3>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px", overflowY: "hidden" }}>
        <CustomFormsEditor onClose={handleClose} formId={systemFormFormModal.formId}/>
      </Modal.Body>
    </Modal></>
  )
}

export { CustomFormsEditorModal }
