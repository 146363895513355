import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTIcon} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../modules/view/QueryRequestProvider'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {InputFieldSet} from '../../../../../_metronic/partials/inputs/InputFieldSet'
import {Dropdown} from 'react-bootstrap'
import SelectAsync from 'react-select/async'
import {getUsers, getLabels} from '../../core/_requests'
import debounce from 'debounce-promise'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
type IformData = {
  cnpj: string
  responsible: any
  label: any
  phone: string
  cel: string
  email: string
}
const customStyles = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
}

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
`

const CompaniesListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const initialFormState: IformData = {
    cnpj: '',
    responsible: null,
    label: null,
    phone: '',
    cel: '',
    email: '',
  }
  const [form, setForm] = useState<IformData>(initialFormState)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setForm(initialFormState)
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {
        cnpj: form.cnpj,
        cel: form.cel,
        phone: form.phone,
        email: form.email,
        responsible: form.responsible?.value ? form.responsible.value : '',
        labelIds: form.label?.value ? form.label.value : '',
      },
      ...initialQueryState,
    })
  }

  const responsiblesOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getUsers('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps && resps.data != undefined) {
        for (let i = 0; i < resps?.data?.length; i++) {
          list.push({label: resps.data[i].name, value: resps.data[i].id})
        }
      }
      resolve(list)
    })
  const debounceResponsiblesOptions = debounce(responsiblesOptions, 500)

  const labelOptions = (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      let list: Array<any> = []
      let resps = await getLabels('page=1&perPage=20&search=' + encodeURIComponent(inputValue))
      if (resps) {
        for (let i = 0; i < resps?.length; i++) {
          list.push({label: resps[i].name, value: resps[i].id})
        }
      }
      resolve(list)
    })
  const debounceLabelOptions = debounce(labelOptions, 500)
  const CNPJMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ]
  const phoneMask = (rawValue: string) => {
    const nums = rawValue.replace(/[^\d]/g, '')
    return nums.length <= 10
      ? ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      : ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
  return (
    <>
      <Dropdown id='dropdown-true'>
        <StyledDropdownToggle
          variant='wl-custom-primary-collor-inverse'
          id='dropdown-basic'
          className='btn btn-sm me-3'
        >
          <i className='fa-solid fa-filter fs-2'></i>
          Filtros
        </StyledDropdownToggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
          <Dropdown.Header className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
          </Dropdown.Header>
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5' data-kt-user-table-filter='form'>
            <div className='mb-7'>
              <InputFieldSet label={'CNPJ'}>
                <MaskedInput
                  mask={CNPJMask}
                  placeholder=''
                  type='text'
                  name='cnpj'
                  value={form.cnpj}
                  guide={false}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      ['cnpj']: e.target.value,
                    }))
                  }
                  className='form-control fieldset-input'
                  disabled={isLoading}
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Responsável'}>
                <SelectAsync
                  placeholder={''}
                  name='responsible'
                  loadOptions={debounceResponsiblesOptions}
                  defaultOptions
                  isClearable
                  value={form.responsible}
                  onChange={(e) => {
                    setForm((prevState: any) => ({
                      ...prevState,
                      ['responsible']: e,
                    }))
                  }}
                  className='fieldset-input'
                  isDisabled={isLoading}
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Etiquetas'}>
                <SelectAsync
                  placeholder={''}
                  name='label'
                  loadOptions={debounceLabelOptions}
                  defaultOptions
                  isClearable
                  value={form.label}
                  onChange={(e) => {
                    setForm((prevState: any) => ({
                      ...prevState,
                      ['label']: e,
                    }))
                  }}
                  className='fieldset-input'
                  isDisabled={isLoading}
                  styles={customStyles}
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Telefone'}>
                <MaskedInput
                  mask={phoneMask}
                  placeholder=''
                  type='text'
                  name='phone'
                  value={form.phone}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      ['phone']: e.target.value,
                    }))
                  }
                  guide={false}
                  className='form-control fieldset-input'
                  disabled={isLoading}
                  autoComplete='off'
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'Celular'}>
                <MaskedInput
                  mask={phoneMask}
                  placeholder=''
                  type='text'
                  name='cel'
                  value={form.cel}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      ['cel']: e.target.value,
                    }))
                  }
                  guide={false}
                  className='form-control fieldset-input'
                  disabled={isLoading}
                  autoComplete='off'
                />
              </InputFieldSet>
            </div>
            <div className='mb-7'>
              <InputFieldSet label={'E-mail'}>
                <input
                  placeholder=''
                  type='text'
                  name='email'
                  value={form.email}
                  onChange={(e) =>
                    setForm((prevState) => ({
                      ...prevState,
                      ['email']: e.target.value,
                    }))
                  }
                  className='form-control fieldset-input'
                  disabled={isLoading}
                  autoComplete='off'
                />
              </InputFieldSet>
            </div>
          </div>

          <div className='d-flex justify-content-end p-3 mt-2'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
            >
              Limpar
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
            >
              Aplicar
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export {CompaniesListFilter}
