import { format } from "date-fns";

export function formatFieldInputDataToView(input: any, field: any) {

  try {
    if (Array.isArray(input)) {
      if (typeof input[0] === 'string') {
        return input.join(',');
      } else if (typeof input[0] === 'object' && 'name' in input[0]) {
        return input.map((obj) => obj.name).join(',');
      } else if (typeof input[0] === 'object' && 'label' in input[0]) {
        return input.map((obj) => obj.label).join(',');
      }
    } else if (field?.type?.key === 'datetime') {
      const date = new Date(input);

      if(!input) return 'Não informado';

      if (isNaN(date.getTime())) {
        return 'Não informado';
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const h = String(date.getHours()).padStart(2, '0');
      const m = String(date.getMinutes()).padStart(2, '0');
      const s = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year} ${h}:${m}:${s}`;
    } else if (field?.type?.key === 'date') {
      const date = new Date(input);
      if (isNaN(date.getTime())) {
        return 'Não informado';
      }
      return format(date, 'dd/MM/yyyy');
    }
     else if (field?.type?.key === 'time') {
      if(!input) return 'Não informado';
      return format(new Date(input), 'HH:mm');
    }
    else if (input && typeof input === 'object' && input.hasOwnProperty('label')) {
      return input.label;
    } else if (typeof input === 'string') {
      return input;
    } else if (typeof input === 'boolean') {
      return input ? 'Sim' : 'Não';
    } else if (field?.type?.key === 'monetary') {
      if (isNaN(parseFloat(input))) {
        return 'Não informado';
      }
      return (
        'R$ ' +
        new Intl.NumberFormat('pt-br', {
          style: 'decimal',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(input)
      );
    } else if (field?.type?.key === 'switch') {
      if (isNaN(Number(input))) {
        return 'Não informado';
      }
      if (input == 1) {
        return 'Sim';
      } else {
        return 'Não';
      }
    } else if (field?.type?.key === 'number') {
      if (isNaN(Number(input))) {
        return 'Não informado';
      }
      return input;
    }

    return 'Não informado'; 
  } catch (e) {
    console.log('Erro formatFieldInputDataToView', e);
    return 'Não informado';
  }
}