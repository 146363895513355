// @ts-nocheck
import { Column } from 'react-table'
import { ActionsCell } from './ActionsCell'
import { CustomHeader } from './CustomHeader'
import { Label } from '../../core/_models'
import { StatusCell } from './StatusCell'
import { IMPORT_STATUS, IMPORT_ENTITY, IMPORT_ENTITY_TYPE } from '../../core/_models'
const tableColumns: ReadonlyArray<Column<Label>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={true} />,
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].status} />,

  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Arquivo' className='min-w-125px' sort={false} />,
    id: 'filename',
    accessor: 'filename',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Data da importação' className='min-w-125px' sort={false} />,
    id: 'createdAt',
    Cell: ({ ...props }) => {
      return new Date(props.data[props.row.index].createdAt).toLocaleDateString("pt-BR", {
        hour: "2-digit",
        hour12: false,
        minute: "2-digit",
        second: "2-digit",
      })
    }
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Entidade' className='min-w-125px' sort={false} />,
    id: 'entity',
    Cell: ({ ...props }) => {
      let entity = props.data[props.row.index].entity;
      let entityText = "";

      switch (entity) {
        case IMPORT_ENTITY.COMPANY:
          entityText = "Empresa";
          break;
        case IMPORT_ENTITY.CONTACT:
          entityText = "Contato";
          break;
        case IMPORT_ENTITY.CARD:
          if(props.data[props.row.index].entityType == IMPORT_ENTITY_TYPE.OPPORTUNITY){
            entityText = "Oportunidade";
          }else{
            entityText = "Tícket";
          }
          
          break;
      }

      return entityText;
    }
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Importado por' className='min-w-125px' sort={false} />,
    id: 'userCreatedId',
    Cell: ({...props}) => props.data[props.row.index].userCreated?.name,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) =>{
      if(props.data[props.row.index].status ==IMPORT_STATUS.COMPLETED){
        return <ActionsCell entity={props.data[props.row.index]} />
      }
    } 
  },
]

export { tableColumns }
