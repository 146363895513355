import {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {getTasks, getNotes, createNote, deleteNote} from '../core/_requests'

import {Formik} from 'formik'
import Swal from 'sweetalert2'
import {InputFieldSet} from '../../../../_metronic/partials/inputs/InputFieldSet'

import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ptBRTimeStrings from 'react-timeago/lib/language-strings/pt-br'

import DetailMenuItemBadge from '../../../modules/view/MenuItemBadge'
import {useContactFormModal, useContactFormModalDispatch} from '../core/ContactFormModalContext'
import {getContactById} from '../core/_requests'

import {TASK_STATUS} from '../../tasks/core/_models'

import {useQueryClient} from '@tanstack/react-query'
import {ContactsForm} from './ContactsForm'
import {useNavigate} from 'react-router-dom'
import AnnotationComponent from '../../../../_metronic/helpers/annotation-component/Index'
import AttachmentComponent from '../../../../_metronic/helpers/attachament-component/Index'
import {getCustomForms, getCardCustomForm} from '../../settings/custom-forms/core/_requests'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import TaskComponent from '../../../../_metronic/helpers/task-component/Index'
import {CARD_STATUS} from '../../cards/core/_models'
import {getCards} from '../../cards/core/_requests'
import {FilterContactCardsDropdown} from './FilterContactCardsDropdown'
import {FilterContactTicketsDropdown} from './FilterContactTicketsDropdown'

const DetailMenu = ({contact}: {contact: any}) => {
  const [item, setItem] = useState<string>('1')

  return (
    <div className='d-flex flex-column'>
      <div className='mb-5'>
        <DetailMenuItemBadge item={'1'} activeItem={item} text='Anotações' onSelect={setItem} />
        <DetailMenuItemBadge item={'2'} activeItem={item} text='Tarefas' onSelect={setItem} />
        <DetailMenuItemBadge item={'3'} activeItem={item} text='Anexos' onSelect={setItem} />
      </div>
      <div className=''>
        {item == '1' ? <AnnotationComponent entityData={contact} entity='contact' /> : null}
        {item == '2' ? <TaskComponent entityData={contact} entity='contact' /> : null}
        {item == '3' ? <AttachmentComponent entityData={contact} entity='contact' /> : null}
      </div>
    </div>
  )
}

const ContactDetailModal = () => {
  const [cardsByContact, setCardsByContact] = useState<any>({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    has_more: false,
    data: [],
    filters: {
      status: null,
    },
  })
  const [ticketsByContact, setTicketsByContact] = useState<any>({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    has_more: false,
    data: [],
    filters: {
      status: null,
    },
  })
  const contactFormModal = useContactFormModal()
  const contactFormModalDispatch = useContactFormModalDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    contactFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/contacts')
  }

  // console.log('contactFormModal', contactFormModal)

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['contacts', 'detail', contactFormModal.contactId],
    queryFn: () => {
      console.log('queryFn getContactById')
      return getContactById(contactFormModal.contactId)
    },
    enabled:
      contactFormModal.open &&
      contactFormModal.form_action == 'detail' &&
      contactFormModal.contactId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'initial-custom-forms', contactFormModal.contactId],
    queryFn: () => {
      return getCardCustomForm('contact', null, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled:
      contactFormModal.open &&
      contactFormModal.form_action == 'detail' &&
      contactFormModal.contactId &&
      data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['contacts', 'detail', 'complement-custom-forms', contactFormModal.contactId],
    queryFn: async () => {
      let auxData = await getCustomForms(`entity=contact&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}`) //Complemento

      return auxData.data
    },
    enabled:
      contactFormModal.open &&
      contactFormModal.form_action == 'detail' &&
      contactFormModal.contactId &&
      data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadingDescriptionCards = (status: any) => {
    let label = ''
    switch (status) {
      case CARD_STATUS.WIN.id:
        label = CARD_STATUS.WIN.label
        break
      case CARD_STATUS.LOST.id:
        label = CARD_STATUS.LOST.label
        break
      case CARD_STATUS.SOLVE.id:
        label = CARD_STATUS.SOLVE.label
        break
      case CARD_STATUS.UNSOLVE.id:
        label = CARD_STATUS.UNSOLVE.label
        break
      case CARD_STATUS.FREEZE.id:
        label = CARD_STATUS.FREEZE.label
        break
      case CARD_STATUS.ARCHIVE.id:
        label = CARD_STATUS.ARCHIVE.label
        break
      default:
        label = CARD_STATUS.OPEN.label
        break
    }

    return label
  }

  const loadingTicketsByContact = async (page = 1, limit = 10, status = null) => {
    let hasMore = false
    let resultData: any = [...ticketsByContact?.data]
    let statusInputed = null
    try {
      let query = `contactId=${contactFormModal?.contactId}&page=${page}&perPage=${limit}`

      if (ticketsByContact?.filters?.status?.value || status) {
        statusInputed = status ? status : ticketsByContact?.filters?.status?.value
        query = query + `&status=${statusInputed}`
      }

      let data: any = await getCards(2, query)
      if (data?.data?.length > 0) {
        if (!statusInputed) {
          resultData = resultData.concat(data?.data)
        } else {
          resultData = data?.data
        }
      } else if (statusInputed) {
        resultData = []
      }

      if (page < data?.pagination?.pagesCount) {
        hasMore = true
      }

      let uniqueIds = new Set()
      let uniqueData = resultData.filter((item: any) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id)
          return true
        }
        return false
      })

      setTicketsByContact({
        total_items: data?.pagination?.total,
        total_pages: data?.pagination?.pagesCount,
        current_page: data?.pagination?.page,
        data: uniqueData,
        has_more: hasMore,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const loadingCardsByContact = async (page = 1, limit = 10, status = null) => {
    let hasMore = false
    let resultData: any = [...cardsByContact?.data]
    let statusInputed = null
    try {
      let query = `contactId=${contactFormModal?.contactId}&page=${page}&perPage=${limit}`

      if (cardsByContact?.filters?.status?.value || status) {
        statusInputed = status ? status : cardsByContact?.filters?.status?.value
        query = query + `&status=${statusInputed}`
      }

      let data: any = await getCards(1, query)
      if (data?.data?.length > 0) {
        if (!statusInputed) {
          resultData = resultData.concat(data?.data)
        } else {
          resultData = data?.data
        }
      } else if (statusInputed) {
        resultData = []
      }

      if (page < data?.pagination?.pagesCount) {
        hasMore = true
      }

      let uniqueIds = new Set()
      let uniqueData = resultData.filter((item: any) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id)
          return true
        }
        return false
      })

      setCardsByContact({
        total_items: data?.pagination?.total,
        total_pages: data?.pagination?.pagesCount,
        current_page: data?.pagination?.page,
        data: uniqueData,
        has_more: hasMore,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (contactFormModal.open && contactFormModal.form_action == 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [contactFormModal.open])

  useEffect(() => {
    //limpando state anterior
    setCardsByContact({
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      has_more: false,
      data: [],
      filters: {
        status: null,
      },
    })
    setTicketsByContact({
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      has_more: false,
      data: [],
      filters: {
        status: null,
      },
    })
  }, [contactFormModal.open])

  if (isError || !data) return <></>

  return (
    <>
      <Modal
        size='xl'
        show={contactFormModal.open && contactFormModal.form_action == 'detail'}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
      >
        <Modal.Header closeButton>
          <h1 style={{fontWeight: '700', color: '#606161'}}>Contato #{data.id}</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{borderRight: '1px #e1e3ea solid', paddingRight: '1.5rem'}}>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail'>
                    Detalhes{' '}
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    loadingCardsByContact()
                  }}
                >
                  <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_cards'>
                    Oportunidades
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    loadingTicketsByContact()
                  }}
                >
                  <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_tickets'>
                    Tickets
                  </a>
                </li>
                {data?.company ? (
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_company'>
                      Empresa
                    </a>
                  </li>
                ) : null}

                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href={'#contact_tab_pane_complement' + form.id}
                      >
                        {form.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <div className='tab-content'>
                {initialCustomForm?.fields?.length > 0 ? (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <ContactsForm
                      customFormId={initialCustomForm?.id}
                      isLoading={false}
                      contact={data}
                    />{' '}
                    <br />
                    {data.createdAt ? `Criado em ${new Date(data.createdAt).toLocaleString()}` : ''}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <ContactsForm isLoading={false} contact={data} />
                    <br />
                    {data.createdAt ? `Criado em ${new Date(data.createdAt).toLocaleString()}` : ''}
                  </div>
                )}

                {/* <div
                  className="tab-pane fade show active overflow-hidden"
                  id="card_tab_pane_detail"
                  role="tabpanel"
                >
                  <ContactsForm isLoading={false} contact={data} />
                  <br />
                  {data.createdAt ? `Criado em ${new Date(data.createdAt).toLocaleString()}` : ""}
                </div> */}
                <div className='tab-pane fade show' id='card_tab_pane_company' role='tabpanel'>
                  {data.company && (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{data.company?.name}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/companies/details/${data.company.id}`)
                          }}
                        ></i>
                      </div>
                      {data.company.corporateName ? (
                        <>
                          {`Razão Social: ${data.company.corporateName}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data.company.cnpj ? (
                        <>
                          {`CNPJ: ${data.company.cnpj}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      {data.company.createdAt ? (
                        <>
                          {`Criado em ${new Date(data.company.createdAt).toLocaleString()}`}
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
                <div className='tab-pane fade show' id='card_tab_pane_cards' role='tabpanel'>
                  <Row>
                    <Col sm='6'>{/* <span>Oportunidades</span> */}</Col>
                    <Col sm='6' align='end'>
                      <FilterContactCardsDropdown
                        cardsByContact={cardsByContact}
                        setCardsByContact={setCardsByContact}
                        loadingCardsByContact={loadingCardsByContact}
                      />
                    </Col>
                  </Row>
                  {cardsByContact?.data?.length <= 0 ? (
                    <div>
                      <span className='mt-2'>Sem oportunidades até o momento.</span>
                    </div>
                  ) : null}
                  {cardsByContact?.data?.map((item: any, index: number) => (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{item?.title}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/opportunities/details/${item?.id}`)
                          }}
                        ></i>
                      </div>

                      <>
                        {`Funil: ${item?.funnel?.name}`}
                        <br />
                      </>

                      {/* {data.company.cnpj ? (
                          <>
                            {`CNPJ: ${data.company.cnpj}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )} */}
                      <div style={{display: 'flex', flexFlow: 'wrap'}}>
                        <div>
                          {' '}
                          {item?.createdAt ? (
                            <>
                              {`Criado em ${new Date(item?.createdAt).toLocaleString()}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            color: 'var(--wl-text-title-primary-collor)',
                            fontWeight: 'bold',
                          }}
                        >
                          {loadingDescriptionCards(item?.status)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {cardsByContact?.has_more ? (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                      <button
                        className='btn btn-sm btn-wl-custom-primary-collor'
                        onClick={() => {
                          loadingCardsByContact(cardsByContact?.current_page + 1)
                        }}
                      >
                        Carregar Mais Oportunidades
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className='tab-pane fade show' id='card_tab_pane_tickets' role='tabpanel'>
                  <Row>
                    <Col sm='6'>{/* <span>Tickets</span> */}</Col>
                    <Col sm='6' align='end'>
                      <FilterContactTicketsDropdown
                        ticketsByContact={ticketsByContact}
                        setTicketsByContact={setTicketsByContact}
                        loadingTicketsByContact={loadingTicketsByContact}
                      />
                    </Col>
                  </Row>
                  {ticketsByContact?.data?.length <= 0 ? (
                    <div>
                      <span className='mt-2'>Sem tickets até o momento.</span>
                    </div>
                  ) : null}
                  {ticketsByContact?.data?.map((item: any, index: number) => (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{item?.title}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/tickets/details/${item?.id}`)
                          }}
                        ></i>
                      </div>

                      <>
                        {`Funil: ${item?.funnel?.name}`}
                        <br />
                      </>

                      {/* {data.company.cnpj ? (
                          <>
                            {`CNPJ: ${data.company.cnpj}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )} */}
                      <div style={{display: 'flex', flexFlow: 'wrap'}}>
                        <div>
                          {' '}
                          {item?.createdAt ? (
                            <>
                              {`Criado em ${new Date(item?.createdAt).toLocaleString()}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            color: 'var(--wl-text-title-primary-collor)',
                            fontWeight: 'bold',
                          }}
                        >
                          {loadingDescriptionCards(item?.status)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {ticketsByContact?.has_more ? (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                      <button
                        className='btn btn-sm btn-wl-custom-primary-collor'
                        onClick={() => {
                          loadingTicketsByContact(ticketsByContact?.current_page + 1)
                        }}
                      >
                        Carregar Mais Tickets
                      </button>
                    </div>
                  ) : null}
                </div>
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <div
                      className={'tab-pane fade show'}
                      id={'contact_tab_pane_complement' + form.id}
                      role='tabpanel'
                      key={index}
                    >
                      <>{form.name}</>
                      <ContactsForm isLoading={false} customFormId={form.id} contact={data} />
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col style={{paddingLeft: '1.5rem'}}>
              <DetailMenu contact={data} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {ContactDetailModal}
