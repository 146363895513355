import { createContext, useContext, useReducer } from 'react';

export interface CardMarkModalContext {
  open: boolean,
  opportunity: any,
  status:any
}

const initialData = {
  open: false,
  opportunity: null,
  status: null
};

const CardMarkModalContext = createContext<CardMarkModalContext>(initialData);
const CardMarkModalDispatchContext = createContext<any>(null);

export function CardMarkModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    cardMarkReducer,
    initialData
  );

  return (
    <CardMarkModalContext.Provider value={data}>
      <CardMarkModalDispatchContext.Provider value={dispatch}>
        {children}
      </CardMarkModalDispatchContext.Provider>
  </CardMarkModalContext.Provider>
  );
}

export function useCardMarkModal() {
  return useContext(CardMarkModalContext);
}

export function useCardMarkModalDispatch() {
  return useContext(CardMarkModalDispatchContext);
}

function cardMarkReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-modal': {
      return { ...data, open: true, opportunity: action.data, status: action.status }
    }
    case 'close-modal': {
      return { ...data, open: false, opportunity: null, status: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
