// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Company} from '../../core/_models'

const tableColumns: ReadonlyArray<Column<Company>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'name',
    accessor: 'name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Razão Social' className='min-w-125px' sort={false} />
    ),
    id: 'corporateName',
    accessor: 'corporateName',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='CNPJ' className='min-w-125px' sort={false} />
    ),
    id: 'cnpj',
    accessor: 'cnpj',
  }, 
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {tableColumns}
