/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, KTSVG, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../../../../../modules/view/ListViewProvider'
import { useQueryResponse } from '../../../../../modules/view/QueryResponseProvider'
import { useImportModalDispatch } from '../../core/ImportModalContext'
import Swal from 'sweetalert2'
import { undoneImports } from '../../core/_requests'
import { IMPORT_STATUS, Import } from '../../core/_models'
type Props = {
  entity: any
}


const ActionsCell: FC<Props> = ( {entity} ) => {
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


 

  
  const confirmUndoneImport = () => {
    Swal.fire({
      title: 'Desfazer Importação',
      text: 'Tem certeza que deseja desfazer esta importação? Esta ação é irreversível.',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        undoneImport(entity.id)
      }
    })
  }

  const undoneImport = async (idImport: any) => {
    try {
      await undoneImports(idImport);
      // Swal.fire('Tarefa Concluída!', '', 'success')
      refetch()
    }
    catch (e: any) {
      console.log(e);
      let errorDetected = ''
      if (e.response?.data?.key && e.response?.data?.message) {
        errorDetected = e.response?.data?.message
      }
      Swal.fire('Opss', 'Falha ao Desfazer a importação. ' + errorDetected, 'error')
    }
  }
  
  return (
    <>
    
    
      <a
        href='#'
        className=''
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen053.svg'
          className='svg-icon svg-icon-2x'
        />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-125px'
        data-kt-menu='true'
      >


        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'

            onClick={confirmUndoneImport}
          >
            Desfazer
          </a>
        </div>
       


      </div>
    
      
    </>
  )
}

export { ActionsCell }
