import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CustomForm, CustomFormsQueryResponse} from './_models'
import {request} from "../../../../services/crm-api/request"

const getCustomForms = async (query: string):  Promise<CustomFormsQueryResponse> => {

  return request({
    method: "GET",
    url: `/forms?${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<CustomFormsQueryResponse>) => d.data);
}

const getCustomFormsEditor = async (query: string):  Promise<CustomFormsQueryResponse> => {

  return request({
    method: "GET",
    url: `/forms?onlyEditorForms=true&${query}`
  },{
    setAuth: true
  })
  .then((d: AxiosResponse<CustomFormsQueryResponse>) => d.data);
}

const getCustomFormById = async (id: ID): Promise<any | undefined> => {
  
  return request({
    method: "GET",
    url: `/forms/${id}`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const getCustomFormByEntity = async (entity: string, type = 1): Promise<any | undefined> => {

  return request({
    method: "GET",
    url: `/forms/details?entity=${entity}&type=${type}`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const getCardCustomForm = async (entity: string, funnel: any, type = 1): Promise<any | undefined> => {

  let url = `/forms/details?entity=${entity}&type=${type}`
  if(funnel)
  {
    url = url + `&funnelId=${funnel}`
  }
//com funnel nao precisa filtrar entityType
  return request({
    method: "GET",
    url:url
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const createCustomForm = (dataCreate: CustomForm): Promise<CustomForm | undefined> => {

  let data = {...dataCreate};

  return request({
    method: "post",
    url: `/forms`,
    data: data
  },{
    setAuth: true
  })
    .then((response: Response<CustomForm>) => response.data)
}

const updateCustomForm = (dataUpdate: CustomForm): Promise<CustomForm | undefined> => {

  let data = {...dataUpdate};

  return request({
    method: "patch",
    url: `/forms/${dataUpdate.id}`,
    data: data
  },{
    setAuth: true
  })
    .then((response: Response<CustomForm>) => response.data)
}

const deleteCustomForm = (id: ID): Promise<CustomForm | undefined> => {

  return request({
    method: "DELETE",
    url: `/forms/${id}`
  },{
    setAuth: true
  })
  .then((response: any) => response.data)
}

const getFieldtypes = async ():  Promise<any> => {

  return request({
    method: "GET",
    url: `/forms/field-types`
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const getDefaultFields = async (entity: string):  Promise<any> => {

  return request({
    method: "GET",
    url: `/forms/default-fields?entity=`+entity
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

const getCustomFields = async (entity: string, entityType? : number):  Promise<any> => {

  return request({
    method: "GET",
    url: `/forms/custom-fields?page=1&perPage=200&entity=`+entity+(entityType ? "&entityType="+entityType : "")
  },{
    setAuth: true
  })
  .then((d: any) => d.data.data);
}

const createCustomFields = async (dataCreate: any): Promise<any | undefined> => {

  return request({
    method: "post",
    url: `/forms/custom-fields`,
    data: dataCreate
  },{
    setAuth: true
  })
  .then((d: any) => d.data);
}

export {getCustomForms, deleteCustomForm, updateCustomForm, createCustomForm, getCustomFormById, getFieldtypes, getDefaultFields, getCustomFields, createCustomFields, getCustomFormByEntity, getCardCustomForm, getCustomFormsEditor}
