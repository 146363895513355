/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { CustomFormsHeader } from './components/header/CustomFormsHeader'
import { CustomFormsTable } from './table/CustomFormsTable'
import { CustomFormsEditorModal } from './manage-modal/CustomFormsEditorModal'
import {getCustomFormsEditor} from './core/_requests'
import { CustomFormModalProvider } from './core/CustomFormFormModalContext'
import { CustomFormEditorProvider } from './core/CustomFormEditorContext'

const CustomFormsPage = () => {
  return (
    <>
      <CustomFormsHeader />
      <KTCard>
        <CustomFormsTable />
      </KTCard>
      <CustomFormsEditorModal />
    </>
  )
}


const CustomForms: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getCustomFormsEditor}>
          <ListViewProvider>
            <CustomFormModalProvider>
                <CustomFormEditorProvider>
                <CustomFormsPage />
                </CustomFormEditorProvider>
            </CustomFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { CustomForms }
