import { createContext, useContext, useReducer } from 'react';

export interface ContactFormModalContextModel {
  open: boolean
  form_action: string | null
  contactId: number | null
}

const initialData = {
  open: false,
  contactId: null,
  form_action: null
};

const ContactFormModalContext = createContext<ContactFormModalContextModel>(initialData);
const ContactFormModalDispatchContext = createContext<any>(null);

export function ContactFormModalProvider({ children }: any) {
    
  const [data, dispatch] = useReducer(
    contactFormModalReducer,
    initialData
  );

  return (
    <ContactFormModalContext.Provider value={data}>
      <ContactFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </ContactFormModalDispatchContext.Provider>
  </ContactFormModalContext.Provider>
  );
}

export function useContactFormModal() {
  return useContext(ContactFormModalContext);
}

export function useContactFormModalDispatch() {
  return useContext(ContactFormModalDispatchContext);
}

function contactFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return { ...data, open: true, form_action: "add" }
    }
    case 'open-edit-modal': {
      return { ...data, open: true, contactId: action.data, form_action: "edit" }
    }
    case 'open-detail-modal': {
      return { ...data, open: true, contactId: action.data, form_action: "detail" }
    }
    case 'close-modal': {
      return { ...data, open: false, contactId: null, form_action: null }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
