import {ID, Response} from '../../../../_metronic/helpers'
export type Task = {
  id?: ID
  title?: string
  date?: Date
  card?: any
  cardId?: any
  contact?: any
  contactId?: any
  company?: any
  companyId?: any
  responsible?: any
  responsibleId?: any
  observation?: string
  status?: number
  type?: any
  customFields?:any[]
  subType?:any
  showSubType?:boolean
}

export const TASK_STATUS = {
  SCHEDULED: 1,
  LATE: 2,
  COMPLETED: 3,
  CANCELED: 4,
};

export const TASK_TYPES = [
  {
    id: 1,
    value: 'Tarefa',
  },
  {
    id: 2,
    value: 'E-mail',
  },
  {
    id: 3,
    value: 'Ligação',
  },
  {
    id: 4,
    value: 'Reunião',
  },
  {
    id: 5,
    value: 'Almoço',
  },
  {
    id: 6,
    value: 'Whatsapp',
  }
];

export type TasksQueryResponse = Response<Array<Task>>

export const initialTask: Task = {

}
