/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { ListViewProvider, useListView } from '../../modules/view/ListViewProvider'
import { KTCard } from '../../../_metronic/helpers'
import { QueryRequestProvider } from '../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../modules/view/QueryResponseProvider'
import { TasksListHeader } from './components/header/TasksListHeader'
import { TasksTable } from './table/TasksTable'
import { TaskFormModal } from './edit-modal/TaskFormModal'
import {getTasks} from './core/_requests'
import {TaskFormModalProvider, useTaskFormModalDispatch} from './core/TaskFormModalContext'
import {useLocation} from 'react-router-dom'

const TasksPage = () => {

  const taskFormModalDispatch = useTaskFormModalDispatch();
  const location = useLocation()
  
  //Analisar URL
  useEffect(() => {
    const regex = /^\/tasks\/(details|edit)\/([^/]+)$/
    const match = location?.pathname?.match(regex)

    if (match) {
      const action = match[1] === 'details' ? 'open-detail-modal' :  match[1] === 'edit' ? 'open-edit-modal' : ''
      const parameter = match[2]
      if (parameter && parameter !== '') {
        taskFormModalDispatch({
          type: action,
          data: parameter,
        })
      }
    }
  }, [location?.pathname])

  return (
    <>
      <TasksListHeader />
      <KTCard>
        <TasksTable />
      </KTCard>
      <TaskFormModal />
    </>
  )
}


const Tasks: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getTasks}>
          <ListViewProvider>
            <TaskFormModalProvider>
              <TasksPage />
            </TaskFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { Tasks }
