

import { Card, Col, Dropdown, Row, Table } from "react-bootstrap"
import { InputFieldSet } from "../../../../../_metronic/partials/inputs/InputFieldSet"
import { useFunnel, useFunnelDispatch } from "../../../funnel/core/FunnelContext"
import styled from "styled-components";
import { KTCard } from "../../../../../_metronic/helpers";
type Props = {
    step: string,
    cards: number,
    open: number,
    close: number,
    discard: number,
    index: number,
    total: number
}
const tableData: any[] = [
    {  name: 'Categoria 1', valor: 1, },
    {  name: 'Categoria 2', valor: 2, },
    {  name: 'Categorai 3', valor: 1, },
  





];

const DashboardCategory = ({ data }: any) => {
    return (

        <Card style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', height:'210px', paddingTop:10, paddingBottom:10}}>
            <div style={{
                position: "relative",
                height: "210px",
                overflow: "auto",
                paddingLeft: 25,
                paddingRight: 25
            }}>
                <Table hover>
                    <thead>
                        <tr>
                            <th><h4><strong>Categorias</strong></h4></th>
                            <th><h2 className="text-wl-custom-title-collor" style={{textAlign:'right' }}><strong>{data?.length}</strong></h2></th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((item:any, index:any) => (
                                <tr key={index}>
                                    <td>
                                        
                                            {item?.name}
                                       

                                    </td>

                                    <td align="right"><h3 className="text-wl-custom-title-collor"><strong>{item?.quantity}</strong></h3></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        Nenhum registro encontrado
                                    </div>
                                </td>
                                
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>


        </Card>
    )


}
export { DashboardCategory }
