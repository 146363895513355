import {useEffect} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useTaskFormModal, useTaskFormModalDispatch} from '../core/TaskFormModalContext'
import {getTaskById} from '../core/_requests'
import {TasksForm} from './TasksForm'
import {useNavigate} from 'react-router-dom'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import {useLocation} from 'react-router-dom'
const TaskFormModal = ({needRefetch}: any) => {
  const location = useLocation()
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  const taskFormModal = useTaskFormModal()
  const taskFormModalDispatch = useTaskFormModalDispatch()

  const handleClose = () => {
    taskFormModalDispatch({
      type: 'close-modal',
    })
    if (location?.pathname?.includes('tasks')) {
      navigate('/tasks')
      refetch()
    }
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['tasks', taskFormModal.taskId],
    queryFn: () => {
      return getTaskById(taskFormModal.taskId)
    },
    enabled:
      taskFormModal.open && taskFormModal.form_action != 'detail' && taskFormModal.taskId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (taskFormModal.open && taskFormModal.form_action != 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [taskFormModal.open])

  if (!taskFormModal.open) return null

  if (isError || (taskFormModal.taskId && !data) || taskFormModal.form_action == 'detail')
    return null

  return (
    <>
      <Modal
        show={taskFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={''}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{fontSize: '1.5rem', fontWeight: '700'}}>
            {taskFormModal.taskId ? 'Alterar ' : 'Adicionar '} Tarefa
          </div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <Row>
            <Col>
              <TasksForm
                isLoading={false}
                task={data}
                add_data={taskFormModal.entity_data}
                needRefetch={needRefetch}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {TaskFormModal}
