import { useListView } from '../../../../../modules/view/ListViewProvider'
import {CustomFormsSearchComponent} from './CustomFormsSearchComponent'
import {CustomFormsFilter} from './CustomFormsFilter'
import { useCustomFormFormModalDispatch } from '../../core/CustomFormFormModalContext'

const CustomFormsHeader = () => {

  const systemFormFormModalDispatch = useCustomFormFormModalDispatch()

  return (
    <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
      <div id="kt_app_toolbar_container" className="d-flex flex-stack container-fluid">

        <div id="kt_page_title" data-kt-swapper="true" data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className="page-title d-flex flex-wrap me-3 flex-row justify-content-center">
          <i className="fa-solid fa-filter fs-2 me-1"></i><h3><strong>Formulários Customizados</strong></h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>

        <CustomFormsSearchComponent />

        <CustomFormsFilter />
        
          <a
            href='#'
            onClick={() => {
              console.log("openn");
              systemFormFormModalDispatch({
                type: 'open-add-modal'
              })
            }}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export { CustomFormsHeader }
