import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {useNavigate} from 'react-router-dom'
import CardComponent from './Components/CardComponent'
import CompanyCard from './Components/CompanyCard'
import ContactCard from './Components/ContactCard'
const CustomDropdownOptions: React.FC<any> = ({
  show,
  data,
  leaveInput,
  setControlDropdownSearch,
}) => {
  const handleToggle = () => {
    if (!leaveInput) return
    setControlDropdownSearch({
      show: false,
      data: null,
      onBlurInput: false,
    })
  }

  const handleRenderCards = (item: any, index: number) => {
    let component = null

    if (item?.entity_type == 'contact') {
      if (index + 1 < data?.length) {
        component = (
          <>
            <ContactCard item={item} />
            <div
              style={{
                border: 'none',
                borderTop: '1px solid white',
                fontWeight: 'bold',
                marginTop: '5px',
              }}
            ></div>
          </>
        )
      } else {
        component = (
          <>
            <ContactCard item={item} />
          </>
        )
      }
    } else if (item?.entity_type == 'card') {
      if (index + 1 < data?.length) {
        component = (
          <>
            <CardComponent item={item} />
            <div
              style={{
                border: 'none',
                borderTop: '1px solid white',
                fontWeight: 'bold',
                marginTop: '5px',
              }}
            ></div>
          </>
        )
      } else {
        component = (
          <>
            <CardComponent item={item} />
          </>
        )
      }
    } else if (item?.entity_type == 'company') {
      if (index + 1 < data?.length) {
        component = (
          <>
            <CompanyCard item={item} />
            <div
              style={{
                border: 'none',
                borderTop: '1px solid white',
                fontWeight: 'bold',
                marginTop: '5px',
              }}
            ></div>
          </>
        )
      } else {
        component = (
          <>
            <CompanyCard item={item} />
          </>
        )
      }
    }

    return component
  }
  if (!show) return null
  return (
    <Dropdown show={show} onToggle={handleToggle}>
      <Dropdown.Menu
        style={{
          position: 'absolute',
          left: '-5px',
          minWidth: '525px',
          maxWidth: '100%',
          width: 'auto',
          backgroundColor: 'var(--wl-second)',
          cursor: 'pointer',
        }}
        className='mt-1'
      >
        {data?.length > 0 ? (
          data?.map((item: any, index: number) => (
            <div key={'row' + index}>{handleRenderCards(item, index)}</div>
          ))
        ) : (
          <strong className='' style={{color: "white", padding: '10px'}}>
            Nenhum resultado encontrado, digite no Mínimo 3 caracteres.
          </strong>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomDropdownOptions
