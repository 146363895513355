/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import {deleteContact} from '../../core/_requests'
import {useContactFormModalDispatch} from '../../core/ContactFormModalContext'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

type Props = {
  id: ID
}

const ActionsCell: FC<Props> = ({id}) => {
  const navigate = useNavigate()
  const {setItemIdForUpdate} = useListView()
  const {query, refetch} = useQueryResponse()
  const queryClient = useQueryClient()

  const contactFormModalDispatch = useContactFormModalDispatch()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    // contactFormModalDispatch({
    //   type: 'open-edit-modal',
    //   data: id
    // })
    navigate(`/contacts/edit/${id}`)
  }

  const openDetailModal = () => {
    // contactFormModalDispatch({
    //   type: 'open-detail-modal',
    //   data: id,
    // })
    navigate(`/contacts/details/${id}`)
  }

  const deleteItem = useMutation(() => deleteContact(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      refetch()
    },
  })

  const confirmDeleteCard = async (id: any) => 
  {
    Swal.fire({
      title: 'Excluir Registro',
      text: 'Tem certeza que deseja excluir o registro?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try{ 
          await deleteItem.mutateAsync()
        }catch(e:any){
          let errorDetected = ''
          if (e.response?.data?.key && e.response?.data?.message) {
            errorDetected = e.response?.data?.message
          }
          Swal.fire('Opss', 'Falha ao Excluir o registro. '+ errorDetected , 'error')
        }
      } else if (result.isDenied) {
        //Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }


  return (
    <>
      <a href='#' className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen053.svg' className='svg-icon svg-icon-2x' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 fw-bold fs-7 w-125px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Alterar
          </a>
        </div>
        {/* end::Menu item */}

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openDetailModal}>
            Detalhes
          </a>
        </div>

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            // data-kt-users-table-filter='delete_row'
            onClick={() => confirmDeleteCard(id)}
          >
            Excluir
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ActionsCell}
