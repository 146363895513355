// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Card} from '../../core/_models'
import {CompanyCell} from './CompanyCell'
import {ContactCell} from './ContactCell'
import {ResponsibleCell} from './ResponsibleCell'
import {StatusCell} from './StatusCell'

const tableColumns: ReadonlyArray<Column<Card>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Título' className='min-w-125px' />,
    id: 'title',
    accessor: 'title',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Empresa' className='min-w-125px' sort={false} />
    ),
    id: 'company',
    Cell: ({...props}) => <CompanyCell company={props.data[props.row.index].company} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Contato' className='min-w-125px' sort={false} />
    ),
    id: 'contact',
    Cell: ({...props}) => <ContactCell contact={props.data[props.row.index].contact} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Responsável' className='min-w-125px' sort={false} />
    ),
    id: 'responsible',
    Cell: ({...props}) => <ResponsibleCell responsible={props.data[props.row.index].responsible} />,
  },
  {
    Header: (props) => {
      // caso seja ticket, omitir

      return (
        <CustomHeader
          tableProps={props}
          title='Prev. Fechamento'
          className='min-w-125px'
          sort={false}
        />
      )
    },
    id: 'toCloseDate',
    Cell: ({...props}) => {
      return props.data[props.row.index].toCloseDate !== null
        ? new Date(props.data[props.row.index].toCloseDate).toLocaleDateString('pt-BR', {
            hour: '2-digit',
            hour12: false,
            minute: '2-digit',
            second: '2-digit',
          })
        : ''
    },
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={false} />
    ),
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].statusName ?? ''} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell id={props.data[props.row.index].id} type={props.data[props.row.index].type} />
    ),
  },
]

export {tableColumns}
