import {UsersListSearchComponent} from './UsersListSearchComponent'
import {UsersListFilter} from './UsersListFilter'
import {useUserFormModalDispatch} from '../../core/UserFormModalContext'
import {useNavigate} from 'react-router-dom'
import {useListView} from '../../../../../modules/view/ListViewProvider'
const UsersListHeader = () => {
  const navigate = useNavigate()
  const userFormModalDispatch = useUserFormModalDispatch()
  const openAddModal = () => {
    // userFormModalDispatch({
    //   type: 'open-add-modal',
    // })
    navigate('/users/add')
  }

  return (
    <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
      <div id='kt_app_toolbar_container' className='d-flex flex-stack container-fluid'>
        <div
          id='kt_page_title'
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap me-3 flex-row justify-content-center'
        >
          <i className='fa-solid fa-users fs-2 me-1'></i>
          <h3>
            <strong> Usuários</strong>
          </h3>
        </div>

        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <UsersListSearchComponent />

          <UsersListFilter />

          <a
            onClick={openAddModal}
            className='btn btn-sm fw-bold btn-wl-custom-primary-collor'
            style={{marginLeft: '3rem'}}
          >
            Adicionar
          </a>
        </div>
      </div>
    </div>
  )
}

export {UsersListHeader}
