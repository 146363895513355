/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {ListViewProvider, useListView} from '../../modules/view/ListViewProvider'
import {KTCard} from '../../../_metronic/helpers'

import {QueryRequestProvider} from '../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../modules/view/QueryResponseProvider'
import {CompaniesListHeader} from './components/header/CompaniesListHeader'
import {CompaniesTable} from './table/CompaniesTable'
import {CompaniesFormModal} from './edit-modal/CompaniesFormModal'
import {getCompanies} from './core/_requests'
import {CompaniesDetailModal} from './detail-modal/CompaniesDetailModal'
import {CompanyFormModalProvider} from './core/CompanyFormModalContext'
import {useLocation} from 'react-router-dom'
import {useCompanyFormModalDispatch, useCompanyFormModal} from './core/CompanyFormModalContext'

const CompaniesPage = () => {
  const location = useLocation()
  const companyFormModalDispatch = useCompanyFormModalDispatch()
  const companyFormModal = useCompanyFormModal();

  //Analisar URL
  useEffect(() => {
    const regex = /^\/companies\/(details|edit)\/([^/]+)$/
    const match = location?.pathname?.match(regex)

    if (match) {
      const action = match[1] === 'details' ? 'open-detail-modal' :  match[1] === 'edit' ? 'open-edit-modal' : ''
      const parameter = match[2]
      if (parameter && parameter !== '') {
        companyFormModalDispatch({
          type: action,
          data: parameter,
        })
      }
    }
    else if(companyFormModal.open)
    {
      companyFormModalDispatch({
        type: 'close-modal',
      })
    }

  }, [location?.pathname])

  return (
    <>
      <CompaniesListHeader />
      <KTCard>
        <CompaniesTable />
      </KTCard>
      <CompaniesFormModal />
      <CompaniesDetailModal />
    </>
  )
}

const Companies: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getCompanies}>
          <ListViewProvider>
            <CompanyFormModalProvider>
              <CompaniesPage />
            </CompanyFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {Companies}
