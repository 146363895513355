/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { ListViewProvider, useListView } from '../../../modules/view/ListViewProvider'
import { KTCard } from '../../../../_metronic/helpers'

import { QueryRequestProvider } from '../../../modules/view/QueryRequestProvider'
import { QueryResponseProvider } from '../../../modules/view/QueryResponseProvider'
import { FunnelsListHeader } from './components/header/FunnelsListHeader'
import { FunnelSettingsTable } from './table/FunnelSettingsTable'
import { FunnelSettingsEditModal } from './edit-modal/FunnelSettingsEditModal'
import {getFunnels} from './core/_requests'
import { FunnelSettingsModalProvider } from './core/FunnelSettingsModalContext'

const FunnelSettingsPage = () => {

  return (
    <>
      <FunnelsListHeader />
      <KTCard>
        <FunnelSettingsTable />
      </KTCard>
      <FunnelSettingsEditModal />
    </>
  )
}


const FunnelSettings: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getFunnels}>
          <ListViewProvider>
            <FunnelSettingsModalProvider>
            <FunnelSettingsPage />
            </FunnelSettingsModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { FunnelSettings }
