import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {CustomField} from '../core/_models'
import clsx from 'clsx'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {createCustomField, updateCustomField, getFieldtypes} from '../core/_requests'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'
import { useQuery } from '@tanstack/react-query'
import Creatable from 'react-select/creatable';
import { useCustomFieldFormModalDispatch } from '../core/CustomFieldFormModalContext'
import { CARD_TYPES} from '../../../cards/core/_models'

type Props = {
  isLoading: boolean
  customField: CustomField
}

const editDataSchema = Yup.object().shape({})

const CustomFieldsForm: FC<Props> = ({customField, isLoading}) => {
  const {refetch} = useQueryResponse()

  const customFieldFormModalDispatch = useCustomFieldFormModalDispatch();

  const mountOptionsToField = (options: Array<any>) => {
    let mountedOptions = [];
    for(let i = 0; i < options.length; i++)
    {
      let useOpf = options[i];
      mountedOptions.push({
        label: useOpf.value,
        value: useOpf.value
      })
    }

    return mountedOptions;
  }

  const [dataForEdit] = useState<CustomField>({
    label: customField ? customField.label : undefined,
    entity: customField ? (customField.entity == "card" ?  customField.entity+":"+customField.entityType : customField.entity) : undefined,
    type: customField ? customField.type?.id : undefined,
    description: customField ? customField.description : undefined,
    options: customField && customField.options ? mountOptionsToField(customField.options) : undefined
  })

  const { isError: fieldTypesIsError, data: fieldTypes, error: fieldTypesError } = useQuery({
    queryKey: ['custom-forms', 'field-types'],
    queryFn: () => {
      return getFieldtypes()
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const handleClose = () => {
    customFieldFormModalDispatch({
      type: 'close-modal'
    })
    refetch()
  };

  const needUseOptions = (type: any) => {
    if(!fieldTypes) return false;

    let findType = fieldTypes.find((e:any) => e.id == type);
    if(findType && findType.useOptions) return true;

    return false;
  }

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      let validErrorText = null;

      if(!values.label && !validErrorText) validErrorText= "Campo Nome é obrigatório."
      if(!values.type && !validErrorText) validErrorText= "Campo Tipo é obrigatório."
      if(!values.entity && !validErrorText) validErrorText= "Preencha o campo Pertence à entidade."
      if((!values.options || values.options.length == 0) && needUseOptions(values.type) &&!validErrorText) validErrorText= "Adicione pelo menos uma opção do campo customizado."
  
      if(validErrorText)
      {
        return Swal.fire({
          title: 'Opss..',
          text: validErrorText,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let auxOptions = [];
      if(values.options &&  values.options.length > 0)
      {
        for(let i = 0; i < values.options.length; i++)
        {
          let useOp = values.options[i];
          auxOptions.push({
            id: i+1,
            value: useOp.value
          });
        }
      }

      let entityType  = null;
      if(values.entity?.includes("card:"))
      {
        entityType = values.entity.replace("card:", "");
        values.entity = "card";

        if(entityType != undefined)
        {
          entityType = parseInt(entityType.toString());
        }
      }

      let auxData: any = {
        id: customField ? customField.id : undefined,
        label: values.label,
        type: values.type,
        entityType: entityType,
        entity: values.entity,
        description: values.description
      };

      if(auxOptions) auxData.options = auxOptions;

      try {
        if (isNotEmpty(auxData.id)) {
          await updateCustomField(auxData)
        } else {
          await createCustomField([auxData])
        }

        Swal.fire('Campo customizado foi Criado!', '', 'success')

        handleClose();
      } catch (ex : any) {
        console.error(ex)

        let errorDetected = ''
        if (ex.response?.data?.key && ex.response?.data?.message) {
          errorDetected = ex.response?.data?.message
        }

        Swal.fire({
          title: 'Opss..',
          text: errorDetected ? errorDetected : 'Houve um problema ao salvar o campo customizado.',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
          <InputFieldSet
            label={<>{"Nome do Campo "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
          >
      
              <input
                placeholder=''
                {...formik.getFieldProps('label')}
                type='text'
                name="label"
                className={clsx(
                  'form-control fieldset-input'
                )}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
          </InputFieldSet>

          </div>

          <div className='fv-row mb-7'>
            <InputFieldSet
              label={<>{"Pertence à Entidade "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
            >
        
            <select
                {...formik.getFieldProps('entity')}
                name='entity'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-sm form-select-white fieldset-input'
                defaultValue=''
                disabled={customField?.id ? true : false}
              >
                <option value=''>Selecione</option>
                <option value='contact'>Contato</option>
                <option value='company'>Empresa</option>
                <option value={`card:${CARD_TYPES.OPPORTUNITY.id}`}>Oportunidade</option>
                <option value={`card:${CARD_TYPES.TICKET.id}`}>Tícket</option>
              </select>
        
            </InputFieldSet>
            </div>

            <div className='fv-row mb-7'>
            <InputFieldSet
              label={<>{"Tipo "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
            >
        
            <select
                {...formik.getFieldProps('type')}
                name='type'
                data-control='select2'
                data-hide-search='true'
                className='form-control form-select form-select-sm form-select-white fieldset-input'
                defaultValue=''
                disabled={customField?.id ? true : false}
              >
                <option value=''>Selecione</option>
                {fieldTypes && fieldTypes.map((el: any) => {
                  return <option value={el.id}>{el.name}</option>
                })}
              </select>
        
            </InputFieldSet>
            </div>

            { needUseOptions(formik.values.type) &&  
            <div className='fv-row mb-7'>
            <InputFieldSet
              label={<>{"Opções "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
            >
            <Creatable
              {...formik.getFieldProps('options')}
              placeholder={"Digite aqui as opções e pressione enter"}
              name="options"
              onChange={(selectedOption:any)  => 
                formik.setFieldValue("options", selectedOption ? selectedOption : null)
              }
              formatCreateLabel={(text) => `Clique ou aperte enter para adicionar "${text}"`}
              isMulti
              isClearable
              className="fieldset-input"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderStyle: "none",
                  backgroundColor: undefined,
                  boxShadow: "none",
                })
              }}
          />
          </InputFieldSet>
          </div>}

          <div className='fv-row mb-7'>
          <InputFieldSet
            label={"Descrição"}
          >
              <textarea
                placeholder=''
                {...formik.getFieldProps('description')}
                name="description"
                className={ 'form-control fieldset-input'}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
          </InputFieldSet>
          </div>


          </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>

          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {CustomFieldsForm}
