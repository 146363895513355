import {FC} from 'react'

type Props = {
  emails?: Array<any>
}

const EmailCell: FC<Props> = ({emails}) => {

  let emailText = "";

  if(emails != undefined && emails.length > 0)
  {
    for(let i = 0; i < emails.length; i++)
    {
      if(i == 2) break;
      if(i == 1) emailText += ", ";
      emailText += emails[i].email;
    }
  }
  
  return <>{emailText}</>;
}

export {EmailCell}
