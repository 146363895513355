import React, { FC, useState } from 'react'
import { CustomForm, CUSTOM_FORM_TYPES } from '../core/_models'
import { getFieldtypes, getDefaultFields, getCustomFields, createCustomForm, updateCustomForm, createCustomFields, deleteCustomForm } from '../core/_requests'
import { useQuery } from '@tanstack/react-query'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FieldTypeDraggable from './CustomFormsFormComponents/FieldTypeDraggable'
import FieldInputEditModal from './CustomFormsFormComponents/FieldInputEditModal'
import FormDroppable from './CustomFormsFormComponents/FormDroppable'
import FieldTypeIcon from './CustomFormsFormComponents/FieldTypeIcon'
import { useCustomFormEditor, useCustomFormEditorDispatch } from '../core/CustomFormEditorContext'
import Swal from 'sweetalert2'
import { CARD_TYPES } from '../../../cards/core/_models'
import Dropdown from 'react-bootstrap/Dropdown';
import FormDetailEditModal from './CustomFormsFormComponents/FormDetailEditModal'
import Accordion from 'react-bootstrap/Accordion';
import { CustomFieldFormModalProvider } from '../../custom-fields/core/CustomFieldFormModalContext'
import { CustomFieldsEditModal } from '../../custom-fields/edit-modal/CustomFieldsEditModal'

type Props = {
  isLoading: boolean
  entity: string
  entityType?: number
  funnel: string
  onClose: any
}

const CustomFormsEditorWorkArea: FC<Props> = ({ isLoading, entity, entityType, funnel, onClose }) => {

  const customFormEditor = useCustomFormEditor();
  const customFormEditorDispatch = useCustomFormEditorDispatch();

  const { isError: fieldTypesIsError, data: fieldTypes, error: fieldTypesError } = useQuery({
    queryKey: ['custom-forms', 'field-types'],
    queryFn: () => {
      return getFieldtypes()
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const { isError: defaultSystemFieldsIsError, data: defaultSystemFields, error: defaultSystemFieldsError } = useQuery({
    queryKey: ['custom-forms', 'default-fields', entity, 'entityType', entityType],
    queryFn: async () => {
      let dfFields = await getDefaultFields(entity);
      let workDfFields: any = [];
      dfFields.forEach((e: any) => {
        if (e.cardTypes && !e.cardTypes.includes(entityType)) return;
        workDfFields.push(e);
      })
      return workDfFields;
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const { isError: customFieldsIsError, data: customFields, error: customFieldsError } = useQuery({
    queryKey: ['custom-forms', 'custom-fields', entity, 'entityType', entityType],
    queryFn: () => {
      return getCustomFields(entity, entityType)
    },
    enabled: true,
    cacheTime: 60 * 1000, //1 minutes
    staleTime: 60 * 1000, //1 minutes
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const checkCanDragInput = (item: any) => {
    let findInput = customFormEditor.forms.find((e: any) => e.fields.find((f: any) => f.id == item.id && f.source == item.source))

    if (findInput) return false;

    return true;
  }

  const confirmDeleteForm = (form: any) => {
    if (form.type != CUSTOM_FORM_TYPES.COMPLEMENT.id) return;

    Swal.fire({
      title: 'Excluir Formulário',
      text: `Tem certeza que deseja excluir o formulário ${form?.name} ?`,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteForm(form)
      }
    })
  }

  const deleteForm = async (form: any) => {
    let findForm = customFormEditor.forms.find((e: any) => e.internalId == form.internalId);

    if (!findForm) return;

    try {
      if (findForm.id) {
        await deleteCustomForm(findForm.id);
      }

      customFormEditorDispatch({
        type: 'remove-form',
        data: findForm
      })
    }
    catch (e) {
      console.error(e);
      Swal.fire({
        title: 'Opss..',
        text: "Houve um problema ao excluir o formulário.",
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }

  }


  const saveForm = async (formInternalId: any) => {
    let customFieldsCreated: Array<any> = [];
    let formData = customFormEditor.forms.find((e: any) => e.internalId == formInternalId);
    let editorData = customFormEditor;

    if (!formData) return Swal.fire({
      title: 'Opss..',
      text: "Houve um problema ao salvar o formulário. Formulário não Encontrado",
      icon: 'error',
      confirmButtonText: 'OK'
    })

    try {

      let dataFields = [...formData.fields];

      //console.log("dataFields", {...dataFields});

      let customFieldsToCreate = [];
      let saveFields = [];
      for (let i = 0; i < dataFields.length; i++) {
        let useField = dataFields[i];
        let field: any = {
          label: useField.label,
          description: useField.description ?? null,
          options: useField.options ?? [],
          required: useField.required,
          status: useField.status
        }
        switch (useField.source) {
          case "defaultfield":
            field.defaultId = useField.id;
            break;
          case "customfield":
            field.customId = useField.id;
            break;
        }
        if (!useField.id) {
          customFieldsToCreate.push({
            label: field.label,
            description: field.description,
            options: field.options,
            type: useField.type.id,
            entity: editorData.entity,
            entityType: editorData.entityType,
          });
        }
        saveFields.push(field);
      }

      if (customFieldsToCreate.length > 0) {
        customFieldsCreated = await createCustomFields(customFieldsToCreate);
      }

      if (customFieldsCreated && customFieldsCreated.length > 0) {
        for (let i = 0; i < saveFields.length; i++) {
          let useField = saveFields[i];
          if (!useField.defaultId && !useField.customId) {
            let customFC = customFieldsCreated.find((el: any) => el.label == useField.label);
            if (customFC) {
              useField.customId = customFC.id;
            }
          }
        }
      }

      let dataSave: any = {
        id: formData.id,
        entity: editorData.entity,
        entityType: editorData.entityType,
        name: formData.name,
        fields: saveFields,
        type: formData.type,
        funnelId: editorData.funnel,
      };

      let saved: any = null;
      if (formData.id) {
        saved = await updateCustomForm(dataSave);
        customFormEditorDispatch({
          type: "save-form",
          data: formData
        });
      }
      else {
        saved = await createCustomForm(dataSave);

        customFormEditorDispatch({
          type: "save-form",
          data: { ...formData, id: saved?.id }
        });
      }

      Swal.fire('Formulário salvo com sucesso!', '', 'success')

    }
    catch (e: any) {
      console.error(e);
      let errorText = "Houve um problema ao salvar o formulário."
      if (e.response?.data?.key && e.response?.data?.message) {
        errorText = e.response.data.message;
      }

      Swal.fire({
        title: 'Opss..',
        text: errorText,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
    finally {
      //Adicionar id dos campos criados ao context de fields do form. Dando certo ou errado o salvamento do formulário
      if (customFieldsCreated) {
        let dataFieldsForm = [...formData.fields];
        for (let i = 0; i < customFieldsCreated.length; i++) {
          let useCustomF = customFieldsCreated[i];
          let findField = dataFieldsForm.find(e => e.label == useCustomF.label && !e.id);

          if (findField) {
            findField.id = useCustomF.id;
            findField.source = "customfield";
            customFormEditorDispatch({
              type: 'update-field',
              data: findField,
              notChangeSaveState: true
            })
          }
        }
      }
    }
  }

  const loadFormTypeNames = () => {
    switch (entity) {
      case "contact":
        return "Contato";
      case "company":
        return "Empresa"
      case "card":
        switch (entityType) {
          case CARD_TYPES.OPPORTUNITY.id:
            return "Oportunidade";
          case CARD_TYPES.TICKET.id:
            return "Tícket";
        }
        return "Oportunidade/Tícket";
    }
  }

  return (
    <>
     <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6 custom-form-manager' style={{paddingLeft: "48px"}}>
            {customFormEditor.forms.map((e: any, i: number) => {
              return <li className='nav-item' key={i}>
              <a className={'nav-link'+(customFormEditor.activeForm==e.internalId?' active':'')} data-bs-toggle='tab' href={`#form_tab_${i}`} onClick={()=>{
                customFormEditorDispatch({
                  type: 'set-active-form',
                  data: e.internalId
                });
              }}>
                {e.type == CUSTOM_FORM_TYPES.INITIAL.id ? "Formulário Inicial" : e.name}{" "}{e.saved ? "" : <i className="fa-solid fa-asterisk" style={{color: "var(--wl-btn-background-primary-collor)", fontSize: "0.65rem", verticalAlign: "middle"}}></i>}
              </a>
              </li>
            })}
            {customFormEditor.forms.find((frm: any) => frm.type == CUSTOM_FORM_TYPES.INITIAL.id && frm.id) &&
            <li className='nav-item' onClick={() => {
                customFormEditorDispatch({
                  type: "open-edit-form-modal",
                  data: {
                    type: CUSTOM_FORM_TYPES.COMPLEMENT.id,
                    name: "Formulário Complementar",
                    fields: []
                  }
                });
            }}>
                  <a className='nav-link' style={{color: "var(--wl-btn-background-primary-collor)", cursor: "pointer"}}>
                    <i className="fa-regular fa-square-plus" style={{color: "var(--wl-btn-background-primary-collor)"}}></i> Formulário
                  </a>
            </li>}
    </ul>
      <DndProvider backend={HTML5Backend}>
        <div style={{ display: "flex", height: "100%" }}>

          <div style={{ backgroundColor: "", width: "280px", padding: "32px 28px", overflowY: "auto" }}>
            <p style={{ padding: "0px 18px" }}>Clique e arraste os campos para montar o formulário</p>
            <Accordion defaultActiveKey={[]} alwaysOpen flush>


              <Accordion.Item eventKey="0">
                <Accordion.Header><strong>Campos Padrões</strong></Accordion.Header>
                <Accordion.Body>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",

                  }}>
                    {defaultSystemFields && defaultSystemFields.length > 0 && defaultSystemFields.map((e: any, i: number) => {
                      return <FieldTypeDraggable canDrag={checkCanDragInput(e)} source={"defaultfield"} obj={e} key={i}><FieldTypeIcon type={e.type} /><div style={{ marginLeft: "8px" }}>{e.label}</div></FieldTypeDraggable>
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><strong>Campos Customizados</strong></Accordion.Header>
                <Accordion.Body>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",

                  }}>
                    {customFields && customFields.length > 0 && customFields.map((e: any, i: number) => {
                      return <FieldTypeDraggable canDrag={checkCanDragInput(e)} source={"customfield"} obj={e} key={i}><FieldTypeIcon type={e.type.key} /><div style={{
                        marginLeft: "8px",
                        wordBreak: "break-word",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        lineHeight: "1.2em",
                        maxHeight: "2.4em"
                      }}>{e.label}</div></FieldTypeDraggable>
                    })}
                  </div>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header><strong>Criar Campo</strong></Accordion.Header>
                <Accordion.Body>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",

                  }}>
                    {fieldTypes && fieldTypes.length > 0 && fieldTypes.map((e: any, i: number) => {
                      if (e.key == "connection") return <></>
                      return <FieldTypeDraggable source={"fieldtype"} obj={e} key={i}><FieldTypeIcon type={e.key} /><div style={{ marginLeft: "8px" }}>{e.name}</div></FieldTypeDraggable>
                    })}
                  </div>

                </Accordion.Body>
              </Accordion.Item>

            </Accordion>

            <div style={{ marginBottom: "40px" }}></div>

          </div>

          <div className='custom-form-wl-custom-collor' style={{flex: "1 0 auto", padding: "25px", overflowY: "auto"}}>
            <div className='tab-content' style={{ height: "100%"}}>

              {customFormEditor.forms.map((e: any, i: number) => {
                return <div
                  className={'tab-pane fade show' + (customFormEditor.activeForm == e.internalId ? ' active' : '')}
                  id={`#form_tab_${i}`}
                  role='tabpanel'
                  style={{ height: "100%" }}
                  key={i}
                >
                  <div style={{
                    margin: "0px auto",
                    backgroundColor: "#f5f6f7",
                    width: "100%",
                    maxWidth: "538px",
                    minHeight: "100%",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column"
                  }}>
                    <div className="form-mount-field-header" style={{ padding: "21px 21px 10px", borderBottom: "1px solid #e1e3ea" }}>
                      <h3><strong>{e.name ? e.name : "Formulário de " + loadFormTypeNames()}</strong></h3>
                      {e.type == CUSTOM_FORM_TYPES.COMPLEMENT.id &&
                        <Dropdown drop={"end"}>
                          <Dropdown.Toggle id={"dropdown-form-opt-" + e.internalId} as={React.forwardRef(({ children, onClick }: { children: any, onClick: any }, ref: any) => (
                            <button
                              ref={ref}
                              disabled={false}
                              type='button'
                              onClick={onClick}
                              className='btn btn-sm form-mount-field-btn-edit'
                            >
                              <i className='fa-solid fa-ellipsis-vertical form-mount-field-btn-edit-icon'></i>
                            </button>
                          ))}>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() =>
                              customFormEditorDispatch({
                                type: "open-edit-form-modal",
                                data: e
                              })
                            }>Editar</Dropdown.Item>
                            <Dropdown.Item onClick={() =>
                              confirmDeleteForm(e)
                            }>Excluir</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    {defaultSystemFields?.length > 0 && fieldTypes?.length > 0 ? <FormDroppable key={i} formIternalId={e.internalId} fieldTypes={fieldTypes} defaultRequiredFields={defaultSystemFields?.filter((e: any) => e?.required)} /> : null}
                  </div>
                  <a onClick={() => { saveForm(e.internalId) }} className="btn-floating-save">
                    <i className="fa-regular fa-floppy-disk btn-floating-save-icon"></i>
                  </a>
                </div>
              })}
            </div>

          </div>

        </div>
      </DndProvider>
      <CustomFieldFormModalProvider>
        <FieldInputEditModal default={defaultSystemFields} />
        <CustomFieldsEditModal />
      </CustomFieldFormModalProvider>
      <FormDetailEditModal />
    </>
  )
}

export { CustomFormsEditorWorkArea }