import {ID, Response} from '../../../../_metronic/helpers'
export type Card = {
  id?: ID
  title?: string
  companyId?: string
  company?: any
  contactId?: string
  contact?: any
  labels?: Array<any>
  status?: number
  observations?: string
  funnel?: any
  funnelId?: string
  funnelStep?: any
  funnelStepId?: string
  value?: number
  isRecurrentValue?: boolean
  toCloseDate?: any
  cardHistory?: Array<any>
  responsibleId?: any
  responsible?: any
  type?: number
  customFields?: Array<any>
  ticketCategory?: any
  createdAt?: any
  updatedAt?: any
  createdBy?: any
}

export type OpportunitiesQueryResponse = Response<Array<Card>>

export const initialData: Card = {}

export const CARD_TYPES = {
  OPPORTUNITY: {id: 1, label: 'Oportunidade'},
  TICKET: {id: 2, label: 'Tícket'},
}

export const CARD_STATUS = {
  OPEN: {id: 1, label: 'Aberto', cardType: [CARD_TYPES.OPPORTUNITY.id, CARD_TYPES.TICKET.id]},
  WIN: {id: 2, label: 'Ganho', cardType: [CARD_TYPES.OPPORTUNITY.id]},
  LOST: {id: 3, label: 'Perdido', cardType: [CARD_TYPES.OPPORTUNITY.id]},
  FREEZE: {id: 4, label: 'Congelado', cardType: [CARD_TYPES.OPPORTUNITY.id]},
  SOLVE: {id: 5, label: 'Resolvido', cardType: [CARD_TYPES.TICKET.id]},
  UNSOLVE: {id: 6, label: 'Não Resolvido', cardType: [CARD_TYPES.TICKET.id]},
  ARCHIVE: {id: 7, label: 'Arquivado', cardType: [CARD_TYPES.TICKET.id]},
  DISCARD: {id: 8, label: 'Descartado', cardType: [CARD_TYPES.TICKET.id]},
}
