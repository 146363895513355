import {useEffect} from 'react'
import { useFunnelSettingsModal, useFunnelSettingsModalDispatch } from '../core/FunnelSettingsModalContext'
import { getFunnelById} from '../core/_requests'
import { useQuery } from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FunnelSettingsForm} from './FunnelSettingsForm'

const FunnelSettingsEditModal = () => {

  const funnelSettingsModal = useFunnelSettingsModal();
  const funnelSettingsModalDispatch = useFunnelSettingsModalDispatch()

  const handleClose = () => {
    funnelSettingsModalDispatch({
      type: 'close-modal'
    })
  };

  console.log("funnelSettingsModal", funnelSettingsModal);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['funnels', 'settings', funnelSettingsModal.funnelId],
    queryFn: () => {
      return getFunnelById(funnelSettingsModal.funnelId);
    },
    enabled: funnelSettingsModal.funnelId ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {

    if (funnelSettingsModal.open) {

      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [funnelSettingsModal.open])


  if (isError || (funnelSettingsModal.funnelId && !data)) return <></>

  return (
    <>
      <Modal
      show={funnelSettingsModal.open}
      onHide={handleClose}
      centered
      scrollable
      contentClassName={""}
      dialogClassName={"modal-md"}
    >
      <Modal.Header closeButton>
        <div style={{fontSize: "1.5rem", fontWeight: "700"}}>{funnelSettingsModal.funnelId? "Alterar " : "Adicionar "} Funil</div>
      </Modal.Header>
      <Modal.Body className='mx-5 mx-xl-5'>
        <Row>
          <Col>
          <FunnelSettingsForm isLoading={false} funnel={data} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal></>
  )
}

export {FunnelSettingsEditModal}
