import { ID, Response} from '../../../../../_metronic/helpers'
export type Import = {
    id?:ID,
    status?: number
    archive?: string
    import_date?: Date
    entity?:number
    imported_by?:number
}

export type ImportQueryResponse = Response<Array<Import>>

export const IMPORT_ENTITY = {
    CARD: 'card',
    CONTACT: 'contact',
    COMPANY: 'company',
  };
  
  export const IMPORT_STATUS = {
    COMPLETED: 1,
    UNDONE: 2,
  };

  export const IMPORT_ENTITY_TYPE = {
    OPPORTUNITY: 1,
    TICKET: 2,
  };
