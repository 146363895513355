import {createContext, useContext, useReducer} from 'react'

export interface UserFormModalContextModel {
  open: boolean
  form_action: string | null
  UserId: number | null
}

const initialData = {
  open: false,
  UserId: null,
  form_action: null,
}

const UserFormModalContext = createContext<UserFormModalContextModel>(initialData)
const UserFormModalDispatchContext = createContext<any>(null)

export function UserFormModalProvider({children}: any) {
  const [data, dispatch] = useReducer(UserFormModalReducer, initialData)

  return (
    <UserFormModalContext.Provider value={data}>
      <UserFormModalDispatchContext.Provider value={dispatch}>
        {children}
      </UserFormModalDispatchContext.Provider>
    </UserFormModalContext.Provider>
  )
}

export function useUserFormModal() {
  return useContext(UserFormModalContext)
}

export function useUserFormModalDispatch() {
  return useContext(UserFormModalDispatchContext)
}

function UserFormModalReducer(data: any, action: any) {
  switch (action.type) {
    case 'open-add-modal': {
      return {...data, open: true, form_action: 'add'}
    }
    case 'open-edit-modal': {
      return {...data, open: true, UserId: action.data, form_action: 'edit'}
    }
    case 'close-modal': {
      return {...data, open: false, UserId: null, form_action: null}
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}
