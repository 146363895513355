import { request } from "../../../../app/services/crm-api/request";

const getListAttachments = async (query: string): Promise<any> => {
    return await request(
        {
            method: 'GET',
            url: `/attachment?${query}`,
        },
        {
            setAuth: true,
        }
    ).then((d: any) => d.data)
}

const getAttachment = async (attachment_id: any): Promise<any> => {
    return await request(
        {
            method: 'GET',
            url: `/attachment/${attachment_id}/download`,
            responseType: 'arraybuffer',
            headers: {
                'Accept': '*/*'
            }
        },
        {
            setAuth: true,
        }
    ).then((d: any) => {
        return d
    })
}
const deleteAttachment = async (id: number) => {
    return await request(
        {
            method: 'delete',
            url: `/attachment/${id}`,
        },
        {
            setAuth: true,
        }
    ).then(() => { })
}

const createAttachment = async (dataForm: any): Promise<any> => {
    return await request(
        {
            method: 'post',
            url: `/attachment`,
            data: dataForm,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        },
        {
            setAuth: true,
        }
    ).then((response: any) => response.data)
}
export {
    getListAttachments,
    getAttachment,
    deleteAttachment,
    createAttachment
}
