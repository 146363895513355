export default {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    height: '20px',
    minHeight: '20px',
    borderStyle: 'none',
    backgroundColor: undefined,
    boxShadow: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '20px',
    padding: '0 6px',
  }),
  input: (base: any) => ({
    ...base,
    margin: '0px',
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '20px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
}
