import {FC} from 'react'
import { TERMINATION_REASON_TYPES } from '../../core/_models'

type Props = {
  type?: number
}

const TypeCell: FC<Props> = ({type}) => {
  let dataReturn : string = "";
  switch(type){
    case TERMINATION_REASON_TYPES.WIN:
      dataReturn = "Ganho"
      break;
    case TERMINATION_REASON_TYPES.LOST:
      dataReturn = "Perda"
      break;
    case TERMINATION_REASON_TYPES.SOLVED:
      dataReturn = "Resolvido"
      break;
    case TERMINATION_REASON_TYPES.UNSOLVED:
      dataReturn = "Não Resolvido"
      break;
  }
  return <>{dataReturn}</>;
}

export {TypeCell}
