import {useEffect, useState} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import ptBRTimeStrings from 'react-timeago/lib/language-strings/pt-br'
import DetailMenuItemBadge from '../../../modules/view/MenuItemBadge'
import {useCompanyFormModal, useCompanyFormModalDispatch} from '../core/CompanyFormModalContext'
import {getCompanyById} from '../core/_requests'
import {CompaniesForm} from './CompaniesForm'
import {useNavigate} from 'react-router-dom'
import AnnotationComponent from '../../../../_metronic/helpers/annotation-component/Index'
import AttachmentComponent from '../../../../_metronic/helpers/attachament-component/Index'
import {getCustomForms} from '../../settings/custom-forms/core/_requests'
import {CUSTOM_FORM_TYPES} from '../../settings/custom-forms/core/_models'
import {getCardCustomForm} from '../../settings/custom-forms/core/_requests'
import TaskComponent from '../../../../_metronic/helpers/task-component/Index'
import {ButtonListAttachment} from '../../../../_metronic/helpers/attachament-component/styles'
import {getCards} from '../../cards/core/_requests'
import {FilterCompanyCardsDropdown} from './FilterCompanyCardsDropdown'
import {CARD_STATUS} from '../../cards/core/_models'
import {FilterCompanyTicketsDropdown} from './FilterCompanyTicketsDropdown'
const formatter = buildFormatter(ptBRTimeStrings)
const DetailMenu = ({company}: {company: any}) => {
  const [item, setItem] = useState<string>('1')

  return (
    <div className='d-flex flex-column'>
      <div className='mb-5'>
        <DetailMenuItemBadge item={'1'} activeItem={item} text='Anotações' onSelect={setItem} />
        <DetailMenuItemBadge item={'2'} activeItem={item} text='Tarefas' onSelect={setItem} />
        <DetailMenuItemBadge item={'3'} activeItem={item} text='Anexos' onSelect={setItem} />
      </div>
      <div className=''>
        {item == '1' ? <AnnotationComponent entityData={company} entity='company' /> : null}
        {item == '2' ? <TaskComponent entityData={company} entity='company' /> : null}
        {item == '3' ? <AttachmentComponent entityData={company} entity='company' /> : null}
      </div>
    </div>
  )
}

const CompaniesDetailModal = () => {
  const [cardsByCompany, setCardsByCompany] = useState<any>({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    has_more: false,
    data: [],
    filters: {
      status: null,
    },
  })
  const [ticketsByCompany, setTicketsByCompany] = useState<any>({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    has_more: false,
    data: [],
    filters: {
      status: null,
    },
  })
  const companyFormModal = useCompanyFormModal()
  const companyFormModalDispatch = useCompanyFormModalDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    companyFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/companies')
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['companies', 'detail', companyFormModal.companyId],
    queryFn: () => {
      return getCompanyById(companyFormModal.companyId)
    },
    enabled:
      companyFormModal.open &&
      companyFormModal.form_action == 'detail' &&
      companyFormModal.companyId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['companies', 'detail', 'complement-custom-forms', companyFormModal.companyId],
    queryFn: async () => {
      let auxData = await getCustomForms(`entity=company&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}`) //Complemento

      return auxData.data
    },
    enabled:
      companyFormModal.open &&
      companyFormModal.form_action == 'detail' &&
      companyFormModal.companyId &&
      data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  // const {
  //   isLoading: initialCustomFormIsLoadingCards,
  //   isError: initialCustomFormIsErrorCards,
  //   data: cardsByCompany,
  //   error: initialCustomFormErrorCards,
  // } = useQuery({
  //   queryKey: ['companies', 'detail', 'initial-custom-forms', companyFormModal.companyId],
  //   queryFn: () => {
  //     return getCards(1,`companyId=${companyFormModal.companyId}`);
  //   },
  //   enabled:
  //   companyFormModal.open &&
  //   companyFormModal.form_action == 'detail' &&
  //   companyFormModal.companyId &&
  //     data?.id
  //       ? true
  //       : false,
  //   cacheTime: 0,
  //   retry: 3,
  //   refetchOnWindowFocus: false,
  // })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['companies', 'detail', 'initial-custom-forms', companyFormModal.companyId],
    queryFn: () => {
      return getCardCustomForm('company', null, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled:
      companyFormModal.open &&
      companyFormModal.form_action == 'detail' &&
      companyFormModal.companyId &&
      data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const loadingDescriptionCards = (status: any) => {
    let label = ''
    switch (status) {
      case CARD_STATUS.WIN.id:
        label = CARD_STATUS.WIN.label
        break
      case CARD_STATUS.LOST.id:
        label = CARD_STATUS.LOST.label
        break
      case CARD_STATUS.SOLVE.id:
        label = CARD_STATUS.SOLVE.label
        break
      case CARD_STATUS.UNSOLVE.id:
        label = CARD_STATUS.UNSOLVE.label
        break
      case CARD_STATUS.FREEZE.id:
        label = CARD_STATUS.FREEZE.label
        break
      case CARD_STATUS.ARCHIVE.id:
        label = CARD_STATUS.ARCHIVE.label
        break
      default:
        label = CARD_STATUS.OPEN.label
        break
    }

    return label
  }
  const loadingTicketsByCompany = async (page = 1, limit = 10, status = null) => {
    let hasMore = false
    let resultData: any = [...ticketsByCompany?.data]
    let statusInputed = null
    try {
      let query = `companyId=${companyFormModal?.companyId}&page=${page}&perPage=${limit}`

      if (ticketsByCompany?.filters?.status?.value || status) {
        statusInputed = status ? status : ticketsByCompany?.filters?.status?.value
        query = query + `&status=${statusInputed}`
      }

      let data: any = await getCards(2, query)
      if (data?.data?.length > 0) {
        if (!statusInputed) {
          resultData = resultData.concat(data?.data)
        } else {
          resultData = data?.data
        }
      } else if (statusInputed) {
        resultData = []
      }

      if (page < data?.pagination?.pagesCount) {
        hasMore = true
      }

      let uniqueIds = new Set()
      let uniqueData = resultData.filter((item: any) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id)
          return true
        }
        return false
      })

      setTicketsByCompany({
        total_items: data?.pagination?.total,
        total_pages: data?.pagination?.pagesCount,
        current_page: data?.pagination?.page,
        data: uniqueData,
        has_more: hasMore,
      })
    } catch (e) {
      console.log(e)
    }
  }
  const loadingCardsByCompany = async (page = 1, limit = 10, status = null) => {
    let hasMore = false
    let resultData: any = [...cardsByCompany?.data]
    let statusInputed = null
    try {
      let query = `companyId=${companyFormModal?.companyId}&page=${page}&perPage=${limit}`

      if (cardsByCompany?.filters?.status?.value || status) {
        statusInputed = status ? status : cardsByCompany?.filters?.status?.value
        query = query + `&status=${statusInputed}`
      }

      let data: any = await getCards(1, query)
      if (data?.data?.length > 0) {
        if (!statusInputed) {
          resultData = resultData.concat(data?.data)
        } else {
          resultData = data?.data
        }
      } else if (statusInputed) {
        resultData = []
      }

      if (page < data?.pagination?.pagesCount) {
        hasMore = true
      }

      let uniqueIds = new Set()
      let uniqueData = resultData.filter((item: any) => {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id)
          return true
        }
        return false
      })

      setCardsByCompany({
        total_items: data?.pagination?.total,
        total_pages: data?.pagination?.pagesCount,
        current_page: data?.pagination?.page,
        data: uniqueData,
        has_more: hasMore,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (companyFormModal.open && companyFormModal.form_action == 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [companyFormModal.open])

  useEffect(() => {
    //limpando state anterior
    setCardsByCompany({
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      has_more: false,
      data: [],
      filters: {
        status: null,
      },
    })
    setTicketsByCompany({
      total_items: 0,
      total_pages: 0,
      current_page: 1,
      has_more: false,
      data: [],
      filters: {
        status: null,
      },
    })
  }, [companyFormModal.open])

  if (isError || !data) return <></>

  return (
    <>
      <Modal
        size='xl'
        show={companyFormModal.open && companyFormModal.form_action == 'detail'}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={'modal-entity-detail'}
      >
        <Modal.Header closeButton>
          <h1 style={{fontWeight: '700', color: '#606161'}}>Empresa #{data.id}</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{borderRight: '1px #e1e3ea solid', paddingRight: '1.5rem'}}>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail'>
                    Detalhes{' '}
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    loadingCardsByCompany()
                  }}
                >
                  <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_cards'>
                    Oportunidades
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    loadingTicketsByCompany()
                  }}
                >
                  <a className='nav-link' data-bs-toggle='tab' href='#card_tab_pane_tickets'>
                    Tickets
                  </a>
                </li>
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <li className='nav-item'>
                      <a
                        className='nav-link'
                        data-bs-toggle='tab'
                        href={'#company_tab_pane_complement' + form.id}
                      >
                        {form.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
              <div className='tab-content'>
                {initialCustomForm?.fields?.length > 0 ? (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                    key={initialCustomForm?.id}
                  >
                    <CompaniesForm
                      key={initialCustomForm?.id}
                      customFormId={initialCustomForm?.id}
                      isLoading={false}
                      company={data}
                    />{' '}
                  </div>
                ) : (
                  <div
                    className='tab-pane fade show active overflow-hidden'
                    id='card_tab_pane_detail'
                    role='tabpanel'
                  >
                    <CompaniesForm isLoading={false} company={data} />
                  </div>
                )}
                <div className='tab-pane fade show' id='card_tab_pane_cards' role='tabpanel'>
                  <Row>
                    <Col sm='6'>{/* <span>Oportunidades</span> */}</Col>
                    <Col sm='6' align='end'>
                      <FilterCompanyCardsDropdown
                        cardsByCompany={cardsByCompany}
                        setCardsByCompany={setCardsByCompany}
                        loadingCardsByCompany={loadingCardsByCompany}
                      />
                    </Col>
                  </Row>
                  {cardsByCompany?.data?.length <= 0 ? (
                    <div>
                      <span className='mt-2'>Sem oportunidades até o momento.</span>
                    </div>
                  ) : null}
                  {cardsByCompany?.data?.map((item: any, index: number) => (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{item?.title}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/opportunities/details/${item?.id}`)
                          }}
                        ></i>
                      </div>

                      <>
                        {`Funil: ${item?.funnel?.name}`}
                        <br />
                      </>

                      {/* {data.company.cnpj ? (
                          <>
                            {`CNPJ: ${data.company.cnpj}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )} */}
                      <div style={{display: 'flex', flexFlow: 'wrap'}}>
                        <div>
                          {' '}
                          {item?.createdAt ? (
                            <>
                              {`Criado em ${new Date(item?.createdAt).toLocaleString()}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            color: 'var(--wl-text-title-primary-collor)',
                            fontWeight: 'bold',
                          }}
                        >
                          {loadingDescriptionCards(item?.status)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {cardsByCompany?.has_more ? (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                      <button
                        className='btn btn-sm btn-wl-custom-primary-collor'
                        onClick={() => {
                          loadingCardsByCompany(cardsByCompany?.current_page + 1)
                        }}
                      >
                        Carregar Mais Oportunidades
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className='tab-pane fade show' id='card_tab_pane_tickets' role='tabpanel'>
                  <Row>
                    <Col sm='6'>{/* <span>Tickets</span> */}</Col>
                    <Col sm='6' align='end'>
                      <FilterCompanyTicketsDropdown
                        ticketsByCompany={ticketsByCompany}
                        setTicketsByCompany={setTicketsByCompany}
                        loadingTicketsByCompany={loadingTicketsByCompany}
                      />
                    </Col>
                  </Row>
                  {ticketsByCompany?.data?.length <= 0 ? (
                    <div>
                      <span className='mt-2'>Sem tickets até o momento.</span>
                    </div>
                  ) : null}
                  {ticketsByCompany?.data?.map((item: any, index: number) => (
                    <div className='entity-relation-card'>
                      <div className='d-flex justify-content-between'>
                        <h5>{item?.title}</h5>
                        <i
                          className='fa-solid fa-arrow-up-right-from-square'
                          style={{
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            color: 'var(--wl-btn-background-primary-collor)',
                          }}
                          onClick={() => {
                            navigate(`/tickets/details/${item?.id}`)
                          }}
                        ></i>
                      </div>

                      <>
                        {`Funil: ${item?.funnel?.name}`}
                        <br />
                      </>

                      {/* {data.company.cnpj ? (
                          <>
                            {`CNPJ: ${data.company.cnpj}`}
                            <br />
                          </>
                        ) : (
                          ''
                        )} */}
                      <div style={{display: 'flex', flexFlow: 'wrap'}}>
                        <div>
                          {' '}
                          {item?.createdAt ? (
                            <>
                              {`Criado em ${new Date(item?.createdAt).toLocaleString()}`}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          style={{
                            marginLeft: 'auto',
                            color: 'var(--wl-text-title-primary-collor)',
                            fontWeight: 'bold',
                          }}
                        >
                          {loadingDescriptionCards(item?.status)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {ticketsByCompany?.has_more ? (
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                      <button
                        className='btn btn-sm btn-wl-custom-primary-collor'
                        onClick={() => {
                          loadingTicketsByCompany(ticketsByCompany?.current_page + 1)
                        }}
                      >
                        Carregar Mais Tickets
                      </button>
                    </div>
                  ) : null}
                </div>
                {/* <div
                  className='tab-pane fade show active overflow-hidden'
                  id='card_tab_pane_detail'
                  role='tabpanel'
                >
                  {' '}
                  <CompaniesForm isLoading={false} company={data} />
                </div> */}
                {complementCustomForms?.map((form: any, index: number) => {
                  return (
                    <div
                      className={'tab-pane fade show'}
                      id={'company_tab_pane_complement' + form.id}
                      role='tabpanel'
                      key={form.id}
                    >
                      <>{form.name}</>
                      <CompaniesForm isLoading={false} customFormId={form.id} company={data} />
                    </div>
                  )
                })}
              </div>
            </Col>
            <Col style={{paddingLeft: '1.5rem'}}>
              <DetailMenu company={data} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CompaniesDetailModal}
