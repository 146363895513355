import {useEffect} from 'react'
import {useQuery, useMutation} from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {useTeamFormModal, useTeamFormModalDispatch} from '../core/TeamFormModalContext'
import {getTeamById} from '../core/_requests'
import {TeamForm} from './TeamForm'
import {useNavigate} from 'react-router-dom'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'

const TeamFormModal = () => {
  const {refetch} = useQueryResponse()
  const navigate = useNavigate()
  const userFormModal = useTeamFormModal()
  const userFormModalDispatch = useTeamFormModalDispatch()

  const handleClose = () => {
    userFormModalDispatch({
      type: 'close-modal',
    })
    navigate('/teams')
    refetch()
  }

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['teams', userFormModal.TeamId],
    queryFn: () => {
      return getTeamById(userFormModal.TeamId)
    },
    enabled:
      userFormModal.open && userFormModal.form_action != 'detail' && userFormModal.TeamId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (userFormModal.open && userFormModal.form_action != 'detail') {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [userFormModal.open])

  if (!userFormModal.open) return null

  if (isError || (userFormModal.TeamId && !data) || userFormModal.form_action == 'detail')
    return null

  return (
    <>
      <Modal
        show={userFormModal.open}
        onHide={handleClose}
        centered
        scrollable
        contentClassName={''}
        dialogClassName={'modal-md'}
      >
        <Modal.Header closeButton>
          <div style={{fontSize: '1.5rem', fontWeight: '700'}}>
            {userFormModal.TeamId ? 'Alterar ' : 'Adicionar '} Equipe
          </div>
        </Modal.Header>
        <Modal.Body className='mx-5 mx-xl-5'>
          <Row>
            <Col>
              <TeamForm isLoading={false} team={data} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {TeamFormModal}
