import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl, KTIcon} from '../../../../../_metronic/helpers'
import {initialLabel, Label} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../../../../modules/view/ListViewProvider'
import {ListLoading} from '../../../../modules/view/ListLoading'
import {createLabel, updateLabel} from '../core/_requests'
import {useQueryResponse} from '../../../../modules/view/QueryResponseProvider'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Swal from 'sweetalert2'

type Props = {
  isLoading: boolean
  label: Label
}

const editDataSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 Caracteres')
    .max(50, 'Máximo 50 Caracteres')
    .required('Nome é obrigatório'),
  color: Yup.string().required('Nome é obrigatório')
})

const LabelEditModalForm: FC<Props> = ({label, isLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [dataForEdit] = useState<Label>({
    ...label,
    name: label.name || initialLabel.name,
    color: label.color || initialLabel.color,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateLabel(values)
        } else {
          await createLabel(values)
        }
        cancel(true)
      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao salvar a etiqueta.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
          <InputFieldSet
            label={<>{"Nome da Etiqueta "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
          >
      
              <input
                placeholder=''
                {...formik.getFieldProps('name')}
                type='text'
                name="name"
                className={clsx(
                  'form-control fieldset-input',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
          </InputFieldSet>
          {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='fv-row mb-7'>
          <InputFieldSet
            label={<>{"Cor "}<i className="fa-solid fa-asterisk" style={{color: "red", fontSize: "0.65rem", verticalAlign: "top"}}></i></>}
          >
              <input
                placeholder=''
                {...formik.getFieldProps('color')}
                type='color'
                name="color"
                className={ 'form-control fieldset-input'}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
          </InputFieldSet>
          </div>

          <div className='fv-row mb-7'>
          <InputFieldSet
            label={"Descrição"}
          >
              <textarea
                placeholder=''
                {...formik.getFieldProps('description')}
                name="description"
                className={ 'form-control fieldset-input'}
                autoComplete='off'
                style={{ paddingRight: "3.5rem" }}
                disabled={formik.isSubmitting || isLoading}
              />
          </InputFieldSet>
          </div>
 

          {label.id != undefined && (
          <div className='fv-row mb-7'>
          <InputFieldSet
            label={"Status"}
          >
      
          <select
          {...formik.getFieldProps('status')}
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-control form-select form-select-sm form-select-white fieldset-input'
              defaultValue='1'
            >
              <option value='1'>Ativo</option>
              <option value='0'>Inativo</option>
            </select>
       
          </InputFieldSet>
          </div>
          )}


          </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-sm btn-wl-custom-secondary-collor me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Fechar
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}

export {LabelEditModalForm}
