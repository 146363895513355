import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext
} from 'react'
import { CustomLayoutModel } from './_models'
import { getDomainCustomization } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'

const getDomain = () => {

  let url = window.location.hostname;
  url = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0];
  }

  return url;
}

type CustomLayoutContextProps = {
  customLayout: CustomLayoutModel | undefined
  saveCustomLayout: (customLayout: CustomLayoutModel | undefined) => void
}

const initCustomLayoutContextPropsState = {
  customLayout: undefined,
  saveCustomLayout: () => { },
}

const CustomLayoutContext = createContext<CustomLayoutContextProps>(initCustomLayoutContextPropsState)

const useCustomLayout = () => {
  return useContext(CustomLayoutContext)
}

/* //Usando localStorage. Mas nao funfa
const CustomLayoutProvider: FC<WithChildren> = ({ children }) => {
  const [customLayout, setCustomLayout] = useState<CustomLayoutModel | undefined>(authHelper.getCustomLayout())

  const saveCustomLayout = (customLayoutObj: CustomLayoutModel | undefined) => {
    setCustomLayout(customLayoutObj)
    if (customLayoutObj) {
      authHelper.setCustomLayout(customLayoutObj)
    } else {
      authHelper.removeCustomLayout()
    }
  }

  return (
    <CustomLayoutContext.Provider value={{ customLayout, saveCustomLayout }}>
      {children}
    </CustomLayoutContext.Provider>
  )
}
*/

const CustomLayoutProvider: FC<WithChildren> = ({ children }) => {
  const [customLayout, setCustomLayout] = useState<CustomLayoutModel | undefined>()

  const saveCustomLayout = (customLayoutObj: CustomLayoutModel | undefined) => {
    setCustomLayout(customLayoutObj)
  }

  return (
    <CustomLayoutContext.Provider value={{ customLayout, saveCustomLayout }}>
      {children}
    </CustomLayoutContext.Provider>
  )
}

const Loading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>Inicializando...</div>
}

const DomainNotFound = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}>
    Domínio não identificado.<br />
    Entre em contato com a equipe de suporte.<br /><br />
    <a href="#" onClick={() => window.location.reload()}>Clique para recarregar a página</a>
  </div>
}

const setInnerHTML = (elm: any, html: any) => {
  elm.innerHTML = html;

  Array.from(elm.querySelectorAll("script"))
    .forEach((oldScriptEl: any) => {
      const newScriptEl = document.createElement("script");

      Array.from(oldScriptEl.attributes).forEach((attr: any) => {
        newScriptEl.setAttribute(attr.name, attr.value)
      });

      const scriptText = document.createTextNode(oldScriptEl.innerHTML);
      newScriptEl.appendChild(scriptText);

      oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
    });
}

const CustomLayoutInit: FC<WithChildren> = ({ children }) => {
  const { customLayout, saveCustomLayout } = useCustomLayout()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {

    const searchParams = new URLSearchParams(window.location.search)
    const layoutDarkMode = searchParams.get('layoutDarkMode');

    if(layoutDarkMode)
    {
      document.body.classList.add("dark-mode");
    }

    let loadDomainCustomization = async () => {

      try {
        
       // console.log("loadDomainCustomization");

        let domain = getDomain();
        
        await require("./custom.css")

        let customization = await getDomainCustomization(domain);

        if (customization?.data) {
          if(customization.data.style){
            const cssContent = customization.data.style;
            const styleElement = document.createElement('style');
            styleElement.innerHTML = cssContent;
            document.head.appendChild(styleElement);
          }
          let configCustom = Object.assign({}, customLayout);

          configCustom.id = customization.data.id;
          configCustom.useEmbed = customization.data.useEmbed;
          configCustom.domain = customization.data.domain;
          configCustom.logoUrl = customization.data.logoUrl;
          configCustom.logoSmallUrl = customization.data.logoSmallUrl;
          configCustom.crmName = customization.data.crmName;

          if (configCustom.logoSmallUrl) {
            const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement
            if (favicon) {
              favicon.href = configCustom.logoSmallUrl
            }
          }

          if (configCustom.crmName) {
            document.title = configCustom.crmName;
          }

          if (customization.data.tagsAppend) {
            let tagsAppend = customization.data.tagsAppend;
            let tagSection = document.getElementById("tagsection");
            if (tagSection) setInnerHTML(tagSection, tagsAppend);
          }

          saveCustomLayout(configCustom)
        }
      }
      catch (e) {
        console.log(e);
      }
      finally {
        setShowSplashScreen(false)
      }
    }

    if (!customLayout) loadDomainCustomization(); //Se ainda não tiver customização, carrega do domínio
    else if (showSplashScreen) setShowSplashScreen(false) //Se já tiver customização, não mostra splash

  }, [])

  if (showSplashScreen) return <Loading />;
  if (!showSplashScreen && !customLayout) return <DomainNotFound />;
  return <>{children}</>
}

export { CustomLayoutProvider, CustomLayoutInit, useCustomLayout }
