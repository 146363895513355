/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {CardFormModalProvider} from '../cards/core/CardFormModalContext'
import {CardDetailModal} from '../cards/detail-modal/CardDetailModal'
import {CardsFormModal} from '../cards/edit-modal/CardsFormModal'
import {FunnelProvider} from './core/FunnelContext'
import {CardFreezeModalProvider} from '../cards/core/CardFreezeModalContext'
import {CardFreezeModal} from '../cards/components/freeze-modal/CardFreezeModal'
import {CardTransferResponsibleModalProvider} from '../cards/core/CardTransferResponsibleModalContext'
import {CardTransferResponsibleModal} from '../cards/components/transfer-responsible-modal/CardTransferResponsibleModal'
import {CardArchiveModalProvider} from '../cards/core/CardArchiveModalContext'
import {CardArchiveModal} from '../cards/components/archive-modal/CardArchiveModal'
import {CardMoveModalProvider} from '../cards/core/CardMoveModalContext'
import {CardMoveModal} from '../cards/components/move-card-modal/CardMoveModal'
import {useLocation} from 'react-router-dom'
import {CardMarkModalProvider} from '../cards/core/CardMarkModalContext'
import {CardMarkModal} from '../cards/components/mark-modal/CardMarkModal'
import FunnelPage from './FunnelPage'

const FunnelWrapper: FC = () => {
  const location = useLocation()
  return (
    <>
      <CardFormModalProvider>
        <CardArchiveModalProvider>
          <CardFreezeModalProvider>
            <CardTransferResponsibleModalProvider>
              <CardMarkModalProvider>
                <CardMoveModalProvider>
                  {/* <SocketProvider> */}
                  <FunnelProvider>
                    <CardsFormModal />
                    <CardDetailModal />
                    <CardFreezeModal />
                    <CardTransferResponsibleModal />
                    <CardMarkModal />
                    <CardArchiveModal />
                    <CardMoveModal />
                    <FunnelPage location={location} />
                  </FunnelProvider>
                  {/* </SocketProvider> */}
                </CardMoveModalProvider>
              </CardMarkModalProvider>
            </CardTransferResponsibleModalProvider>
          </CardFreezeModalProvider>
        </CardArchiveModalProvider>
      </CardFormModalProvider>
    </>
  )
}

export {FunnelWrapper}
