import { format } from "date-fns";

export function formatCustomFieldInputDataToDb(fieldInput: any, customFields: any) {
  try {

    //console.log("fieldInput", fieldInput);
    let findCustomField = customFields?.find((e:any) => e.id == fieldInput.id);

    if (findCustomField?.type?.key === 'date') {
      if (isNaN(fieldInput.value?.getTime())) {
        return undefined;
      }
      return  format(fieldInput.value, 'yyyy-MM-dd');
    } else if (findCustomField?.type?.key === 'time') {
      if (isNaN(fieldInput.value?.getTime())) {
        return undefined;
      }
      return  format(fieldInput.value, 'HH:mm:ss');
    } else if (findCustomField?.type?.key === 'datetime') {
      if (isNaN(fieldInput.value?.getTime())) {
        return undefined;
      }
      return  format(fieldInput.value, 'yyyy-MM-dd HH:mm:ss');
    }

    return fieldInput.value; 
  } catch (e) {
    console.log('Erro formatCustomFieldInputDataToDb', e);
    return undefined;
  }
}