import { FC, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ListLoading } from '../../../../modules/view/ListLoading'
import Swal from 'sweetalert2'
import { InputFieldSet } from '../../../../../_metronic/partials/inputs/InputFieldSet'
import Select from 'react-select';
import { useFunnelDispatch } from '../../../funnel/core/FunnelContext'
import { useCardMoveModalDispatch } from '../../core/CardMoveModalContext'
import { moveCard, getFunnelStages } from '../../core/_requests'

type Props = {
  card: any
}

const editDataSchema = Yup.object().shape({

})

const CardMoveModalForm: FC<Props> = ({ card }) => {

  const funnelDispatch = useFunnelDispatch();
  const cardMoveModalDispatch = useCardMoveModalDispatch();

  const [dataForEdit] = useState<any>({
    funnelStepId: card.funnelStep ? { label: card.funnelStep.name, value: card.funnelStep.id } : null
  })

  const [steps, setSteps] = useState<any>([])

  const formik = useFormik({
    initialValues: dataForEdit,
    validationSchema: editDataSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      if (!values.funnelStepId || !values.funnelStepId.value) Swal.fire({
        title: 'Opss..',
        text: 'Preencha os Campos corretamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      })

      try {

        let opUpdated = await moveCard(card.id, {
          funnelId: card.funnelId,
          stepId: values.funnelStepId.value,
        });

        if (opUpdated) {
          let newCardData = { ...card, funnelStepId: values.funnelStepId.value }
          if (funnelDispatch) {

            funnelDispatch({
              type: 'move-step-card',
              data: newCardData,
              oldStepId: card.funnelStepId,
            })
          }
        }

        cardMoveModalDispatch({
          type: "close-modal"
        })

        Swal.fire('Registro movido!', '', 'success')

      } catch (ex) {
        console.error(ex)
        Swal.fire({
          title: 'Opss..',
          text: 'Houve um problema ao mover o registro.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {

    const loadStepsOptions = async () => {
      try {
        let list: Array<any> = [];
        let funnelStages = await getFunnelStages(card.funnelId, 'page=1&perPage=100')
        if (funnelStages && funnelStages != undefined) {
          for (let i = 0; i < funnelStages?.length; i++) {
            list.push({
              label: funnelStages[i].title,
              value: funnelStages[i].id,
            })
          }
        }
  
        setSteps(list);
      } catch (e) { console.log(e) }
    };

    loadStepsOptions();

  }, [])

  return (
    <>
      <form id='kt_modal_freeze_opportunity' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_freeze_opportunity_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_freeze_opportunity_header'
          data-kt-scroll-wrappers='#kt_modal_freeze_opportunity_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}

          <div className='fv-row mb-7'>
            <InputFieldSet
              label={"Etapa do Funil"}
            >
              <Select
                menuPortalTarget={document.body}
                placeholder={""}
                name="funnelStepId"
                options={steps}
                isClearable={true}
                value={formik.values.funnelStepId}
                onChange={selectedOption =>
                  formik.setFieldValue("funnelStepId", selectedOption ? selectedOption : null)
                }
                className="fieldset-input"
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderStyle: "none",
                    backgroundColor: undefined,
                    boxShadow: "none",
                  })
                }}
              />
            </InputFieldSet>
          </div>

        </div>

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-10'>
          <button
            type='submit'
            className='btn btn-sm btn-wl-custom-primary-collor'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Mover</span>
            {(formik.isSubmitting) && (
              <span className='indicator-progress'>
                Por Favor Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting) && <ListLoading />}
    </>
  )
}

export { CardMoveModalForm }
