import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, AUTH_LOCAL_STORAGE_KEY } from './modules/auth'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import pt_BR from 'date-fns/locale/pt-BR';
import { getNotifications, markNotificationAsRead } from './services/crm-api/notifications'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'

const App = () => {

  const navigate = useNavigate()

  useEffect(() => {

    let intervalMinutes = 1;

    const addMinutes = (dateOrig: any, minutes: any) => {

      const date = new Date(dateOrig);

      date.setTime(date.getTime() + minutes * 60 * 1000);

      return date;
    }

    const checkNewNotifications = async () => {

      try {

        let authStorage = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
        if (!authStorage) {
          return;
        }

        let start = new Date();
        let end = addMinutes(start, 30);
        let urlQuery = `&date_start=${start.toISOString()}&date_end=${end.toISOString()}`;

        let ntfs = await getNotifications(`page=1&perPage=5&key=CREATED_BY_TASK&read=0${urlQuery}`);

        if (ntfs.data?.length > 0) {
          for (let i = 0; i < ntfs.data.length; i++) {
            let useNotf = ntfs.data[i];

            toast.info("Lembrete: " + useNotf.description + " às " + new Date(useNotf.date).toLocaleTimeString("pt-BR", {
              hour: "2-digit",
              hour12: false,
              minute: "2-digit",
            }), {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });

            try {
              await markNotificationAsRead(useNotf.id);
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    };

    let interval = setInterval(checkNewNotifications, intervalMinutes * 60 * 1000);

  }, [])

  const RouterEvents = () => {
    const location = useLocation();

    let urlSearchParams = "";
    if (location && location.search) {
      let objSearchParams = new URLSearchParams(location.search)
      if (objSearchParams) {
        objSearchParams.delete('tk')
        urlSearchParams = objSearchParams.toString();
      }
    }

    useEffect(() => {
      if (location.pathname != "/") {
        if (window.parent) {
          window.parent.postMessage(`{"origin": "trictionCRM", "route": { "pathname": "${location.pathname}", "search": "${urlSearchParams}"}}`, '*')
        }
      }
    }, [location]);

    return <></>;
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt_BR}>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <RouterEvents />
            </AuthInit>
          </LocalizationProvider>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
    </Suspense>
  )
}

export { App }
