/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'

import {KTCard} from '../../../../_metronic/helpers'
import {ListViewProvider} from '../../../modules/view/ListViewProvider'
import {QueryRequestProvider} from '../../../modules/view/QueryRequestProvider'
import {QueryResponseProvider} from '../../../modules/view/QueryResponseProvider'
import {TeamsListHeader} from './components/header/TeamsListHeader'
import {TeamsTable} from './table/TeamsTable'
import {TeamFormModal} from './edit-modal/TeamFormModal'
import {getTeams} from './core/_requests'
import {TeamFormModalProvider, useTeamFormModalDispatch} from './core/TeamFormModalContext'
import {useLocation} from 'react-router-dom'

const TeamPage = () => {
  const teamFormModalDispatch = useTeamFormModalDispatch()
  const location = useLocation()

  //Analisar URL
  useEffect(() => {
    const regex = /^\/teams\/(details|edit)\/([^/]+)$/
    const match = location?.pathname?.match(regex)

    if (match) {
      const action =
        match[1] === 'details' ? 'open-detail-modal' : match[1] === 'edit' ? 'open-edit-modal' : ''
      const parameter = match[2]
      if (parameter && parameter !== '') {
        teamFormModalDispatch({
          type: action,
          data: parameter,
        })
      }
    }
    if (location?.pathname == '/teams/add') {
      teamFormModalDispatch({
        type: 'open-add-modal',
      })
    }
  }, [location?.pathname])

  return (
    <>
      <TeamsListHeader />
      <KTCard>
        <TeamsTable />
      </KTCard>
      <TeamFormModal />
    </>
  )
}

const Teams: FC = () => {
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider listRequest={getTeams}>
          <ListViewProvider>
            <TeamFormModalProvider>
              <TeamPage />
            </TeamFormModalProvider>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {Teams}
