import {FC} from 'react'

type Props = {
  contact?: any
}

const ContactCell: FC<Props> = ({contact}) => {

  let text = "";

  if(contact != undefined)
  {
    text = contact.name;
  }
  
  return <>{text}</>;
}

export {ContactCell}
