import {useEffect} from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTicketCategoryFormModal, useTicketCategoryFormModalDispatch } from '../core/TicketCategoryFormModalContext'
import { getTicketCategoryById } from '../core/_requests';
import { TicketCategoriesForm } from './TicketCategoriesForm'

const TicketCategoriesEditModal = () => {
  const ticketCategoryFormModal = useTicketCategoryFormModal();
  const ticketCategoryFormModalDispatch = useTicketCategoryFormModalDispatch()

  const handleClose = () => {
    ticketCategoryFormModalDispatch({
      type: 'close-modal'
    })
  };

  console.log("ticketCategoryFormModal", ticketCategoryFormModal);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['ticket-categories', ticketCategoryFormModal.categoryId],
    queryFn: () => {
      return getTicketCategoryById(ticketCategoryFormModal.categoryId);
    },
    enabled: ticketCategoryFormModal.categoryId ? true : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {

    if (ticketCategoryFormModal.open) {

      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }

  }, [ticketCategoryFormModal.open])


  if (isError || (ticketCategoryFormModal.categoryId && !data)) return <></>

  return (<>

    <Modal
      show={ticketCategoryFormModal.open}
      onHide={handleClose}
      centered
      scrollable
      contentClassName={""}
      dialogClassName={"modal-md"}
    >
      <Modal.Header closeButton>
        <div style={{fontSize: "1.5rem", fontWeight: "700"}}>{ticketCategoryFormModal.categoryId? "Alterar " : "Adicionar "} Categoria de Tícket</div>
      </Modal.Header>
      <Modal.Body className='mx-5 mx-xl-5'>
        <Row>
          <Col>
          <TicketCategoriesForm isLoading={false} category={data} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal></>
  )
}

export {TicketCategoriesEditModal}
