import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CARD_TYPES } from '../core/_models'
import { useCardFormModalDispatch } from '../core/CardFormModalContext'
import { CardFormModalProvider } from '../core/CardFormModalContext'
import { CardFormAddPrimaryData } from '../edit-modal/CardFormAddPrimaryData'
import { CardsFormTabsEmbed } from './CardsFormTabsEmbed'

const CardsFormEmbed = () => {

  const [locationData, setLocationData] = useState<any>({
    cardType: null,
    action: null,
    customData: null,
    formData: null
  })

  const cardFormModalDispatch = useCardFormModalDispatch();
  const location = useLocation()

  useEffect(() => {
    const regex = /^\/embed\/(opportunities|tickets)\/(edit|add)\/*([^/]+)*$/
    const match = location?.pathname?.match(regex)

    let queryVars: any = Object.fromEntries(new URLSearchParams(location.search));

    let cardType = null;
    let customData = null;
    let action = null;

    if(queryVars) customData = queryVars;

    if (match && match.length > 0) {

      switch (match[1]) {
        case 'opportunities':
          cardType = CARD_TYPES.OPPORTUNITY.id;
          break;
        case 'tickets':
          cardType = CARD_TYPES.TICKET.id;
          break;
      }

      switch (match[2]) {
        case 'edit':
          action = 'open-edit-modal';
          break;
        case 'add':
          action = 'open-add-primary-data-modal';
          break;
      }

      setLocationData({
        cardType: cardType,
        action: action,
        customData: customData
      })

      if (action == 'open-edit-modal') {
        const parameter = match[3]
        if (parameter && parameter !== '') {
          cardFormModalDispatch({
            type: action,
            data: {
              id: parameter,
              type: cardType,
              customData: customData
            }
          })
        }
      }

    }
    
  }, [location?.pathname])

  const handleSave = (data: any) => {
    
      setLocationData({
        ...locationData,
        formData: data
      })

      setTimeout(() => {
        cardFormModalDispatch({
          type: 'open-add-modal',
          data: data
        })
      })
  }

  if(locationData.action == 'open-add-primary-data-modal' && !locationData.formData)
  {
    return <CardFormAddPrimaryData data={locationData} onSave={handleSave} isEmbed={true} />
  }
  
  return <></>
}

const CardsFormEmbedPage = () => {

  return <div className='form-section'>
  
    <CardFormModalProvider>
      <CardsFormEmbed />
      <CardsFormTabsEmbed />
    </CardFormModalProvider>
  </div>
}


export { CardsFormEmbedPage }
