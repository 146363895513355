import { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useCardFormModal, useCardFormModalDispatch } from '../core/CardFormModalContext'
import { getCardById } from '../core/_requests'
import { CARD_TYPES } from '../core/_models'
import { CardsForm } from '../edit-modal/CardsForm'
import { useNavigate } from 'react-router-dom'
import { getCustomForms } from '../../settings/custom-forms/core/_requests'
import { CUSTOM_FORM_TYPES } from '../../settings/custom-forms/core/_models'
import { getCardCustomForm } from '../../settings/custom-forms/core/_requests'
import { ListLoading } from '../../../modules/view/ListLoading'
import { useQueryResponse } from '../../../modules/view/QueryResponseProvider'

const CardsFormTabsEmbed = () => {
  const cardFormModal = useCardFormModal()
  const cardFormModalDispatch = useCardFormModalDispatch()
  const navigate = useNavigate()
  const { refetch } = useQueryResponse()
  const queryClient = useQueryClient()

  const [formSaveCount, setFormSaveCount] = useState(0)

  //console.log("CardsFormTabsEmbed");

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['cards', 'edit', cardFormModal.cardId],
    queryFn: () => {
      return getCardById(cardFormModal.cardId)
    },
    enabled:
      cardFormModal.open && cardFormModal.formAction != 'detail' && cardFormModal.cardId
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: complementCustomFormsIsLoading,
    isError: complementCustomFormsIsError,
    data: complementCustomForms,
    error: complementCustomFormsError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'complement-custom-forms', cardFormModal.cardId],
    queryFn: async () => {
      let funnelId = null
      if (data?.funnelId) {
        funnelId = data.funnelId
      } else if (cardFormModal?.funnelId) {
        funnelId = cardFormModal?.funnelId
      }
      if (!funnelId) return null
      let auxData = await getCustomForms(
        `entity=card&type=${CUSTOM_FORM_TYPES.COMPLEMENT.id}&funnelId=${funnelId}`
      ) //Complemento

      return auxData.data
    },
    enabled:
      cardFormModal.open && (cardFormModal.formAction == 'edit' || cardFormModal.formAction == 'duplicate') && cardFormModal.cardId && data?.id
        ? true
        : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: initialCustomFormIsLoading,
    isError: initialCustomFormIsError,
    data: initialCustomForm,
    error: initialCustomFormError,
  } = useQuery({
    queryKey: ['cards', 'detail', 'initial-custom-forms', cardFormModal.cardId],
    queryFn: () => {
      let funnelId = null
      if (data?.funnelId) {
        funnelId = data.funnelId
      } else if (cardFormModal?.funnelId) {
        funnelId = cardFormModal?.funnelId
      }

      if (!funnelId) return null

      return getCardCustomForm('card', funnelId, CUSTOM_FORM_TYPES.INITIAL.id)
    },
    enabled:
      (cardFormModal?.formAction == 'edit' || cardFormModal?.formAction == 'duplicate') && data?.id
        ? true
        : cardFormModal?.formAction == 'add' && cardFormModal.open
          ? true
          : false,
    cacheTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  })

  const handleSave = (data: any) => {

    if (cardFormModal?.formAction == 'add' || !cardFormModal.customData?.formSettingsKeepOpenOnUpdate) {
      cardFormModalDispatch({
        type: 'close-modal',
      })
    }
    else if (cardFormModal?.formAction == 'edit') {
      queryClient.invalidateQueries(['cards', 'edit', cardFormModal.cardId])
     
    }

    if (window.parent) {
      window.parent.postMessage(`{"origin": "trictionCRM", "event": "cardSaved", "data":${JSON.stringify(data)}}`, '*')
    }
  }

  useEffect(() => {
    setTimeout(() => setFormSaveCount(formSaveCount + 1))
  }, [data, data?.updatedAt])

  if ((cardFormModal.formAction == 'edit' || cardFormModal.formAction == 'duplicate') && !data) return null

  if (!cardFormModal.open || cardFormModal.formAction == 'detail') return null

  if (isError) return null

  if (
    (isLoading || complementCustomFormsIsLoading || initialCustomFormIsLoading) &&
    (cardFormModal.open && cardFormModal.formAction != 'add')
  )
    return <ListLoading />

  return (
    <>
      <Row>
        <Col>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#card_tab_pane_detail' style={{ marginLeft: "1rem" }}>
                Cadastro{' '}
              </a>
            </li>
            {cardFormModal.formAction == 'edit'
              ? complementCustomForms?.map((form: any, index: number) => {
                return (
                  <li className='nav-item' key={index}>
                    <a
                      className='nav-link'
                      data-bs-toggle='tab'
                      href={'#card_tab_pane_complement' + form.id}
                    >
                      {form.name}
                    </a>
                  </li>
                )
              })
              : null}
          </ul>
          <div className='tab-content'>
            {initialCustomForm?.fields?.length > 0 ? (
              <div
                className='tab-pane fade show active'
                id='card_tab_pane_detail'
                role='tabpanel'
              >
                <CardsForm
                  key={"initialCustomForm" + initialCustomForm?.id + formSaveCount}
                  customFormId={initialCustomForm?.id}
                  addData={cardFormModal.addData}
                  isLoading={false}
                  action={cardFormModal.formAction}
                  card={data}
                  cardtype={cardFormModal.cardType}
                  funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id}
                  onSave={handleSave}
                />{' '}
              </div>
            ) : (
              <div
                className='tab-pane fade show active'
                id='card_tab_pane_detail'
                role='tabpanel'
              >
                <CardsForm
                  key={"defaultForm" + formSaveCount}
                  addData={cardFormModal.addData}
                  isLoading={false}
                  card={data}
                  action={cardFormModal.formAction}
                  cardtype={cardFormModal.cardType}
                  funnelId={cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id}
                  onSave={handleSave}
                />
              </div>
            )}

            {complementCustomForms?.map((form: any, index: number) => {
              //console.log("formsss ", form.id, form.name);
              return (
                <div
                  className={'tab-pane fade show'}
                  id={'card_tab_pane_complement' + form.id}
                  role='tabpanel'
                  key={index}
                >
                  <CardsForm
                    key={"complement" + form.id + formSaveCount} //Necessário para atualizar o conteúdo do form quando o data mudar e colocar o parametro card no useeffect de dentro do cardform
                    isLoading={false}
                    addData={cardFormModal.addData}
                    customFormId={form.id}
                    action={cardFormModal.formAction}
                    card={data}
                    cardtype={cardFormModal.cardType}
                    funnelId={
                      cardFormModal.funnelId ? cardFormModal.funnelId : data?.funnel?.id
                    }
                    onSave={handleSave}
                  />
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
    </>
  )
}

export { CardsFormTabsEmbed }
