// @ts-nocheck
import {Column} from 'react-table'
import {StatusCell} from './StatusCell'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {AutomaticAction} from '../../core/_models'
import {TriggerIdCell} from './TriggerIdCell'

const tableColumns: ReadonlyArray<Column<AutomaticAction>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='ID' className='min-w-125px' sort={false} />
    ),
    id: 'id',
    // accessor: 'id',
    Cell: ({...props}) => {
      return props.data[props.row.index].id
    },
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Título' className='min-w-125px' />,
    id: 'title',
    // accessor: 'title',
    Cell: ({...props}) => {
      return props.data[props.row.index].title
    },
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title='Tipo de Gatilho'
        className='min-w-125px'
        sort={false}
      />
    ),
    id: 'triggerId',
    accessor: 'triggerId',
    Cell: ({...props}) => <TriggerIdCell triggerId={props.data[props.row.index].triggerId} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Status' className='min-w-125px' sort={false} />
    ),
    id: 'status',
    Cell: ({...props}) => <StatusCell status={props.data[props.row.index].status} />,
  },

  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index].status}
      />
    ),
  },
]

export {tableColumns}
