import React from 'react'
import { FC, useState, useRef, useCallback } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import FieldTypeIcon from './FieldTypeIcon'
import Dropdown from 'react-bootstrap/Dropdown';
import { useCustomFormEditor, useCustomFormEditorDispatch } from '../../core/CustomFormEditorContext'

interface FieldInputDraggableProps {
    id: any
    field: any
    index: number
    input: any
}

interface DragItem {
    index: number
    id: string
    type: string
}

const FieldInputDraggable: FC<FieldInputDraggableProps> = ({ id, field, index, input }) => {
    const customFormEditorDispatch = useCustomFormEditorDispatch();
    const ref = useRef<HTMLDivElement>(null)

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: "fieldInput",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            // moveCard(dragIndex, hoverIndex)
            customFormEditorDispatch({
                type: 'move-field',
                data: {
                    dragIndex,
                    hoverIndex,
                    formInternalId: field.formInternalId
                }
            })

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: "fieldInput",
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
        <div className="form-mount-field" ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            <div className="form-mount-field-header">
                <div className='d-flex flex-row align-items-center'>
                    <FieldTypeIcon type={field.type?.key} />
                    <strong style={{ marginLeft: "8px" }}>{field.label}</strong>
                </div>
                <Dropdown drop={"end"}>
                    <Dropdown.Toggle id={"dropdown-field-" + field.internalId} as={React.forwardRef(({ children, onClick }: { children: any, onClick: any }, ref: any) => (
                        <button
                            ref={ref}
                            disabled={false}
                            type='button'
                            onClick={onClick}
                            className='btn btn-sm form-mount-field-btn-edit'
                        >
                            <i className='fa-solid fa-ellipsis-vertical form-mount-field-btn-edit-icon'></i>
                        </button>
                    ))}>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() =>
                            customFormEditorDispatch({
                                type: 'open-config-field-modal',
                                data: field
                            })
                        }>Configurar</Dropdown.Item>
                        <Dropdown.Item onClick={() =>
                            customFormEditorDispatch({
                                type: 'remove-field',
                                data: field
                            })
                        }>Remover</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {input}

        </div>
    )
}

export default FieldInputDraggable;