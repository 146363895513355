/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login, getMe} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AuthInput from '../../../../_metronic/helpers/custom-inputs/AuthInput'
import {useAuth} from '../core/Auth'
import { useCustomLayout } from '../../layout/core/CustomLayout'
import { ScrollComponent, ToggleComponent } from '../../../../_metronic/assets/ts/components'


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de Email inválido.')
    .min(3, 'Mínimo de 3 caracteres.')
    .max(50, 'Máximo de 100 caracteres.')
    .required('Email é obrigatório.'),
  password: Yup.string()
    .min(3, 'Mínimo de 3 caracteres.')
    .max(50, 'Máximo de 100 caracteres')
    .required('Senha é obrigatório.'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {registerAuth, registerCurrentUser} = useAuth()
  const { customLayout } = useCustomLayout()

  const urlDomain = customLayout?.domain || "";

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password, urlDomain)
        registerAuth(auth)

        const userData = await getMe(auth.accessToken)
        if (userData?.data) {
          registerCurrentUser(userData?.data)
        }
        setTimeout(() => {
          ScrollComponent.bootstrap(); //força renderização do scroll
        }, 200)
      } catch (error) {
        console.error(error)
        registerAuth(undefined)
        setStatus('Email ou Senha incorretos')
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          Olá novamente!👋
          <p style={{fontSize: '13px', fontWeight: '500', alignItems: 'start'}}>Faça login</p>
        </h1>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>E-Mail</label>
        <AuthInput
          name='email'
          value={formik.values['email']}
          type='email'
          placeholder='Digite seu E-Mail'
          onChange={(e: any) => {
            formik.setFieldValue('email', e?.target?.value)
          }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {' '}
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
        <AuthInput
          name='password'
          value={formik.values['password']}
          type='password'
          placeholder='Digite sua senha'
          onChange={(e: any) => {
            formik.setFieldValue('password', e?.target?.value)
          }}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div style={{display: 'flex', flexFlow: 'row'}}>
        <div style={{marginLeft: 'auto'}}>
          {' '}
          <Link to={'/forgot-password'}>Esqueceu a senha?</Link>
        </div>
      </div>
      {/* className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )} */}
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-wl-custom-primary-collor' disabled={loading}>
          {!loading && <span className='indicator-label fw-bold'>Entrar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Processando solicitação...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
